import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import Stepper from 'bs-stepper';
import { AgmMap, MapsAPILoader } from '@agm/core';

import { MapStyles } from 'src/app/shared/models/map-style';
import { CommonService } from '../../services/common.service';
import { APIResponse, Status } from '../../models/api-response';
import { DynamicModel } from '../../models/dynamic-model';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CannedResponse, FileUploadResponse, uploadPhotoFilePath, WorkOrderDetail } from '../../models/work-order';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { MethodsForAllRoles } from '../../services/methods-for-all-roles.service';

@Component({
  selector: 'app-work-order-resident-fieldagent',
  templateUrl: './work-order-resident-fieldagent.component.html',
  styleUrls: ['./work-order-resident-fieldagent.component.css'],
})

export class WorkOrderResidentFieldagentComponent implements OnInit {
  apiUrl = environment.apiUrl;
  @Input() taskOrderList: any;
  workCategoryList = [];
  @Output() onWorkOrderUpdateResponse: EventEmitter<any>;
  statusList: Status[] = [];
  firstLine: boolean = false;
  secondLine: boolean = false;
  DynamicFieldsList: DynamicModel[] = [];
  address: string;
  latitude: number;
  longitude: number;
  zoom: number;
  public searchElementRef: ElementRef;
  @ViewChild(AgmMap, { static: true }) public agmMap: AgmMap;
  //#region - map style
  styles = MapStyles;
  iconURI = {
    url: '../../../../../assets/image/track-location.svg',
    scaledSize: {
      width: 100,
      height: 100,
    },
  };
  bsConfig: Partial<BsDatepickerConfig>;
  uploadBeforePhotoFilePath: uploadPhotoFilePath[] = [];
  uploadAfterPhotoFilePath: uploadPhotoFilePath[] = [];
  UploadedPhotoAfterIdsTemp: string[] = [];
  UploadedPhotoBeforeIdsTemp: string[] = [];
  minDate: Date;
  maxDate: Date;
  completeByInit: Date;
  cannedResponseList: CannedResponse[] = [];
  WorkOrderNotesDetail: WorkOrderDetail[] = [];
  @Input() WorkIdVal;
  private stepper: Stepper;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private commonService: CommonService,
    private authService: AuthenticationService,
    private location: Location,
    private methodsForAllRoles: MethodsForAllRoles,
  ) {
    this.onWorkOrderUpdateResponse = new EventEmitter<any>();
    this.bsConfig = Object.assign(
      {},
      { containerClass: 'theme-red', adaptivePosition: true }
    );
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.completeByInit = new Date();
    this.completeByInit.setDate(this.maxDate.getDate() + 30);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.taskOrderList.CompletedBy = this.completeByInit;
    this.taskOrderList.CreatedDate = moment(
      this.taskOrderList.CreatedDate
    ).format('DD/MM/YYYY');
    this.taskOrderList.CannedResponseId = this.taskOrderList.CannedResponseId
      ? this.taskOrderList.CannedResponseId
      : null;
    this.taskOrderList.UploadedPhotoBeforeIds
      ? this.UploadedPhotoBeforeIdsTemp.push(
        this.taskOrderList.UploadedPhotoBeforeIds
      )
      : (this.UploadedPhotoBeforeIdsTemp = []);
    this.taskOrderList.UploadedPhotoAfterIds
      ? this.UploadedPhotoAfterIdsTemp.push(
        this.taskOrderList.UploadedPhotoAfterIds
      )
      : (this.UploadedPhotoAfterIdsTemp = []);
    this.DynamicFieldsList = this.taskOrderList.DynamicFields;
    this.WorkOrderNotesDetail = this.taskOrderList.WorkOrderNotesDetail || [];
    if (this.taskOrderList && this.taskOrderList.WorkCategory) {
      this.getCannedResponseList(this.taskOrderList.WorkCategory);
    }
    this.loadPlaceMap();
    this.getBeforeFile(this.taskOrderList.UploadedDocPathsBefore);
    this.getAfterFile(this.taskOrderList.UploadedDocPathsAfter);
  }

  getBeforeFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadBeforePhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
  }

  getAfterFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadBeforePhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
  }

  createWorkOrder() {
    let beforeIds = '';
    let afterIds = '';
    if (
      this.UploadedPhotoBeforeIdsTemp &&
      this.UploadedPhotoBeforeIdsTemp.length > 0
    ) {
      beforeIds = this.UploadedPhotoBeforeIdsTemp.toString();
    }
    if (
      this.UploadedPhotoAfterIdsTemp &&
      this.UploadedPhotoAfterIdsTemp.length > 0
    ) {
      afterIds = this.UploadedPhotoAfterIdsTemp.toString();
    }
    let model = {
      CreatedBy: this.authService.currentLoginUserValue.Id,
      CompletedBy: this.taskOrderList.CompletedBy,
      WorkCategory: this.taskOrderList.WorkCategory,
      Notes: this.taskOrderList.Notes ? this.taskOrderList.Notes : '',
      DynamicFields: this.DynamicFieldsList,
      UploadedPhotoBeforeIds: this.UploadedPhotoBeforeIdsTemp
        ? beforeIds
        : this.taskOrderList.UploadedPhotoBeforeIds,
      UploadedPhotoAfterIds: this.UploadedPhotoAfterIdsTemp
        ? afterIds
        : this.taskOrderList.UploadedPhotoAfterIds,
      DocId: '',
      CannedResponseId: this.taskOrderList.CannedResponseId,
      CompletedAddress: this.taskOrderList.CompletedAddress
        ? this.taskOrderList.CompletedAddress
        : '',
    };
    this.onWorkOrderUpdateResponse.emit(model);
  }

  next(stepperVal) {
    this.commonService.setToDisplayBackBtn(true);
    if (stepperVal == 'first') {
      this.firstLine = true;
    } else if (stepperVal == 'second') {
      this.secondLine = true;
    }
    this.stepper.next();
  }

  previous() {
    this.firstLine = this.stepper['_currentIndex'] === 2;
    this.secondLine = this.stepper['_currentIndex'] === 2;
    this.stepper.previous();
  }

  onSubmit() {
    return false;
  }

  ngOnInit() {
    this.getWorkCategoryList();
    this.loadPlaceMap();
  }

  getCannedResponseList(category) {
    this.commonService
      .getCannedResponseList(category)
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data || [];
      });
  }

  //#region - On Change of work category
  onChangeOfWorkCategory(selectedWorkCategory) {
    this.getCannedResponseList(selectedWorkCategory);
  }

  getWorkCategoryList() {
    this.commonService.getWorkCategoryList().subscribe((res: APIResponse) => {
      this.workCategoryList = res.Data || [];
    });
  }

  loadPlaceMap() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
  }

  fileUploadResponseBefore(response: FileUploadResponse): void {
    this.UploadedPhotoBeforeIdsTemp.push(response.Data.DocumentID);
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    let tempFilesArr: uploadPhotoFilePath[];
    tempFilesArr = fileUploadResponse ? this.uploadBeforePhotoFilePath.concat(fileUploadResponse) : [];
    this.uploadBeforePhotoFilePath = tempFilesArr;

    if (response && response.photoLatitude) {
      this.latitude = response.photoLatitude;
    }
    if (response && response.photoLongitude) {
      this.longitude = response.photoLongitude;
    }
  }

  fileUploadResponseAfter(response: FileUploadResponse): void {
    this.UploadedPhotoAfterIdsTemp.push(response.Data.DocumentID);
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    let tempFilesArr: uploadPhotoFilePath[];
    tempFilesArr = fileUploadResponse ? this.uploadAfterPhotoFilePath.concat(fileUploadResponse) : [];
    this.uploadAfterPhotoFilePath = tempFilesArr;

    if (response && response.photoLatitude) {
      this.latitude = response.photoLatitude;
    }
    if (response && response.photoLongitude) {
      this.longitude = response.photoLongitude;
    }
  }

  onAddMoreFieldsResponse(response) {
    this.DynamicFieldsList.push(response);
  }

  backToLast() {
    this.location.back();
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(() => {
        this.latitude = this.taskOrderList.Latitude;
        this.longitude = this.taskOrderList.Longitude;
        this.zoom = 6;
      });
    }
  }
}
