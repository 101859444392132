<div
  class="button"
  (click)="click()"
  [class.disabled]="disabled"
  [class.outline]="outline"
  matRipple matRippleColor="#1D58A8"
  [style.height.px]="size === 'large' ? 49 : size === 'middle' ? 41 : size === 'small' ? 32 : 32"
  [style.paddingLeft.px]="size === 'large' ? 40 : size === 'middle' ? 20 : size === 'small' ? 15 : 15"
  [style.paddingRight.px]="size === 'large' ? 40 : size === 'middle' ? 20 : size === 'small' ? 15 : 15"
>
  <div
    *ngIf="loading"
  >
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
  <span *ngIf="!loading">
    <ng-content></ng-content>
  </span>
</div>
