import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { NotificationList } from 'src/app/shared/models/notification-list';
import { environment } from 'src/environments/environment';
import { of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '../../Users/main-users/shared/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: User;
  justUser: string;
  updateStatus: boolean = true;
  message;
  notificationList: NotificationList[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  notificationLength;
  photoURL: string;
  activeToggle: boolean;
  showBackBtn = false;

  constructor(
    private authenticationService: AuthenticationService,
    private msgService: MessagingService,
    private userService: UserService,
    private commonService: CommonService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) {
    this.commonService.toggleSidebarValue
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.activeToggle = value;
      });
  }

  get HeaderTitle(): any {
    return localStorage.getItem(environment.headerTitle);
  }

  get isSupervisor() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.SuperVisor
    );
  }

  get isFieldAgent() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.FieldAgent
    );
  }

  get isAdmin() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.Admin
    );
  }

  get isCommunityManager() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.CommunityManager
    );
  }

  ngOnInit(): void {
    // Check push notification for user
    this.msgService.receiveMessage();
    this.msgService.currentMessage

      .subscribe((updateMsg) => {
        if (updateMsg) {
          this.getNotificationByUserId();
        }
      });
    this.commonService.headerTitle$.subscribe((res: boolean) => res && this.getCurrentUser());
    this.getCurrentUser();
    // Get the value to display the btn for work order
    this.commonService.displayBackBtnObservables
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.showBackBtn = res;
      });
    this.commonService.changeStatusProfileSubjectObservables.subscribe(
      (value) => {
        if (value == true) {
          this.updateStatus = false;
          this.commonService
            .getProfileByUserId()
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: APIResponse) => {
              this.justUser = res.Data.FirstName + ' ' + res.Data.LastName;
            });
        }
      }
    );
  }

  markAll() {
    this.commonService
      .setMarkAllNotification(this.currentUser.Id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        if (res.Success) {
          this.notificationLength = 0;
        }
      });
  }

  getCurrentUser() {
    this.cdr.detectChanges();
    this.authenticationService.currentUser
      .pipe(
        switchMap((user: User) => {
          if(user !== null) {
            return this.userService.getUserLogin(user.Id);
          }
        }),
        catchError(error => {
          // Handle the error from the outer observable
          throwError(error);
          return of();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((value: User) => {
        this.currentUser = value['Data'] as User;
        if (this.currentUser) {
          this.photoURL = this.currentUser.DocPath;
          this.getNotificationByUserId();
        }
      });
  }

  // Get Notification by current logged in user
  getNotificationByUserId() {
    this.commonService
      .getNotificationByUserId()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        if (res && res.Success && res.Data) {
          this.notificationList = res.Data.Result;
          this.unreadNotificationList(this.notificationList);
        }
      });
  }

  /**
   * Update notification to make it readable
   * @param Id - Notification ID
   */
  updateNotification(Id, index) {
    const updateNotifyObject = {
      NotificationId: Id,
      Read: true
    };
    this.commonService
      .updateNotificationById(updateNotifyObject)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        if (res && res.Success && res.Data) {
          this.notificationList.filter((x) => {
            if (x.NotificationId === Id) {
              return (x.Read = true);
            }
          });
          this.unreadNotificationList(this.notificationList);
          // redirect logic here
          const redirectURL = this.notificationList[index];

          this.isSupervisor
            ? this.commonService.navigateByURL(redirectURL.Title)
            : this.commonService.navigateByURL(redirectURL.Title);
        }
      });
  }

  /**
   * Get the unread notification list
   * @param notifyListObject: Actual notification list
   */
  unreadNotificationList(notifyListObject) {
    if (notifyListObject) {
      const notifiedList = [];
      notifyListObject.filter((x) => {
        if (x.Read === false) {
          return notifiedList.push(x);
        }
      });
      this.notificationLength = notifiedList ? notifiedList.length : 0;
    }
  }

  toggleSidebar() {
    this.commonService.setToggleSidebar(!this.activeToggle);
  }

  backToPrevious() {
    this.commonService.setToDisplayBackBtn(false);
    this.location.back();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
