import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-file-upload-zone',
  templateUrl: './file-upload-zone.component.html',
  styleUrls: ['./file-upload-zone.component.scss']
})
export class FileUploadZoneComponent implements OnInit {
  public fileName: string | null = null;

  @Output()
  public file: EventEmitter<File> = new EventEmitter<File>();

  public hoverWithFile: boolean;
  public fileLoad: boolean = false;

  public uploadForm = new FormGroup({
    file: new FormControl(null),
  });

  constructor() {
  }

  ngOnInit(): void {
  }

  public clearFile(): void {
    this.uploadForm.patchValue({
      file: null
    });
    this.fileName = null;
  }

  public onFileChange(event: FileList): void {
    const file: File = event?.[0];
    this.fileLoad = true;
    this.fileName = file.name;
    this.file.emit(file);
  }

  public allowDrop(event: any): void {
    event.preventDefault();
  }

  public drop(event: any): void {
    event.preventDefault();
    this.onFileChange(event.dataTransfer.files);
  }
}
