<div class="container">
  <div class="row">
    <div class="col-12 header">
      <div class="header-backlist">
        <img alt="arrow" src="../../../../assets/image/arrow_back_black_24dp.svg" class="img-fluid" />
        <span class="header-backlist_back" (click)="backToTheList()">Back to the list</span>
      </div>
      <div>
        <h3 class="information">User Information</h3>
      </div>
      <div>
        <form [formGroup]="userform" (ngSubmit)="onSubmit(userform)">
          <div class="row">
            <div class="row-one form-group">
              <div class="mb-3">
                <div>
                  <label class="form-label">First Name</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">First Name</mat-label>
                  <input matInput formControlName="firstname" type="text" [ngClass]="{'error': forms_errors.firstname}"
                    (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.firstname">
                    {{forms_errors.firstname}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">
                <div>
                  <label class="form-label">Last Name</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">Last Name</mat-label>
                  <input matInput formControlName="lastname" type="text" [ngClass]="{'error': forms_errors.lastname}"
                    (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.lastname">
                    {{forms_errors.lastname}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">
                <div>
                  <label class="form-label">Email</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">Email</mat-label>
                  <input matInput type="email" formControlName="email" [ngClass]="{'error': forms_errors.email}"
                    (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.email">
                    {{forms_errors.email}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <mat-divider class="divider" [vertical]="true"></mat-divider>
            <div class="row-two">
              <div class="mb-3">
                <div>
                  <label class="form-label">Phone</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">Phone</mat-label>
                  <input matInput formControlName="phone" type="text" mask="(000) 000-00-00"
                    [ngClass]="{'error':forms_errors.phone }" (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.phone">
                    {{forms_errors.phone}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">
                <div>
                  <label for="jobs" class="form-label">Role</label>
                </div>
                <mat-form-field floatLabel="never">
                  <mat-label class="label">Role</mat-label>
                  <mat-select formControlName="role" [ngClass]="{'error':forms_errors.role }" (blur)="blurForms()"
                    id="role">
                    <mat-option *ngFor="let item of roleList" [value]="item.DisplayText">{{item.DisplayText}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="forms_errors.role">
                    {{forms_errors.role}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">
                <div>
                  <label for="jobs" class="form-label">Jobs Assigned</label>
                </div>
                <mat-form-field floatLabel="never">
                  <mat-label class="label">Jobs Assigned</mat-label>
                  <mat-select formControlName="jobs"
                              id='jobs'
                              multiple [ngClass]="{'error':forms_errors.jobs }"
                              (blur)="blurForms()">
                    <mat-option *ngFor="let item of workList"
                                [value]="item.Id"
                                (onSelectionChange)='jobListChange(item, $event)'>{{item.Name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="forms_errors.jobs">
                    {{forms_errors.jobs}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
<!--            <mat-divider class="divider" [vertical]="true"></mat-divider>-->
<!--            <div class="col-4 but">-->
<!--              <button type="button" class="btn btn-secondary btn-lg" (click)="resetPassword()">Reset password</button>-->
<!--            </div>-->
<!--            <mat-divider class="divider" [vertical]="true"></mat-divider>-->
<!--            <div class="col-4 but">-->
<!--              <button type="button" class="btn btn-secondary btn-lg" (click)="resetPassword()">Reset password</button>-->
<!--            </div>-->
          </div>
          <!--Future issue-->
          <!-- <div>
            <div class="form-check-inline">
              <input formControlName="vendor" class="form-check-input" type="checkbox" value="" id="vendor">
              <label class="form-check-label" for="vendor">
                Is a Vendor
              </label>
            </div>
            <div class="form-check-inline">
              <input formControlName="punch" class="form-check-input" type="checkbox" value="" id="punch">
              <label class="form-check-label" for="punch">
                Punch List
              </label>
            </div>

          </div> -->
          <div class="group">
            <button type="submit"  class="btn btn-danger btn-lg">Save</button>
            <button type="button" class="btn btn-outline-danger btn-lg" (click)="deleteUser(template)">Delete</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefDelete.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h6>Are you sure to delete user?</h6>
  </div>
  <div class="group-delete">
    <button type="button" class="btn btn-danger btn-template " (click)="clickOk()">OK</button>
    <button type="button" class="btn btn-outline-danger btn-template " (click)="modalRefDelete.hide()">Cancel</button>
  </div>`
</ng-template>

<ng-template #templateDelete>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefDelete.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h6>User {{selecterUserName}} has been deleted</h6>
  </div>
  <div class="group">
    <button type="button" class="btn btn-danger" (click)="modalRefClose.hide()">OK</button>
  </div>`
</ng-template>

<ng-template #templateChange>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalAndRedirect()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>Changes has been saved</h5>
  </div>
  <div class="group">
    <button type="button" class="btn btn-danger btn-lg" (click)="closeModalAndRedirect()">OK</button>
  </div>`
</ng-template>
