import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { APIResponse, Status } from '../../models/api-response';
import { DynamicFieldsModel, DynamicModel } from '../../models/dynamic-model';
import { ConstantsService } from '../../services/constants.service';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { FieldAgentUser } from '../../models/field-agent-user';

import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { CmService } from 'src/app/modules/oclm-community-manager/services/cm.service';
import { WorkOrderCommunityModel } from '../../models/preview-community-page.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take, takeUntil } from 'rxjs/operators';
import { SupervisorService } from '../../../modules/oclm-supervisor/services/supervisor.service';
import { Subject } from 'rxjs';
import { CannedResponse, Role, SupervisorDetails, uploadPhotoFilePath, WorkOrderDetail } from '../../models/work-order';
import { JobList } from '../../models/job-list';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { LoaderService } from '../../../core/helpers/services/loader.service';

@Component({
  selector: 'app-preview-community-page',
  templateUrl: './preview-community-page.component.html',
  styleUrls: ['./preview-community-page.component.css'],
})
export class PreviewCommunityPageComponent implements OnInit {
  workOrderDetails: WorkOrderCommunityModel = new WorkOrderCommunityModel();
  assignFieldAgent;
  fieldAgentId;
  workStatus;
  uploadBeforePhotoFilePath: uploadPhotoFilePath[] = [];
  uploadAfterPhotoFilePath: uploadPhotoFilePath[] = [];
  extLong;
  extLat;
  DynamicFieldsList: DynamicFieldsModel[] = [];
  WorkOrderNotesDetail: WorkOrderDetail[] = [];
  CannedResponseName;
  existingRecord = true;
  minDate: Date;
  maxDate: Date;
  completeByInit: Date;
  bsConfig: Partial<BsDatepickerConfig>;
  showScheduleDate = false;
  cannedResponseList: CannedResponse[] = [];
  jobListDetails: JobList[] = [];
  workCategoryList: DynamicModel[] = [];
  selectedJobName = '';
  jobId;
  supervisorList: Role[];
  currentUserRole;
  statusList: Status[] = [];
  fieldAgentListDetails: FieldAgentUser[] = [];
  cmWorkOrderEnable = false;
  workOrderId: number;
  activeTab;
  modalRef: BsModalRef;
  message: string;
  @ViewChild('template') template: TemplateRef<any>;
  supervisorDetails: SupervisorDetails[] = [];
  stayOnSamePage = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  workOrderType = 'Resident';

  constructor(
    private commonService: CommonService,
    private modalService: BsModalService,
    private authService: AuthenticationService,
    private supervisorService: SupervisorService,
    private cmService: CmService,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: 'theme-red', adaptivePosition: true }
    );
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.completeByInit = new Date();
    this.completeByInit.setDate(this.maxDate.getDate() + 30);
  }

  ngOnInit(): void {
    this.commonService.setToDisplayBackBtn(true);
    localStorage.setItem(environment.headerTitle, 'Preview work order');

    this.workOrderDetails.StartDate = new Date();
    this.currentUserRole = this.authService.currentLoginUserValue.Role;
    this.getWorkCategoryList();
    this.getStatusList();
    this.getFieldAgentBySupervisorId();
    if (
      this.currentUserRole &&
      this.currentUserRole === ConstantsService.User_Role.Admin
    ) {
      this.getAllJobListDetails();
    } else {
      this.getJobListBySupervisorId();
    }
    // Get work order details by Id
    this.route.params.subscribe((params) => {
      this.workOrderId = params['workOrderId'];
      this.activeTab = params['activeTab'];
      this.cmWorkOrderEnable = params['cmWorkOrderEnable'];
      this.getExistingWorkOrderManual(this.workOrderId);
    });
  }

  workOrderStatusChange(event) {
    this.showScheduleDate = event.target.value === 'Schedule';
  }

  confirm(): void {
    this.modalRef.hide();
    if (!this.stayOnSamePage) {
      this.commonService.navigateByURL('/communitymanager/home/details');
    }
  }

  onImageDelete(docId: string) {
    this.uploadBeforePhotoFilePath = this.uploadBeforePhotoFilePath.filter(
      (x) => !x.filePath.includes(docId)
    );

    this.uploadAfterPhotoFilePath = this.uploadAfterPhotoFilePath.filter(
      (x) => !x.filePath.includes(docId)
    );

    if (docId) {
      this.supervisorService
        .DeleteWorkOrderDocument(this.workOrderId, docId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.supervisorService
            .getWorkOrderByWorkId(this.workOrderId, 'Resident')
            .subscribe(() => {
            });
        });
    }
  }

  onImageCommentUpdated(data) {
    if (data) {
      this.supervisorService
        .EditWorkOrderComment(data.docId, data.comment)
        .subscribe(() => {
          this.supervisorService
            .getWorkOrderByWorkId(this.workOrderId, this.workOrderType)
            .pipe(take(1))
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
            });
        });
    }
  }

  getExistingWorkOrderManual(id) {
    this.cmService
      .getTaskOrderDetailsByTaskOrderId(id)
      .subscribe((res: APIResponse) => {
        if (res && res.Data && res.Success) {
          this.workOrderDetails = res.Data[0];
          this.extLat = this.workOrderDetails.Latitude;
          this.extLong = this.workOrderDetails.Longitude;
          this.assignFieldAgent = this.workOrderDetails.FieldAgentId
            ? 'other'
            : 'myself';
          this.DynamicFieldsList = this.workOrderDetails.DynamicFields;
          this.WorkOrderNotesDetail = this.workOrderDetails.WorkOrderNotesDetail
            ? this.workOrderDetails.WorkOrderNotesDetail
            : [];
          this.workOrderDetails.StartDate = moment(
            this.workOrderDetails.StartDate
          ).format('MM/DD/YYY');
          this.workOrderDetails.CompletedBy = moment(
            this.workOrderDetails.CompletedBy
          ).format('MM/DD/YYY');
          this.workOrderDetails.CompletedOn = this.workOrderDetails.CompletedOn
            ? moment(this.workOrderDetails.CompletedOn).format('MM/DD/YYY')
            : '';
          this.getCannedResponseList(this.workOrderDetails.WorkCategory);
          this.getBeforeFile(this.workOrderDetails.uploadBeforeDocument, this.workOrderDetails.UploadedDocPathsBefore);
          this.getAfterFile(this.workOrderDetails.uploadAfterDocument, this.workOrderDetails.UploadedDocPathsAfter);
        }
      });

    if (this.workOrderDetails.WorkStatusText === 'Schedule') {
      this.showScheduleDate = true;
    }
  }

  getBeforeFile(uploadBeforeDocument, UploadedDocPathsBefore) {
    UploadedDocPathsBefore.forEach((element) => {
      let fileExtension = element.split('.');
      let uploadId = fileExtension[fileExtension.length - 2].split('/');
      uploadId = uploadId[uploadId.length - 1];
      fileExtension = fileExtension[fileExtension.length - 1];
      const foundFile = uploadBeforeDocument.find(item => item.uploadId === uploadId);
      this.uploadBeforePhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
        uploadComment: foundFile.uploadComment,
        uploadDate: foundFile.uploadDate,
      });
    });
  }

  getAfterFile(uploadAfterDocument, UploadedDocPathsAfter) {
    UploadedDocPathsAfter.forEach((element) => {
      let fileExtension = element.split('.');
      let uploadId = fileExtension[fileExtension.length - 2].split('/');
      uploadId = uploadId[uploadId.length - 1];
      fileExtension = fileExtension[fileExtension.length - 1];
      const foundFile = uploadAfterDocument.find(item => item.uploadId === uploadId);
      this.uploadAfterPhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
        uploadComment: foundFile.uploadComment,
        uploadDate: foundFile.uploadDate,
      });
    });
  }

  getFieldAgentBySupervisorId() {
    this.commonService
      .getFieldAgentListByLoggedInUser()
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.fieldAgentListDetails = res.Data;
        }
      });
  }

  getStatusList() {
    this.commonService.getStatusList().subscribe((res: APIResponse) => {
      if (res && res.Success) {
        this.statusList = res.Data;
      }
    });
  }

  getWorkCategoryList() {
    this.commonService.getWorkCategoryList().subscribe((res: APIResponse) => {
      this.workCategoryList = res.Data || [];
    });
  }

  // Get canned response from DB
  getCannedResponseList(workCategory) {
    this.commonService
      .getCannedResponseList(workCategory)
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data || [];
      });
  }

  getJobListBySupervisorId() {
    this.commonService
      .getJobListBySupervisor()
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.jobListDetails = res.Data;
        }
      });
  }

  getAllJobListDetails() {
    const serachText = '';
    this.commonService
      .getAllJobList(serachText)
      .subscribe((res: APIResponse) => {
        this.jobListDetails = res.Data;
        if (this.workOrderDetails && this.workOrderDetails.JobName) {
          this.getSelectedJobs(this.workOrderDetails.JobName);
        }
      });
  }

  getSupervisorListByJobId() {
    if (this.jobId) {
      this.commonService
        .getSupervisorAndFAListByJobId(this.jobId)
        .subscribe((res: APIResponse) => {
          if (res && res.Data && res.Success) {
            this.supervisorList = res.Data;
          }
        });
    }
  }

  getSelectedJobs(Id) {
    if (Id) {
      let selectedJobs = this.jobListDetails.find((x) => x.Id == Id);
      this.selectedJobName = selectedJobs ? selectedJobs.Name : '';
      if (
        this.authService.currentLoginUserValue.Role ===
        ConstantsService.User_Role.Admin
      ) {
        this.jobId = Id;
        this.workOrderDetails.CustomerName = this.selectedJobName;
        this.getSupervisorListByJobId();
      }
    }
  }

  sendEmail(data: string) {
    let model = {
      UserEmail: this.workOrderDetails.ContactEmail,
      Subject: `${
        data === 'accepted' || data === 'rejected'
          ? `Work Order ID ${this.workOrderDetails.WorkId} / ${
            this.workOrderDetails.JobName
          } has been ${
            data === 'rejected' ? 'closed' : 'approved and is now in progress'
          }`
          : `Update on Work Order ID ${this.workOrderDetails.WorkId} / ${this.workOrderDetails.JobName} `
      }`,
      Body: `
      ${
        data === 'accepted' || data === 'rejected'
          ? `
          <div style="width: 80%;">
      <P style="margin-bottom: 5px;">Hello ${
            this.workOrderDetails.FirstName
          } ${this.workOrderDetails.LastName},</P>

      <p style="margin-bottom: 5px;">Your Work Order ID
      ${`<a href="${environment.applicationUrl}/resident/${this.workOrderDetails.WorkId}/mytaskorder">
      ${this.workOrderDetails.WorkId}
      </a>`}
      has been ${
            data === 'rejected' ? 'reviewed' : 'approved and is now in progress '
          } by ${this.workOrderDetails.JobName}  ${
            data === 'rejected' ? 'has been closed' : ''
          } with the following note:
      ${
            this.workOrderDetails.NotesVal
              ? `<p>${this.workOrderDetails.NotesVal}</p>`
              : ``
          }
      </p>

      <address>
<p>Thank you for your business,</p>
          <p style='margin-bottom: 20px;'>OCLM Team</p>
          <p style='font-style: italic;margin-bottom: 20px;'>*** This is an automatically generated email, please do not reply ***</p>
      <img src="${environment.apiUrl}WorkOrderIMages/new-logo.jpg">
      </address>
     </div>`
          : `<div style="width: 80%;">
            <P style="margin-bottom: 5px;">Hello ${
                  this.workOrderDetails.FirstName
                } ${this.workOrderDetails.LastName},</P>
            <p style="margin-bottom: 5px;">You have received the following update from ${
                  this.workOrderDetails.JobName
                } on Work Order ID ${`<a href="${environment.applicationUrl}/resident/${this.workOrderDetails.WorkId}/mytaskorder">
            ${this.workOrderDetails.WorkId}
            </a>`}:</p>
            <p style="margin-bottom: 5px;">${this.workOrderDetails.NotesVal} </p>
          <address>
          <p style='font-weight: 400'>Thank you for your business,</p>
          <p style='margin-bottom: 20px;'>OCLM Team</p>
          <p style='font-style: italic;margin-bottom: 20px;'>*** This is an automatically generated email, please do not reply ***</p>
      <img src="${environment.apiUrl}WorkOrderIMages/new-logo.jpg">
      </address>
     </div>`
      }
      `,
      IsHtml: true,
    };
    this.workOrderDetails.NotesVal = '';
    this.commonService
      .sendEmailByUserId(model)
      .subscribe((res) => console.log('email has been send to user', res));
  }

  addNotificationToAPI() {
    // temp use
    let workOrderId = this.workOrderDetails.WorkId;
    if (workOrderId) {
      let notificationData = {
        To: this.authService.currentLoginUserValue.SuperVisorId,
        Title:
          '/supervisor/home/details/' +
          this.workOrderDetails.WorkId.substr(2) +
          '/Resident',
        MessageText: `You have received the following note from ${this.authService.currentLoginUserValue.Name} on Work Order ID
      ${this.workOrderDetails.WorkId} Note - ${this.workOrderDetails.NotesVal} `,
      };
      this.commonService
        .createNotificationToAPI(notificationData)
        .subscribe((item) => {
          console.log('Added notification in ', item);
        });
    } else {
      console.error('Notification will not trigger');
    }
  }

}
