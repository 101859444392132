import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MapsAPILoader, AgmMap, AgmInfoWindow } from '@agm/core';
import { MapStyles } from 'src/app/shared/models/map-style';

@Component({
  selector: 'app-google-pin',
  templateUrl: './google-pin.component.html',
  styleUrls: ['./google-pin.component.css'],
})
export class GooglePinComponent implements OnInit {
  @ViewChild(AgmMap, { static: true }) public agmMap: AgmMap;
  @Input() workOrderDetails = [];
  @Input() role: string;
  @Input() zoom: number = 6;
  currentIW: AgmInfoWindow;
  previousIW: AgmInfoWindow;
  geoCoder: google.maps.Geocoder;
  latitude: number;
  longitude: number;
  styles = MapStyles;
  iconURI = {
    url: '../../../../../assets/image/track-location.svg',
    scaledSize: {
      width: 100,
      height: 100,
    },
  };

  constructor(private mapsAPILoader: MapsAPILoader) {
    this.currentIW = null;
    this.previousIW = null;
  }

  ngOnInit(): void {
    this.loadAPIPlaceAutoComplete();
  }

  loadAPIPlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
    });
  }

  mapClick(event) {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  markerClick(infoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
  }

  onReportMap(workId: number, workOrderType: string) {
    window.open(`/#/${this.role}/home/details/${workId}/${workOrderType}`);
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(() => {
        this.latitude = 33.53727;
        this.longitude = -117.641396;
        // this.latitude = position.coords.latitude; TODO DO not touch this
        // this.longitude = position.coords.longitude;
      });
    }
  }
}
