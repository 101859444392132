export class WorkOderModel {
  WorkRequestID: string;
  CustomerId: number;
  WorkCategory: string;
  CompletedBy: Date;
  CompletedOn: Date;
  Address: string;
  Notes: string;
  CannedResponseId: number;
  CannedResponseText: string;
  StartDate: Date = new Date();
  SuperVisorId: number;
  SuperVisorName?: number;
  CustomerName: string;
  CannedResponseName: string;
}
