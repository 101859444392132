import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[beforePrintStyles]'
})
export class BeforePrintStylesDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('window:beforeprint')
  onBeforePrint() {
    this.renderer.setStyle(this.el.nativeElement, 'border', '2px solid black');
  }
}
