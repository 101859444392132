import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { APIResponse, Status } from '../../models/api-response';
import { DynamicFieldsModel, DynamicModel } from '../../models/dynamic-model';
import { ConstantsService } from '../../services/constants.service';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SupervisorService } from 'src/app/modules/oclm-supervisor/services/supervisor.service';
import { FieldAgentUser } from '../../models/field-agent-user';

import * as moment from 'moment';
import { WorkOrderModel } from '../../models/preview-page.model';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/core/helpers/services/loader.service';
import { User } from '../../models/user';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CannedResponse, commentDoc, Role, uploadPhotoFilePath, WorkOrderDetail } from '../../models/work-order';
import { JobList } from '../../models/job-list';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.css'],
})
export class PreviewPageComponent implements OnInit {
  workOrderDetails: WorkOrderModel = new WorkOrderModel();
  assignFieldAgent: string;
  fieldAgentId: string;
  uploadBeforePhotoFilePath: uploadPhotoFilePath[] = [];
  uploadAfterPhotoFilePath: uploadPhotoFilePath[] = [];
  uploadBeforeDocumentComments:  commentDoc[] = [];
  uploadAfterDocumentComments: commentDoc[] = [];
  uploadBeforeCommentData: commentDoc[] = [];
  uploadAfterCommentData: commentDoc[] = [];
  DynamicFieldsList: DynamicFieldsModel[] = [];
  WorkOrderNotesDetail: WorkOrderDetail[] = [];
  existingRecord = true;
  minDate: Date;
  maxDate: Date;
  completeByInit: Date;
  bsConfig: Partial<BsDatepickerConfig>;
  showScheduleDate: boolean = false;
  cannedResponseList: CannedResponse[] = [];
  jobListDetails: JobList[] = [];
  workCategoryList: DynamicModel[] = [];
  selectedJobName: string = '';
  jobId;
  supervisorList: Role[];
  currentUserRole: string;
  statusList: Status[] = [];
  fieldAgentListDetails: FieldAgentUser[] = [];
  cmWorkOrderEnable: boolean = false;
  workOrderId: string;
  currentUser: User;
  modalRef: BsModalRef;
  message: string;
  @ViewChild('template') template: TemplateRef<any>;
  workOrderType: string;
  isHide: boolean;

  constructor(
    private commonService: CommonService,
    private modalService: BsModalService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private supervisorService: SupervisorService,
    private loaderService: LoaderService
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: 'theme-red', adaptivePosition: true }
    );
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.completeByInit = new Date();
    this.completeByInit.setDate(this.maxDate.getDate() + 30);
  }

  ngOnInit(): void {
    this.commonService.setToDisplayBackBtn(true);
    localStorage.setItem(environment.headerTitle, 'Preview work order');
    this.workOrderDetails.StartDate = new Date();
    this.route.params.subscribe((params) => {
      this.workOrderId = params['id'];
      this.workOrderType = params['workordertype'];
      this.cmWorkOrderEnable = params['cmWorkOrderEnable'];
      this.getExistingWorkOrderManual(this.workOrderId, this.workOrderType);
    });
    this.currentUserRole = this.authService.currentLoginUserValue.Role;
    this.getWorkCategoryList();
    this.getStatusList();
    this.getFieldAgentBySupervisorId();
    if (
      this.currentUserRole &&
      this.currentUserRole === ConstantsService.User_Role.Admin
    ) {
      this.getAllJobListDetails();
    } else {
      this.getJobListBySupervisorId();
    }
  }

  workOrderStatusChange(event) {
    this.showScheduleDate = event.target.value === 'Schedule';
  }

  completeOrder() {
    this.supervisorService
      .WorkOrderComplete(this.workOrderId)
      .subscribe((res: APIResponse) => {
        this.completeWorkOrderEmail(res.Data);
        this.router.navigateByUrl('/supervisor/home/details');
      });
  }

  async completeWorkOrderEmail(data: any) {
    let userDetail: { name: string; email: string; imagePath?: string };
    if (data.ContactEmail) {
      userDetail = {
        name: `${data.ContactFirstName} ${data.ContactLastName}`,
        email: data.ContactEmail,
        imagePath: data.DocPathsAfter[0],
      };
    } else {
      userDetail = {
        name: `${this.authService.currentLoginUserValue.FirstName} ${this.authService.currentLoginUserValue.LastName}`,
        email: this.authService.currentLoginUserValue.Email,
        imagePath: data.DocPathsAfter[0],
      };
    }
    let model = {
      UserEmail: userDetail.email,
      Subject: 'Work order' + this.workOrderId + 'for' +  this.selectedJobName + ' has been completed ',
      Body: `<div style="width: 80%; margin: 30px auto;">
              <p style="margin-bottom: 5px;font-weight: 400;">Hello ${userDetail.name}</p>
              <p style="margin-bottom: 5px;font-weight: 400;">Work order ${this.workOrderId} has been closed.
              </p>
              <p style="margin-bottom: 20px;font-weight: 400;">Here are the details of your work order:</p>
              <p style="margin-bottom: 5px;">Job Name: <span style='font-weight: 400;'>${this.selectedJobName}</span></p>
              <p style="margin-bottom: 5px;">Address of issue: <span style='font-weight: 400;'>${this.workOrderDetails.Address}</span></p>
              <p style="margin-bottom: 5px;">Work Category: <span style='font-weight: 400;'>${this.workOrderDetails.WorkCategory}</span></p>
              <p style="margin-bottom: 5px;">Work Requested: <span style='font-weight: 400;'>${data.WorkRequestID}</span></p>
              <p style="margin-bottom: 20px;"> Action taken: <span style='font-weight: 400;'>${data.CannedResponseName}.
               ${data.CannedResponseText ? `${data.CannedResponseText}` : ``}</span></p>
              <div style='margin-bottom: 5px;font-weight: 400;'> ${userDetail.imagePath ? `<img  src="${environment.imagePath + userDetail.imagePath}"/>` : ''}</div>
              <p style='margin-bottom: 20px;font-weight: 400;'>
              Please click
              <a href="${environment.applicationUrl}/${this.authService.currentLoginUserValue.Role.toLocaleLowerCase()}/home/${this.workOrderId}/${this.workOrderType}">here</a>
              to see more details of this work order.
              </p>
              <address>
              <p style='font-weight: 400;'>Thank you for your business,</p>
              <p style='margin-bottom: 20px;font-weight: 400;'>OCLM Team</p>
              <p style='font-style: italic;font-weight: 400;margin-bottom: 20px;'>*** This is an automatically generated email, please do not reply ***</p>
              <img alt="icon"  src="${environment.apiUrl}WorkOrderIMages/new-logo.jpg">
            </address>
            </div>`,
      IsHtml: true,
      Type: 'Closed',
    };
    this.commonService
      .sendEmailByUserId(model)
      .subscribe(() => console.log('email has been send to user'));
  }

  getExistingWorkOrderManual(id: string, workOrderType: string) {
    this.supervisorService
      .getWorkOrderByWorkId(id, workOrderType)
      .subscribe((res: APIResponse) => {
        if (res && res.Data && res.Success) {
          this.workOrderDetails = res.Data;
          if (this.workOrderDetails && this.workOrderDetails.CustomerId) {
            this.getSelectedJobs(this.workOrderDetails.CustomerId);
          }
          this.assignFieldAgent = this.workOrderDetails.FieldAgentId
            ? 'other'
            : 'myself';
          this.DynamicFieldsList = this.workOrderDetails.DynamicFields;
          this.WorkOrderNotesDetail = this.workOrderDetails.WorkOrderNotesDetail
            ? this.workOrderDetails.WorkOrderNotesDetail
            : [];

          if (this.workOrderDetails.SupervisorName === '') {
            this.workOrderDetails.SupervisorName = this.workOrderDetails.FieldAgentName;
          }

          this.workOrderDetails.StartDate = moment(
            this.workOrderDetails.StartDate
          ).format('L');
          this.workOrderDetails.CompletedBy = moment(
            this.workOrderDetails.CompletedBy
          ).format('L');
          this.workOrderDetails.CompletedOn = this.workOrderDetails.CompletedOn
            ? moment(this.workOrderDetails.CompletedOn).format('L')
            : '';
          this.getCannedResponseList(this.workOrderDetails.WorkCategory);
          this.getBeforeFile(this.workOrderDetails.UploadedDocPathsBefore);
          this.getAfterFile(this.workOrderDetails.UploadedDocPathsAfter);

          this.workOrderDetails.uploadBeforeDocument
            ? (this.uploadBeforeDocumentComments = this.workOrderDetails.uploadBeforeDocument)
            : (this.uploadBeforeDocumentComments = []);

          this.workOrderDetails.uploadAfterDocument
            ? (this.uploadAfterDocumentComments = this.workOrderDetails.uploadAfterDocument)
            : (this.uploadAfterDocumentComments = []);

          this.getAfterCommentDocuments(this.uploadAfterDocumentComments);
          this.getBeforeCommentDocuments(this.uploadBeforeDocumentComments);
        }
      });

    if (this.workOrderDetails.WorkStatusText === 'Schedule') {
      this.showScheduleDate = true;
    }
  }

  getBeforeCommentDocuments(uploadBeforeDocumentComments: commentDoc[]) {
    uploadBeforeDocumentComments.forEach((item) => {
      this.uploadBeforeCommentData.push({
        uploadId: item.uploadId,
        uploadComment: item.uploadComment,
      });
    });
  }

  getAfterCommentDocuments(uploadBeforeDocumentComments: commentDoc[]) {
    uploadBeforeDocumentComments.forEach((item) => {
      this.uploadAfterCommentData.push({
        uploadId: item.uploadId,
        uploadComment: item.uploadComment,
      });
    });
  }

  getBeforeFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadBeforePhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
  }

  getAfterFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadAfterPhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
  }
  hideEditButton(): boolean {
    this.isHide = !((this.currentUserRole === ConstantsService.User_Role.Admin ||
        this.currentUserRole === ConstantsService.User_Role.CommunityManager) &&
      this.workOrderDetails.WorkStatusText === 'Schedule');
    return this.isHide;
  }

  getFieldAgentBySupervisorId() {
    this.commonService
      .getFieldAgentListByLoggedInUser()
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.fieldAgentListDetails = res.Data;
        }
      });
  }

  getStatusList() {
    this.commonService.getStatusList().subscribe((res: APIResponse) => {
      if (res && res.Success) {
        this.statusList = res.Data;
      }
    });
  }

  getWorkCategoryList() {
    this.commonService.getWorkCategoryList().subscribe((res: APIResponse) => {
      this.workCategoryList = res.Data || [];
    });
  }

  getCannedResponseList(workCategory) {
    this.commonService
      .getCannedResponseList(workCategory)
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data;
      });
  }

  getJobListBySupervisorId() {
    this.commonService
      .getJobListBySupervisor()
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.jobListDetails = res.Data;
        }
      });
  }

  getAllJobListDetails() {
    let serachText = '';
    this.commonService
      .getAllJobList(serachText)
      .subscribe((res: APIResponse) => {
        this.jobListDetails = res.Data;
        if (this.workOrderDetails && this.workOrderDetails.CustomerId) {
          this.getSelectedJobs(this.workOrderDetails.CustomerId);
        }
      });
  }

  getSupervisorListByJobId() {
    if (this.jobId) {
      this.commonService
        .getSupervisorAndFAListByJobId(this.jobId)
        .subscribe((res: APIResponse) => {
          if (res && res.Data && res.Success) {
            this.supervisorList = res.Data;
          }
        });
    }
  }

  getSelectedJobs(Id) {
    if (Id) {
      let selectedJobs = this.jobListDetails.find((x) => x.Id == Id);
      this.selectedJobName = selectedJobs ? selectedJobs.Name : '';
      if (
        this.authService.currentLoginUserValue.Role ===
        ConstantsService.User_Role.Admin
      ) {
        this.jobId = Id;
        this.workOrderDetails.CustomerName = this.selectedJobName;
        this.getSupervisorListByJobId();
      }
    }
  }

  navigateURL(workOrderType: string) {
    let role = this.currentUserRole.toLowerCase();
    this.commonService.navigateByURL(
      `${role}/home/details/${this.workOrderDetails.WorkId}/${workOrderType}`
    );
  }

  addMoreFields() {
    if (!this.workOrderDetails.NotesVal) {
      return;
    }
    this.loaderService.isLoading.next(true);
    let model = {
      NotesText: this.workOrderDetails.NotesVal,
      CreatedBy: this.authService.currentLoginUserValue.Id,
      WorkId: this.workOrderDetails.WorkId,
    };
    this.commonService.saveCommunityManagerNotes(model).subscribe(
      () => {
        this.loaderService.isLoading.next(false);
        this.sendEmail();
        this.addNotificationToAPI();
        this.workOrderDetails.NotesVal = '';

        this.message = ConstantsService.SuccessMessage.NotesMailSuccess;
        this.modalRef = this.modalService.show(this.template, {
          class: 'modal-md',
        });
      },
      () => {
        this.loaderService.isLoading.next(false);
      }
    );
  }

  confirm(): void {
    this.modalRef.hide();
  }
  // Send email to user
  sendEmail() {
    let model = {
      UserId: this.authService.currentLoginUserValue.SuperVisorId,
      Subject: `Client added a note on Work Order ID ${this.workOrderDetails.WorkId}`,
      Body: `
      <div style="width: 80%;">
      <P style="margin-bottom: 5px;font-weight: 400;">Hello User,</P>
      <p style="margin-bottom: 5px;font-weight: 400;">You have received the following note from: ${this.authService.currentLoginUserValue.Name}</p>
      <p style="margin-bottom: 15px;font-weight: 400;">on Work order ${this.workOrderDetails.WorkId} Note - ${this.workOrderDetails.NotesVal} </p>
      <address>
             <p>Thank you for your business,</p>
          <p style='margin-bottom: 20px;font-weight: 400;'>OCLM Team</p>
          <p style='font-style: italic;font-weight: 400;margin-bottom: 20px;'>*** This is an automatically generated email, please do not reply ***</p>
      <img src="${environment.apiUrl}WorkOrderIMages/new-logo.jpg">
      </address>
     </div>`,
      IsHtml: true,
      Type: 'Commented',
    };
    this.commonService
      .sendEmailByUserId(model)
      .subscribe(() => console.log('email has been send to user'));
  }

  addNotificationToAPI() {
    // temp use
    let workOrderId = this.workOrderDetails.WorkId;
    if (workOrderId) {
      let notificationData = {
        To: this.authService.currentLoginUserValue.SuperVisorId,
        Title:
          '/supervisor/home/details/' +
          this.workOrderDetails.WorkId +
          '/Manual',
        MessageText: `You have received the following note from ${this.authService.currentLoginUserValue.Name} on Work Order ID
      ${this.workOrderDetails.WorkId} Note - ${this.workOrderDetails.NotesVal} `,
      };
      this.commonService
        .createNotificationToAPI(notificationData)
        .subscribe((item) => {
          console.log('Added notification in ', item);
        });
    } else {
      console.error('Notification will not trigger');
    }
  }
}
