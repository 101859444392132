import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { LoaderService } from 'src/app/core/helpers/services/loader.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DeviceDetectorService } from 'ngx-device-detector';
import { compare, SortableTableDirective, SortEvent } from 'src/app/shared/components/sortable-table/sortable-table.directive';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserShort } from '../../models/userShort';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { UserService } from '../../services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ItemPerPage, WorkOrderType } from '../../../../../../shared/models/work-order';
import { UserList } from '../../../../../../shared/models/api-response';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit, OnDestroy {
  public activeTab = 'WorkInProgress';
  @ViewChild('templateDelete') templateDelete: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public workOrderType: WorkOrderType[] = ConstantsService.WorkOrderType;
  public itemPerPage: ItemPerPage[] = ConstantsService.ItemsPerPage;
  @ViewChildren(SortableTableDirective)
  headers: QueryList<SortableTableDirective>;
  public modalRefDelete: BsModalRef;
  public modalRefClose: BsModalRef;
  public UserList: UserShort[] = [];
  public userListPgination: number;
  public selectedUser: number;
  public selecterUserName: string;
  public sortedColumn: string;
  public isSortedDesc: string;
  public isDesktop: boolean;
  public isTablet: boolean;
  public pageNo: number;
  public pageSize: number;
  public maxSize = 5;
  public searchText: string = '';
  public sortByUser = true;
  public sessionWorkId;

  constructor(
    private loaderService: LoaderService,
    private commonService: CommonService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private modalService: BsModalService
  ) {
    this.pageNo = this.commonService.CurrentPaginationIndexValue;
    this.pageSize = this.commonService.CurrentPageSize;
    this.isDesktop = this.deviceService.isDesktop();
    this.isTablet = this.deviceService.isTablet();
  }

  ngOnInit(): void {
    this.commonService.text.subscribe((text: string) => {
      this.searchText = text;
      this.getWorkOrderListByStatusKey();
    });
    this.commonService.passSearchTextSubjectObservables.subscribe(
      (data: string) => {
        this.searchText = data;
        this.getWorkOrderListByStatusKey();
      }
    );
  }

  onSort({ column, direction }: SortEvent) {
    if (column !== 'Jobs') {
      this.UserList = [...this.UserList].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    } else {
      this.UserList = [
        ...this.UserList.sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        })
      ].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  getWorkOrderListByStatusKey(isSortedDesc = 'false') {
    this.loaderService.isLoading.next(true);
    this.isSortedDesc = isSortedDesc;
    this.commonService.setCurrentPaginationValue({
      pageNumber: this.pageNo
    });
    this.userService
      .getListOfUsers(
        this.pageNo,
        this.pageSize,
        this.searchText,
        this.isSortedDesc
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: UserList) => {
          if (res && res.Data && res.Success) {
            this.UserList = res.Data.profiles;
            this.userListPgination = res.Data.totalCount
              ? res.Data.totalCount
              : 0;
            this.loaderService.isLoading.next(false);
          }
        },
        () => {
          this.loaderService.isLoading.next(false);
        }
      );
  }

  sortByWorkOrderType(value) {
    this.sortByUser = value;
    this.getWorkOrderListByStatusKey();
  }

  pageChanged(event: PageChangedEvent): void {
    this.pageNo = event.page;
    this.pageSize = event.itemsPerPage;
    this.commonService.setCurrentPageSize({ pageSize: this.pageSize });
    this.commonService.setCurrentPaginationValue({ pageNumber: this.pageNo });
    this.getWorkOrderListByStatusKey();
  }

  selectItemPerPage(value: number) {
    this.pageSize = value;
    const numOfPages = Math.ceil(this.userListPgination / value);
    if (numOfPages < 5) {
      this.maxSize = numOfPages;
    }
    this.commonService.setCurrentPageSize({ pageSize: value });
    this.getWorkOrderListByStatusKey(this.searchText);
  }

  blockUser(template: TemplateRef<any>, id, name) {
    this.modalRefDelete = this.modalService.show(template);
    this.selectedUser = id;
    this.selecterUserName = name;
  }

  clickOk() {
    this.userService
      .blockUser(this.selectedUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.modalRefDelete.hide();
        if (res['StatusCode'] === 200) {
          this.modalRefClose = this.modalService.show(this.templateDelete);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
