<div class="table-responsive">
  <table class="table mt-3">
    <thead class="table-header">
      <tr>
        <th class="w-20" sortable="WorkId" (sort)="onSort($event)">
          # / Job Name
        </th>
        <th class="w-20" sortable="WorkCategory" (sort)="onSort($event)">
          Work Category
        </th>
        <th class="w-20" sortable="WorkRequestId" (sort)="onSort($event)">
          Task
        </th>
        <th class="w-20" sortable="StartDate" (sort)="onSort($event)">
          Scheduled Date
        </th>
        <th class="w-20" sortable="CreatedDate" (sort)="onSort($event)">
          Created By
        </th>
        <th class="w-20" sortable="AssignedTo" (sort)="onSort($event)">
          Assigned To
        </th>
        <th class="w-20" sortable="DueDate" (sort)="onSort($event)">
          Due Date
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of scheduleWorkOrderTableData">
        <td
          class="w-20"
          (click)="setWorkIdInSession(item.WorkID, item.WorkOrderType)"
        >
          {{ item.WorkID }} / {{ item.JobName }}
        </td>
        <td
          class="w-20"
          (click)="setWorkIdInSession(item.WorkID, item.WorkOrderType)"
        >
          {{ item.WorkCategory }}
        </td>
        <td
          class="w-20"
          (click)="setWorkIdInSession(item.WorkID, item.WorkOrderType)"
        >
          {{ item.OriginalTaskName }}
        </td>
        <td
          class="w-20"
          (click)="setWorkIdInSession(item.WorkID, item.WorkOrderType)"
        >
          {{ item.StartDate | date: 'd MMMM, y' }}
        </td>
        <td
          class="w-20"
          (click)="setWorkIdInSession(item.WorkID, item.WorkOrderType)"
        >
          {{ item.CreatedBy }}
        </td>
        <td
          class="w-20"
          (click)="setWorkIdInSession(item.WorkID, item.WorkOrderType)"
        >
          {{ item.AssignedTo }}
        </td>
        <td
          class="w-20"
          (click)="setWorkIdInSession(item.WorkID, item.WorkOrderType)"
        >
          {{ item.DueDate | date: 'd MMMM, y' }}
        </td>
      </tr>
    </tbody>
    <ng-template *ngIf="scheduleWorkOrderTableData.length == 0">
      <div class="inbox-body">
        <span class="pl-4">{{ 'Message.NoRecordFound' | translate }}</span>
      </div>
    </ng-template>
  </table>
</div>
<div class="page-select mt-4">
  <div class="coustom-width ma-l">
    <select
      class="form-control"
      id="itemperpage"
      #itemperpage
      [(ngModel)]="pageSize"
      (change)="selectItemPerPage(itemperpage.value)">
      <option [value]="10">
        {{ 'CommonLabel.ItemPerPage' | translate }}
      </option>
      <option *ngFor="let value of itemPerPage" [value]="value.pageSize">
        <input type="checkbox" />
        {{ value.pageSize }}
      </option>
    </select>
  </div>
  <ng-container *ngIf="calendarListTableLength">
    <pagination
      [maxSize]="5"
      [totalItems]="calendarListTableLength"
      (pageChanged)="pageChanged($event)"
      [itemsPerPage]="pageSize"
      [(ngModel)]="pageNo"
      [boundaryLinks]="true"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;">
    </pagination>
  </ng-container>
</div>
