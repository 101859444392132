<div class="task-list main-padding">
  <div class="task-left-box">
    <p class="task-left">Task List</p>
    <div class="wrp">
      <input type="text" placeholder="Search" class="form-control" />
      <img src="../../../../assets/image/search-icon.svg" class='user-icon' />
    </div>
  </div>
  <div class="task-right">
    <div>
      <input type="text" placeholder="Jobs" class="form-control" />
    </div>
    <span>
      <img
        src="../../../../../assets/image/filter-btn-1.svg"
        class="img-fluid"
      />
    </span>
    <button
      type="button"
      data-toggle="modal"
      data-target="#exampleModal"
      class="btn btn-oclm"
    >
      Create Work Order
      <img src="../../../../../assets/image/add-icon.svg" class="add-icon" />
    </button>
  </div>
</div>

<div class="main-padding">
  <div class="inbox-boder-box">
    <div class="inbox-head">
      <div class="padding-wrapper">
        <div>
          <p>Order ID</p>
        </div>
        <div>
          <p>Field Agent</p>
        </div>
        <div>
          <p>Task</p>
        </div>
        <div>
          <p>Address</p>
        </div>
      </div>
    </div>

    <div class="inbox-body">
      <a href="#CollapsFirst" data-toggle="collapse">
        <div class="padding-wrapper">
          <div>
            <p>TID114</p>
          </div>
          <div>
            <p>Robert Gumm</p>
          </div>
          <div class="mobile-width">
            <p>Leaf removal, edging and trimming</p>
          </div>
          <div class="mobile-width">
            <p>125th street, San Jose City, California</p>
          </div>
        </div>
      </a>
    </div>
    <div class="collapse" id="CollapsFirst">
      <div class="card card-body">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>First Name</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="First Name"
                  class="form-control"
                />
                <img
                  src="../../../../../assets/image/user-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Last Name</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Last Name"
                  class="form-control"/>
                <img
                  src="../../../../../assets/image/user-icon.png"
                  class='user-icon'/>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Work Request</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Work Request"
                  class="form-control"
                />
                <img
                  src="../../../../../assets/image/work-order-icon-2.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Create Date</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Create Date"
                  class="form-control"
                />
                <img
                  src="../../../../assets/image/date-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Customer Account</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Customer Account"
                  class="form-control"
                />
                <img
                  src="../../../../assets/image/comment-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Contact Name</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Contact Name"
                  class="form-control"
                />
                <img
                  src="../../../../../assets/image/user-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Supervisor</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Supervisor"
                  class="form-control"
                />
                <img
                  src="../../../../assets/image/supervisor-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Email</label>
              <div class="wrp">
                <input
                  formControlName="name"
                  type="email"
                  placeholder="Email ID"
                  class="form-control"
                />
                <img
                  src="../../../../../assets/image/inbox-icon-2.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group">
              <label>Address</label>
              <div class="wrp">
                <textarea
                  formControlName="Address"
                  rows="1"
                  required
                  class="form-control"
                ></textarea>
                <img
                  src="../../../../../assets/image/region-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>

          <!-- button here-->
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12 form-group">
                <button type="submit" class="btn btn-oclm-null w-100">
                  Back
                </button>
              </div>
              <div class="col-md-8 col-sm-6 col-xs-12 form-group">
                <button type="submit" class="btn btn-oclm w-100">
                  Assign to Field Agent
                  <img
                    src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group text-right">
              <span class="d-color">Download</span>
              <img
                src="../../../../assets/image/pdf-download.svg"
                class="pdf-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inbox-body">
      <div class="padding-wrapper">
        <div>
          <p>TID116</p>
        </div>
        <div>
          <p>Robert Gumm</p>
        </div>
        <div>
          <p>Leaf removal, edging and trimming</p>
        </div>
        <div>
          <p>125th street, San Jose City, California</p>
        </div>
      </div>
    </div>
    <div class="inbox-body">
      <div class="padding-wrapper">
        <div>
          <p>TID115</p>
        </div>
        <div>
          <p>Robert Gumm</p>
        </div>
        <div>
          <p>Leaf removal, edging and trimming</p>
        </div>
        <div>
          <p>125th street, San Jose City, California</p>
        </div>
      </div>
    </div>
  </div>
</div>
