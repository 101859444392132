import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// Modules
import { SharedModule } from './shared/shared.module';

// Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { GlobalLoaderComponent } from './core/components/global-loader/global-loader.component';
import { LoaderService } from './core/helpers/services/loader.service';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AsyncPipe } from '@angular/common';
import { MessagingService } from './shared/services/messaging.service';
import { ProfileComponent } from './core/components/profile/profile.component';
import { SettingsComponent } from './core/components/settings/settings.component';

import { CmHeaderComponent } from './core/components/cm-header/cm-header.component';
import { ReportsComponent } from './core/components/reports/reports.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { BlankPageComponent } from './core/components/blank-page/blank-page.component';
import { GooglePinComponent } from './core/components/google-pin/google-pin.component';
import { ReportDetailsTabularViewComponent } from './core/components/report-details-tabular-view/report-details-tabular-view.component';
import { ReportDetailsComponent } from './core/components/report-details/report-details.component';
import { ReportDetailsTableComponent } from './core/components/report-details-table/report-details-table.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UsersModule } from './core/Users/main-users/users.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NoCacheInterceptor } from './core/helpers/interceptors/no-cache-interceptor.service';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentsModule } from './core/Users/main-users/shared/components/components.module';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    GlobalLoaderComponent,
    ProfileComponent,
    SettingsComponent,
    CmHeaderComponent,
    ReportsComponent,
    BlankPageComponent,
    GooglePinComponent,
    ReportDetailsTabularViewComponent,
    ReportDetailsComponent,
    ReportDetailsTableComponent
  ],
  imports: [
    BrowserModule,
    AngularMultiSelectModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    UsersModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HighchartsChartModule,
    NgxMaskModule,
    ComponentsModule
  ],
  providers: [LoaderService, MessagingService, AsyncPipe,
    { provide: HttpHeaders, useValue: { 'cache-control': 'no-cache, no-store' },
    },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  exports: [GlobalLoaderComponent]
})

export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
