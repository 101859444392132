import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() {

  }

  checkForClear(): void {
    if (this.isAppUpdated()) {
      this.clearAllCookies();
    }
  }


  isAppUpdated(): boolean {
    const currentAppVersion = '1.0.4';
    const storedAppVersion = this.getStoredAppVersion();
    if (currentAppVersion !== storedAppVersion) {
      this.setStoredAppVersion(currentAppVersion);
      return true;
    }
    return false;
  }

  clearAllCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
    }
  }

  getStoredAppVersion(): string {
    return localStorage.getItem('appVersion') || '';
  }

  setStoredAppVersion(version: string) {
    localStorage.setItem('appVersion', version);
  }
}
