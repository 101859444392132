<div class="container">
  <div class="container__rows" [formGroup]="form">
    <ng-container *ngFor="let item of data">
      <div class="row">
        <div class="titles">
          <span>{{ item.header }}</span>
          <!--<span>{{ item.column }}</span>-->
        </div>
        <div class="toggle">
          <mat-slide-toggle color="primary" [formControlName]="item.column"></mat-slide-toggle>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="container__actions">
    <app-button size="small" (click)="save()">Appliquer</app-button>
    <app-button size="small" (click)="cancel()">Annuler</app-button>
  </div>
</div>
