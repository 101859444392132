<div
  bsModal
  #lgModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <!-- File Upload Confirmation -->
          {{ modelDetails?.header }}
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="lgModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Do you want to upload the Completion of work order documents? -->
        <p class="delete-msg">{{ modelDetails?.description }}</p>

        <div class="foot-btn">
          <button type="button" class="btn btn-oclm-null" (click)="confirm()">
            <!-- Yes -->
            {{ modelDetails?.btnText1 }}
          </button>
          <button type="button" class="btn btn-oclm" (click)="decline()">
            <!-- No -->
            {{ modelDetails?.btnText2 }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
