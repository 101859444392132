<nav class="container-fluid" *ngIf="isSupervisor || isFieldAgent || isAdmin || isCommunityManager">
  <div class="head main-padding d-print-none">
    <div class="head-left">
      <a class="menu-btn" (click)="toggleSidebar()">
        <img alt="icon" src="../../../../assets/image/side-menu-btn.svg" />
      </a>

      <h2 *ngIf="HeaderTitle">{{ HeaderTitle }}</h2>
      <h2 *ngIf="!HeaderTitle && isSupervisor">Home</h2>
      <h2 *ngIf="!HeaderTitle && isFieldAgent">Inbox</h2>
    </div>
    <div class="head-right">
      <!-- <div id="google_translate_element"></div> -->
<!--      <div class="notification-badge">-->
<!--        <span>-->
<!--          <div class="dropdown">-->
<!--            <button *ngIf="!isAdmin" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"-->
<!--              aria-expanded="false" class="notify-icon dropdown-toggle">-->
<!--              <img alt="icon" src="../../../../assets/image/notification-icon-1.svg" />-->
<!--              <span class="badge">{{-->
<!--                notificationLength > 0 ? notificationLength : ''-->
<!--              }}</span>-->
<!--            </button>-->
<!--            <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-center notify-body">-->
<!--              <div class="notify-contain">-->
<!--                <span class="ml-1 text-danger">Click to:</span>-->
<!--                <a class="mx-2 my-2 btn btn-outline-danger" (click)="markAll()">Mark All as Read</a>-->
<!--                <ng-container *ngFor="let item of notificationList; let i = index">-->
<!--                  <a (click)="updateNotification(item.NotificationId, i)" class="dropdown-item"-->
<!--                    [ngClass]="{ unread: !item.Read }">-->
<!--                    <span>-->
<!--                      <span class="notify-msg" *ngIf="!isSupervisor">{{ item.UserTo | firstWord }}-->
<!--                      </span>-->
<!--                      <span class="notify-msg" *ngIf="isSupervisor">{{ item.UserTo | firstWord }}-->
<!--                      </span>-->
<!--                    </span>-->
<!--                    <span class="notify-text">-->
<!--                      <i class="blur-text">{{ item.MessageText }}</i>-->
<!--                      <span class="notify-date">{{-->
<!--                        item.CreatedDate | date: 'MMM d, y'-->
<!--                      }}</span>-->
<!--                    </span>-->
<!--                  </a>-->
<!--                </ng-container>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </span>-->
<!--      </div>-->
      <div class="user">
        <p *ngIf="updateStatus">{{ currentUser.Name }}</p>
        <p *ngIf="!updateStatus">{{ justUser }}</p>
        <div class="user-img" *ngIf="!currentUser.DocPath">
          <img alt="icon" src="../../../../../../assets/image/user-image-2.png" />
        </div>
        <div class="user-img" *ngIf="currentUser.DocPath">
          <img alt="icon" src="{{ photoURL }}" />
        </div>
      </div>
    </div>
  </div>
</nav>
