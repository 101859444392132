import { HostListener, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { JobList } from '../models/job-list';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { APIResponse, WorkOrderStatusCount } from '../models/api-response';
import { CommonService } from './common.service';
import { fromEvent, Observable } from 'rxjs';
import { FileUploadResponse, uploadPhotoFilePath } from '../models/work-order';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class MethodsForAllRoles {

  constructor(private readonly commonService: CommonService) {
  }

  setHeaderTitleInSession(englishExpression = 'Home', otherLangExpression = 'Hogar'): void {
    if (JSON.parse(localStorage.currentUser).LanguageName === 'English') {
      localStorage.setItem(environment.headerTitle, englishExpression);
    } else {
      localStorage.setItem(environment.headerTitle, otherLangExpression);
    }
  }

  getJobListBySupervisorId(withBlocked = false): Observable<JobList[]> {
    let jobList: JobList[] = [];
    return this.commonService
      .getJobListBySupervisor(withBlocked)
      .pipe(map((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          jobList = res.Data;
          jobList.forEach((element) => {
            return (element.checked = false);
          });
          return jobList;
        }
      }));
  }

  searchFilter(nativeElement): Observable<any> {
    return fromEvent(nativeElement, 'keyup')
      .pipe(
        map((event: KeyboardEvent) => {
          return (event.target as HTMLInputElement).value;
        }),
        debounceTime(1000),
        distinctUntilChanged());
  }

  fileUploadResponse(response: FileUploadResponse): uploadPhotoFilePath[] {
    const arr: uploadPhotoFilePath[] = [];
    if (
      response &&
      response.Data &&
      response.Data.FilePath &&
      response.Data.FilePath.length > 0
    ) {
      let result = response.Data.FilePath;
      result.forEach((element) => {
        let fileExtension = element.split('.');
        arr.push({
          filePath: element,
          extension: fileExtension[fileExtension.length - 1],
        });
      });
    }
    return arr;
  }

  changeDate(value): { dateFrom: string, dateTo: string } {
    const startDate = value['startDate'];
    let endDate = value['endDate'];
    endDate = endDate.subtract(1, 'days');
    const dateFrom = startDate ? moment(startDate['$d']).format('MM-DD-YYYY') : null;
    const dateTo = endDate ? moment(endDate['$d']).format('MM-DD-YYYY') : null;
    return { dateFrom, dateTo };
  }

  setWorkOrderLength(activeTab: string, WorkOrderCountDetails: WorkOrderStatusCount): number {
    let workListLengthPagination;
    if (activeTab === 'Completed') {
      workListLengthPagination = WorkOrderCountDetails.Completed;
    } else if (activeTab === 'WorkInProgress') {
      workListLengthPagination = WorkOrderCountDetails.WorkInProgress;
    } else if (activeTab === 'UnderReview') {
      workListLengthPagination = WorkOrderCountDetails.UnderReview;
    } else if (activeTab === 'Pending') {
      workListLengthPagination = WorkOrderCountDetails.Pending;
    } else {
      workListLengthPagination = WorkOrderCountDetails.Schedule;
    }
    return workListLengthPagination;
  }

  getTileData(obj) {
    const arr = Object.keys(obj);
    return  arr.map(function(key) {
      let tileName: string;
      switch ([key][0]) {
        case 'Pending':
          tileName = 'Open';
          break;
        case 'WorkInProgress':
          tileName = 'Work In Progress';
          break;
        case 'UnderReview':
          tileName = 'Under Review';
          break;
        case 'Completed':
          tileName = 'Completed';
          break;
        // case 'Schedule':
        //   tileName = 'Schedule';
        //   break;

        default:
          tileName;
      }
      return { id: [key][0], name: `${tileName} , ${obj[key]}` };
    });
  }

}
