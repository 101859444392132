import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input()
  public loading: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public outline: boolean = true;

  @Input()
  public size: 'middle' | 'large' | 'small' = 'large';

  @Output()
  public onClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public click(): void {
    if (this.disabled) {
      return;
    }
    this.onClick.emit();
  }
}
