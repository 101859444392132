<!--New version-->
<div class='image-box'>
  <ng-container *ngIf='base64; else usualPreview'>
    <ng-container *ngFor='let item of uploadFile; let i = index'>

      <div class='img-show-thumb'>
        <div
          class='action-buttons'
          [class.one-item-grid]='!isEdit'>
          <a (click)='onDeleteImage(item, i)' tooltip='Delete file'>
            <img alt='icon' src='../../../../assets/image/delete.svg' class='small-delete-icon' />
          </a>
        </div>
        <div class='main-img'>
          <!--Image-->


          <a *ngIf="
          (item.file.type == 'image/png' ||
          item.file.type == 'image/jpg' ||
          item.file.type == 'image/jpeg' ||
          item.file.type == 'image/gif' ||
          item.file.type == 'image/tiff')"
          (click)='openDocumentInNewTab(item.Content, item.file.type)'>
            <img
              width='100'
              height='100'
              [src]='get64Url(item.Content, item.file.type)'
              [title]='item.file.type' />
          </a>
          <a *ngIf="item.file.type == 'application/pdf'"
             (click)='openDocumentInNewTab(item.Content, item.file.type)'>
            <img alt='icon'
                 width='100'
                 height='100'
                 src='../../../../assets/image/PDFThumbnail.png' />
          </a>
          <span *ngIf="
                item.file.type == 'm4a' ||
                item.file.type == 'ogv' ||
                item.file.type == 'mp4' ||
                item.file.type == 'mov' ||
                item.file.type == 'wmv' ||
                item.file.type == 'avi' ||
                item.file.type == 'mkv' ||
                item.file.type == 'flv' ||
                item.file.type == '3gpp'">Video</span>
          <video *ngIf="
                item.file.type == 'm4a' ||
                item.file.type == 'ogv' ||
                item.file.type == 'mp4' ||
                item.file.type == 'mov' ||
                item.file.type == 'wmv' ||
                item.file.type == 'avi' ||
                item.file.type == 'mkv' ||
                item.file.type == 'flv' ||
                item.file.type == '3gpp'"
                 class='ml-2'
                 width='100'
                 height='100'
                 controls>
            <source [src]='item.filePath' type='video/mp4'>
          </video>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #usualPreview>
    <ng-container>
      <ng-container *ngFor='let item of uploadFile; let i = index'>
        <div class='img-show-thumb'>
          <div
            class='action-buttons' [class.one-item-grid]='!isEdit' *ngIf='isDelete'>
            <a *ngIf='isEdit'
               (click)='onImageCommentUpdate(item.filePath, EditCommentTemplate)'
               tooltip='Edit comment'>
              <img alt='icon' src='../../../../assets/image/edit.svg' class='small-edit-icon' /></a>
            <a (click)='onDeleteImage(item, i)' tooltip='Delete file'>
              <img alt='icon' src='../../../../assets/image/delete.svg' class='small-delete-icon' />
            </a>
          </div>
          <div class='main-img' (click)='onImageClick(template, item)'>
            <!--Image-->
            <a *ngIf="
        (item.extension.toLowerCase() == 'png' ||
          item.extension.toLowerCase() == 'jpg' ||
          item.extension.toLowerCase() == 'jpeg' ||
          item.extension.toLowerCase() == 'gif' ||
          item.extension.toLowerCase() == 'tiff')">
              <img width='100' height='100' [src]='getUrl(item.filePath)' [title]='item.extension' />
            </a>
            <!--PDF-->
            <a *ngIf="item.extension == 'pdf'" [href]='item.filePath' target='_blank'>
              <img alt='icon' width='100' height='100' src='../../../../assets/image/PDFThumbnail.png' />
            </a>
            <!-- Video-->
            <video *ngIf="
        item.extension.toLowerCase() == 'm4a' ||
        item.extension.toLowerCase() == 'ogv' ||
        item.extension.toLowerCase() == 'mp4' ||
        item.extension.toLowerCase() == 'mov' ||
        item.extension.toLowerCase() == 'wmv' ||
        item.extension.toLowerCase() == 'avi' ||
        item.extension.toLowerCase() == 'mkv' ||
        item.extension.toLowerCase() == 'flv' ||
        item.extension.toLowerCase() == '3gpp'" class='ml-2' width='100' height='100' controls>
              <source [src]='item.filePath' type='video/mp4'>
            </video>
          </div>
        </div>
      </ng-container>

    </ng-container>
  </ng-template>

</div>

<ng-template #template >
  <div class='modal-header'>
    <button
      type='button'
      class='close pull-right'
      aria-label='Close'
      (click)='modalRef.hide()'>
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body work-modal' >
    <carousel
      [interval]='false'
      [(activeSlide)]='activeSlideIndex'>
      <div *ngFor='let item of carouselItems'>
        <slide *ngIf='findExt(item.filePath)'>
          <img [src]='item.filePath' [alt]='item.filePath' style='display: block; width: 100%' />
          <p *ngIf='item.uploadDate' style='float: right; margin-bottom: 1rem'>
            Attached on: {{ item.uploadDate | date }}
          </p>
          <div class='carousel-caption d-md-block'>
            <p class='comment'>
              {{ item.uploadComment }}
            </p>
          </div>
        </slide>
      </div>
    </carousel>
  </div>
</ng-template>

<ng-template #EditCommentTemplate>
  <div class='modal-header'>
    <button
      type='button'
      class='close pull-right'
      aria-label='Close'
      (click)='modalRef.hide()'>
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body work-modal'>
    <div class='form-group'>
      <label for='exampleFormControlInput1'>Comment:</label>
      <input type='text' class='form-control' [(ngModel)]='comment' />
      <button
        type='submit'
        (click)='updateImageComment(comment, commentId)'
        class='btn btn-oclm mb-2 mt-2'>
        Update
      </button>
    </div>
  </div>
</ng-template>
