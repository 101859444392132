import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-datepicker-custom-header',
  templateUrl: './datepicker-custom-header.component.html',
  styleUrls: ['./datepicker-custom-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerCustomHeaderComponent<D> implements OnDestroy {
  private $destroyed = new Subject<void>();

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS)
    private dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    calendar.stateChanges
      .pipe(takeUntil(this.$destroyed))
      .subscribe(() => changeDetectorRef.markForCheck());
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  get month(): string {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
  }

  public previous(): void {
    if (this.calendar.currentView === 'month') {
      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    }
    if (this.calendar.currentView === 'year') {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
    }
    if (this.calendar.currentView === 'multi-year') {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -24);
    }
  }

  public next(): void {
    if (this.calendar.currentView === 'month') {
      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    }
    if (this.calendar.currentView === 'year') {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
    }
    if (this.calendar.currentView === 'multi-year') {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 24);
    }
  }

  public yearSelection(): void {
    this.calendar.currentView = 'multi-year';
  }
}
