<div class="con-info-group first-form">
  <h5 >{{ 'BtnText.ContactInfo' | translate }}</h5>
  <button class="btn btn-oclm" data-toggle="modal" data-target="#contact-info">
    {{ 'BtnText.ViewDetails' | translate }}
  </button>
</div>
<div class="wrp-map-stpr">
  <div id="stepper1" class="bs-stepper">
    <div class="bs-stepper-header">
      <div class="step activeted" data-target="#test-l-1">
        <button class="btn step-trigger">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">
            {{ 'BtnText.WorkOrderDetails' | translate }}
          </span>
        </button>
      </div>
      <div class="line" [ngClass]="{ 'red-line': showLine }"></div>
      <div
        class="step activeted"
        [ngClass]="{ 'activeted ': showLine }"
        data-target="#test-l-2"
      >
        <button class="btn step-trigger">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">
            {{ 'BtnText.UploadPictures' | translate }}
          </span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content">
      <form [formGroup]="workOrderForm">
        <div id="test-l-1" class="content">
          <div class="card card-body">
            <div class="first-form">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.JobName' | translate }} /
                      {{ 'CommonLabel.CustomerName' | translate }}
                    </label>
                    <div class="wrp">
                      <select
                        class="form-control"
                        id="fieldAgent"
                        formControlName="CustomerId"
                        (change)="getSelectedJobs($event.target.value)"
                        [ngClass]="{
                          'is-invalid': submitted && f.CustomerId.errors
                        }"
                      >
                        <option [value]="null">
                          -{{ 'CommonLabel.Select' | translate }}-
                        </option>
                        <option
                          *ngFor="let value of jobListDetails"
                          [value]="value.Id"
                        >
                          {{ value.Name }}
                        </option>
                      </select>
                      <img
                        src="../../../../assets/image/work-request-icon.png"
                        class='user-icon'
                      />
                      <div
                        *ngIf="submitted && f.CustomerId.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.CustomerId.errors.required">
                          Job Name/Customer Name is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.Location' | translate }}/{{
                        'CommonLabel.Address' | translate
                      }}
                    </label>
                    <div class="wrp">
                      <input
                        type="text"
                        placeholder="{{ 'CommonLabel.Location' | translate }}"
                        formControlName="Address"
                        (keydown.enter)="setLocationName($event)"
                        (focusout)="setLocationName($event)"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        #search
                        [ngClass]="{
                          'is-invalid': submitted && f.Address.errors
                        }"
                        class="form-control"
                      />
                      <img
                        src="../../../../assets/image/region-icon.png"
                        class='user-icon'
                      />
                      <div
                        *ngIf="submitted && f.Address.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.Address.errors.required">
                          {{ 'Message.LocationRequired' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label>{{ 'Table.WorkCategory' | translate }}</label>
                    <div class="wrp">
                      <select
                        class="form-control"
                        id="workcatergory"
                        formControlName="WorkCategory"
                        (change)="onChangeOfWorkCategory($event.target.value)"
                        [ngClass]="{
                          'is-invalid': submitted && f.WorkCategory.errors
                        }"
                      >
                        <option [value]="null">
                          -{{ 'CommonLabel.Select' | translate }}-
                        </option>
                        <option
                          *ngFor="let value of workCategoryList"
                          [value]="value.Text"
                        >
                          {{ value.Text }}
                        </option>
                      </select>
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                      <div
                        *ngIf="submitted && f.WorkCategory.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.WorkCategory.errors.required">
                          {{ 'Message.WorkCategoryRequired' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.DescriptionOfWork' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      formControlName="WorkRequestID"
                      [ngClass]="{
                        'is-invalid': submitted && f.WorkRequestID.errors
                      }"
                      placeholder="{{
                        'CommonLabel.DescriptionOfWork' | translate
                      }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/comment-icon.png"
                      class='user-icon'
                    />
                    <div
                      *ngIf="submitted && f.WorkRequestID.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.WorkRequestID.errors.required">
                        Description of Work is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12"
                *ngIf="workOrderForm['controls'].CompletedOn.value == null"
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.CompleteBy' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      formControlName="CompletedBy"
                      class="form-control"
                    />
                    <img
                      src="../../../../assets/image/date-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12"
                *ngIf="workOrderForm['controls'].CompletedOn.value != null"
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.CompleteOn' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      placeholder="{{ 'CommonLabel.CompleteOn' | translate }}"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      formControlName="CompletedOn"
                      class="form-control"
                      disabled
                    />
                    <img
                      src="../../../../assets/image/date-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="form-group">
                  <label>{{ 'CommonLabel.Notes' | translate }}</label>
                  <div class="wrp">
                    <textarea
                      rows="1"
                      formControlName="Notes"
                      class="form-control"
                    ></textarea>
                    <img
                      src="../../../../../assets/image/comment-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.ScheduleBy' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      formControlName="StartDate"
                      placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      class="form-control"
                    />
                    <img
                      src="../../../../assets/image/date-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <!-- View Notes start here -->
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                *ngIf="WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0"
              >
                <div class="form-group">
                  <label>{{ 'CommonLabel.ViewNotes' | translate }}</label>
                  <div class="view-note-box">
                    <!-- 1st box -->
                    <ng-container *ngFor="let item of WorkOrderNotesDetail">
                      <div
                        class="view-note"
                        *ngIf="
                          item && item.NotesText && item.NotesText.length > 0
                        "
                      >
                        <div class="notify-msg">
                          {{ item.CreatedBy | firstWord }}
                        </div>
                        <div class="notify-text">
                          <span class="notify-date">
                            {{ item.CreatedBy }},
                            {{ item.CreatedDate | date: 'd MMMM, y,h:mm a' }}
                          </span>
                          <span>{{ item.NotesText }}</span>
                        </div>
                      </div>
                      <!-- 2st box -->
                    </ng-container>
                  </div>
                </div>
              </div>

              <ng-container
                *ngFor="let item of DynamicFieldsList; let i = index"
              >
                <div class="col-md-8 col-sm-8 col-xs-4" *ngIf="i % 2 == 0">
                  <div class="form-group">
                    <label>{{ item.FieldName }}</label>
                    <div class="wrp">
                      <input
                        class="form-control mb-3"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="item.Values"
                        placeholder="Values"
                      />
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4" *ngIf="i % 2 != 0">
                  <div class="form-group">
                    <label>{{ item.FieldName }}</label>
                    <div class="wrp">
                      <input
                        class="form-control mb-3"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="item.Values"
                        placeholder="Values"
                      />
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-8 col-sm-8 col-xs-12 add-field">
                <span>
                  <strong>{{ 'BtnText.AddMoreField' | translate }}</strong>
                </span>
                <span>
                  <app-add-more-fields
                    (onAddMoreFields)="onAddMoreFieldsResponse($event)"
                  ></app-add-more-fields>
                </span>
              </div>
              <div class="col-md-6 c0l-xs-12 mb-4">
                <p class="thumb-text">
                  {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
                </p>
                <app-preview-file
                  [uploadFile]="uploadBeforePhotoFilePath"
                ></app-preview-file>
                <!-- <button type="submit" class="btn btn-secondary z-index"> Upload More </button> -->
                <app-multi-file-upload
                  (OnFileUploadResponse)="fileUploadResponseBefore($event)"
                  [UploadBtnText]="'Upload Picture'"
                >
                </app-multi-file-upload>
              </div>
            </div>
            <div class="group-btn-last mt-5">
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-oclm-null"
                  (click)="backToPrevious()"
                >
                  {{ 'BtnText.Cancel' | translate }}
                </button>
              </div>
              <div class="download-cls" *ngIf="existingRecord">
                <app-download-work-order
                  [WorkIdVal]="WorkIdVal"
                  [workOrderType]="'manual'"
                ></app-download-work-order>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-oclm w-50"
                  (click)="createWorkOrder('Save')"
                >
                  {{ 'BtnText.Save' | translate }}
                  <img
                    src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
              <div class="form-group text-right">
                <button
                  type="submit"
                  class="btn btn-oclm w-50"
                  (click)="next('first')"
                >
                  {{ 'BtnText.Next' | translate }}
                  <img
                    src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="test-l-2" class="content">
          <div class="card card-body">
            <div class="first-form">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label>
                      {{
                        'CommonLabel.LocationCompletionWorkOrder' | translate
                      }}
                    </label>
                    <div class="wrp">
                      <textarea
                        rows="1"
                        formControlName="CompletedAddress"
                        class="form-control"
                      ></textarea>
                      <img
                        src="../../../../../assets/image/region-icon.png"
                        class='user-icon'
                      />
                    </div>
                  </div>
                </div>
                <!-- START -  This dropdown will be displayed only status change -->
                <div
                  class="col-md-12 col-sm-12 col-xs-12"
                  *ngIf="existingRecord"
                >
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.CannedResponse' | translate }}
                    </label>
                    <div class="wrp">
                      <select
                        class="form-control"
                        id="cannedCategory"
                        formControlName="CannedResponseId"
                        (change)="getCannedResponseText($event.target.value)"
                      >
                        <option [value]="null">
                          -{{ 'CommonLabel.Select' | translate }}-
                        </option>
                        <option
                          *ngFor="let value of cannedResponseList"
                          [ngValue]="value.ID"
                        >
                          {{ value.Text }}
                        </option>
                      </select>
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-12 col-sm-12 col-xs-12"
                  *ngIf="
                    existingRecord &&
                    CannedResponseName === 'Other' &&
                    workOrderForm['controls'].CannedResponseText.value
                  "
                >
                  <div class="form-group">
                    <label>
                      <!-- {{'CommonLabel.CannedResponse' | translate}} -->
                    </label>
                    <div class="wrp">
                      <input
                        type="text"
                        placeholder="Other canned response"
                        formControlName="CannedResponseText"
                        class="form-control"
                      />
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                    </div>
                  </div>
                </div>

                <!-- END -  This dropdown will be displayed only status change -->

                <div class="col-md-6 c0l-xs-12 mb-4">
                  <p class="thumb-text">
                    {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
                  </p>
                  <app-preview-file
                    [uploadFile]="uploadAfterPhotoFilePath"
                  ></app-preview-file>
                  <!-- <button type="submit" class="btn btn-secondary z-index"> Upload Picture </button> -->
                  <app-multi-file-upload
                    (OnFileUploadResponse)="fileUploadResponseAfter($event)"
                    [UploadBtnText]="'Upload Picture'"
                  >
                  </app-multi-file-upload>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6 col-sm-6 col-5 form-group">
                <button
                  type="submit"
                  class="btn btn-oclm-null"
                  (click)="previous()"
                >
                  {{ 'BtnText.Back' | translate }}
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-7 form-group text-right">
                <button
                  type="submit"
                  class="btn btn-oclm w-50"
                  (click)="createWorkOrder('finalSave')"
                >
                  {{ 'BtnText.UpdateWorkOrder' | translate }}
                  <img
                    src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="login-left-Mapbox" style="opacity: 0.8">
    <app-google-map
      [extLat]="latitude"
      [extLong]="longitude"
      (gmapResponse)="gmapResponseUpdate($event)"
    >
    </app-google-map>
  </div>
</div>

<ng-container *ngFor="let item of uploadAfterPhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngFor="let item of uploadBeforePhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model0{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
  id="contact-info"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">
          {{ 'BtnText.ContactInfo' | translate }}
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="ContactForm">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.FirstName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                formControlName="ContactFirstName"
                placeholder="{{ 'CommonLabel.FirstName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.LastName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                formControlName="ContactLastName"
                placeholder="{{ 'CommonLabel.LastName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.Email' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                formControlName="ContactEmail"
                placeholder="{{ 'CommonLabel.Email' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/email-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>
              {{ 'CommonLabel.PhoneNumber' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                appPhoneNumber
                formControlName="ContactPhoneNumber"
                placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
                class="form-control"
                maxlength="10"
                minlength='10'
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                required
              />
              <img
                src="../../../../assets/image/phone-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-12 form-group">
            <label>
              {{ 'CommonLabel.Location' | translate }}/{{
                'CommonLabel.Address' | translate
              }}
            </label>
            <div class="wrp">
              <input
                type="text"
                maxlength="200"
                formControlName="ContactAddress"
                placeholder="{{ 'CommonLabel.Address' | translate }}"
                class="form-control"
              />
              <img
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
      </div>
      <div class="foot-btn text-center mt-1 mb-4">
        <button type="button" class="btn btn-oclm w-50" data-dismiss="modal">
          {{ 'BtnText.SaveDetails' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-model #modalInfo (response)="modalResponse($event)"></app-model>
