<div class="login-left-box">
  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleCaptions"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          alt="..."
        />
        <div class="carousel-caption d-none d-md-block">
          <h5>Based on</h5>
          <p>
            O'Connell Landscape is a Los Angeles based area landscape and
            maintenance services company.
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          alt="..."
        />
        <div class="carousel-caption d-none d-md-block">
          <h5>Based on</h5>
          <p>
            O'Connell Landscape is a Los Angeles based area landscape and
            maintenance services company.
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          alt="..."
        />
        <div class="carousel-caption d-none d-md-block">
          <h5>Based on</h5>
          <p>
            O'Connell Landscape is a Los Angeles based area landscape and
            maintenance services company.
          </p>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleCaptions"
      role="button"
      data-slide="prev"
    >
      <span class="prev-icon c-btn" aria-hidden="true"> Back </span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleCaptions"
      role="button"
      data-slide="next"
    >
      <span class="next-icon c-btn" aria-hidden="true"> Next </span>
    </a>
  </div>
</div>
