<!-- Do you want to upload the Completion of work order documents? -->

    <ng-content></ng-content>


<div class="row" *ngIf="version === 'standard'" >
  <div class="col-md-12">
    <h4 class="d-flex justify-content-center mt-5 my-charts">
      Work Order Details
    </h4>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr class="text-left">
            <th
              scope="col"
              style="width: 11.11%"
              sortable="WorkID"
              (sort)="onSort($event)">
              # / Job Name
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="WorkCategory"
              (sort)="onSort($event)">
              Work Category
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="TaskName"
              (sort)="onSort($event)"
            >
              Task
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="Address"
              (sort)="onSort($event)"
            >
              Address
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="WorkStatusText"
              (sort)="onSort($event)"
            >
              Work Order Status
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="AssignedTo"
              (sort)="onSort($event)"
            >
              Assigned To
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="CreatedBy"
              (sort)="onSort($event)"
            >
              Created By
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="CompletedOn"
              (sort)="onSort($event)"
            >
              Completed On
            </th>
            <th
              scope="col"
              style="width: 11.11%"
              sortable="CreatedDate"
              (sort)="onSort($event)"
            >
              Created Date
            </th>
          </tr>
        </thead>

        <tbody>
          <ng-container
            *ngIf="modelDetails && modelDetails.length > 0; else elseTemplate"
          >
            <tr
              *ngFor="let item of modelFilter ?? modelDetails"
              class="text-left"
              style="cursor: pointer"
              (click)="detailPage(item)"
            >
              <td>{{ item.WorkID }} / {{ item.JobName }}</td>
              <td>{{ item.WorkCategory }}</td>
              <td>{{ item.TaskName }}</td>
              <td>{{ item.Address }}</td>
              <td>{{ item.WorkStatusText }}</td>
              <td>{{ item.AssignedToList }}</td>
              <td>{{ item.CreatedBy }}</td>
              <td>{{ item.CompletedOn | date: 'MMM d, y' }}</td>
              <td>{{ item.CreatedDate | date: 'MMM d, y' }}</td>
            </tr>
          </ng-container>
          <ng-template #elseTemplate>
            <tr>
              <td colspan="7">No Record Found!</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row" *ngIf="version === 'print'">
  <div class="col-md-12">
    <h4 class="d-flex justify-content-center mt-5 my-charts">
      Work Order Details
    </h4>
    <div class="table-responsive">
      <table class="table table-striped print-table">
        <thead>
        <tr class="text-left">
          <th
            scope="col"
            style="width: 17%"
            sortable="WorkID"
            (sort)="onSort($event)">
            # / Job Name
          </th>
          <th
            scope="col"
            style="width: 11%"
            sortable="WorkCategory"
            (sort)="onSort($event)">
            Category
          </th>
          <th
            scope="col"
            style="width: 19%"
            sortable="Address"
            (sort)="onSort($event)">
            Address
          </th>
          <th
            scope="col"
            style="width: 9%"
            sortable="WorkStatusText"
            (sort)="onSort($event)">
            Status
          </th>
          <th
            scope="col"
            style="width: 17%"
            sortable="AssignedTo"
            (sort)="onSort($event)">
            Assigned To
          </th>
          <th
            scope="col"
            style="width: 11%"
            sortable="CreatedBy"
            (sort)="onSort($event)">
            Created By
          </th>
          <th
            scope="col"
            style="width:9%;"
            sortable="CompletedOn"
            (sort)="onSort($event)">
            Completed
          </th>
          <th
            scope="col"
            style="width: 7%;"
            sortable="CreatedDate"
            (sort)="onSort($event)">
            Created
          </th>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngIf="modelDetails && modelDetails.length > 0; else elseTemplate">
          <ng-container *ngFor="let item of modelFilter ?? modelDetails">
            <tr style="cursor: pointer" class='print-table'>
              <td>{{ item.WorkID }} / {{ item.JobName }}</td>
              <td>{{ item.WorkCategory }}</td>
              <td>{{ item.Address }}</td>
              <td>{{ item.WorkStatusText }}</td>
              <td>{{ item.AssignedToList }}</td>
              <td>{{ item.CreatedBy }}</td>
              <td>{{ item.CompletedOn | date: 'M/d/yy' }}</td>
              <td>{{ item.CreatedDate | date: 'M/d/yy' }}</td>
            </tr>
            <tr style="cursor: pointer" >
              <td colspan="12" style='padding: 0.25rem 0.5rem'>{{ item.TaskName }}</td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-template #elseTemplate>
          <tr>
            <td colspan="7">No Record Found!</td>
          </tr>
        </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>
