<div class="col-md-12 col-sm-12 col-xs-12">
  <div class="form-group mb-3">
    <button class="btn btn-oclm w-100" (click)="addItem()">
      {{ 'BtnText.AddMore' | translate }}
      <img
        alt='icon'
        src="../../../../assets/image/add-icon.svg"
        width="20"
        height="20"
        class="arrow-right"
      />
    </button>
  </div>
</div>

<!-- Display model to show on add fields -->
<div
  *ngIf="isModalShown"
  [config]="{ show: true }"
  (onHidden)="onHidden()"
  bsModal
  #autoShownModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">
          {{ 'BtnText.AddMoreField' | translate }}
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 form-group">
            <label>{{ 'CommonLabel.Label' | translate }}</label>
            <div class="wrp">
              <input
                [(ngModel)]="FieldName"
                type="text"
                placeholder="Missing details"
                class="form-control"
                maxlength="100"/>
              <img
                src="../../../../assets/image/job-icon.svg"
                class='user-icon'/>
            </div>
          </div>
          <div class="col-md-12 form-group">
            <label>{{ 'CommonLabel.Value' | translate }}</label>
            <div class="wrp">
              <input
                [(ngModel)]="Values"
                type="text"
                placeholder="Type details.."
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/value-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="foot-btn text-center mt-4">
          <button
            type="button"
            class="btn btn-oclm w-50"
            (click)="addMoreFields()"
          >
            {{ 'BtnText.Add' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
