import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  constructor() {}

  public static Common_Const = {
    MySelf: 'Myself',
  };

  public static WorkOrderType = [
    {
      name: 'New to Old',
      value: true,
    },
    {
      name: 'Old to New',
      value: false,
    },
  ];

  public static ItemsPerPage = [
    {
      pageSize: 25,
    },
    {
      pageSize: 50,
    },
    {
      pageSize: 75,
    },
  ];

  public static User_Role = {
    SuperVisor: 'SuperVisor',
    FieldAgent: 'FieldAgent',
    CommunityManager: 'CommunityManager',
    Admin: 'Admin',
  };

  public static Common_APIURL = {
    MasterDetails: {
      GetFieldAgentList: 'FieldAgentList',
      GetProfileByEmail: 'GetProfileByEmail',
      GetJobListBySupervisor: 'JobListBySuperVisor',
      getAddressByCustomer: 'AddressByCustomer',
      getClientIdsByCustomer: 'ClientIdsByCustomer',
      GetSearchFieldAgent: 'SearchFieldAgent',
      GetStatusList: 'WorkStatusList',
      FieldAgentJob: 'FieldAgentJob',
      UpdateProfileJob: 'UpdateProfileJob',
      GetJobNameByZipCode: 'jobyZip',
      JobBySearchText:"jobBySearchText",
      GetUserTypeList: 'UserTypeList',
      GetCannedResponseList: 'CannedResponseList',
      CannedResponses: 'CannedResponses',
      GetTemplateList: 'TemplateList',
      GetBranchNameByJobId: 'BranchByJob',
      GetSupervisorList: 'SupervisorListByjobId',
      GetSupervisors : 'GetSupervisors',
      GetJobBySearchText: 'jobBySearchText',
      SupervisorIdsByCustomer: 'SupervisorIdsByCustomer',
      WorkCategoryList:"workCategoryList",
      DecryptUserId:"decryptUserId"
    },
    profileDetails: {
      UserLogin: 'UserLogin',
      sendEmailToUser: 'SendEmail',
      saveAgreement: 'SaveAgreement'
    },
    CreateUserToken: 'CreateUserToken',
    CreateNotification: 'CreateNotification',
    GetNotification: 'GetNotification',
    UpdateNotificationById: 'UpdateNotification',
    GetProfileByUserId: 'GetProfile',
    UpdateProfile: 'UpdateProfile',
    GetUserToken: 'GetUserToken',
    GetWorkCategoryList: 'WorkCategoryList',
    DeleteFieldAgent: 'DeleteFieldAgent',
    DeleteFieldAgentBySupervisor: 'DeleteFieldAgentBySupervisor',
    UpdateProfileSetting: 'UpdateProfileSetting',
    downloadWorkOrder: 'Export',
    getToken: 'token',
    getWorkOrderScheduled: 'WorkOrderScheduled',
    deleteWorkOrder: 'DeleteWorkOrderByID',
    getWorkOrderReport: 'WorkOrderReport',
    getWorkOrderReportStatusWiseByJob: 'WorkOrderReportStatusWiseByJob',
    getWorkOrderReportStatusWiseByOwner: 'WorkOrderReportStatusWiseByOwner',
    ExportWorkOrderReport: 'ExportWorkOrderReport',
    WorkOrderReportStatusWiseBycategory: 'WorkOrderReportStatusWiseBycategory',
    ExportCSV: 'ExportCSV',
    getWorkOrderReportView: 'WorkOrderReportView',
    getWorkOrderReportDetail: 'WorkOrderReportDetail',
    fcmAPIURL: 'https://fcm.googleapis.com/fcm/send',
    ExportPDF: 'ExportPDF'
  };

  // tslint:disable-next-line: variable-name
  public static ResidentModule_APIURL = {
    SearchWorkOrder: 'SearchWorkOrder',
    CreateWorkOrder: 'CreateWorkOrder',
    UpdateWorkOrder: 'UpdateWorkOrder',
    SupervisorIdsByCustomer: 'SupervisorIdsByCustomer',
    GetAdminEmail: 'GetAdminEmail',
    CMIsWorkOrderApproved: 'CMIsWorkOrderApproved',
    SendOTP: 'SendOTP',
    GetAdminUserDetailsByUserId: 'rms/get/adminuserdetailsbyuserid',
    GetFilledResourceDetailsbyUserId:
      'rms/get/getfilledresourcedetailsbyuserId',
    DeleteAdminUserDetailsById: 'rms/post/deleteadminuserdetailsbyid',
  };

  public static SupervisorModule_APIURL = {
    InviteFieldAgent: 'FieldAgentInvite',
    DeleteWorkOrderDocument: 'DeleteWorkOrderDocument',
    EditWorkOrderComment: 'EditWorkOrderComment',
    GetWorkOrderListByJobs: 'WorkOrderListInbox',
    PostAssignWorkOrder: 'WorkOrderAssign',
    PostSuperVisorWorkOrder: 'SuperVisorWorkOrder',
    WorkOrderComplete: 'WorkOrderComplete',
    GetWorkOrderListByStatus: 'WorkOrderListByStatus',
    GetWorkOrderByWorkId: 'WorkOrderByID',
    UpdatePDFWorkOrder: 'PDFWorkOrderUpdate',
    UpdateMultiWorkOrderAssign: 'MultiWorkOrderAssign',
    GetFieldAgentWorkorderCompletedReport: 'FieldAgentWorkorderCompletedReport',
    GetPDFWorkOrderDetail: 'PDFWorkOrderDetail',
    MarkAllNotification: 'MarkAllNotification',
  };

  // tslint:disable-next-line:variable-name
  public static FieldAgent_APIURL = {
    FieldAgentById: 'FieldAgentById',
    FieldAgentSignUp: 'FieldAgentSignUp',
    GetFieldAgentWorkOrderListInbox: 'FieldAgentWorkOrderListInbox',
    GetFieldAgentWorkOrderListByStatus: 'FieldAgentWorkOrderListByStatus',
  };

  public static CMModule_APIURL = {
    PostWOrkOrder: 'CMWorkOrder',
    GetCMWorkOrderListByStatus: 'CMWorkOrderListByStatus',
    PostMoreFieldNotes: 'CMWorkOrderNotes',
  };

  public static Admin_APIURL = {
    GetAdminListWorkOrderByStatus: 'AdminWorkOrderListByStatus',
    GetAdminWorkOrderFilter: 'AdminWorkOrderFilter',
    GetUserListUrl: 'UserList',
  };
  public static User_APIURL = {
    ProfileList:"ProfileList",
    BlockUser:"BlockUser",
    GetUserById:"GetUserById",
    GetUserLogin:"GetUserLogin",
    EditProfile:"EditProfile",
    CreateProfile:"CreateProfile",
    SendInvite:"sendInvite",
    SavePassword:"SavePassword"
  }
  public static Jobs_APIURL = {
    CustomersListWithUsers:"CustomersListWithUsers",
    CustomerList:"CustomerList",
    DeleteCustomer:"DeleteCustomer",
    CreateCustomer:"CreateCustomer",
    EditCustomer:"EditCustomer",
    GetCustomer:"GetCustomer",
    GetAdditionalLabels:"GetAdditionalLabels",
    UpdateAdditionalLabels:"UpdateAdditionalLabels",
    GetCustomerLabels: "GetCustomerLabels",
  }
  public static Authenticateuser_APIURL = {
    POST: 'authentication/authenticateuserlogin',
  };

  public static FieldAgentSignUp = {
    URL: '/#/fieldagent/signup',
  };

  public static ResidentStatus = {
    Pending: 'Pending',
    Closed: 'Closed',
    Completed: 'Completed',
    WorkInProgress: 'WorkInProgress',
    UnderReview: 'UnderReview',
  };

  public static UnauthorizedUser = {
    SendEmail: 'SendEmail'
  }

  // Set common constant for sesison storage
  public static LoggedInResponse = 'LoggedInResponse';

  // Alert Types
  public static Success = 'Success';
  public static Error = 'Error';
  public static Warning = 'Warning';
  public static Confirm = 'Confirm';

  // Customize toast message
  public static ToastSeverity = {
    SeverityError: 'error',
    SeveritySuccess: 'success',
  };
  public static ToastSummary = {
    AuthenticationFailed: 'Authentication Failed',
    FileUpload: 'File Upload',
    ServerError: 'Server Error',
    InviteAgent: 'Invitation Success',
    ErrorMessage: 'Error',
    Saved: 'Save',
    Notification: 'Notification',
    Approved: 'Approved',
    Reject: 'Reject',
    Success: 'Success',
  };

  public static SuccessMessage = {
    FileUploadSuccess: 'File has been uploaded successfully',
    SomeThingWrongMessage: 'Oops, something went wrong',
    InvitationSuccessMessage: 'Invitation has been sent to Field Agent',
    WorkOrderUpdatedSuccess: 'Work Order has been Updated successfully',
    WOrkOrderSaveSuccess: 'Work Order has been saved successfully.',
    NotificationSuccess: 'Work Order has been scheduled in Calendar',
    WOrkOrderApprovedSuccess: 'Work Order has been approved successfully',
    WOrkOrderRejectedSuccess: 'Work Order has been rejected successfully',
    ProfileUpdateSuccess: 'Profile has been updated successfully',
    NotesMailSuccess: 'Note has been added and Notification will send to user',
    MediaHasBeenSaved:'Media has been saved successfully '
  };

  public static ErrorMessage = {
    FileUploadFailed: 'Oops, something went wrong.Please upload again',
    SomeThingWrongMessage: 'Oops, something went wrong.Please try again',
    OTPNotMatch: 'Invalid OTP. Please Enter again',
    LoginFailed: 'Incorrect User Id and Password',
    FileUploadExtension: 'Only JPEG and PNG images are allowed',
    MultiFileUpload: 'Multiple file is not allowed',
  };

  // User details message
  public static AddedUserDetailsMsg =
    'User details has been added successfully';
  public static UpdatedUserDetailsMsg =
    'User details has been updated successfully';
  public static DeleteUserDetails =
    'User details has been Deleted successfully';

  // Login Details data
  public static UserName = 'UserName';
  public static UID = 'UID';
  public static RememberMe = 'RememberMe';
  public static UserType = 'UserType';

  // Global OTP Timing
  public static DefaultOTPExpTiming = 60;

  // Image format
  public static imageFormat = '.png,.jpg,.jpeg, .gif, .tiff';
  public static videoFormat =
    '.m4a, .ogv, .mp4, .mov, .wmv, .flv, .avi, .mkv, .3gpp';
  public static fileFormat = '.pdf';
}
