<agm-map
  [latitude]="latitude"
  [longitude]="longitude"
  [zoom]="zoom"
  [scrollwheel]="false"
  [fullscreenControl]="true"
  [mapTypeControl]="true"
  (mapClick)="mapClick($event)">
  <agm-marker
    *ngFor="let data of workOrderDetails; let i = index"
    [latitude]="data.Latitude"
    [longitude]="data.Longitude"
    [markerDraggable]="false"
    (markerClick)="markerClick(iw)">
    <agm-info-window #iw>
      <a (click)="onReportMap(data.WorkID, data.WorkOrderType)">
        <span>
          Job Name:
          <b>{{ data.JobName }}</b>
        </span>
        <span>
          Created By:
          <b>{{ data.CreatedBy }}</b>
        </span>
        <span>
          Work Order Description:
          <b>{{ data.TaskName }}</b>
        </span>
      </a>
    </agm-info-window>
  </agm-marker>
</agm-map>
