import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      this.checkForClear();
      const noCacheReq = request.clone({
        headers: request.headers.set('cache-control', 'no-cache, must-revalidate')
      });
    return next.handle(noCacheReq);
  }

  checkForClear(): void {
    if (this.isAppUpdated()) {
      this.clearAllCookies();
    }
  }

  isAppUpdated(): boolean {
    const currentAppVersion = '1.0.6';
    const storedAppVersion = this.getStoredAppVersion();
    if (currentAppVersion !== storedAppVersion) {
      this.setStoredAppVersion(currentAppVersion);
      console.log('cleared cookies');
      return true;
    }
    return false;
  }

  clearAllCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
    }
  }

  getStoredAppVersion(): string {
    return localStorage.getItem('appVersion') || '';
  }

  setStoredAppVersion(version: string) {
    localStorage.setItem('appVersion', version);
  }
}
