import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, TemplateRef, ViewChild, } from '@angular/core';
import {
  CannedResponse,
  commentDoc,
  FileUploadResponse,
  Role,
  uploadPhotoFilePath,
  WorkOrderDetail,
  WorkOrderForm
} from '../../models/work-order';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { DynamicFieldsModel, DynamicModel } from '../../models/dynamic-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstantsService } from '../../services/constants.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';
import { ModelComponent } from '../model/model.component';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { APIResponse } from '../../models/api-response';
import { AngularFireAuth } from '@angular/fire/auth';
import { JobList } from '../../models/job-list';
import { Location } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { User } from '../../models/user';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import Stepper from 'bs-stepper';
import { MethodsForAllRoles } from '../../services/methods-for-all-roles.service';
import { WorkOderModel } from '../../models/workOderModel';

declare var gapi: any;
@Component({
  selector: 'app-work-order-manual',
  templateUrl: './work-order-manual.component.html',
  styleUrls: ['./work-order-manual.component.css'],
})
export class WorkOrderManualComponent implements OnInit, OnChanges {
  apiUrl = environment.apiUrl;
  private stepper: Stepper;
  workOrderForm: FormGroup;
  customizedWorkOrderForm: FormGroup;
  ContactForm: FormGroup;
  DynamicFieldsList: DynamicFieldsModel[] = [];
  uploadBeforeDocumentComments = [];
  uploadAfterDocumentComments: commentDoc[] = [];
  uploadBeforeCommentData: commentDoc[] = [];
  uploadAfterCommentData: commentDoc[] = [];
  uploadBeforeComment: string;
  uploadAfterComment: string;

  uploadBeforeId: string;
  uploadAfterId: string;
  bsConfig: Partial<BsDatepickerConfig>;
  @Input() existingRecord;
  @Input() existingWorkOrderDetails: WorkOrderForm;
  @Output() onWorkOrderUpdateResponse: EventEmitter<any>;
  @Output() onJobChange: EventEmitter<any>;
  jobListDetails: JobList[] = [];
  workCategoryList: DynamicModel[] = [];
  modalRef: BsModalRef;
  message: string;
  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('commentBeforeTemplate') commentBeforeTemplate: TemplateRef<any>;
  @ViewChild('commentAfterTemplate') commentAfterTemplate: TemplateRef<any>;
  submitted: boolean = false;
  showLine: boolean = false;
  secondLine: boolean = false;
  selectedJobName: string = '';
  uploadBeforePhotoFilePath: uploadPhotoFilePath[] = [];
  uploadAfterPhotoFilePath: uploadPhotoFilePath[] = [];
  workOrder: WorkOderModel = new WorkOderModel();
  minDate: Date;
  maxDate: Date;
  completeByInit: Date;
  cannedResponseList: CannedResponse[] = [];
  UploadedPhotoAfterIdsTemp: string[] = [];
  UploadedPhotoBeforeIdsTemp: string[] = [];
  btnText: string = 'Close Work Order';
  currentUserRole;
  WorkOrderNotesDetail: WorkOrderDetail[] = [];
  showScheduleDate: boolean = false;
  @Input() WorkIdVal;
  jobId;
  supervisorList: Role[];
  CannedResponseName;
  @ViewChild(ModelComponent) modalInfo: ModelComponent;
  @ViewChild('modalInfo') modelEle: ElementRef;
  user$: Observable<firebase.User>;
  existingLatitude: number;
  existingLongitude: number;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  workOrderType: string;
  city: string;
  zipCode: string;
  showNextBtn: boolean;
  currentUser: User;
  WorkStateComplete = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private authService: AuthenticationService,
    private commonService: CommonService,
    private location: Location,
    public afAuth: AngularFireAuth,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private methodsForAllRoles: MethodsForAllRoles,
  ) {
    this.initClient();
    this.user$ = afAuth.authState;

    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-red',
        useUtc: true,
        animated: true,
        dateInputFormate: 'DD/MM/YYYY',
      }
    );
    this.onWorkOrderUpdateResponse = new EventEmitter<any>();
    this.onJobChange = new EventEmitter<any>();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.completeByInit = new Date();
    this.completeByInit.setDate(this.maxDate.getDate() + 30);
    this.authService.currentUser.subscribe((value) => {
      this.currentUser = value;
    });
  }

  scrollToElement(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  confirm(): void {
    this.modalRef.hide();
  }

  saveBeforeComment() {
    this.uploadBeforeDocumentComments.push({
      uploadId: this.uploadBeforeId,
      uploadComment: this.uploadBeforeComment,
    });
    this.workOrderForm.patchValue({
      uploadBeforeDocument: this.uploadBeforeDocumentComments,
    });
    this.createWorkOrder('fileUpload');
    this.modalRef.hide();
    this.uploadBeforeComment = '';
    this.getBeforeCommentDocuments(this.uploadBeforeDocumentComments);
  }

  saveAfterComment() {
    this.uploadAfterDocumentComments.push({
      uploadId: this.uploadAfterId,
      uploadComment: this.uploadAfterComment,
    });
    this.workOrderForm.patchValue({
      uploadAfterDocument: this.uploadAfterDocumentComments,
    });
    this.createWorkOrder('fileUpload');
    this.modalRef.hide();
    this.uploadAfterComment = '';
    this.getAfterCommentDocuments(this.uploadAfterDocumentComments);
  }
  initClient() {
    gapi.load('client', () => {
      gapi.client.init({
        apiKey: environment.apiKey,
        clientId: environment.clientId,
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
        ],
        scope: 'https://www.googleapis.com/auth/calendar',
      });

      gapi.client.load('calendar', 'v3', () => console.log('loaded calendar'));
    });
  }

  async login() {
    const googleAuth = gapi.auth2.getAuthInstance();
    const googleUser = await googleAuth.signIn();
    const token = googleUser.getAuthResponse().id_token;
    const credential = auth.GoogleAuthProvider.credential(token);
    const response = await this.afAuth.auth.signInWithCredential(credential);
    if (response) {
      this.message = ConstantsService.SuccessMessage.NotificationSuccess;
      this.modalRef = this.modalService.show(this.template, {
        class: 'modal-md',
      });
    }
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  async insertEvent(data: any) {
    const response = await gapi.client.calendar.events.insert({
      calendarId: 'primary',
      start: {
        dateTime: this.getStartDateTime(data.CompletedBy),
        timeZone: 'America/Los_Angeles',
      },
      end: {
        dateTime: this.getEndDateTime(data.CompletedBy),
        timeZone: 'America/Los_Angeles',
      },
      summary: `Job Title: ${data.CustomerName}` || 'Created by admin',
      description: data.WorkRequestID,
      location: data.Address,
      title: data.WorkRequestID,
      creator: {
        email: this.authService.currentLoginUserValue.Email,
      },
      organizer: {
        email: this.authService.currentLoginUserValue.Email,
      },
    });
  }

  // Model Response
  modalResponse(event) {
    if (!event) {
      this.stepper.next();
      this.secondLine = true;
      this.bindValueInField();
    }
  }

  getStartDateTime = (eventStartDate) => {
    if (eventStartDate.length === 12) {
      eventStartDate = moment(eventStartDate.slice(0, 10)).toISOString();
      var date = new Date(eventStartDate);
      var eventDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, '');
      return `${eventDate.split(' ')[0]}T02:30:00.350Z`;
    }
    var new_date = new Date(eventStartDate);
    const isoStartDate = new Date(
      new_date.getTime() - new_date.getTimezoneOffset() * 60000
    ).toISOString();
    return `${isoStartDate.split('T')[0]}T02:30:00.350Z`;
  };

  getEndDateTime = (eventStartDate) => {
    if (eventStartDate.length === 12) {
      eventStartDate = moment(eventStartDate.slice(0, 10)).toISOString();
      var date = new Date(eventStartDate);
      var eventDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, '');
      return `${eventDate.split(' ')[0]}T03:00:00.350Z`;
    }
    var new_date = new Date(eventStartDate);
    const isoStartDate = new Date(
      new_date.getTime() - new_date.getTimezoneOffset() * 60000
    ).toISOString();
    return `${isoStartDate.split('T')[0]}T03:00:00.350Z`;
  };

  next(stepperValue) {
    this.commonService.setToDisplayBackBtn(true);
    if (stepperValue == 'first') {
      this.submitted = true;
      this.createWorkOrder('nextStep');
      this.showLine = true;
      this.stepper.next();
    } else if (stepperValue == 'second') {
      if (
        this.workOrderForm.value.UploadedPhotoAfterIds.length == 0 &&
        this.workOrderForm.value.CannedResponseText
      ) {
        let model = {
          header: 'File Upload Confirmation',
          description:
            'Do you want to upload the Completion of work order documents?',
          btnText1: 'Yes',
          btnText2: 'No',
        };
        this.modalInfo.showInfoModal(model);
        return;
      }
      this.secondLine = true;
      this.bindValueInField();
      this.stepper.next();
    }
  }
  previous() {
    this.showLine = this.stepper['_currentIndex'] === 2;
    this.secondLine = this.stepper['_currentIndex'] === 2;
    this.stepper.previous();
  }

  onSubmit() {
    return false;
  }
  // alive;
  ngOnInit() {
    this.currentUserRole = this.authService.currentLoginUserValue.Role;
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });
    // Create form
    this.createForm();
    this.createCustomizedWorkOrder();
    this.creatContactForm();
    this.getWorkCategoryList();
    // Display next Button
    this.commonService.displayNextBtnObservables.subscribe((res) => {
      this.showNextBtn = res;
    });

    if (this.existingRecord) {
      this.getExistingWorkOrder();
    }
    if (
      this.currentUserRole &&
      this.currentUserRole === ConstantsService.User_Role.Admin
    ) {
      this.getAllJobListDetails();
    } else {
      this.getJobListBySupervisorId();
    }
    this.getWorkOrderStatus();
    this.getWorkOrderCompleteStatus();
    this.getLocationLatitudeLong();
  }

  private getLocationLatitudeLong() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  getWorkOrderStatus() {
    this.commonService.workOrderStatusObs.subscribe((res) => {
      this.showScheduleDate = res;
    });
  }

  getWorkOrderCompleteStatus() {
    this.commonService.workOrderStatusCompleteObs.subscribe((res) => {
      this.WorkStateComplete = res;
      if (this.WorkStateComplete) {
        this.workOrderForm
          .get('CannedResponseId')
          .setValidators([Validators.required]);
        this.workOrderForm.get('CannedResponseId').updateValueAndValidity();
      } else {
        this.workOrderForm.get('CannedResponseId').clearValidators();
        this.workOrderForm.get('CannedResponseId').updateValueAndValidity();
      }
    });
  }

  setLocationName(event: {
    preventDefault: () => void;
    target: { value: any };
  }) {
    event.preventDefault();
    if (event.target.value) {
      this.workOrderForm.patchValue({
        Address: event.target.value,
      });
      this.customizedWorkOrderForm.patchValue({
        Address: event.target.value,
      });
      this.zipCode = event.target.value;
    }
  }

  setCustomerLocationName(event) {
    event.preventDefault();
    if (event.target.value) {
      this.ContactForm.patchValue({
        ContactAddress: event.target.value,
      });
    }
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (
          this.latitude !== this.existingLatitude &&
          this.longitude !== this.existingLongitude
        ) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 8;
          this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
            this.workOrderForm.patchValue({
              Address: this.address,
              Latitude: latitude,
              Longitude: longitude,
            });
            this.customizedWorkOrderForm.patchValue({
              Address: this.address,
            });
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  ngOnChanges() {
    if (this.existingRecord) {
      this.createCustomizedWorkOrder();
      this.createForm();
      this.getExistingWorkOrder();
      this.getLocationLatitudeLong();
      this.getCannedResponseTextName(
        this.existingWorkOrderDetails.CannedResponseId
      );
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.workOrderForm.controls;
  }

  // Contact form
  creatContactForm() {
    this.ContactForm = this.formBuilder.group({
      ContactFirstName: [''],
      ContactLastName: [''],
      ContactPhoneNumber: [''],
      ContactEmail: [''],
      ContactAddress: [''],
    });
  }

  // Create form
  createForm() {
    this.workOrderForm = this.formBuilder.group({
      WorkRequestID: [null, Validators.required],
      CreatedDate: [new Date()],
      CustomerId: [null, Validators.required],
      WorkCategory: [null, Validators.required],
      CompletedBy: [null, Validators.required],
      CompletedOn: [null],
      Address: ['', Validators.required],
      DynamicFields: [''],
      uploadBeforeDocument: [''],
      uploadAfterDocument: [''],
      Notes: [''],
      Latitude: [''],
      Longitude: [''],
      CreatedBy: [this.authService.currentLoginUserValue.Id],
      UploadedPhotoBeforeIds: [''],
      UploadedPhotoAfterIds: [''],
      CannedResponseId: [null],
      CannedResponseText: [null],
      CompletedAddress: [''],
      notifyCheck: [false],
      StartDate: [new Date()],
      EndDate: [''],
      SuperVisorId: [null, Validators.required],
      FieldAgentId: [null],
      FieldAgentName: [''],
      SuperVisorName: [''],
      CustomerName: [''],
      SaveBtnText: [''],
      CannedResponseName: [''],
    });
    this.workOrderForm.controls['CompletedBy'].setValue(this.completeByInit);
  }

  createCustomizedWorkOrder() {
    this.customizedWorkOrderForm = this.formBuilder.group({
      WorkRequestID: [''],
      Address: [''],
      CustomerName: [''],
      CompletedBy: [null, Validators.required],
      CompletedOn: [null],
      StartDate: [null],
    });
  }

  bindValueInField() {
    if (this.jobId) {
      this.workOrderForm.patchValue({
        CustomerId: this.jobId,
        CannedResponseName: this.CannedResponseName,
      });
    }
    this.workOrder = this.workOrderForm.value;
  }

  getExistingWorkOrder() {
    if (this.existingWorkOrderDetails) {
      this.workOrderForm.patchValue({
        CompletedBy: moment(this.existingWorkOrderDetails.CompletedBy).format(
          'L'
        ),
        Notes:
          this.existingWorkOrderDetails && this.existingWorkOrderDetails.Notes
            ? this.existingWorkOrderDetails.Notes
            : '',
        CompletedOn:
          this.existingWorkOrderDetails.CompletedOn != null
            ? moment(this.existingWorkOrderDetails.CompletedOn).format('L')
            : null,
        WorkRequestID: this.existingWorkOrderDetails.WorkRequestID,
        CreatedDate: moment(this.existingWorkOrderDetails.CreatedDate).format(
          'L'
        ),
        CustomerId: this.existingWorkOrderDetails.CustomerId,
        WorkCategory: this.existingWorkOrderDetails.WorkCategory,
        Address: this.existingWorkOrderDetails.Address,
        CustomerName: this.existingWorkOrderDetails.JobName,
        CreatedBy: this.existingWorkOrderDetails.CreatedBy,
        UploadedPhotoAfterIds: this.existingWorkOrderDetails
          .UploadedPhotoAfterIds
          ? this.existingWorkOrderDetails.UploadedPhotoAfterIds
          : [],

        UploadedPhotoBeforeIds: this.existingWorkOrderDetails
          .UploadedPhotoBeforeIds
          ? this.existingWorkOrderDetails.UploadedPhotoBeforeIds
          : [],
        DynamicFields: this.existingWorkOrderDetails.DynamicFields
          ? this.existingWorkOrderDetails.DynamicFields
          : '',
        uploadBeforeDocument: this.existingWorkOrderDetails.uploadBeforeDocument
          ? this.existingWorkOrderDetails.uploadBeforeDocument
          : '',
        uploadAfterDocument: this.existingWorkOrderDetails.uploadAfterDocument
          ? this.existingWorkOrderDetails.uploadAfterDocument
          : '',

        Latitude: this.existingWorkOrderDetails.Latitude,
        Longitude: this.existingWorkOrderDetails.Longitude,
        CannedResponseId: this.existingWorkOrderDetails.CannedResponseId
          ? this.existingWorkOrderDetails.CannedResponseId
          : null,

        CannedResponseName: this.existingWorkOrderDetails.CannedResponseName
          ? this.existingWorkOrderDetails.CannedResponseName
          : null,

        CompletedAddress: this.existingWorkOrderDetails.CompletedAddress
          ? this.existingWorkOrderDetails.CompletedAddress
          : '',
        StartDate: moment(this.existingWorkOrderDetails.CompletedBy).format(
          'L'
        ),
        EndDate: this.existingWorkOrderDetails.EndDate
          ? this.existingWorkOrderDetails.EndDate
          : new Date(),
        SuperVisorId: this.existingWorkOrderDetails.SuperVisorId,
        SuperVisorName: this.existingWorkOrderDetails.SupervisorName,
        FieldAgentName: this.existingWorkOrderDetails.FieldAgentName,
        FieldAgentId: this.existingWorkOrderDetails.FieldAgentId,
        CannedResponseText: this.existingWorkOrderDetails.CannedResponseText
          ? this.existingWorkOrderDetails.CannedResponseText
          : '',
      });
      this.customizedWorkOrderForm.patchValue({
        WorkRequestID: this.existingWorkOrderDetails.WorkRequestID,
        Address: this.existingWorkOrderDetails.Address,
        CustomerName: this.existingWorkOrderDetails.JobName,
        CompletedBy: this.existingWorkOrderDetails.StartDate
          ? moment(this.existingWorkOrderDetails.StartDate).format('L')
          : moment(this.existingWorkOrderDetails.CompletedBy).format('L'),
        CompletedOn:
          this.existingWorkOrderDetails.CompletedOn != null
            ? moment(this.existingWorkOrderDetails.CompletedOn).format('L')
            : null,
        StartDate: moment(this.existingWorkOrderDetails.CompletedBy).format(
          'L'
        ),
      });
      this.ContactForm.patchValue({
        ContactFirstName: this.existingWorkOrderDetails.ContactFirstName
          ? this.existingWorkOrderDetails.ContactFirstName
          : '',
        ContactLastName: this.existingWorkOrderDetails.ContactLastName
          ? this.existingWorkOrderDetails.ContactLastName
          : '',
        ContactPhoneNumber: this.existingWorkOrderDetails.ContactPhoneNumber
          ? this.existingWorkOrderDetails.ContactPhoneNumber
          : '',
        ContactEmail: this.existingWorkOrderDetails.ContactEmail
          ? this.existingWorkOrderDetails.ContactEmail
          : '',
        ContactAddress: this.existingWorkOrderDetails.ContactAddress
          ? this.existingWorkOrderDetails.ContactAddress
          : '',
      });
      if (this.existingWorkOrderDetails.CustomerId) {
        this.onJobChange.emit(this.existingWorkOrderDetails.CustomerId);
      }
      this.WorkOrderNotesDetail = this.existingWorkOrderDetails
        .WorkOrderNotesDetail
        ? this.existingWorkOrderDetails.WorkOrderNotesDetail
        : [];

      this.latitude = this.existingWorkOrderDetails.Latitude;
      this.longitude = this.existingWorkOrderDetails.Longitude;
      this.existingLatitude = this.latitude;
      this.existingLongitude = this.longitude;
      this.workOrderType = this.existingWorkOrderDetails.WorkOrderType;

      this.DynamicFieldsList = this.existingWorkOrderDetails.DynamicFields;
      this.existingWorkOrderDetails.UploadedPhotoBeforeIds
        ? this.UploadedPhotoBeforeIdsTemp.push(
            this.existingWorkOrderDetails.UploadedPhotoBeforeIds
          )
        : (this.UploadedPhotoBeforeIdsTemp = []);
      this.existingWorkOrderDetails.UploadedPhotoAfterIds
        ? this.UploadedPhotoAfterIdsTemp.push(
            this.existingWorkOrderDetails.UploadedPhotoAfterIds
          )
        : (this.UploadedPhotoAfterIdsTemp = []);

      this.existingWorkOrderDetails.uploadBeforeDocument
        ? (this.uploadBeforeDocumentComments =
            this.existingWorkOrderDetails.uploadBeforeDocument)
        : (this.uploadBeforeDocumentComments = []);

      this.existingWorkOrderDetails.uploadAfterDocument
        ? (this.uploadAfterDocumentComments =
            this.existingWorkOrderDetails.uploadAfterDocument)
        : (this.uploadAfterDocumentComments = []);
      this.getCannedResponseList(this.existingWorkOrderDetails.WorkCategory);
      this.getBeforeFile(this.existingWorkOrderDetails.UploadedDocPathsBefore);
      this.getAfterFile(this.existingWorkOrderDetails.UploadedDocPathsAfter);
      this.getAfterCommentDocuments(this.uploadAfterDocumentComments);
      this.getBeforeCommentDocuments(this.uploadBeforeDocumentComments);
    }
  }

  getBeforeCommentDocuments(uploadBeforeDocumentComments: commentDoc[]) {
    uploadBeforeDocumentComments.forEach((item) => {
      this.uploadBeforeCommentData.push({
        uploadId: item.uploadId,
        uploadComment: item.uploadComment,
      });
    });
  }

  getAfterCommentDocuments(uploadBeforeDocumentComments: commentDoc[]) {
    uploadBeforeDocumentComments.forEach((item) => {
      this.uploadAfterCommentData.push({
        uploadId: item.uploadId,
        uploadComment: item.uploadComment,
      });
    });
  }

  onChangeOfWorkCategory(selectedWorkCategory) {
    selectedWorkCategory = selectedWorkCategory.split(':')[1].trim();
    this.getCannedResponseList(selectedWorkCategory);
  }

  onDelete(item) {
    this.DynamicFieldsList = this.DynamicFieldsList.filter(
      (x) => x.Values !== item
    );
    this.workOrderForm.patchValue({
      DynamicFields: this.DynamicFieldsList,
    });
  }

  getBeforeFile(fileArray) {
    fileArray.map((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadBeforePhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });

    var unique = [];
    var distinct = [];
    for (let i = 0; i < this.uploadBeforePhotoFilePath.length; i++) {
      if (!unique[this.uploadBeforePhotoFilePath[i].filePath]) {
        distinct.push({
          filePath: this.uploadBeforePhotoFilePath[i].filePath,
          extension: this.uploadBeforePhotoFilePath[i].extension,
        });
        unique[this.uploadBeforePhotoFilePath[i].filePath] = 1;
      }
    }
    this.uploadBeforePhotoFilePath = distinct;
  }

  getAfterFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadAfterPhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
    var unique = [];
    var distinct = [];
    for (let i = 0; i < this.uploadAfterPhotoFilePath.length; i++) {
      if (!unique[this.uploadAfterPhotoFilePath[i].filePath]) {
        distinct.push({
          filePath: this.uploadAfterPhotoFilePath[i].filePath,
          extension: this.uploadAfterPhotoFilePath[i].extension,
        });
        unique[this.uploadAfterPhotoFilePath[i].filePath] = 1;
      }
    }
    this.uploadAfterPhotoFilePath = distinct;
  }

  getWorkCategoryList() {
    this.commonService.getWorkCategoryList().subscribe((res: APIResponse) => {
      this.workCategoryList = res.Data;
    });
  }

  getCannedResponseList(workCategory) {
    this.commonService
      .getCannedResponseList(workCategory)
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data || [];
      });
  }

  getJobListBySupervisorId() {
    this.commonService
      .getJobListBySupervisor()
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.jobListDetails = res.Data;
        }
      });
  }

  getAllJobListDetails() {
    let searchText = '';
    this.commonService
      .getAllJobList(searchText)
      .subscribe((res: APIResponse) => {
        this.jobListDetails = res.Data;
        if (
          this.existingWorkOrderDetails &&
          this.existingWorkOrderDetails.CustomerId
        ) {
          this.getSelectedJobs(this.existingWorkOrderDetails.CustomerId);
        }
      });
  }

  getSupervisorListByJobId() {
    if (this.jobId) {
      this.commonService
        .getSupervisorAndFAListByJobId(this.jobId)
        .subscribe((res: APIResponse) => {
          if (res && res.Data && res.Success) {
            this.supervisorList = res.Data.map(
              (item: Role) => {
                return {
                  ID: item.ID,
                  Text: item.Text,
                  RoleCode: item.RoleCode,
                };
              }
            );
          }
        });
    }
  }

  onDescription(data) {
    if (data.target.value) {
      this.customizedWorkOrderForm.patchValue({
        WorkRequestID: data.target.value,
      });
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.jobId = event.item.Id;
    this.workOrderForm.patchValue({
      CustomerName: event.item.Name,
      CustomerId: this.jobId,
    });
    this.customizedWorkOrderForm.patchValue({
      CustomerName: event.item.Name,
    });

    this.commonService
      .getAddressByCustomer(this.jobId)
      .subscribe((res: APIResponse) => {
        this.city = res.Data?.City;
        this.zipCode = res.Data?.ZipCode;
        this.workOrderForm.patchValue({
          Address: `${this.city} ${this.zipCode}`,
        });
      });

    this.getSupervisorListByJobId();
  }

  onSelectSupervisor(event: TypeaheadMatch): void {
    if (event.item.RoleCode === 'S') {
      this.workOrderForm.patchValue({
        SuperVisorId: event.item.ID,
        SuperVisorName: event.item.Text,
        FieldAgentId: 0,
      });
    } else {
      this.workOrderForm.patchValue({
        FieldAgentId: event.item.ID,
      });
    }
  }

  getSelectedJobs(Id) {
    if (Id) {
      this.onJobChange.emit(Id);
      let selectedJobs = this.jobListDetails.find((x) => x.Id == Id);
      this.selectedJobName = selectedJobs ? selectedJobs.Name : '';
      if (this.currentUser.Role !== ConstantsService.User_Role.Admin) {
        this.commonService.getAddressByCustomer(Id).subscribe((res: APIResponse) => {
          this.city = res.Data?.City;
          this.zipCode = res.Data?.ZipCode;
          this.workOrderForm.patchValue({
            Address: `${this.city} ${this.zipCode}`,
          });
        });
      }

      this.customizedWorkOrderForm.patchValue({
        CustomerName: this.selectedJobName,
      });

      if (this.authService.currentLoginUserValue) {
        this.jobId = Id;
        this.workOrderForm.patchValue({
          CustomerName: this.selectedJobName,
          CreatedBy: this.authService.currentLoginUserValue.Id,
        });
        this.getSupervisorListByJobId();
      }
    }
  }

  onAddMoreFieldsResponse(response) {
    this.DynamicFieldsList.push(response);
    this.workOrderForm.patchValue({
      DynamicFields: this.DynamicFieldsList,
    });
  }

  createWorkOrder(btnText?: string) {
    this.submitted = true;
    this.existingRecord = false;
    if (this.currentUserRole === ConstantsService.User_Role.SuperVisor) {
      this.workOrderForm.patchValue({
        SuperVisorId: this.currentUser.Id,
      });
    }
    if (
      btnText === 'nextStep' &&
      this.WorkStateComplete &&
      this.workOrderForm.value.CannedResponseId === null
    ) {
      this.stepper.next();
    }

    if (this.workOrderForm.invalid) {
      return;
    }
    if (this.existingRecord) {
      this.bindValueInField();
    }
    this.workOrderForm.patchValue({
      EndDate: this.workOrderForm.value.CompletedBy,
      SaveBtnText: btnText ? btnText : '',
    });

    if (this.workOrderForm.value.UploadedPhotoBeforeIds.length == 0) {
      this.workOrderForm.patchValue({
        UploadedPhotoBeforeIds: '',
      });
    }
    if (this.workOrderForm.value.UploadedPhotoAfterIds.length == 0) {
      this.workOrderForm.patchValue({
        UploadedPhotoAfterIds: '',
      });
    }
    Object.assign(this.workOrderForm.value, this.ContactForm.value);
    this.onWorkOrderUpdateResponse.emit(this.workOrderForm.value);
    if (
      this.workOrderForm.value.SuperVisorId &&
      this.workOrderForm.value.CustomerName
    ) {
      this.workOrderForm.patchValue({
        Notes: '',
      });
    }
  }

  createCustomizedWorkOrderScheduled() {
    this.submitted = true;

    this.customizedWorkOrderForm.patchValue({
      CompletedBy: this.workOrderForm.value.StartDate,
      StartDate: this.workOrderForm.value.StartDate,
    });

    this.login();
    this.insertEvent(this.customizedWorkOrderForm.value);
    if (!this.showScheduleDate) {
      this.createWorkOrder('saveWithSchedule');
    } else {
      this.createWorkOrder('saveWithStatusSchedule');
    }
  }

  gmapResponseUpdate(eventElement: { latitude: any; longitude: any }) {
    this.workOrderForm.patchValue({
      Latitude: eventElement.latitude,
      Longitude: eventElement.longitude,
    });
    this.getAddress(eventElement.latitude, eventElement.longitude);
  }

  gmapResponseForCityName(eventElement: {
    latitude: number;
    longitude: number;
    address: string;
  }) {
    this.workOrderForm.patchValue({
      Address: eventElement.address,
      Latitude: eventElement.latitude,
      Longitude: eventElement.longitude,
    });
    this.customizedWorkOrderForm.patchValue({
      Address: eventElement.address,
    });
  }

  //  File Upload response
  fileUploadResponseBefore(response: FileUploadResponse): void {
    this.modalRef = this.modalService.show(this.commentBeforeTemplate, {
      class: 'modal-md',
    });
    this.UploadedPhotoBeforeIdsTemp.push(response.Data.DocumentID);

    this.uploadBeforeId = response.Data.DocumentID;

    this.workOrderForm.controls['UploadedPhotoBeforeIds'].setValue(
      this.UploadedPhotoBeforeIdsTemp.toString()
    );
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    let tempFilesArr: uploadPhotoFilePath[];
    tempFilesArr = fileUploadResponse ? this.uploadBeforePhotoFilePath.concat(fileUploadResponse) : [];
    this.uploadBeforePhotoFilePath = tempFilesArr;
  }

  fileUploadResponseAfter(response: FileUploadResponse): void {
    this.UploadedPhotoAfterIdsTemp.push(response.Data.DocumentID);
    this.uploadAfterId = response.Data.DocumentID;
    this.workOrderForm.controls['UploadedPhotoAfterIds'].setValue(
      this.UploadedPhotoAfterIdsTemp.toString()
    );
    if (
      response &&
      response.Data &&
      response.Data.FilePath &&
      response.Data.FilePath.length > 0
    ) {
      let result = response.Data.FilePath;
      this.modalRef = this.modalService.show(this.commentAfterTemplate, {
        class: 'modal-md',
      });
      result.forEach((element) => {
        let fileExtension = element.split('.');
        this.uploadAfterPhotoFilePath.push({
          filePath: element,
          extension: fileExtension[fileExtension.length - 1],
        });
      });
    }
  }

  backToPrevious() {
    this.location.back();
  }

  getCannedResponseText(cannedId) {
    let cannedResponse = this.cannedResponseList.find(
      (item) => item.ID == cannedId.split(' ')[1]
    );
    this.CannedResponseName = cannedResponse.Text;
    this.workOrderForm.patchValue({
      CannedResponseName: cannedResponse.Text,
    });

    this.commonService.setStatusTextSubjectMethod(true);
  }

  getCannedResponseTextName(cannedId) {
    let cannedResponse = this.cannedResponseList.find(
      (item) => item.ID == cannedId
    );
    this.CannedResponseName = cannedResponse.Text;
    this.commonService.setStatusTextSubjectMethod(true);
  }
}
