import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ConstantsService } from 'src/app/shared/services/constants.service';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { User } from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root',
})
export class SupervisorService {
  currentUser: User;
  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = this.authenticationService.currentLoginUserValue;
  }

  InviteFieldAgent(data) {
    Object.assign(data, { superVisorId: this.currentUser.Id.toString() });
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.InviteFieldAgent}`;
    return this.httpClient.post(API_URL, data);
  }

  DeleteWorkOrderDocument(workId, docId) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.DeleteWorkOrderDocument}`;
    return this.httpClient.delete(`${API_URL}?workId=${workId}&docId=${docId}`);
  }
  EditWorkOrderComment(docId, comment) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.EditWorkOrderComment}`;
    return this.httpClient.post(`${API_URL}`, { docId, comment });
  }

  getWorkOrderListByJobs(
    pageNo,
    pageSize,
    searchText: string = '',
    isCompleteByMyself = false,
    JobsIds: string = '',
    dateFrom: string = null,
    dateTo: string = null,
    fieldAgentId: string = ''
  ) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.GetWorkOrderListByJobs}`;
    let params = new HttpParams();
    params = params.append('superVisorId', this.currentUser.Id.toString());
    params = params.append('pageNo', pageNo);
    params = params.append('pageSize', pageSize);
    params = params.append('isCompleteByMyself', isCompleteByMyself);
    params = params.append('searchText', searchText);
    params = params.append('fieldAgentId', fieldAgentId);
    params = params.append(
      'jobIds',
      JobsIds ? JobsIds : this.currentUser.JobIds
    );
    params = params.append('dateFrom', dateFrom);
    params = params.append('dateTo', dateTo);
    return this.httpClient.get(API_URL, { params });
  }

  getTaskOrderDetailsByTaskOrderId(taskOrderId) {
    const API_URL = `${environment.apiUrl}${ConstantsService.ResidentModule_APIURL.SearchWorkOrder}`;
    let params = new HttpParams();
    params = params.append('searchText', taskOrderId);
    return this.httpClient.get(API_URL, { params });
  }

  WorkOrderAssign(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.PostAssignWorkOrder}`;
    return this.httpClient.post(API_URL, data);
  }

  createWorkOrderSupervisor(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.PostSuperVisorWorkOrder}`;
    return this.httpClient.post(API_URL, data);
  }
  WorkOrderComplete(workId: string) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.WorkOrderComplete}`;
    return this.httpClient.post(`${API_URL}?workId=${workId}`, {});
  }

  getWorkOrderListByStatus(
    statusKey,
    pageNo,
    pageSize,
    searchText,
    sortByWorkOrder,
    selectedJobs,
    sortedColumn,
    isSortedDesc,
    dateFrom,
    dateTo,
    assignedTo?
  ) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.GetWorkOrderListByStatus}`;
    let params = new HttpParams();
    const isCompleted = false;
    params = params.append('dateFrom', dateFrom);
    params = params.append('dateTo', dateTo);
    params = params.append('status', statusKey);
    this.currentUser = this.authenticationService.currentLoginUserValue;
    params = params.append('SuperVisorId', this.currentUser.Id.toString());
    params = params.append('pageNo', pageNo);
    params = params.append('pageSize', pageSize);
    params = params.append('isCompleteByMyself', isCompleted);
    if (sortedColumn && isSortedDesc) {
      params = params.append('sortedColumn', sortedColumn);
    }
    if (isSortedDesc === 'true') {
      params = params.append('isSortedDesc', 'desc');
    } else {
      params = params.append('isSortedDesc', 'asc');
    }
    if(assignedTo) {
      params = params.append('assignedTo', assignedTo)
    }

    params = params.append('searchText', searchText ? searchText : '');
    params = params.append(
      'sortByCreateDate',
      sortByWorkOrder ? sortByWorkOrder : true
    );
    params = params.append(
      'jobIds',
      selectedJobs
        ? selectedJobs
        : null
    );
    return this.httpClient.get(API_URL, { params });
  }

  getWorkOrderByWorkId(workId, workOrderType) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.GetWorkOrderByWorkId}`;
    let params = new HttpParams();
    params = params.append('workId', workId);
    params = params.append('workOrderType', workOrderType);
    return this.httpClient.get(API_URL, { params });
  }

  getWorkOrderDetailsForPDFByWorkId(workId, templateName) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.GetPDFWorkOrderDetail}`;
    let params = new HttpParams();
    params = params.append('docId', workId);
    params = params.append('templateName', templateName);
    return this.httpClient.get(API_URL, { params });
  }

  updateMultipleWorkOrder(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.UpdateMultiWorkOrderAssign}`;
    return this.httpClient.post(API_URL, data);
  }

  uploadPDFToCreateWO(data) {
    const uploadURL = `${environment.apiUrl}api/SuperVisor/Upload`;
    return this.httpClient.post(uploadURL, data);
  }

  getworkOrderListById(request) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.GetFieldAgentWorkorderCompletedReport}`;
    let params = new HttpParams();
    params = params.append('userId', request.userId);
    params = params.append('pageNo', request.pageNo);
    params = params.append('pageSize', request.pageSize);
    params = params.append('jobIds', request.jobIds);
    params = params.append('sortByCreateDate', request.sortByCreateDate);
    params = params.append('isOnTime', request.isOnTime);
    params = params.append('searchText', request.searchText);
    return this.httpClient.get(API_URL, { params });
  }
}
