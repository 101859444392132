import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { APIResponse } from 'src/app/shared/models/api-response';
import { CommonService } from 'src/app/shared/services/common.service';
import { LoaderService } from '../../helpers/services/loader.service';

@Component({
  selector: 'app-report-details-tabular-view',
  templateUrl: './report-details-tabular-view.component.html',
  styleUrls: ['./report-details-tabular-view.component.css'],
})
export class ReportDetailsTabularViewComponent {
  modelDetails;

  @ViewChild(ModalDirective) public lgModal: ModalDirective;

  decline(): void {
    this.lgModal.hide();
  }

}
