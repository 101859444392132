import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { JwtInterceptor } from '../core/helpers/interceptors/jwt-interceptor.service';

import { PhoneNoValidateDirective } from './directive/validation/phone-no-validate.directive';
import { CarouselSlideComponent } from '../core/components/carousel-slide/carousel-slide.component';
import { ObjectKeyValuePipe } from './pipes/object-key-value.pipe';
import { environment } from 'src/environments/environment';
import { FirstWordPipe } from './pipes/first-word.pipe';

import { ExistingWorkOrderComponent } from './components/existing-work-order/existing-work-order.component';
import { MustMatchDirective } from './directive/validation/must-match.directive';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarEventComponent } from '../core/components/calendar-event/calendar-event.component';
import { ModelComponent } from './components/model/model.component';
import { CalendarTableComponent } from '../core/components/calendar-table/calendar-table.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PreviewPageComponent } from './components/preview-page/preview-page.component';
import { PreviewCommunityPageComponent } from './components/preview-community-page/preview-community-page.component';
import { WorkOrderResidentFieldagentComponent } from './components/work-order-resident-fieldagent/work-order-resident-fieldagent.component';
import { WorkOrderResidentCommunityComponent } from './components/work-order-resident-community/work-order-resident.component';
import { WorkOrderResidentComponent } from './components/work-order-resident/work-order-resident.component';
import { WorkOrderManualPdfComponent } from './components/work-order-manual-pdf/work-order-manual-pdf.component';
import { WorkOrderManualAgentComponent } from './components/work-order-manual-agent/work-order-manual.agent.component';
import { WorkOrderManualComponent } from './components/work-order-manual/work-order-manual.component';
import { SortableTableDirective } from './components/sortable-table/sortable-table.directive';
import { PreviewFileComponent } from './components/preview-file/preview-file.component';
import { AddMoreFieldsComponent } from './components/add-more-fields/add-more-fields.component';
import { AgreementPopupComponent } from './components/agreement-popup/agreement-popup.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { DownloadWorkOrderComponent } from './components/download-work-order/download-work-order.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { MultiFileUploadComponent } from './components/multi-file-upload/multi-file-upload.component';
import { MultiSelectDropdownJobsComponent, SortByPipe } from './components/multi-select-dropdown-jobs/multi-select-dropdown-jobs.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { PhoneNumberDirective } from './directive/validation/phone-number.directive';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from './material/material.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    FileUploadComponent,
    PhoneNoValidateDirective,
    CarouselSlideComponent,
    ObjectKeyValuePipe,
    MultiSelectDropdownComponent,
    FirstWordPipe,
    AddMoreFieldsComponent,
    WorkOrderResidentComponent,
    WorkOrderManualComponent,
    GoogleMapComponent,
    MultiFileUploadComponent,
    WorkOrderResidentFieldagentComponent,
    ExistingWorkOrderComponent,
    WorkOrderManualPdfComponent,
    MustMatchDirective,
    CalendarEventComponent,
    DownloadWorkOrderComponent,
    PreviewFileComponent,
    PreviewPageComponent,
    ModelComponent,
    WorkOrderResidentCommunityComponent,
    PreviewCommunityPageComponent,
    CalendarTableComponent,
    PreviewPageComponent,
    PreviewCommunityPageComponent,
    WorkOrderResidentCommunityComponent,
    WorkOrderResidentComponent,
    WorkOrderManualPdfComponent,
    WorkOrderManualComponent,
    SortableTableDirective,
    AgreementPopupComponent,
    AutoCompleteComponent,
    DownloadWorkOrderComponent,
    GoogleMapComponent,
    MultiFileUploadComponent,
    MultiSelectDropdownJobsComponent,
    SortByPipe,
    WorkOrderManualAgentComponent,
    MultiSelectDropdownComponent,
    PhoneNumberDirective
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgOtpInputModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ['places']
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    TranslateModule,
    GooglePlaceModule,
    NgxMaskModule,
    MaterialModule,
    MatChipsModule,
    MatIconModule
  ],
  exports: [
    WorkOrderResidentFieldagentComponent,
    WorkOrderResidentCommunityComponent,
    WorkOrderResidentComponent,
    WorkOrderManualPdfComponent,
    WorkOrderManualAgentComponent,
    WorkOrderManualComponent,
    SortableTableDirective,
    PreviewFileComponent,
    AddMoreFieldsComponent,
    AgreementPopupComponent,
    AutoCompleteComponent,
    DownloadWorkOrderComponent,
    GoogleMapComponent,
    MultiFileUploadComponent,
    MultiSelectDropdownJobsComponent,
    SortByPipe,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    PhoneNoValidateDirective,
    FileUploadComponent,
    AgmCoreModule,
    TranslateModule,
    CarouselSlideComponent,
    ModalModule,
    TypeaheadModule,
    BsDatepickerModule,
    PaginationModule,
    ObjectKeyValuePipe,
    MultiSelectDropdownComponent,
    FirstWordPipe,
    WorkOrderResidentComponent,
    WorkOrderManualComponent,
    GoogleMapComponent,
    MultiFileUploadComponent,
    WorkOrderResidentFieldagentComponent,
    ExistingWorkOrderComponent,
    WorkOrderManualPdfComponent,
    MustMatchDirective,
    CalendarModule,
    CalendarEventComponent,
    DownloadWorkOrderComponent,
    ModelComponent,
    WorkOrderResidentCommunityComponent,
    PreviewCommunityPageComponent,
    CalendarTableComponent,
    WorkOrderManualAgentComponent,
    SortByPipe,
    MultiSelectDropdownJobsComponent,
    TooltipModule,
    SortableTableDirective,
    NgAddToCalendarModule,
    PreviewPageComponent,
    PreviewCommunityPageComponent,
    MultiSelectDropdownComponent,
    PhoneNumberDirective
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
