<div>
  <div class="upload-comp">
    <div class="wrp-boxs">
      <input
        type="file"
        class="file-style"
        name="file-upload"
        (change)="onFileChange($event)"
        [multiple]="multiple"
        [accept]="accept"
      />
      <img
        src="../../../../assets/image/upload-btn-1.png"
        class="upload-thumb"
      />
      <ng-container *ngIf="inputFiles">
        <label *ngFor="let item of inputFiles">{{ item.name }}</label>
      </ng-container>
    </div>
    <button
      type="submit"
      class="btn btn-secondary z-index"
      (click)="onSubmit()"
    >
      {{ UploadBtnText }}
    </button>
    <!-- </form> -->
  </div>
</div>
<!-- Added toast message -->
<!-- <toast-message></toast-message> -->
<ng-template #template>
  <div class="modal-body text-center">
    <p>{{ message }}</p>
    <div class="modal-footer">
      <button type="button" class="btn btn-oclm" (click)="confirm()">Ok</button>
    </div>
  </div>
</ng-template>
