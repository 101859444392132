import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';

interface Option {
  id: number;
  name: string;
}

@Component({
  selector: 'app-input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./input-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputControlComponent),
      multi: true,
    }
  ],
  animations: [
    trigger('rotatedState', [
      state('false', style({transform: 'rotate(0)'})),
      state('true', style({transform: 'rotate(-180deg)'})),
      transition('true => false', animate('200ms ease-out')),
      transition('false => true', animate('200ms ease-in'))
    ])
  ]
})
export class InputControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  public placeholder: string = 'Entrez du texte...';

  @Input()
  public type: 'text' | 'password' = 'text';

  @Input()
  public disabled: boolean = false;

  @Input()
  public outline: boolean = false;

  @Input()
  public autocompleteOptions: Option[] = [];

  @Output()
  private onAutoCompleteOptionSelect: EventEmitter<Option> = new EventEmitter<Option>();

  @Input()
  public maxlength: number = null;

  @Input()
  public number: boolean = false;

  public filteredOptions: Observable<Option[]>;

  public control = new FormControl();

  private onDestroy: Subject<void> = new Subject<void>();

  private inputValue: string;

  private onChange: (_: string) => void = () => null;

  private onTouched: () => void = () => null;

  get value(): string {
    return this.control.value;
  }

  set value(val){
    if( val !== undefined && this.value !== val){
    this.value = val
    this.onChange(val)
    this.onTouched()
    }
  }

  constructor() {
  }

  public ngOnInit(): void {
    this.autoCompleteFilter();
    this.disabled ? this.control.disable() : this.control.enable();
  }

  public displayFn(option: Option): string {
    return option && option.name ? option.name : '';
  }

  public autocompleteOptionClick(): void {
    this.onAutoCompleteOptionSelect.emit(this.control.value);
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public resetInput(event: Event): void {
    event.stopPropagation();
    if (!this.control.value) {
      return;
    }
    this.control.setValue('');
    this.writeValue('');
  }

  public writeValue(value: string): void {
    this.onTouched();
    const valueChanged = value !== this.inputValue;
    if (valueChanged) {
      this.inputValue = value;
      this.control.setValue(value);
      this.onChange(value);
    }
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    // TODO implement validation if autocomplete enabled: value from array
    const value = control.value;
    if (!value) {
      return {
        notDeclared: {
          value
        }
      };
    }
  }

  private autoCompleteFilter(): void {
    this.filteredOptions = this.control.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(name => name ? this.filter(name) : this.autocompleteOptions.slice())
      );

    this.control.valueChanges.subscribe(result => {
      this.writeValue(result);
    });
  }

  private filter(name: string): Option[] {
    const filterValue = name.toLowerCase();
    return this.autocompleteOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
}
