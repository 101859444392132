import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { SettingModel, User } from 'src/app/shared/models/user';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { APIResponse, UserListData } from 'src/app/shared/models/api-response';
import { LoaderService } from '../../helpers/services/loader.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { role } from 'src/utils';
import { MethodsForAllRoles } from '../../../shared/services/methods-for-all-roles.service';
import { EmailSettings, EmailSettingsIds } from '../../../shared/models/settings';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  @ViewChild('elementRef') inputLang;
  currentUser: User;
  newPassword = '';
  confPassword = '';
  modalRef: BsModalRef;
  message: string;
  @ViewChild('template') template: TemplateRef<any>;
  settingDetails: SettingModel = new SettingModel();
  show: boolean;
  showConf: boolean;
  languageList: {
    key: string,
    value: string,
  }[] = [
    {
      key: 'English',
      value: 'en'
    },
    {
      key: 'Spanish',
      value: 'sp'
    }
  ];
  backEnable = false;
  userListData: UserListData[];
  updatedUserId: number;
  emailSettings: EmailSettings[] = [];

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private translate: TranslateService,
    private location: Location,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private title: Title,
    private methodsForAllRoles: MethodsForAllRoles
  ) {
    this.authenticationService.currentUser.subscribe((value) => {
      this.currentUser = value;
      this.title.setTitle(`OCLM - ${role(this.currentUser.Role)} - Settings`);
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.currentUser);
    this.settingDetails.LanguageName =
      this.currentUser.LanguageName === 'English' ? 'en' : 'sp';
    this.settingDetails.IsNotification = this.currentUser.IsNotification
      ? 'true'
      : 'false';
    this.settingDetails.IsEnableEmailNotification = this.currentUser
      .IsEnableEmailNotification
      ? 'true'
      : 'false';
    this.settingDetails.IsVacation = this.currentUser.IsVacation
      ? 'true'
      : 'false';
    this.methodsForAllRoles.setHeaderTitleInSession();
    this.getUserList();
    this.getProfile();
  }

  getUserList() {
    this.commonService.getUserList()
      .subscribe((res: APIResponse) => {
        this.userListData = res.Data;
      });
  }

  onSelect(data: any) {
    if (data.item.Id) {
      this.commonService
        .getSupervisorProfileById(data.item.Id)
        .subscribe((res: APIResponse) => {
          this.settingDetails.IsNotification = res.Data.IsNotification
            ? 'true'
            : 'false';
          this.settingDetails.IsEnableEmailNotification = res.Data
            .IsEnableEmailNotification
            ? 'true'
            : 'false';

          this.settingDetails.IsVacation = res.Data.IsVacation
            ? 'true'
            : 'false';
          this.updatedUserId = data.item.Id;
        });
    }
  }

  // TODO disable for password change for Supervisor

  onSave() {
    this.loaderService.isLoading.next(true);
    if (this.backEnable) {
      return;
    }
    this.initEmailSettings();
    const model = {
      Password: this.newPassword,
      ProfileId: this.updatedUserId ? this.updatedUserId : this.currentUser.Id,
      LanguageName:
        this.settingDetails.LanguageName === 'en' ? 'English' : 'Spanish',
      IsNotification:
        this.settingDetails.IsNotification === 'true',
      IsEnableEmailNotification:
        this.settingDetails.IsEnableEmailNotification === 'true',
      IsVacation: this.settingDetails.IsVacation === 'true',
      EmailSettings: this.initEmailSettings()
    };
    this.commonService.updateUserProfileSettings(model).subscribe(
      (res: APIResponse) => {
        if (res && res.Success) {
          const lang =
            this.settingDetails.LanguageName === 'en' ? 'English' : 'Spanish';
          if (!this.updatedUserId) {
            this.updateSessionStorage({
              LanguageName: lang,
              IsNotification: model.IsNotification,
              IsVacation: model.IsVacation,
              IsEnableEmailNotification: model.IsEnableEmailNotification
            });
          }

          this.translate.setDefaultLang(this.settingDetails.LanguageName);
          this.methodsForAllRoles.setHeaderTitleInSession();
          this.loaderService.isLoading.next(false);

          this.message = ConstantsService.SuccessMessage.ProfileUpdateSuccess;
          this.modalRef = this.modalService.show(this.template, {
            class: 'modal-md'
          });
        } else {
          this.loaderService.isLoading.next(false);

          this.message = ConstantsService.ErrorMessage.SomeThingWrongMessage;
          this.modalRef = this.modalService.show(this.template, {
            class: 'modal-md'
          });
        }
      },
      () => {
        this.loaderService.isLoading.next(false);
      }
    );
  }

  updateSessionStorage(value) {
    const prevData = JSON.parse(localStorage.getItem(environment.CUID));
    Object.keys(value).forEach(function(val) {
      prevData[val] = value[val];
    });
    localStorage.setItem(environment.CUID, JSON.stringify(prevData));
  }

  password(event) {
    if (event === 'confPass') {
      this.showConf = !this.showConf;
    } else {
      this.show = !this.show;
    }
  }

  backToPrevious(val) {
    this.backEnable = val;
    this.location.back();
  }

  switchLang(data) {
  }

  confirm(): void {
    this.modalRef.hide();
  }

  private getProfile() {
    this.commonService.getProfileByUserId()
      .subscribe(
        (res: APIResponse) => {
          if (res) {
            this.emailSettings = JSON.parse(JSON.stringify(res.Data.EmailSettings));
            this.emailSettings.map((item: EmailSettings) => {
              if (item.Enabled) {
                return item.Enabled = 'true';
              } else {
                return item.Enabled = 'false';
              }
            });
          }
        }
      );
  }

  private initEmailSettings(): EmailSettingsIds[] {
    const settings: EmailSettingsIds[] = [];
    this.emailSettings.forEach((item: EmailSettings) => {
      settings.push({
        id: item.Id,
        enabled: item.Enabled.toString()
      });
    });
    return settings;
  }
}
