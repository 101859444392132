<nav class="container-fluid">
  <div class="head main-padding">
    <div class="head-left">
      <div class="cm-head-text">
        <a class="menu-btn"
          ><img src="../../../../assets/image/side-menu-btn.svg"
        /></a>
        <a
          *ngIf="
            showBackBtn ||
            HeaderTitle === 'Settings' ||
            HeaderTitle === 'Profile'
          "
          class="menu-btn"
          (click)="backToPrevious()"
        >
          <img src="../../../../assets/image/back-btn-copy.svg" />
        </a>
        <h2 *ngIf="HeaderTitle">{{ HeaderTitle }}</h2>
      </div>
    </div>
    <div class="head-right">
<!--      <div class="notification-badge">-->
<!--        <span>-->
<!--          <div class="dropdown">-->
<!--            <button-->
<!--              type="button"-->
<!--              id="dropdownMenuButton"-->
<!--              data-toggle="dropdown"-->
<!--              aria-haspopup="true"-->
<!--              aria-expanded="false"-->
<!--              class="notify-icon dropdown-toggle"-->
<!--            >-->
<!--              <img src="../../../../assets/image/notification-icon-1.svg" />-->
<!--              <span class="badge">{{ notificationLength }}</span>-->
<!--            </button>-->
<!--            <div-->
<!--              aria-labelledby="dropdownMenuButton"-->
<!--              class="dropdown-menu dropdown-menu-center notify-body"-->
<!--            >-->
<!--              <div class="notify-contain">-->
<!--                <ng-container-->
<!--                  *ngFor="let item of notificationList; let i = index"-->
<!--                >-->
<!--                  <a-->
<!--                    (click)="updateNotification(item.NotificationId, i)"-->
<!--                    class="dropdown-item"-->
<!--                    [ngClass]="{ unread: !item.Read }"-->
<!--                  >-->
<!--                    <span>-->
<!--                      <span class="notify-msg"-->
<!--                        >{{ item.UserTo | firstWord }}-->
<!--                      </span>-->
<!--                    </span>-->
<!--                    <span class="notify-text">-->
<!--                      <i class="blur-text">{{ item.MessageText }}</i>-->
<!--                      <span class="notify-date">{{-->
<!--                        item.CreatedDate | date: 'MMM d, y'-->
<!--                      }}</span>-->
<!--                    </span>-->
<!--                  </a>-->
<!--                </ng-container>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </span>-->
<!--      </div>-->
      <div class="user">
        <div class="dropdown">
          <button
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="profile-log dropdown-toggle"
          >
            {{ currentUser.Name }}
          </button>
          <div
            aria-labelledby="dropdownMenuButton"
            class="dropdown-menu dropdown-menu-right"
          >
            <a class="dropdown-item boder-b" (click)="navigateToProfile()"
              ><img src="../../../../assets/image/field-agent-icon-2.svg" />
              Profile
            </a>
            <a class="dropdown-item" data-toggle="modal" data-target="#logout">
              <img src="../../../../assets/image/logout-icon-2.svg" /> Logout
            </a>
          </div>
        </div>
        <div class="user-img" *ngIf="currentUser.DocPath">
          <img src="{{ photoURL }}" />
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Modal -->
<div
  class="modal fade"
  id="logout"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">Log Out</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="delete-msg">
          Are you sure you want to
          <br />
          Log Out from Website?
        </p>
        <div class="foot-btn">
          <button
            type="button"
            class="btn btn-oclm-null"
            (click)="logout()"
            data-dismiss="modal"
          >
            Log Out
          </button>

          <button type="submit" class="btn btn-oclm" data-dismiss="modal">
            Not Now
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
