import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import StatusData from '../../../assets/data/status.json';
import { environment } from 'src/environments/environment';
import { ConstantsService } from './constants.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, filter, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { User } from '../models/user';
import { Toast } from '../models/Toast.interface';
import { IReports } from '../models/reports';
import { APIResponse } from '../models/api-response';
import { HandleerrorService } from './handleerror.service';
import { Status, UserType } from '../models/work-order';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  currentUser: User;
  subject: BehaviorSubject<Toast>;
  toast$: Observable<Toast>;
  headerTitle$: Subject<boolean> = new Subject<boolean>();
  paginationInboxSubject: BehaviorSubject<{ pageNo: number }>;
  currPaginationInboxIndex: Observable<{ pageNo: number }>;
  paginationIndexSubject: BehaviorSubject<{ pageNumber: number }>;
  currentPaginationIndex: Observable<any>;
  WorkIdSubject: BehaviorSubject<any>;
  CurrentWorkIdValue: Observable<any>;
  pageSizeSubject: BehaviorSubject<any>;
  currentPageSize: Observable<any>;
  pageSizeInboxSubject: BehaviorSubject<any>;
  currentInboxPageSize: Observable<any>;
  private toggleSideBarSubject = new BehaviorSubject(false);
  toggleSidebarValue = this.toggleSideBarSubject.asObservable();
  private workOrderStatusSubject = new BehaviorSubject(false);
  workOrderStatusObs = this.workOrderStatusSubject.asObservable();
  private workOrderStatusCompleteSubject = new BehaviorSubject(false);
  workOrderStatusCompleteObs =
    this.workOrderStatusCompleteSubject.asObservable();
  private displayBackBtnSubject = new BehaviorSubject(false);
  displayBackBtnObservables = this.displayBackBtnSubject.asObservable();
  private hideNextBtnSubject = new BehaviorSubject(true);
  displayNextBtnObservables = this.hideNextBtnSubject.asObservable();
  private passSearchTextSubject = new BehaviorSubject('');
  passSearchTextSubjectObservables = this.passSearchTextSubject.asObservable();
  private passFilterTextChart = new BehaviorSubject('');
  passFilterTextChartObservable = this.passFilterTextChart.asObservable();
  private passFilterTextChartByStatus = new BehaviorSubject('');
  passFilterTextChartByStatusObservable = this.passFilterTextChartByStatus.asObservable();
  private setStatusTextSubject = new BehaviorSubject(false);
  getStatusTextSubjectObservables = this.setStatusTextSubject.asObservable();
  private changeStatusProfile = new BehaviorSubject(false);
  changeStatusProfileSubjectObservables = this.changeStatusProfile.asObservable();
  private currentStatusSubject = new BehaviorSubject({
    Status: 'WorkInProgress'
  });
  currentStatusValue = this.currentStatusSubject.asObservable();
  private searchedText = new BehaviorSubject<string>('');
  text = this.searchedText.asObservable();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private handleErrorService: HandleerrorService
  ) {
    this.authService.currentUser.subscribe((res) => {
      this.currentUser = res;
    });

    this.paginationIndexSubject = new BehaviorSubject<{ pageNumber: number }>({ pageNumber: 1 });
    this.currentPaginationIndex = this.paginationIndexSubject.asObservable();

    this.paginationInboxSubject = new BehaviorSubject<{ pageNo: number }>({ pageNo: 1 });
    this.currPaginationInboxIndex = this.paginationInboxSubject.asObservable();

    this.WorkIdSubject = new BehaviorSubject<{ selectedWorkId: string }>({ selectedWorkId: '' });
    this.CurrentWorkIdValue = this.WorkIdSubject.asObservable();

    this.pageSizeSubject = new BehaviorSubject<{ pageSize: 10 }>({ pageSize: 10 });
    this.currentPageSize = this.paginationIndexSubject.asObservable();

    this.pageSizeInboxSubject = new BehaviorSubject<{ pageSize: 10 }>({ pageSize: 10 });
    this.currentInboxPageSize = this.pageSizeInboxSubject.asObservable();

    this.subject = new BehaviorSubject<Toast>(null);
    this.toast$ = this.subject
      .asObservable()
      .pipe(filter((toast) => toast !== null));
  }

  public get CurrentStatusValue(): string {
    return this.currentStatusSubject.value.Status;
  }

  public get CurrentPaginationIndexValue(): number {
    return this.paginationIndexSubject.value.pageNumber;
  }

  public get CurrPaginationInboxIndexValue(): number {
    return this.paginationInboxSubject.value.pageNo;
  }

  public get CurrWorkIdValue(): number {
    return this.WorkIdSubject.value.selectedWorkId;
  }

  public get CurrentPageSize(): number {
    return this.pageSizeSubject.value.pageSize;
  }

  public get CurrentInboxPageSize(): number {
    return this.pageSizeInboxSubject.value.pageSize;
  }

  setStatusTextSubjectMethod(value) {
    this.setStatusTextSubject.next(value);
  }

  setWorkOrderStatus(value?: boolean) {
    this.workOrderStatusSubject.next(value);
  }

  setFilterTextChartMethod(value) {
    this.passFilterTextChart.next(value);
  }

  setFilterTextChartByStatusMethod(value) {
    this.passFilterTextChartByStatus.next(value);
  }

  setWorkOrderStatusComplete(value = false) {
    this.workOrderStatusCompleteSubject.next(value);
  }

  setToggleSidebar(value?: boolean) {
    this.toggleSideBarSubject.next(value);
  }

  changeStausProfile(value) {
    this.changeStatusProfile.next(value);
  }

  passSearchText(value) {
    this.passSearchTextSubject.next(value);
  }

  setToDisplayBackBtn(value?: boolean) {
    this.displayBackBtnSubject.next(value);
  }

  setNextButtonToggle(value?: boolean) {
    this.hideNextBtnSubject.next(value);
  }

  setCurrentStatus(value?: Status) {
    this.currentStatusSubject.next(value);
  }

  public setCurrentPaginationValue(value) {
    this.paginationIndexSubject.next(value);
  }

  public setCurrentPaginationInboxvalue(value) {
    this.paginationInboxSubject.next(value);
  }

  public setCurrentWorkId(value) {
    this.WorkIdSubject.next(value);
  }

  public setCurrentPageSize(value) {
    this.log(value);
    this.pageSizeSubject.next(value);
  }

  public setCurrentInboxPageSize(value) {
    this.pageSizeInboxSubject.next(value);
  }

  downloadWorkOrder(model) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.downloadWorkOrder}`;
    return this.httpClient.post(API_URL, model);
  }

  getStatusList() {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetStatusList}`;
    return this.httpClient.get(API_URL);
  }

  getStatusListLocal(local: string = 'en') {
    return StatusData.filter((x) => x.local === local);
  }

  changeText(searchText: string) {
    return this.searchedText.next(searchText);
  }

  FieldAgentJob(supervisorId, fieldAgentId) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.FieldAgentJob}`;
    let params: HttpParams = new HttpParams();
    params = params.append('supervisorId', supervisorId);
    params = params.append('fieldAgentId', fieldAgentId);
    return this.httpClient.get(API_URL, { params });
  }

  UpdateProfileJob(JobIds, UserId) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.UpdateProfileJob}`;
    return this.httpClient.post(API_URL, { JobIds, UserId });
  }

  getUserList(search = '') {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Admin_APIURL.GetUserListUrl}`;
    let params: HttpParams = new HttpParams();
    params = params.append('searchText', search);
    return this.httpClient.get(API_URL, { params });
  }

  getWorkCategoryList() {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.WorkCategoryList}/.`;
    return this.httpClient.get(API_URL);
  }

  getCannedResponseList(value) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetCannedResponseList}`;
    let params: HttpParams = new HttpParams();
    params = params.append('WorkCategory', value);
    return this.httpClient.get(API_URL, { params });
  }

  getCannedResponseListForReports(value) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.CannedResponses}`;
    let params: HttpParams = new HttpParams();
    params = params.append('workCategories', value.toString());
    return this.httpClient.get(API_URL, { params });
  }

  GetProfileByEmail(email: string) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetProfileByEmail}`;
    let params: HttpParams = new HttpParams();
    params = params.append('userEmail', email);
    return this.httpClient.get(API_URL, { params });
  }

  getFieldAgentListByLoggedInUser() {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetFieldAgentList}`;
    let params: HttpParams = new HttpParams();
    this.currentUser = this.authService.currentLoginUserValue;
    params = params.append('superVisorId', this.currentUser.Id.toString());
    return this.httpClient.get(API_URL, { params });
  }

  getFieldAgentListByLoggedInUserAndJobIds(jobId) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetFieldAgentList}`;
    let params: HttpParams = new HttpParams();
    const userId: string =
      this.authService.currentLoginUserValue.Role === 'FieldAgent'
        ? '0'
        : this.authService.currentLoginUserValue.Id.toString();
    params = params.append('superVisorId', userId);
    params = params.append('jobId', jobId);
    return this.httpClient.get(API_URL, { params });
  }

  getFieldAgentOnSearchFilter(searchText) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetSearchFieldAgent}`;
    let params: HttpParams = new HttpParams();
    this.currentUser = this.authService.currentLoginUserValue;
    params = params.append('userId', this.currentUser.Id.toString());
    params = params.append('searchText', searchText);
    return this.httpClient.get(API_URL, { params });
  }

  deleteFieldAgentByUserId(userId: number, supervisorId: number) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.DeleteFieldAgentBySupervisor}`;
    return this.httpClient.post(API_URL, { FieldAgentId: userId, SupervisorId: supervisorId });
  }

  setMarkAllNotification(userId) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.MarkAllNotification}`;
    return this.httpClient.post(`${API_URL}?userId=${userId}`, {});
  }

  getUserTypeList() {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetUserTypeList}`;
    return this.httpClient.get(API_URL)
      .pipe(map((data: APIResponse) => {
        if (data.Data && data.Success && data.StatusCode === 200) {
          const userTypes: UserType[] = [];
          data.Data.forEach((userType: UserType) => {
            userTypes.push(userType);
          });
          return userTypes;
        }
      }));
  }

  getTemplateList() {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetTemplateList}`;
    return this.httpClient.get(API_URL);
  }

  getJobNameByZipCode(zipCode) {
    let params: HttpParams = new HttpParams();
    params = params.append('zip', zipCode);
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetJobNameByZipCode}`;
    return this.httpClient.get(API_URL, { params });
  }

  getBranchByJobId(jobId) {
    let params: HttpParams = new HttpParams();
    params = params.append('jobIds', jobId.toString());
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetBranchNameByJobId}`;
    return this.httpClient.get(API_URL, { params });
  }

  getJobListBySupervisor(withBlocked = false) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetJobListBySupervisor}`;
    let params: HttpParams = new HttpParams();
    params = params.append('superVisorId', this.currentUser.Id.toString());
    params = params.append('withBlocked', withBlocked);
    return this.httpClient.get(API_URL, { params });
  }

  getAddressByCustomer(customerId: number) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.getAddressByCustomer}`;
    let params: HttpParams = new HttpParams();
    params = params.append('customerId', customerId.toString());
    return this.httpClient.get(API_URL, { params });
  }

  getSupervisorByCustomer(customerId: number) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.SupervisorIdsByCustomer}`;
    let params: HttpParams = new HttpParams();
    params = params.append('customerId', customerId.toString());
    return this.httpClient.get(API_URL, { params });
  }

  getClientIdsByCustomer(customerId: number) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.getClientIdsByCustomer}`;
    let params: HttpParams = new HttpParams();
    params = params.append('customerId', customerId.toString());
    return this.httpClient.get(API_URL, { params });
  }

  getAllJobList(searchText) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetJobBySearchText}`;
    let params: HttpParams = new HttpParams();
    params = params.append('searchText', searchText);
    return this.httpClient.get(API_URL, { params });
  }

  getSupervisorAndFAListByJobId(JobId) {
    let params: HttpParams = new HttpParams();
    params = params.append('JobId', JobId);
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetSupervisorList}`;
    return this.httpClient.get(API_URL, { params });
  }

  getSupervisors(JobId) {
    let params: HttpParams = new HttpParams();
    params = params.append('JobId', JobId);
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.GetSupervisors}`;
    return this.httpClient.get(API_URL, { params });
  }

  getBrowserTokenbyUserId(Id) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.GetUserToken}`;
    let params: HttpParams = new HttpParams();
    params = params.append('userID', Id);
    return this.httpClient.get(API_URL, { params });
  }

  updateLoggedInUserbyBrowserId(browserId) {
    let user;
    this.authService.currentUser.subscribe((res) => {
      user = res;
    });
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.CreateUserToken}`;
    if (user) {
      let updateUserDetails = {
        UserId: user.Id,
        TokenId: browserId
      };
      return this.httpClient.post(API_URL, updateUserDetails);
    }
  }

  sendEmailByUserId(model) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.profileDetails.sendEmailToUser}`;
    return this.httpClient.post(API_URL, model);
  }

  sendNotification(data) {
    const httpOptions = {
      headers: null
    };
    httpOptions.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'key=' + environment.fireBaseLegacyServerKey
    });
    return this.httpClient
      .post(ConstantsService.Common_APIURL.fcmAPIURL, data, httpOptions)
      .pipe(catchError(this.handleErrorService.handleError('Issue to pass in header of fcm')));
  }

  createNotificationToAPI(data) {
    Object.assign(data, { From: this.currentUser.Id.toString() });
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.CreateNotification}`;
    return this.httpClient.post(API_URL, data);
  }

  getNotificationByUserId() {
    let params: HttpParams = new HttpParams();
    params = params.append('userId', this.currentUser.Id.toString());
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.GetNotification}`;
    return this.httpClient.get(API_URL, { params });
  }

  updateNotificationById(notifyObject) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.UpdateNotificationById}`;
    return this.httpClient.post(API_URL, notifyObject);
  }

  getProfileByUserId() {
    let params = new HttpParams();
    params = params.append('userId', this.currentUser.Id.toString());
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.GetProfileByUserId}`;
    return this.httpClient.get(API_URL, { params });
  }

  getSupervisorProfileById(userId) {
    let params: HttpParams = new HttpParams();
    params = params.append('userId', userId);
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.GetProfileByUserId}`;
    return this.httpClient.get(API_URL, { params });
  }

  updateProfileDetails(request) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.UpdateProfile}`;
    return this.httpClient.post(API_URL, request);
  }

  updateUserProfileSettings(request) {
    const API_URL: string = `${environment.apiUrl}${ConstantsService.Common_APIURL.UpdateProfileSetting}`;
    return this.httpClient.post(API_URL, request);
  }

  navigateByURL(url: string) {
    this.router.navigateByUrl(url);
  }

  /** Log a HeroService message with the MessageService */
  public log(message: string) {
  }

  getScheduleWorkOrderByID(searhText = '') {
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.getWorkOrderScheduled}`;
    let params = new HttpParams();
    params = params.append('userId', this.currentUser.Id.toString());
    params = params.append('searhText', searhText);
    return this.httpClient.get(API_URL, { params });
  }

  getReportsDetails(model: IReports) {
    let userId;
    if (
      this.authService &&
      this.authService.currentLoginUserValue &&
      this.authService.currentLoginUserValue.Role ===
      ConstantsService.User_Role.Admin
    ) {
      userId = 0;
    } else {
      userId = this.authService.currentLoginUserValue.Id.toString();
    }
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.getWorkOrderReport}`;
    let params = new HttpParams();
    if (model.label) {
      params = params.append('label', model.label)
    }
    params = params.append(
      'jobIds',
      model.jobIds
        ? model.jobIds
        : null
    );
    params = params.append(
      'WorkCategory',
      model.WorkCategory ? model.WorkCategory : ''
    );
    params = params.append(
      'Branch',
      model.Branch == null || model.Branch == '' ? '' : model.Branch
    );
    params = params.append('DateFrom', model.DateFrom ? model.DateFrom : null);
    params = params.append('DateTo', model.DateTo ? model.DateTo : null);
    params = params.append(
      'fieldAgentIds',
      model.fieldAgentIds ? model.fieldAgentIds : ''
    );
    params = params.append('statusIds', model.statusIds ? model.statusIds : '');
    params = params.append('userId', userId);
    params = params.append(
      'searchText',
      model.searchText ? model.searchText : ''
    );
    params = params.append('Days', model.Days ? model.Days : 0);
    if (model.cannedResponse) {
      params = params.append('cannedResponse', model.cannedResponse);
    }
    return this.httpClient.get(API_URL, { params });
  }

  getWorkOrderReportStatusWiseByJob(model: IReports) {
    let userId;
    if (
      this.authService &&
      this.authService.currentLoginUserValue &&
      this.authService.currentLoginUserValue.Role ===
      ConstantsService.User_Role.Admin
    ) {
      userId = 0;
    } else {
      userId = this.authService.currentLoginUserValue.Id.toString();
    }
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.getWorkOrderReportStatusWiseByJob}`;
    let params = new HttpParams();
    params = params.append(
      'jobIds',
      model.jobIds
        ? model.jobIds
        : this.authService.currentLoginUserValue.JobIds
    );
    params = params.append(
      'WorkCategory',
      model.WorkCategory ? model.WorkCategory : ''
    );
    params = params.append(
      'Branch',
      model.Branch == null || model.Branch == '' ? '' : model.Branch
    );
    params = params.append('DateFrom', model.DateFrom ? model.DateFrom : null);
    params = params.append('DateTo', model.DateTo ? model.DateTo : null);
    params = params.append(
      'fieldAgentIds',
      model.fieldAgentIds ? model.fieldAgentIds : ''
    );
    params = params.append('statusIds', model.statusIds ? model.statusIds : '');
    params = params.append('userId', userId);
    params = params.append(
      'searchText',
      model.searchText ? model.searchText : ''
    );
    params = params.append('Days', model.Days ? model.Days : 0);

    return this.httpClient.get(API_URL, { params });
  }

  getWorkOrderReportStatusWiseByOwner(model: IReports) {
    let userId;
    if (
      this.authService &&
      this.authService.currentLoginUserValue &&
      this.authService.currentLoginUserValue.Role ===
      ConstantsService.User_Role.Admin
    ) {
      userId = 0;
    } else {
      userId = this.authService.currentLoginUserValue.Id.toString();
    }
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.getWorkOrderReportStatusWiseByOwner}`;
    let params = new HttpParams();
    params = params.append(
      'jobIds',
      model.jobIds
        ? model.jobIds
        : this.authService.currentLoginUserValue.JobIds
    );
    params = params.append(
      'WorkCategory',
      model.WorkCategory ? model.WorkCategory : ''
    );
    params = params.append(
      'Branch',
      model.Branch == null || model.Branch == '' ? '' : model.Branch
    );
    params = params.append('DateFrom', model.DateFrom ? model.DateFrom : null);
    params = params.append('DateTo', model.DateTo ? model.DateTo : null);
    params = params.append(
      'fieldAgentIds',
      model.fieldAgentIds ? model.fieldAgentIds : ''
    );
    params = params.append('statusIds', model.statusIds ? model.statusIds : '');
    params = params.append('userId', userId);
    params = params.append(
      'searchText',
      model.searchText ? model.searchText : ''
    );
    params = params.append('Days', model.Days ? model.Days : 0);

    return this.httpClient.get(API_URL, { params });
  }

  exportWorkOrdersCSV(data): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.ExportCSV}`;
    let params = new HttpParams();
    if (data.label) {
      params = params.append('label', data.label)
    }
    params = params.append(
      'jobIds',
      data.jobIds ? data.jobIds : ''
    );
    params = params.append(
      'workCategoryIds',
      data.workCategoryIds ? data.workCategoryIds : null
    );
    params = params.append('userId', this.currentUser.Id.toString());

    if (data.searchText) {
      params = params.append(
        'searchText',
        data.searchText ? data.searchText : ''
      );
    }

    if (data.statusIds) {
      params = params.append('statusIds', data.statusIds ? data.statusIds : '');
    }

    if (data.DateTo) {
      params = params.append('DateTo', data.DateTo ? data.DateTo : '');
    }

    if (data.DateFrom) {
      params = params.append('DateFrom', data.DateFrom ? data.DateFrom : '');
    }

    if (data.cannedResponseIds) {
      params = params.append('cannedResponseIds', data.cannedResponseIds ? data.cannedResponseIds : '');
    }

    return this.httpClient.get(API_URL, { params });
  }

  exportWorkOrdersPDF(data): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.ExportPDF}`;
    let params = new HttpParams();
    if (data.label) {
      params = params.append('label', data.label)
    }
    params = params.append(
      'jobIds',
      data.jobIds ? data.jobIds : ''
    );
    params = params.append(
      'workCategoryIds',
      data.workCategoryIds ? data.workCategoryIds : null
    );
    params = params.append('userId', this.currentUser.Id.toString());

    if (data.searchText) {
      params = params.append(
        'searchText',
        data.searchText ? data.searchText : ''
      );
    }

    if (data.statusIds) {
      params = params.append('statusIds', data.statusIds ? data.statusIds : '');
    }

    if (data.DateTo) {
      params = params.append('DateTo', data.DateTo ? data.DateTo : '');
    }

    if (data.DateFrom) {
      params = params.append('DateFrom', data.DateFrom ? data.DateFrom : '');
    }

    if (data.cannedResponseIds) {
      params = params.append('cannedResponseIds', data.cannedResponseIds ? data.cannedResponseIds : '');
    }

    return this.httpClient.get(API_URL, { params });
  }

  WorkOrderReportStatusWiseByCategory(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.WorkOrderReportStatusWiseBycategory}`;
    let params = new HttpParams();
    let userId;
    if (
      this.authService &&
      this.authService.currentLoginUserValue &&
      this.authService.currentLoginUserValue.Role ===
      ConstantsService.User_Role.Admin
    ) {
      userId = 0;
    } else {
      userId = this.authService.currentLoginUserValue.Id.toString();
    }
    params = params.append('userId', userId);
    params = params.append(
      'jobIds',
      data.jobIds ? data.jobIds : this.authService.currentLoginUserValue.JobIds
    );
    params = params.append(
      'WorkCategoryId',
      data.WorkCategory ? data.WorkCategory : ''
    );

    if (data.Branch) {
      params = params.append('Branch', data.Branch ? data.Branch : '');
    }
    if (data.statusIds) {
      params = params.append(
        'statudsIds',
        data.statusIds ? data.statusIds : ''
      );
    }
    if (data.DateFrom) {
      params = params.append('DateFrom', data.DateFrom ? data.DateFrom : '');
    }

    if (data.DateTo) {
      params = params.append('DateTo', data.DateTo ? data.DateTo : '');
    }
    if (data.fieldAgentIds) {
      params = params.append(
        'fieldAgentIds',
        data.fieldAgentIds ? data.fieldAgentIds : ''
      );
    }

    if (data.Days) {
      params = params.append('Days', data.Days ? data.Days : '');
    }
    if (data.searchText) {
      params = params.append(
        'searchText',
        data.searchText ? data.searchText : ''
      );
    }
    return this.httpClient.get(API_URL, { params });
  }

  saveCommunityManagerNotes(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.CMModule_APIURL.PostMoreFieldNotes}`;
    return this.httpClient.post(API_URL, data);
  }

  getWorkOrderDetailsByWorkIdsForReports(model) {
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.getWorkOrderReportView}`;
    let params = new HttpParams();
    params = params.append('workOrderIds', model.toString());
    return this.httpClient.get(API_URL, { params });
  }

  getWorkOrderReportDetail(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.getWorkOrderReportDetail}`;
    let params = new HttpParams();
    params = params.append(
      'userId',
      this.authService.currentLoginUserValue.Id.toString()
    );
    if (data.downLoad) {
      params = params.append('downLoad', data.downLoad ? data.downLoad : '');
    }
    params = params.append(
      'jobIds',
      data.jobIds ? data.jobIds : this.authService.currentLoginUserValue.JobIds
    );
    if (data.WorkCategory) {
      params = params.append(
        'WorkCategory',
        data.WorkCategory ? data.WorkCategory : ''
      );
    }

    if (data.Branch) {
      params = params.append('Branch', data.Branch ? data.Branch : '');
    }
    if (data.statusIds) {
      params = params.append('statusIds', data.statusIds ? data.statusIds : '');
    }
    if (data.DateFrom) {
      params = params.append('DateFrom', data.DateFrom ? data.DateFrom : '');
    }

    if (data.DateTo) {
      params = params.append('DateTo', data.DateTo ? data.DateTo : '');
    }
    if (data.fieldAgentIds) {
      params = params.append(
        'fieldAgentIds',
        data.fieldAgentIds ? data.fieldAgentIds : ''
      );
    }
    if (data.Days) {
      params = params.append('Days', data.Days ? data.Days : '');
    }
    if (data.searchText) {
      params = params.append(
        'searchText',
        data.searchText ? data.searchText : ''
      );
    }
    if (data.reportView) {
      params = params.append(
        'reportView',
        data.reportView ? data.reportView : ''
      );
    }

    return this.httpClient.get(API_URL, { params });
  }

  deleteWorkOrder(workId: number, workType: string) {
    let deleteUrl = '';
    if (workType === 'Resident') {
      deleteUrl = 'DeleteWorkOrder';
    } else {
      deleteUrl = 'DeleteWorkOrderByID';
    }

    const API_URL = `${environment.apiUrl}${deleteUrl}`;
    return this.httpClient.delete(`${API_URL}?workId=${workId.toString()}`);
  }

  decripteUserId(token): Observable<APIResponse> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.MasterDetails.DecryptUserId}`;
    return this.httpClient.get<APIResponse>(`${API_URL}?userToken=${token}`);
  }

  savePasswordForInvitetion(
    userId: number,
    password: string
  ): Observable<APIResponse> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.SavePassword}`;
    return this.httpClient.post<APIResponse>(
      `${API_URL}?userId=${userId}&password=${password}`,
      {}
    );
  }
}
