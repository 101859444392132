<div class='main-padding'>
  <form [formGroup]='profileForm'>
    <div class='user-img-wrpper'>
      <div class='display-img' *ngIf="!profileForm['controls'].DocPath.value">
        <img alt='icon'
             src='../../../../../assets/image/user-image-2.png'
             class='img-fluid'
        />
      </div>
      <div class='display-img' *ngIf="profileForm['controls'].DocPath.value">
        <img alt='icon' src='{{ photoURL }}' class='img-fluid' />
      </div>
      <div class='btn-oclm-gry'>
        <input
          type='file'
          class='file-style'
          name='file-upload'
          (change)='onFileChange($event)'
          [multiple]='multipleImage'
          [accept]='accept'
        />
        {{ 'BtnText.Change' | translate }}
      </div>
    </div>
    <div class='row'>
      <div class='col-md-5'>
        <div class='form-group'>
          <label>
            {{ 'CommonLabel.FirstName' | translate }}
            <sup class='text-dan'>*</sup>
          </label>
          <div class='wrp'>
            <input
              formControlName='FirstName'
              maxlength='50'
              type='text'
              placeholder="{{ 'CommonLabel.FirstName' | translate }}"
              class='form-control'
              [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" />
            <img alt='icon'
                 src='../../../../assets/image/user-icon.png'
                 class='user-icon' />
            <div
              *ngIf='submitted && f.FirstName.errors'
              class='invalid-feedback'>
              <div *ngIf='f.FirstName.errors.required'>
                First Name is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md-5'>
        <div class='form-group'>
          <label>
            {{ 'CommonLabel.LastName' | translate }}
            <sup class='text-dan'>*</sup>
          </label>
          <div class='wrp'>
            <input
              formControlName='LastName'
              maxlength='50'
              type='text'
              placeholder="{{ 'CommonLabel.LastName' | translate }}"
              class='form-control'
              [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }"
            />
            <img alt='icon'
                 src='../../../../../assets/image/user-icon.png'
                 class='user-icon'
            />
            <div
              *ngIf='submitted && f.LastName.errors'
              class='invalid-feedback'
            >
              <div *ngIf='f.LastName.errors.required'>
                Last Name is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col-md-10'>
        <div class='form-group'>
          <label>
            {{ 'CommonLabel.Email' | translate }}
            <sup class='text-dan'>*</sup>
          </label>
          <div class='wrp'>
            <input
              email
              formControlName='Email'
              maxlength='200'
              type='email'
              placeholder="{{ 'CommonLabel.Email' | translate }}"
              class='form-control'
              [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
            />
            <img alt='icon'
                 src='../../../../../assets/image/email-icon.png'
                 class='user-icon'
            />
            <div *ngIf='submitted && f.Email.errors' class='invalid-feedback'>
              <div *ngIf='f.Email.errors.required'>Email is required</div>
              <div *ngIf='f.Email.errors.email'>
                Please enter valid email address
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md-5'>
        <div class='form-group'>
          <label>
            {{ 'CommonLabel.PhoneNumber' | translate }}
            <sup class='text-dan'>*</sup>
          </label>
          <div class='wrp'>
            <input
              formControlName='PhoneNumber'
              maxlength="12"
              mask="000-000-0000"
              minlength='10'
              appPhoneNumber
              type='text'
              onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode >= 32 && event.charCode <= 47 || event.charCode >= 58 && event.charCode <= 64'
              placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
              class='form-control'
              [ngClass]="{ 'is-invalid': submitted && f.PhoneNumber.errors }"/>
            <img
              src="../../../../assets/image/phone-icon.png"
              class='user-icon'/>
            <div *ngIf='submitted && f.PhoneNumber?.errors' class='invalid-feedback'>
              <div *ngIf='f.PhoneNumber?.errors?.required'>{{'Message.ContactPhoneRequired' | translate}}</div>
              <div *ngIf="f.PhoneNumber?.errors?.minlength || f.PhoneNumber?.errors?.maxlength">
                {{ 'Message.ContactPhoneTenDigits' | translate }}
              </div>
              <div *ngIf="f.PhoneNumber?.errors?.pattern">
                {{"Message.OnlyNumbersAndSymbols" | translate}}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class='row'>
      <div class='form-group ml-3'>
        <button type='submit' class='btn btn-oclm w-100' (click)='onSave()'>
          {{ 'BtnText.SaveProfile' | translate }}
          <img alt='icon'
               src='../../../../assets/image/065-right-arrow-1.svg'
               class='arrow-right ml-2'
          />
        </button>
      </div>
    </div>
  </form>
  <ng-container *ngIf="currentUser.Role === 'SuperVisor' || currentUser.Role === 'FieldAgent'">
    <ng-container  *ngTemplateOutlet="userList"></ng-container>
  </ng-container>
</div>


<ng-template #userList>
  <div class="table-maine">
    <div class="tab-content">
      <div class="tab-pane active">
        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table table-style-admin">
              <thead>
              <th sorttable="FirstName">
                {{ 'Jobs.CustomerId' | translate }}
              </th>
              <th sorttable="UserId">
              {{ 'Jobs.CustomerName' | translate }}
              </th>
              <th sorttable="Role">
                {{ 'Jobs.ZipCode' | translate }}
              </th>
              <th sorttable="Phone">
              {{ 'Jobs.City' | translate }}
              </th>
              <th sorttable="Email">
                {{ 'Jobs.CustomerCLass' | translate }}
              </th>
              <th sorttable="FirstName">
                {{ 'Jobs.BranchName' | translate }}
              </th>
              <th></th>
              </thead>
              <tbody>
              <ng-container *ngIf="jobList && jobList.length > 0">
                <tr *ngFor="let item of jobList; let i = index">
                  <td>
                    <p>{{ item.CustomerId }}</p>
                  </td>
                  <td>
                    <strong>{{ item.Name }}</strong>
                  </td>
                  <td>
                    {{ item.ZipCode }}
                  </td>
                  <td>
                    <p>
                      {{ item.City }}
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.CustomerClass }}
                    </p>
                  </td>
                  <td>
                    <p>{{ item.BranchName }}</p>
                  </td>
<!--                  <td>-->
<!--                    <p *ngFor="let i of item.Jobs; let t = index"> {{ i?.Name }}</p>-->
<!--                  </td>-->
<!--                  <td><a [routerLink]="['/users',item.UserId]"><img-->
<!--                    src="../../../../../../../assets/image/edit_black_24dp.svg" class="img-fluid" /></a>-->
<!--                    &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--                    <img (click)="-->
<!--                        blockUser(template,item.UserId,item.FirstName)-->
<!--                      " src="../../../../../assets/image/delete.svg" class="img-fluid" />-->

<!--                  </td>-->
                </tr>
              </ng-container>
              </tbody>
<!--              <ng-container *ngIf="userListPgination == 0">-->
<!--                <div class="inbox-body">-->
<!--                  {{ 'Message.NoRecordFound' | translate }}-->
<!--                </div>-->
<!--              </ng-container>-->
            </table>
          </div>
<!--          <div class="page-select">-->
<!--            <div class="coustom-width ma-l">-->
<!--              <select class="form-control" id="itemperpage" #itemperpage [(ngModel)]="pageSize"-->
<!--                      (change)="selectItemPerPage(itemperpage.value)">-->
<!--                <option [value]="10">-->
<!--                  {{ 'CommonLabel.ItemPerPage' | translate }}-->
<!--                </option>-->
<!--                <option *ngFor="let value of itemPerPage" [value]="value.pageSize">-->
<!--                  <input type="checkbox" />-->
<!--                  {{ value.pageSize }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <ng-container *ngIf="userListPgination != 0">-->
<!--              <pagination [totalItems]="userListPgination" (pageChanged)="pageChanged($event)" [itemsPerPage]="pageSize"-->
<!--                          [(ngModel)]="pageNo" [maxSize]="maxSize" [boundaryLinks]="true" previousText="&lsaquo;"-->
<!--                          nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>-->
<!--            </ng-container>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #template>
  <div class='modal-body text-center'>
    <p>{{ message }}</p>
    <button type='button' class='btn btn-oclm' (click)='confirm()'>Ok</button>
  </div>
</ng-template>
