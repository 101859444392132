import { FormGroup } from '@angular/forms';
import {
  validation_messages,
  forms_errors,
} from '../../../shared/constants/message-validators';
import { CreateUserComponent } from '../../Users/main-users/shared/components/create-user/create-user.component';

export function ValidationForms(
  group: FormGroup = CreateUserComponent.initialForm()
): void {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];
    if (abstractControl instanceof FormGroup) {
      this.validationForms(abstractControl);
    } else {
      forms_errors[key] = '';
      if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
        const message = validation_messages[key];
        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            forms_errors[key] += message[errorKey] + ' ';
          }
        }
      }
    }
  });
}
