import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CmService {
  currentUser: User;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.currentUser = this.authService.currentLoginUserValue;
  }

  createWorkOrder(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.CMModule_APIURL.PostWOrkOrder}`;
    return this.http.post(API_URL, data);
  }

  getWorkOrderListByStatus(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.CMModule_APIURL.GetCMWorkOrderListByStatus}`;
    let params = new HttpParams();
    params = params.append('dateFrom', data.dateFrom);
    params = params.append('dateTo', data.dateTo);
    params = params.append('status', data.statusKey);
    this.currentUser = this.authService.currentLoginUserValue;
    params = params.append('userId', this.currentUser.Id.toString());
    params = params.append('pageNo', data.pageNo);
    params = params.append('pageSize', data.pageSize);
    params = params.append(
      'searchText',
      data.searchText ? data.searchText : ''
    );
    params = params.append(
      'sortByCreateDate',
      data.sortByWorkOrder ? data.sortByWorkOrder : true
    );
    params = params.append(
      'jobIds',
      data.jobIds ? data.jobIds : null
    );

    if (data.sortedColumn && data.isSortedDesc) {
      params = params.append('sortedColumn', data.sortedColumn);
    }
    if (data.isSortedDesc === 'true') {
      params = params.append('isSortedDesc', 'desc');
    } else {
      params = params.append('isSortedDesc', 'asc');
    }
    return this.http.get(API_URL, { params: params });
  }

  getWorkOrderByWorkId(workId, workOrderType) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.GetWorkOrderByWorkId}`;
    let params = new HttpParams();
    params = params.append('workId', workId);
    params = params.append('workOrderType', workOrderType);
    return this.http.get(API_URL, { params: params });
  }

  getTaskOrderDetailsByTaskOrderId(taskOrderId) {
    const API_URL = `${environment.apiUrl}${ConstantsService.ResidentModule_APIURL.SearchWorkOrder}`;
    let params = new HttpParams();
    params = params.append('searchText', taskOrderId);
    return this.http.get(API_URL, { params: params });
  }

  SupervisorIdsByCustomer(orderId) {
    const API_URL = `${environment.apiUrl}${ConstantsService.ResidentModule_APIURL.SupervisorIdsByCustomer}`;
    let params = new HttpParams();
    params = params.append('customerId', orderId);
    return this.http.get(API_URL, { params });
  }
  cMIsWorkOrderApprove(
    workOrderId: number,
    isApproved = true,
    approvedBy: number
  ) {
    const API_URL = `${environment.apiUrl}${ConstantsService.ResidentModule_APIURL.CMIsWorkOrderApproved}`;
    return this.http.post(API_URL, { workOrderId, isApproved, approvedBy });
  }
}
