import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConstantsService } from '../../../shared/services/constants.service';
import { map } from 'rxjs/operators';
import { JobData } from '../models/models';
import { LabelForWO } from '../../../shared/models/user';
import { ICustomLabelData } from '../../../shared/models/custom-label.interface';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient) { }

  createJob(form): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.CreateCustomer}`;
    return this.http.post<any>(API_URL,form)
  }

  getJobList( pageNo,
              pageSize,
              searchText,
              isSortedDesc): Observable<JobData> {
    let params = new HttpParams();
    if (searchText === '') {
      params = params.append('pageNo', pageNo);
      params = params.append('pageSize', pageSize);
      params = params.append('isSortedDesc', isSortedDesc);
    } else {
      params = params.append('searchText', searchText);
      params = params.append('pageNo', pageNo);
      params = params.append('pageSize', pageSize);
      params = params.append('isSortedDesc', isSortedDesc);
    }
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.CustomerList}`;
    return this.http.get<JobData>(API_URL,{ params })
  }

  deleteJob(id: number): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.DeleteCustomer}?id=${id}`;
    return this.http.post(API_URL,{id})
  }

  getJob(id: number): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.GetCustomer}`;
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get(API_URL,{params}).pipe(map((item: any) => item.Data))
  }

  editJob(body: any): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.EditCustomer}`;
    return this.http.post(API_URL, body)
  }

  getAdditionalLabels(workOrderId: number): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.GetAdditionalLabels}`;
    let params = new HttpParams();
    params = params.append('workOrderId', workOrderId);
    return this.http.get(API_URL,{params}).pipe(map((item: any) => item.Data))
  }

  updateAdditionalLabels(workOrder: LabelForWO): Observable<any> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.UpdateAdditionalLabels}`;
    return this.http.post(API_URL, workOrder).pipe(map((item: any) => item.Data))
  }

  getCustomerLabels(customerId: number): Observable<Array<ICustomLabelData>> {
    const API_URL = `${environment.apiUrl}${ConstantsService.Jobs_APIURL.GetCustomerLabels}`;
    let params = new HttpParams();
    params = params.append('customerId', customerId);
    return this.http.get(API_URL,{params}).pipe(map((item: any) => item.Data))
  }
}
