<div [formGroup]='addressForm'>
  <input
    class='form-control'
    formControlName='address'
    [ngClass]="{ 'is-invalid': !!f.address?.errors }"
    ngx-google-places-autocomplete
    #placesRef='ngx-places'
    (mouseenter)='onMouseLeave($event)'
    (mouseleave)='onMouseEnter($event)'
    (onAddressChange)='onChange($event)'/>
  <div *ngIf='f.address?.errors?.required' class='invalid-feedback'>
    {{ 'Message.ContactAddressRequired' | translate }}
  </div>
</div>
