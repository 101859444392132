import { Component, ElementRef, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import * as Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { finalize, takeUntil, } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CommonService } from 'src/app/shared/services/common.service';
import { IReportDetail, IReports, ReportType, } from 'src/app/shared/models/reports';
import * as moment from 'moment';
import { JobList } from 'src/app/shared/models/job-list';
import { APIResponse } from 'src/app/shared/models/api-response';
import { FieldAgentUser } from 'src/app/shared/models/field-agent-user';
import { LoaderService } from '../../helpers/services/loader.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { ReportDetailsTabularViewComponent } from '../report-details-tabular-view/report-details-tabular-view.component';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { role } from 'src/utils';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { MethodsForAllRoles } from '../../../shared/services/methods-for-all-roles.service';
import { generateUniqueColors } from '../../../shared/utils/utils';

Drilldown(Highcharts);

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;
  reportValues: ReportType = new ReportType();
  destroy$: Subject<boolean> = new Subject<boolean>();
  OwnerOption: any;
  categoryOption: any;
  backLogOption: any;
  completedOption: any;
  chartName;
  @ViewChild('searchInputText', { static: true }) searchInputText: ElementRef;
  @ViewChild('screenCapture', { static: true }) screenCapture: ElementRef;
  @ViewChild(ReportDetailsTabularViewComponent)
  modalInfo: ReportDetailsTabularViewComponent;
  enableCrossIcon = false;
  searchText = '';
  bsConfig: Partial<BsDatepickerConfig>;
  jobListDetails: JobList[];
  selectedJobs: string;
  selectedAgents;
  selectedStatus;
  deSelectCheckBox;
  workCategoryList = [];
  statusList = [];
  fielgAgentList: FieldAgentUser[] = [];
  branchList = [];

  WorkCategory = null;
  Branch = null;
  DateFrom;
  DateTo;
  Days = null;
  isSetCustomDate = false;
  currentUser: User;
  workOrderDetails = [];
  applyFilter = false;
  applicationUrl = environment.applicationUrl;
  userRole: string;

  constructor(
    private commonService: CommonService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private title: Title,
    private router: Router,
    private methodsForAllRoles: MethodsForAllRoles

  ) {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-red',
        dateInputFormat: 'DD-MM-YYYY',
        displayOneMonthRange: true,
      }
    );
    this.authenticationService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.currentUser = value;
        this.title.setTitle(`OCLM - ${role(this.currentUser.Role)} - Report`);
        this.userRole = this.currentUser.Role.toLowerCase();
      });
  }

  get isCommunityManager() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.CommunityManager
    );
  }

  ngOnInit(): void {
    this.methodsForAllRoles.setHeaderTitleInSession('Reports', 'Informes')
    this.getJobListBySupervisorId();
    this.getWorkCategoryList();
    this.getFieldAgentList();
    this.getWorkStatusList();
    this.getReportsDetails();
    this.searchFilter();
  }

  getBranchByJobId() {
    this.commonService
      .getBranchByJobId(this.selectedJobs)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        const branchListObject = res.Data || [];
        this.branchList = branchListObject.map((element) => {
          return {
            checked: false,
            Name: element.Text,
            Id: element.ID,
          };
        });
      });
  }

  getJobListBySupervisorId() {
    this.methodsForAllRoles.getJobListBySupervisorId()
      .subscribe((res: JobList[]) => {
        this.jobListDetails = res
      })
  }

  shareCheckedJobList(item: number[]) {
    this.selectedJobs = item.toString();
    this.getBranchByJobId();
  }

  shareIndividualCheckedList(item: boolean) {
    this.deSelectCheckBox = item;
  }

  shareCheckedListAgent(item: number[]) {
    this.selectedAgents = item.toString();
  }

  shareCheckedListStatus(item: string[]) {
    this.selectedStatus = item.toString();
  }

  shareIndividualCheckedListAgent(item: boolean) {
    this.deSelectCheckBox = item;
  }

  shareCheckedListCat(item: string[]) {
    this.WorkCategory = item.toString();
  }

  shareIndividualCheckedCat(item: boolean) {
    this.deSelectCheckBox = item;
  }

  shareStatusCheckedCat(item: boolean) {
    this.deSelectCheckBox = item;
  }

  shareCheckedListBranch(item: number[]) {
    this.Branch = item.toString();
  }

  shareIndividualCheckedBranch(item: boolean) {
    this.deSelectCheckBox = item;
  }

  getWorkCategoryList() {
    this.commonService
      .getWorkCategoryList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        const workCategoryListObject = res.Data || [];
        this.workCategoryList = workCategoryListObject.map((element) => {
          return {
            checked: false,
            Name: element.Text,
            Id: element.Value,
          };
        });
      });
  }

  getWorkStatusList() {
    this.commonService
      .getStatusList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        const workStatusListObject = res.Data || [];
        this.statusList = workStatusListObject.map((element) => {
          return {
            checked: false,
            Name: element.DisplayText,
            Id: element.Value,
          };
        });
      });
  }

  getFieldAgentList() {
    this.commonService
      .getFieldAgentListByLoggedInUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        if (res && res.Success && res.Message === 'Success' && res.Data) {
          this.fielgAgentList = res.Data;

          let mySelfDD = [{
            Name: ConstantsService.Common_Const.MySelf,
            Id: 'MS',
          }];
          this.fielgAgentList = [...this.fielgAgentList, ...mySelfDD];
          // Object.assign(this.fielgAgentList,[mySelfDD])
        }
      });
  }

  getReportsDetails() {
    this.loaderService.isLoading.next(true);
    this.reportValues = new ReportType();
    let model: IReports = {
      jobIds: this.selectedJobs,
      WorkCategory: this.WorkCategory,
      Branch: this.Branch,
      DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
      DateFrom: this.DateFrom
        ? moment(this.DateFrom).format('L')
        : this.DateFrom,
      Days: this.Days ? this.Days.split(' ')[0] : this.Days,
      fieldAgentIds: this.selectedAgents,
      statusIds: this.selectedStatus,
      searchText: this.searchText,
    };

    this.commonService
      .getReportsDetails(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.isLoading.next(false))
      )
      .subscribe(
        (res: APIResponse) => {
          if (res && res.Data && res.Success) {
            this.reportValues = res.Data;
            this.workOrderDetails = res.Data.WorkOrders;
            this.createBackLogReport();
            this.createWorkOrderOwnersReport();
            this.createWorkOrderCategoryReport();
            this.completedWOrkOrderReport();
          }
        },
        (error) => {
          console.error(error.message);
        },
        () => {
          if (localStorage.getItem('reportDetails') === 'details') {
            localStorage.removeItem('reportParams');
            localStorage.removeItem('reportDetails');
          }
        }
      );
  }

  // Create work order report for owner
  createWorkOrderOwnersReport() {
    if (
      this.reportValues &&
      this.reportValues.WorkOrderOwners &&
      this.reportValues.WorkOrderOwners.length > 0
    ) {
      let colors = ['#FDCC0C', '#c12731', '#231F20', '#5aa453', '#ADADAD'];
      let Xcat = [
        ...new Set(
          this.reportValues.WorkOrderOwners.map((res) => res.UserName)
        ),
      ];
      var o = {};
      var result = this.reportValues.WorkOrderOwners.reduce(function(
          r,
          el,
          currentIndex
        ) {
          var e = el.WorkStatus;
          if (!o[e]) {
            o[e] = {
              name: el.WorkStatus,
              color: colors[currentIndex],
              data: [],
              workIds: el.WorkOrderIds,
            };
            r.push(o[e]);
          }
          o[e].data.push([el.UserName, el.Count]);
          return r;
        },
        []);


      // Create an option to render on charts
      this.OwnerOption = {
        chart: {
          type: 'column',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: Xcat,
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        plotOptions: {
          column: {
            point: {
              events: {
                click: function() {
                }.bind(this),
              },
            },
          },
        },
        legend: {
          layout: 'horizontal',
          verticalAlign: 'top',
          symbolRadius: 0,
        },
        tooltip: {
          //  valueSuffix:" °C"
        },
        series: result,
      };
    }
  }

  // Create work order report for Category
  createWorkOrderCategoryReport() {
    if (
      this.reportValues &&
      this.reportValues.workOrderCategories &&
      this.reportValues.workOrderCategories.length > 0
    ) {
      let colors = generateUniqueColors(this.reportValues.workOrderCategories.length)
      // Mapped the report value into specific values which is needed for reports
      let seriesObj = this.reportValues.workOrderCategories.map(
        (ele, index) => {
          return {
            name: ele.WorkCategoryName,
            count: ele.Count,
            color: colors[index],
            y: ele.Percent,
            percentage: ele.Percent,
            drilldown: ele.WorkCategoryName,
          };
        }
      );

      this.categoryOption = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}% </b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          symbolRadius: 0,
          useHTML: true,
          labelFormatter: function() {
            return (
              '<div style="text-align: left; width:150px;">' +
              this.name +
              '</div><div style="width:40px; text-align:right;">' +
              Math.round(this.percentage * 100) / 100 +
              '% (<b>Count :' +
              this.count +
              '</b>)</div>'
            );
          },
        },
        credits: {
          enabled: false,
        },

        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: 'Work Order Category',
            colorByPoint: true,
            data: seriesObj,
          },
        ],
        drilldown: {
          series: this.reportValues.CategoryDrillDown,
        },
      };
    }
  }

  completedWOrkOrderReport() {
    if (
      this.reportValues &&
      this.reportValues.WorkOrderJob &&
      this.reportValues.WorkOrderJob.length > 0
    ) {
      let colors = ['#FDCC0C', '#c12731', '#231F20', '#5aa453', '#ADADAD'];
      let XJob = [
        ...new Set(
          this.reportValues.WorkOrderJob.map((res) => res.CustomerName)
        ),
      ];
      // Mapped the report value into specific values which is needed for reports
      var o = {};
      var result = this.reportValues.WorkOrderJob.reduce(function(
          r,
          el,
          currentIndex
        ) {
          var e = el.WorkStatus;
          if (!o[e]) {
            o[e] = {
              name: el.WorkStatus,
              color: colors[currentIndex],
              workIds: el.WorkOrderIds,
              data: [],
            };
            r.push(o[e]);
          }
          o[e].data.push([el.CustomerName, el.Count]);
          return r;
        },
        []);

      // Create an option to render on charts
      this.completedOption = {
        chart: {
          type: 'column',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: XJob,
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        plotOptions: {
          column: {
            point: {
              events: {
                click: function() {
                }.bind(this),
              },
            },
          },
        },
        legend: {
          layout: 'horizontal',
          verticalAlign: 'top',
          symbolRadius: 0,
        },
        tooltip: {
          //  valueSuffix:" °C"
        },
        series: result,
      };
    }
  }

  // Create work order report for Backlog work order
  createBackLogReport() {
    // map the object
    if (
      this.reportValues &&
      this.reportValues.BackLogs &&
      this.reportValues.BackLogs.length > 0
    ) {
      let colors = generateUniqueColors(this.reportValues.BackLogs.length)
      let seriesValues = this.reportValues.BackLogs.map((ele, index) => {
        return {
          name: ele.UserName,
          count: ele.Count,
          y: ele.Percentage,
          percentage: ele.Percentage,
          dataLabels: {
            enabled: false,
          },
          color: colors[index],
          workIds: ele.WorkOrderIds,
        };
      });
      this.backLogOption = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          symbolRadius: 0,
          useHTML: true,
          labelFormatter: function() {
            return (
              '<div style="text-align: left; width:150px;">' +
              this.name +
              '</div><div style="width:40px; text-align:right;">' +
              Math.round(this.percentage * 100) / 100 +
              '% (<b>Count :' +
              this.count +
              '</b>)</div>'
            );
          },
        },
        tooltip: { enabled: false },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              cursor: 'pointer',
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white',
              },
            },
            point: {
              events: {
                click: function() {
                }.bind(this),
              },
            },
            showInLegend: true,
            startAngle: 180,
            endAngle: 90,
            center: ['50%', '50%'],
            size: '100%',
          },
        },
        series: [
          {
            type: 'pie',
            name: 'Work Order Backlog',
            innerSize: '70%',
            data: seriesValues,
          },
        ],
      };
    }
  }

  // Filter button to get the all records
  applyFilters() {
    this.applyFilter = true;
    this.getReportsDetails();
  }

  // searchFilter using form control
  searchFilter() {
    this.methodsForAllRoles.searchFilter(this.searchInputText.nativeElement)
      .pipe(takeUntil(this.destroy$))
      .subscribe((text: string) => {
        this.searchText = text;
        this.searchText.length > 0
          ? (this.enableCrossIcon = true)
          : (this.enableCrossIcon = false);
        this.getReportsDetails();
      });
  }

  // Reset search input text
  resetInputSearch() {
    this.searchInputText.nativeElement.value = '';
    this.searchText = '';
    this.enableCrossIcon = false;
    this.getReportsDetails();
  }

  exportWorkOrderReportByKey(key) {
    this.loaderService.isLoading.next(true);
    this.chartName = key;
    let model: IReports = {
      jobIds: this.selectedJobs,
      WorkCategory: this.WorkCategory,
      Branch: this.Branch,
      DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
      DateFrom: this.DateFrom
        ? moment(this.DateFrom).format('L')
        : this.DateFrom,
      Days: this.Days ? this.Days.split(' ')[0] : this.Days,
      fieldAgentIds: this.selectedAgents,
      statusIds: this.selectedStatus,
      searchText: this.searchText,
      downLoad: key,
    };
    if (key === 'detail') {
      this.loaderService.isLoading.next(false);
      const queryParams = {
        jobIds: this.selectedJobs,
        WorkCategory: this.WorkCategory,
        Branch: this.Branch,
        DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
        DateFrom: this.DateFrom
          ? moment(this.DateFrom).format('L')
          : this.DateFrom,
        Days: this.Days ? this.Days.split(' ')[0] : this.Days,
        fieldAgentIds: this.selectedAgents,
        statusIds: this.selectedStatus,
        searchText: this.searchText,
        downLoad: key,
      };

      window.open(
        `${environment.applicationUrl}/reports/detail?jobIds=${
          queryParams.jobIds ? queryParams.jobIds : ''
        }&WorkCategory=${
          queryParams.WorkCategory ? queryParams.WorkCategory : ''
        }&DateTo=${queryParams.DateTo ? queryParams.DateTo : null}&DateFrom=${
          queryParams.DateFrom ? queryParams.DateFrom : null
        }&Days=${queryParams.Days ? queryParams.Days : ''}&fieldAgentIds=${
          queryParams.fieldAgentIds ? queryParams.fieldAgentIds : ''
        }&statusIds=${
          queryParams.statusIds ? queryParams.statusIds : ''
        }&searchText=${
          queryParams.searchText ? queryParams.searchText : ''
        }&downLoad=${queryParams.downLoad ? queryParams.downLoad : ''}`
      );
    } else {
      this.commonService
        .getReportsDetails(model)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.loaderService.isLoading.next(false))
        )
        .subscribe(
          (res: APIResponse) => {
            if (res && res.Data && res.Success) {
              const a = document.createElement('a');
              let url = environment.apiUrl + res.Data.WorkOrders;
              a.href = url;
              a.download = url;
              a.click();
              window.URL.revokeObjectURL(url)
            }
          }
        );
    }
  }

  onChange(event) {
    this.isSetCustomDate = event.target.value === 'Set custom dates';
  }

  onCategoryShowDetail() {
    let model: IReportDetail = this.reportChartParams(this.currentUser.Id, '1');
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reports/Categories`], { queryParams: model })
    );
    window.open('#' + url, '_blank');

  }

  onBacklogShowDetail() {
    let model: IReportDetail = this.reportChartParams(this.currentUser.Id, '2');
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reports/Backlog`], { queryParams: model })
    );
    window.open('#' + url, '_blank');
  }

  onOwnerShowDetail() {
    let model: IReportDetail = this.reportChartParams(this.currentUser.Id, '0');
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reports/Owner`], { queryParams: model })
    );
    window.open('#' + url, '_blank');
  }

  onJobShowDetail() {
    let model: IReportDetail = this.reportChartParams(this.currentUser.Id, '3');
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reports/Job`], { queryParams: model })
    );
    window.open('#' + url, '_blank');
  }

  reportChartParams(userId, reportView): IReportDetail {
    return {
      jobIds: this.selectedJobs,
      WorkCategory: this.WorkCategory,
      Branch: this.Branch,
      DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
      DateFrom: this.DateFrom
        ? moment(this.DateFrom).format('L')
        : this.DateFrom,
      Days: this.Days ? this.Days.split(' ')[0] : this.Days,
      fieldAgentIds: this.selectedAgents,
      statusIds: this.selectedStatus,
      searchText: this.searchText,
      userId,
      reportView,
    };
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
