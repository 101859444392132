<div>
  <div class="upload-comp">
    <div class="wrp-boxs">

      <button type="submit" class="btn btn-secondary z-index">
        {{ 'BtnText.UploadMore' | translate }}
        <input
          type="file"
          class="file-style"
          name="file-upload"
          (change)="onFileChange($event)"
          [accept]="accept"
        />
      </button>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>{{ message }}</p>
    <div class="modal-footer">
      <button type="button" class="btn btn-oclm" (click)="confirm()">Ok</button>
    </div>
  </div>
</ng-template>
