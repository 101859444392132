<div class="file-block">
  <div class="file">
    <form [formGroup]="uploadForm">
      <input (change)="onFileChange($event.target.files)" #fileInput type="file" name="file"
             [multiple]="false"
             required>
      <span (click)="fileInput.click()"
         (dragenter)="hoverWithFile = true;"
         (dragleave)="hoverWithFile = false;"
         (dragover)="allowDrop($event)"
         (drop)="drop($event)"
         [ngClass]="{'opacity50': hoverWithFile, 'opacity100': !hoverWithFile}"
      >
        <ng-container *ngIf="!fileName">Parcourir</ng-container>
        <ng-container *ngIf="fileName">
          <span>{{ fileName }}</span>
        </ng-container>
      </span>
    </form>
  </div>
</div>
<span class="replace-file-font" (click)="clearFile()">Remplacer l’image</span>
