import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { APIResponse } from 'src/app/shared/models/api-response';
import { NotificationList } from 'src/app/shared/models/notification-list';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cm-header',
  templateUrl: './cm-header.component.html',
  styleUrls: ['./cm-header.component.css'],
})
export class CmHeaderComponent implements OnInit {
  currentUser: User;
  message;
  notificationList: NotificationList[] = [];
  notificationLength;
  photoURL: string;
  activeToggle = true;
  showBackBtn: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private msgService: MessagingService,
    private commonService: CommonService,
    private location: Location
  ) {
    this.authenticationService.currentUser.subscribe((value) => {
      this.currentUser = value;
      if (this.currentUser) {
        this.photoURL = environment.apiUrl + this.currentUser.DocPath;
        this.getNotificationByUserId();
      }
    });
  }

  get HeaderTitle(): any {
    return localStorage.getItem(environment.headerTitle);
  }

  ngOnInit(): void {
    // Check push notification for user
    this.msgService.receiveMessage();
    this.msgService.currentMessage.subscribe((updateMsg) => {
      if (updateMsg) {
        this.getNotificationByUserId();
      }
    });

    // Get the value to display the btn for work order
    this.commonService.displayBackBtnObservables.subscribe((res) => {
      this.showBackBtn = res;
    });
  }

  // Get Notification by current logged in user
  getNotificationByUserId() {
    this.commonService
      .getNotificationByUserId()
      .subscribe((res: APIResponse) => {
        if (res && res.Success && res.Data) {
          this.notificationList = res.Data.Result;
          this.unreadNotificationList(this.notificationList);
        }
      });
  }

  /**
   * Update notification to make it readable
   * @param Id - Notification ID
   */
  updateNotification(Id, index) {
    let updateNotifyObject = {
      NotificationId: Id,
      Read: true,
    };
    this.commonService
      .updateNotificationById(updateNotifyObject)
      .subscribe((res: APIResponse) => {
        if (res && res.Success && res.Data) {
          this.notificationList.filter((x) => {
            if (x.NotificationId === Id) {
              return (x.Read = true);
            }
          });
          this.unreadNotificationList(this.notificationList);
          // redirect logic here
          let redirectURL = this.notificationList[index];
          this.commonService.navigateByURL(redirectURL.Title);
        }
      });
  }

  /**
   * Get the unread notification list
   * @param notifyListObject: Actual notification list
   */
  unreadNotificationList(notifyListObject) {
    if (notifyListObject) {
      let notifiedList = [];
      notifyListObject.filter((x) => {
        if (x.Read === false) {
          return notifiedList.push(x);
        }
      });
      this.notificationLength = notifiedList ? notifiedList.length : 0;
    }
  }

  // Set subject to show/hide the sidebar for mobile device

  backToPrevious() {
    this.commonService.setToDisplayBackBtn(false);
    this.location.back();
  }

  logout() {
    this.commonService.setToDisplayBackBtn(false);
    this.authenticationService.logout();
    // this.authenticationService.setCurrentLoginUser();
    this.commonService.navigateByURL('');
  }

  navigateToProfile() {
    this.commonService.setToDisplayBackBtn(true);
    localStorage.setItem(environment.headerTitle, 'Profile');
    this.commonService.navigateByURL('/profile');
  }
}
