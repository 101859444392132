import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { CommonService } from 'src/app/shared/services/common.service';
import { ChartData, IReports, ReportType } from 'src/app/shared/models/reports';
import { JobList } from 'src/app/shared/models/job-list';
import { APIResponse, Status } from 'src/app/shared/models/api-response';
import { FieldAgentUser } from 'src/app/shared/models/field-agent-user';
import { LoaderService } from '../../helpers/services/loader.service';
import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { ActivatedRoute } from '@angular/router';
import { ReportDetailsTableComponent } from '../report-details-table/report-details-table.component';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { finalize, takeUntil } from 'rxjs/operators';
import { WorkCategoryList, WorkOrderForm } from '../../../shared/models/work-order';
import { MethodsForAllRoles } from '../../../shared/services/methods-for-all-roles.service';
import { environment } from '../../../../environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import * as moment from 'moment/moment';
import { JobService } from '../../../modules/jobs/services/customer.service';
import { ICustomLabelData} from '../../../shared/models/custom-label.interface';
import { generateUniqueColors } from '../../../shared/utils/utils';

Drilldown(Highcharts);

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css']
})
export class ReportDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts;
  reportValues: ReportType = new ReportType();
  destroy$: Subject<boolean> = new Subject<boolean>();
  ownerOption: any;
  categoryOption: any;
  statusByCategoryOption: any;
  backLogOption: any;
  completedOption: any;
  deSelectCheckBox;
  chartName = 'Categories';

  @ViewChild(ReportDetailsTableComponent)
  modalInfo: ReportDetailsTableComponent;
  @ViewChild('printContent', { static: false }) printContent: ElementRef;

  enableCrossIcon: boolean = false;
  searchText = '';
  modelDetails: WorkOrderForm[];
  constantDetails: WorkOrderForm[];
  selectedModelDetails: string[] = [];
  jobListDetails: JobList[];
  selectedJobs;
  workCategoryList: any[] = [];
  workCategoryListForPrint: any[] = [];
  cannedResponseForPrint: any[] = [];
  statusIdsForPrint: any[] = [];
  fielgAgentList: FieldAgentUser[] = [];
  WorkCategory = null;
  isCategory = true;
  Branch = null;
  DateFrom;
  DateTo;
  Days = null;
  currentUser: User;
  workOrderDetails: WorkOrderForm[] = [];
  reportView: string;
  downLoad: string;
  fieldAgentIds: string;
  jobIds: string;
  statusIds: string;
  statusData: any;
  categoriesNames: string;
  statusCategoriesNames: {};
  statusList: Status[];
  cannedResponseList: any[] = [];
  cannedResponseIds: string = '';
  isSetCustomDate = false;
  bsConfig: Partial<BsDatepickerConfig>;
  categoriesCharData: any[] = [];
  categoryIds: string[] = [];
  printedDate: Date = new Date();
  WorkOrderCustomerLabels: any[] = [];
  customerLabelsOptions: any[];
  customerLabelFilterData: ICustomLabelData[] = []
  selectedCustomLabels = new Map<number, number>();

  constructor(
    private commonService: CommonService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private elementRef: ElementRef,
    private methodsForAllRoles: MethodsForAllRoles,
    private jobService: JobService
  ) {
    this.title.setTitle('OCLM - Report detail');
    this.authenticationService.currentUser.subscribe((value) => {
      this.currentUser = value;
    });
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-red',
        dateInputFormat: 'MM-DD-YYYY',
        displayOneMonthRange: true
      }
    );
  }

  get isCommunityManager() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.CommunityManager
    );
  }

  ngOnInit() {
    combineLatest([
      this.activatedRoute.paramMap,
      this.activatedRoute.queryParamMap
    ]).subscribe(([pathParams, queryParams]) => {
      this.title.setTitle(`Report:  Work Orders by ${this.chartName} ${ this.DateFrom ?  '| Date Range:' + this.DateFrom +'-' +this.DateTo: ''}`);
      this.chartName = pathParams.get('chartType');
      this.reportView = queryParams.get('reportView');
      this.Branch = queryParams.get('Branch');
      this.WorkCategory = queryParams.get('WorkCategory');
      this.downLoad = queryParams.get('downLoad');
      this.fieldAgentIds = queryParams.get('fieldAgentIds');
      this.jobIds = queryParams.get('jobIds');
      this.searchText = queryParams.get('searchText');
      this.statusIds = queryParams.get('statusIds');
      this.DateFrom = queryParams.get('DateFrom');
      this.DateTo = queryParams.get('DateTo');
      this.Days = queryParams.get('Days');

      if (this.chartName === 'detail') {
        this.GetWorkOrderDetailReports();
      } else {
      }
    });
    this.getCannedResponseList();
    this.methodsForAllRoles.setHeaderTitleInSession(`Reports ${this.chartName}`, 'Informes');
    this.getJobListBySupervisorId();
    this.getWorkCategoryList();
    this.getWorkStatusList();
    this.getCustomerLabels();
    this.setDateFromUrl();
  }

  ngAfterViewInit(): void {
    this.getReportsDetails();
  }

  private setDateFromUrl() {
    if (this.Days !== 'Set' && Boolean(this.Days) !== false) {
      this.Days += ` Days`
    }

    if (this.Days === 'Set') {
      this.Days = 'Set custom dates'
      this.isSetCustomDate = true
    }
  }

  private getCustomerLabels(): void {
    const jobsIds = Boolean(this.jobIds) ? this.jobIds.split(',') : []
    if (jobsIds.length === 1) {
      const customerId = +jobsIds[0];
      this.jobService.getCustomerLabels(customerId).pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.customerLabelFilterData = data;
      })
    }
  }

  getCannedResponseList(): void {
    this.commonService.getCannedResponseListForReports(this.workCategoryList)
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data.map((element) => {
          return {
            checked: false,
            Name: element.Text,
            Id: element.Id
          };
        });
      });
  }

  GetWorkOrderDetailReports() {
    this.loaderService.isLoading.next(true);
    this.commonService
      .getWorkOrderReportDetail({
        reportView: this.reportView,
        Branch: this.Branch,
        WorkCategory: this.WorkCategory,
        downLoad: this.downLoad,
        fieldAgentIds: this.fieldAgentIds,
        jobIds: this.jobIds,
        searchText: this.searchText,
        statusIds: this.statusIds,
        DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
        DateFrom: this.DateFrom
          ? moment(this.DateFrom).format('L')
          : this.DateFrom,
        Days: this.Days ? this.Days.split(' ')[0] : this.Days
      })
      .pipe(takeUntil(this.destroy$), finalize(() => this.loaderService.isLoading.next(false)))
      .subscribe((res: APIResponse) => {
        this.modelDetails = res.Data;
      });
  }

  goBack() {
    window.close();
  }

  getJobListBySupervisorId() {
    this.methodsForAllRoles.getJobListBySupervisorId(true)
      .subscribe((res: JobList[]) => {
        this.jobListDetails = res;
      });
  }

  getWorkCategoryList() {
    const workCategoryFromUrl = Boolean(this.WorkCategory) ? this.WorkCategory.split(',') : [];
    this.commonService
      .getWorkCategoryList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        const workCategoryListObject = res.Data || [];
        this.categoryIds;
        this.workCategoryList = workCategoryListObject.map((element) => {
          this.categoryIds.push(element.Value);
          return {
            checked: workCategoryFromUrl.includes(element.Value),
            Name: element.Text,
            Id: element.Value
          };
        });
        this.statusCategoriesNames = this.convertArrayIntoObject(
          this.workCategoryList
        );
      });
  }

  private initAndPrintCategoryReport(): void {
    this.categoriesCharData = [];
    const categorizedTasks = this.categorizeWOs(this.modelDetails);
    this.printedDate = new Date();
    for (let categorizedTasksKey in categorizedTasks) {
      this.categoriesCharData.push(this.createWorkOrderStatusByStatusId(categorizedTasks[categorizedTasksKey]));
    }

    if (this.isSafari()) {
      const newWindow = window.open('', 'PRINT', `height=${window.screen.height},width=${window.screen.width}`);
      newWindow.document.head.innerHTML = document.head.innerHTML;
      newWindow.document.body.innerHTML = this.printContent.nativeElement.innerHTML;
      newWindow.print();
      newWindow.onafterprint = () => {
        newWindow.close();
      };
    } else {
      window.print();
    }
  }

  convertArrayIntoObject(workCategoryList: WorkCategoryList[]) {
    var result = {};
    for (var i = 0; i < workCategoryList.length; i++) {
      result[workCategoryList[i].Id] = workCategoryList[i].Name;
    }
    return result;
  }

  shareCheckedListCat(item: string[]) {
    this.WorkCategory = null;
    if (item.length > 0) {
      this.categoriesNames = this.getStatusName(item);
      this.WorkCategory = item.toString();

      const idSet = new Set(this.WorkCategory);
      this.workCategoryListForPrint = this.workCategoryList.filter(item => idSet.has(item.Id));
      this.isCategory = false;
    } else {
      this.isCategory = true;
      this.WorkCategory = '';
      this.loaderService.isLoading.next(false);
    }
  }

  getStatusName(statusIds) {
    let names = [];
    statusIds.forEach((item) => {
      names.push(this.statusCategoriesNames[item]);
    });
    return names.join(',');
  }

  shareIndividualCheckedCat(item) {
    this.deSelectCheckBox = item;
  }

  getReportsDetails() { // here WorkOrderReport cannedResponse = [1,2]
    const labels = Array.from(this.selectedCustomLabels).map(subArray => `[${subArray.join(',')}]`).join(';');
    this.loaderService.isLoading.next(true);
    this.reportValues = new ReportType();
    const model: IReports = {
      Branch: this.Branch,
      WorkCategory: this.WorkCategory,
      downLoad: this.downLoad,
      fieldAgentIds: this.fieldAgentIds,
      jobIds: this.jobIds,
      label: labels,
      searchText: this.searchText,
      statusIds: this.statusIds,
      DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
      DateFrom: this.DateFrom
        ? moment(this.DateFrom).format('L')
        : this.DateFrom,
      Days: this.Days ? this.Days.split(' ')[0] : this.Days,
      cannedResponse: this.cannedResponseIds ? this.cannedResponseIds : ''
    };
    this.commonService
      .getReportsDetails(model)
      .pipe(takeUntil(this.destroy$),
        finalize(() => this.loaderService.isLoading.next(false))
      )
      .subscribe((res: APIResponse) => {
        if (res && res.Data && res.Success) {
          this.reportValues = res.Data;
          this.modelDetails = res.Data.WorkOrders;
          this.constantDetails = res.Data.WorkOrders;
          this.WorkOrderCustomerLabels = res.Data.WorkOrderCustomerLabels;
          this.createBackLogReport();
          this.createWorkOrderOwnersReport();
          this.createWorkOrderCategoryReport();
          this.completedWOrkOrderReport();
          this.createWorkOrderCustomerLabelsReport();
        }
      });
  }

  // createWorkOrderCustomerLabelsReport() {
  //   if (
  //     this.reportValues &&
  //     this.reportValues.WorkOrderCustomerLabels &&
  //     this.reportValues.WorkOrderCustomerLabels.length > 0
  //   ) {
  //     console.log(this.reportValues.WorkOrderCustomerLabels);
  //     let colors = ['#FDCC0C', '#c12731', '#231F20', '#5aa453', '#ADADAD'];
  //
  //     // Collect all unique label names
  //     let Xcat = [
  //       ...new Set(
  //         this.reportValues.WorkOrderCustomerLabels.map((res) => res.LabelName)
  //       )
  //     ];
  //
  //     let series = [];
  //
  //     // Iterate over each label group
  //     this.reportValues.WorkOrderCustomerLabels.forEach((labelGroup, labelIndex) => {
  //       labelGroup.LabelValues.forEach((labelValue, valueIndex) => {
  //         let seriesIndex = series.findIndex(s => s.name === labelValue.Name);
  //         if (seriesIndex === -1) {
  //           series.push({
  //             name: labelValue.Name,
  //             color: colors[valueIndex % colors.length],
  //             data: Xcat.map(cat => 0) // Initialize data array with zeros for each category
  //           });
  //           seriesIndex = series.length - 1;
  //         }
  //         let dataIndex = Xcat.indexOf(labelGroup.LabelName);
  //         if (dataIndex !== -1) {
  //           series[seriesIndex].data[dataIndex] = labelValue.Count;
  //         }
  //       });
  //     });
  //
  //     // Create an option to render on charts
  //     this.customerLabelsOption = {
  //       chart: {
  //         type: 'column',
  //         plotBackgroundColor: null,
  //         plotBorderWidth: null,
  //         plotShadow: false
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //       title: {
  //         text: null
  //       },
  //       xAxis: {
  //         categories: Xcat
  //       },
  //       yAxis: {
  //         title: {
  //           text: null
  //         }
  //       },
  //       plotOptions: {
  //         column: {
  //           pointPadding: 0.2,
  //           borderWidth: 0,
  //           point: {
  //             events: {
  //               click: function() {
  //                 this.chartName = 'CustomerLabel';
  //               }.bind(this)
  //             }
  //           }
  //         }
  //       },
  //       legend: {
  //         align: 'right',
  //         verticalAlign: 'middle',
  //         layout: 'vertical',
  //         symbolRadius: 0,
  //         useHTML: true,
  //         labelFormatter: function() {
  //           let total = this.options.data.reduce((sum, d) => sum + d, 0);
  //           return (
  //             '<div style="text-align: left; width:150px;">' +
  //             this.name +
  //             '</div><div style="width:40px; text-align:right;">' +
  //             total +
  //             ' (<b>Count: ' +
  //             total +
  //             '</b>)</div>'
  //           );
  //         }
  //       },
  //       tooltip: {
  //         formatter: function() {
  //           return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}`;
  //         }
  //       },
  //       series: series
  //     };
  //   }
  // }
  createWorkOrderCustomerLabelsReport() {
    if (
      this.reportValues &&
      this.reportValues.WorkOrderCustomerLabels &&
      this.reportValues.WorkOrderCustomerLabels.length > 0
    ) {
      this.customerLabelsOptions = this.reportValues.WorkOrderCustomerLabels.map((labelGroup, groupIndex) => {
        const colors = generateUniqueColors(labelGroup.LabelValues.length)
        let seriesData = labelGroup.LabelValues.map((labelValue, valueIndex) => ({
          name: labelValue.Name,
          y: labelValue.Count,
          color: colors[valueIndex]
        }));

        let totalCount = seriesData.reduce((sum, data) => sum + data.y, 0);

        return {
          chart: {
            type: 'pie',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
          },
          credits: {
            enabled: false
          },
          title: {
            text: labelGroup.LabelName
          },
          tooltip: {
            pointFormat: '<b>{point.name}</b>: {point.percentage:.1f}% ({point.y})'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            symbolRadius: 0,
            useHTML: true,
            labelFormatter: function () {
              let percentage = (this.y / totalCount) * 100;
              return (
                '<div style="text-align: left; width:150px;">' +
                this.name +
                '</div><div style="width:40px; text-align:right;">' +
                this.y +
                ' (<b>' +
                percentage.toFixed(1) +
                '%</b>)</div>'
              );
            }
          },
          series: [{
            name: 'Customer Labels',
            colorByPoint: true,
            data: seriesData
          }]
        };
      });
    }
  }

  createWorkOrderOwnersReport() {
    if (
      this.reportValues &&
      this.reportValues.WorkOrderOwners &&
      this.reportValues.WorkOrderOwners.length > 0
    ) {
      let colors = ['#FDCC0C', '#c12731', '#231F20', '#5aa453', '#ADADAD'];
      let Xcat = [
        ...new Set(
          this.reportValues.WorkOrderOwners.map((res) => res.UserName)
        )
      ];
      var o = {};
      var result = this.reportValues.WorkOrderOwners.reduce(function(
          r,
          el,
          currentIndex
        ) {
          var e = el.WorkStatus;
          if (!o[e]) {
            o[e] = {
              name: el.WorkStatus,
              color: colors[currentIndex],
              data: [],
              workIds: el.WorkOrderIds
            };
            r.push(o[e]);
          }
          o[e].data.push([el.UserName, el.Count]);
          return r;
        },
        []);
      // Create an option to render on charts
      this.ownerOption = {
        chart: {
          type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          categories: Xcat
        },
        yAxis: {
          title: {
            text: null
          }
        },
        plotOptions: {
          column: {
            point: {
              events: {
                click: function() {
                  this.chartName = 'Owner';
                }.bind(this)
              }
            }
          }
        },
        legend: {
          layout: 'horizontal',
          verticalAlign: 'top',
          symbolRadius: 0
        },
        tooltip: {
          //  valueSuffix:" °C"
        },
        series: result
      };
    }
  }

  createWorkOrderCategoryReport() {
    let colors = generateUniqueColors(this.reportValues.workOrderCategories.length)
    if (
      this.reportValues &&
      this.reportValues.workOrderCategories &&
      this.reportValues.workOrderCategories.length > 0
    ) {
      // Mapped the report value into specific values which is needed for reports
      let seriesObj = this.reportValues.workOrderCategories.map(
        (item, index) => {
          return {
            name: item.WorkCategoryName,
            count: item.Count,
            color: colors[index],
            y: item.Percent,
            percentage: item.Percent,
            drilldown: item.WorkCategoryName,
          };
        }
      );
      this.categoryOption = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        }
        ,
        title: {
          text: 'Categories'
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          symbolRadius: 0,
          useHTML: true,
          labelFormatter: function() {
            return (
              '<div style="text-align: left; width:150px;">' +
              this.name +
              '</div><div style="width:40px; text-align:right;">' +
              Math.round(this.percentage * 100) / 100 +
              '% (<b>Count :' +
              this.count +
              '</b>)</div>'
            );
          },
        },
        credits: {
          enabled: true
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true,
          }
        },
        series: [
          {
            name: 'Work Order Category',
            colorByPoint: true,
            data: seriesObj,
          }
        ]
      };
    }
  }

  createWorkOrderStatusByCategoryId(): void {
    if (
      this.statusData &&
      this.statusData.workOrderCategories &&
      this.statusData.workOrderCategories.length > 0
    ) {
      let seriesObj: ChartData = this.statusData.workOrderCategories.map(
        (ele, index) => {
          return {
            name: ele.WorkCategoryName,
            count: ele.Count,
            y: ele.Percent,
            // color: colors[index]
          };
        });

      this.statusByCategoryOption = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: seriesObj[0].name
        },
        tooltip: {
          pointFormat: '<b>{point.count}</b>'
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          symbolRadius: 0,
          useHTML: true,
          labelFormatter: function() {
            return (
              '<div style="text-align: left; width:150px;">' +
              this.name +
              `: ` +
              this.count +
              '</div>'
            );
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          column: {
            point: {
              events: {
                click: function() {
                  this.chartName = 'Category';
                }.bind(this)
              }
            }
          }
        },
        series: [
          {
            name: 'Work Order Status Category',
            colorByPoint: true,
            data: seriesObj
          }
        ]
      };
    }
  }

  createWorkOrderStatusByStatusId(statusData: any): any {
    if (statusData) {
      const workOrdersByStatuses = this.getWOByStatusesDataForCharts(statusData);
      let colors = generateUniqueColors(workOrdersByStatuses?.length || 0)
      let seriesObj: ChartData = workOrdersByStatuses.map(
        (ele, index) => {
          return {
            name: ele.WorkStatusText,
            WorkCategory: ele.WorkCategory,
            count: ele.Count,
            y: ele.Percent,
            percentage: ele.Percent,
            color: colors[index],
            drilldown: ele.WorkStatusText

          };
        });
      this.statusByCategoryOption = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: seriesObj[0].WorkCategory
        },
        tooltip: {
          pointFormat: '<b>{point.count}</b>'
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          symbolRadius: 0,
          useHTML: true,
          labelFormatter: function() {
            return (
              '<div style="text-align: left; width:150px;">' +
              this.name +
              '</div><div style="width:40px; text-align:right;">' +
              Math.round(this.percentage * 100) / 100 +
              '% (<b>Count :' +
              this.count +
              '</b>)</div>'
            );
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: 'Work Order Status Category',
            colorByPoint: true,
            data: seriesObj
          }
        ]
      };
      return this.statusByCategoryOption;
    } else {
      return null;
    }
  }

  completedWOrkOrderReport() {
    if (
      this.reportValues &&
      this.reportValues.WorkOrderJob &&
      this.reportValues.WorkOrderJob.length > 0
    ) {
      let colors = ['#FDCC0C', '#c12731', '#231F20', '#5aa453', '#ADADAD'];
      let XJob = [
        ...new Set(
          this.reportValues.WorkOrderJob.map((res) => res.CustomerName)
        )
      ];
      // Mapped the report value into specific values which is needed for reports
      var o = {};

      var result = this.reportValues.WorkOrderJob.reduce(function(
          r,
          el,
          currentIndex
        ) {
          var e = el.WorkStatus;
          if (!o[e]) {
            o[e] = {
              name: el.WorkStatus,
              color: colors[currentIndex],
              workIds: el.WorkOrderIds,
              data: []
            };
            r.push(o[e]);
          }
          o[e].data.push([el.CustomerName, el.Count]);
          return r;
        },
        []);
      // Create an option to render on charts
      this.completedOption = {
        chart: {
          type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          categories: XJob
        },
        yAxis: {
          title: {
            text: null
          }
        },
        plotOptions: {
          column: {
            point: {
              events: {
                click: function() {
                  this.chartName = 'Job';
                }.bind(this)
              }
            }
          }
        },
        legend: {
          layout: 'horizontal',
          verticalAlign: 'top',
          symbolRadius: 0
        },
        series: result
      };
    }
  }

  createBackLogReport() {
    let colors = generateUniqueColors(this.reportValues.BackLogs.length)
    // map the object
    if (
      this.reportValues &&
      this.reportValues.BackLogs &&
      this.reportValues.BackLogs.length > 0
    ) {
      let seriesValues = this.reportValues.BackLogs.map((ele, index) => {
        return {
          name: ele.UserName,
          count: ele.Count,
          y: ele.Percentage,
          percentage: ele.Percentage,
          dataLabels: {
            enabled: false
          },
          color: colors[index],
          workIds: ele.WorkOrderIds
        };
      });
      this.backLogOption = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          symbolRadius: 0,
          useHTML: true,
          labelFormatter: function() {
            return (
              '<div style="text-align: left; width:150px;">' +
              this.name +
              '</div><div style="width:40px; text-align:right;">' +
              Math.round(this.percentage * 100) / 100 +
              '% (<b>Count :' +
              this.count +
              '</b>)</div>'
            );
          }
        },
        tooltip: { enabled: false },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              cursor: 'pointer',
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            point: {
              events: {
                click: function() {
                  this.chartName = 'Backlog';
                }.bind(this)
              }
            },
            showInLegend: true,
            startAngle: 180,
            endAngle: 90,
            center: ['50%', '50%'],
            size: '100%'
          }
        },
        series: [
          {
            type: 'pie',
            name: 'Work Order Backlog',
            innerSize: '70%',
            data: seriesValues
          }
        ]
      };
    }
  }

  onPrint() {
    this.initAndPrintCategoryReport();
    // window.print();
  }

  getWorkStatusList() {
    const statusIdsFromUrl = Boolean(this.statusIds) ? this.statusIds.split(',') : [];
    this.commonService
      .getStatusList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        const workStatusListObject = res.Data || [];
        this.statusList = workStatusListObject.map((element) => {
          return {
            checked: statusIdsFromUrl.includes(element.Value),
            Name: element.DisplayText,
            Id: element.Value
          };
        });
      });
  }

  shareCheckedListStatus(event: any, days = false) {
    if (days) {
      this.isSetCustomDate = event.target.value === 'Set custom dates';
      this.isSetCustomDate ? this.Days = '' : this.Days = event.target.value;
    } else {
      this.statusIds = event.toString();
    }
    if (event.length >= 0 && !this.isSetCustomDate) {
    } else {
      this.isCategory = true;
    }
  }

  shareCheckedListStatusCanned(item: any) {
    this.cannedResponseIds = item;
    this.cannedResponseForPrint = this.cannedResponseList
      .filter(item => this.cannedResponseIds.includes(item['Id']));
  }

  shareCheckedListCustomerLabels(labelId: number, data: Event) {
    const value = (data.target as HTMLInputElement).value;
    if (!value) {
      this.selectedCustomLabels.delete(labelId)
    } else {
      this.selectedCustomLabels.set(labelId, +value);
    }
  }

  shareCheckedListStatusOwner(item: string[]) {
    if (item.length >= 0) {
      this.statusIds = item.toString();
      const idSet = new Set(this.statusIds);
      this.statusIdsForPrint = this.statusList.filter(item => idSet.has(item['Id']));
    } else {
      this.isCategory = true;
      this.WorkCategory = '';
    }
  }

  shareStatusCheckedCat(item) {
    this.deSelectCheckBox = item;
  }

  onPointSelect(chart) {
    this.statusData
      ? this.commonService.setFilterTextChartByStatusMethod(chart.point?.name)
      : this.commonService.setFilterTextChartMethod(chart.point?.name);
  }

  downloadCSV() {
    const jobIds = this.modelDetails.map((item: any) => item.JobId);
    const labels = Array.from(this.selectedCustomLabels).map(subArray => `[${subArray.join(',')}]`).join(';');
    let model = {
      searchText: this.searchText,
      workCategoryIds: this.WorkCategory,
      jobIds: this.jobIds,
      label: labels,
      statusIds: this.statusIds,
      DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
      DateFrom: this.DateFrom
        ? moment(this.DateFrom).format('L')
        : this.DateFrom,
      cannedResponseIds: this.cannedResponseIds
    };
    this.loaderService.isLoading.next(true);
    this.commonService
      .exportWorkOrdersCSV(model)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (res: APIResponse) => {
          if (res && res.Data && res.Success) {
            const a = document.createElement('a');
            let url = environment.apiUrl + res.Data;
            a.href = url;
            a.download = url;
            a.click();
            window.URL.revokeObjectURL(url);
            this.loaderService.isLoading.next(false);
          }
        }, () => this.loaderService.isLoading.next(false)
      );
  }

  downloadPDF(): void {
    const currentUser = this.authenticationService.currentLoginUserValue;
    const labels = Array.from(this.selectedCustomLabels).map(subArray => `[${subArray.join(',')}]`).join(';');
    let model = {
      searchText: this.searchText,
      workCategoryIds: this.WorkCategory,
      jobIds: this.jobIds,
      label: labels,
      statusIds: this.statusIds,
      DateTo: this.DateTo ? moment(this.DateTo).format('L') : this.DateTo,
      DateFrom: this.DateFrom
        ? moment(this.DateFrom).format('L')
        : this.DateFrom,
      cannedResponseIds: this.cannedResponseIds
    };
    this.loaderService.isLoading.next(true);
    this.commonService
      .exportWorkOrdersPDF(model)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (res: APIResponse) => {
          if (res && res.Data && res.Success) {
            const a = document.createElement('a');
            let url = environment.apiUrl + res.Data
            a.href = url;
            a.target = '_blank';
            a.download = url;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.loaderService.isLoading.next(false);
          }
        }, () => this.loaderService.isLoading.next(false)
      );
  }

  applyDates() {
    // this.getReportsDetails();
    // this.GetWorkOrderDetailReports();
  }

  // Todo ask backend to send such info
  private getWOByStatusesDataForCharts(wo: any): any {
    const totalCount = wo.length;
    const workStatusesMap = new Map();

    // Count occurrences of each WorkStatusText and WorkCategory
    wo.forEach(task => {
      const status = task.WorkStatusText;
      const category = task.WorkCategory;
      const key: string = `${status}-${category}`;
      const count = workStatusesMap.get(key) || 0;
      workStatusesMap.set(key, count + 1);
    });

    // Convert the map to an array of objects with Count, Percent, WorkStatusText, and WorkCategory
    return Array.from(workStatusesMap).map(([key, count]) => {
      const [status, category] = key.split('-');
      return {
        WorkStatusText: status,
        WorkCategory: category,
        Count: count,
        Percent: Math.round((count / totalCount) * 100)
      };
    });
  }

  private categorizeWOs(modelDetails:any): any {
   return  modelDetails.reduce((acc, task) => {
      const category = task.WorkCategory || 'Uncategorized';

      if (!acc[category]) {
        acc[category] = [];
      }

      acc[category].push(task);
      return acc;
    }, {})
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private isSafari(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return /safari/.test(userAgent) && !/chrome/.test(userAgent) && !/android/.test(userAgent);
  }
}
