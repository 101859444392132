import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  PipeTransform,
  Pipe,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(value: any[]): any[] {
    value.sort(function (a, b) {
      let alc = a.checked;
      let blc = b.checked;
      return alc < blc ? 1 : alc > blc ? 0 : 1;
    });
    return value;
  }
}

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css'],
})
export class MultiSelectDropdownComponent implements OnChanges {
  private _list = [];
  @Input() set list(value: {checked:  boolean, Name: string, Id: string}[]) {
    queueMicrotask(() => {
      if (value?.length) {
        this._list = value;
        this.multiSelectData = this._list.sort(this.compare);
      }
    })
  }
  @Input() deSelectCheckBox;

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  @Input() labelText?: string = this.translate.instant('CommonLabel.SelectJob');

  checkedList: any[];
  currentSelected: {};
  showDropDown;
  selectName = [];
  multiSelectData = [];
  lengthSelectedName: number;
  constructor(private translate: TranslateService) {
    this.checkedList = [];
    this.selectName = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.deSelectCheckBox) {
      this.checkedList = [];
      this.selectName = [];
      this.currentSelected = {};
    }
    if (this._list) {
      this.multiSelectData = this._list.sort(this.compare);
    }
  }

  compare(a: { checked: any }, b: { checked: any }) {
    const _a = a.checked;
    const _b = b.checked;

    let comparison = 0;
    if (_a > _b) {
      comparison = -1;
    } else if (_a < _b) {
      comparison = 1;
    }
    return comparison;
  }

  getSelectedValue(status: Boolean, value: String, Name: String) {
    if (status) {
      this.lengthSelectedName = this.checkedList.length + 1;
      this.checkedList.push(value);
      this.selectName.push(Name);
      this.deSelectCheckBox = true;
    } else {
      var index = this.checkedList.indexOf(value);
      const selectedNameIndex = this.selectName.indexOf(Name)
      if (index >= 0) {
        this.checkedList.splice(index, 1);
      }
      if (selectedNameIndex >= 0) {
        this.selectName.splice(selectedNameIndex, 1);
      }
      this.lengthSelectedName = this.checkedList.length + 1 - 1;
    }

    this.currentSelected = { checked: status, Id: value };
    if (this._list) {
      this.multiSelectData = this._list.sort(this.compare);
    }
    this.shareCheckedlist();
    this.shareIndividualStatus();
  }
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.deSelectCheckBox);
  }
}
