<!-- Render Header -->

<div class="wrapper" [ngClass]="{'not-clickable': notAccepted}">
  <app-sidebar></app-sidebar>
  <!-- Render Sidebar version-1 -->
  <div
    id="content"
    [ngClass]="{ 'margin-left': isUserActive && !hideActiveClass }">
    <ng-container *ngIf="isUserActive || isCommunityManager">
      <app-header></app-header>
    </ng-container>
    <div class="container-fluid">
      <router-outlet></router-outlet>
      <app-global-loader></app-global-loader>
    </div>
  </div>
</div>
<app-agreement-popup *ngIf="notAccepted" (acceptAgreement)="acceptAgreement()">asd</app-agreement-popup>
