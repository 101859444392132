<div>
  <input
    type="text"
    style="display: none"
    class="form-control"
    (keydown.enter)="setLocationName($event)"
    (focusout)="setLocationName($event)"
    placeholder="{{ 'CommonLabel.SearchNearestLocation' | translate }}"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    type="text"
    #search
  />
</div>
<agm-map
  [style.height]="agmHeight"
  [latitude]="latitude"
  [longitude]="longitude"
  [zoom]="zoom"
  [fullscreenControl]="true"
  [mapTypeControl]="true"
  (mapClick)="mapClicked($event)">
  <agm-marker
    [latitude]="latitude"
    [longitude]="longitude"
    [markerDraggable]="markerDraggable"
    (dragEnd)="markerDragEnd($event)">
  </agm-marker>
</agm-map>
