import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentLoginUserValue;
    const isLoggedIn = currentUser && currentUser.AccessToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (
      isLoggedIn &&
      isApiUrl &&
      (currentUser.Role === ConstantsService.User_Role.SuperVisor ||
        currentUser.Role === ConstantsService.User_Role.FieldAgent ||
        currentUser.Role === ConstantsService.User_Role.CommunityManager ||
        currentUser.Role === ConstantsService.User_Role.Admin)
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.AccessToken}`,
        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            localStorage.removeItem('currentUser');
            this.authenticationService.setCurrentLoginUser();
            this.commonService.navigateByURL('/login');
          }
        }
      )
    );
  }
}
