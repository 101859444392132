import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';

import { WorkOrderListByStatus } from '../../../shared/models/api-response';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/shared/models/user';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FieldAgentService {
  currentUser: User;

  paginationInboxSubject: BehaviorSubject<any>;
  currPaginationInboxIndex: Observable<any>;

  paginationIndexSubject: BehaviorSubject<any>;
  currentPaginationIndex: Observable<any>;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.currentUser = this.authService.currentLoginUserValue;
    this.paginationIndexSubject = new BehaviorSubject<any>({ pageNumber: 1 });
    this.currentPaginationIndex = this.paginationIndexSubject.asObservable();
    this.paginationInboxSubject = new BehaviorSubject<any>({ pageNo: 1 });
    this.currPaginationInboxIndex = this.paginationInboxSubject.asObservable();
  }

  getPreFilledDetailsById(Id) {
    const API_URL = `${environment.apiUrl}${ConstantsService.FieldAgent_APIURL.FieldAgentById}`;
    let params = new HttpParams();
    params = params.append('id', Id);
    return this.http.get(API_URL, { params });
  }

  signUpById(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.FieldAgent_APIURL.FieldAgentSignUp}`;
    return this.http.post(API_URL, data);
  }

  getWorkOrderListInbox(
    pageNo,
    pageSize,
    searchText: string = '',
    JobsIds: string = '',
    createDate: string = null
  ) {
    const API_URL = `${environment.apiUrl}${ConstantsService.FieldAgent_APIURL.GetFieldAgentWorkOrderListInbox}`;
    let params = new HttpParams();
    params = params.append('fieldAgentId', this.currentUser.Id.toString());
    params = params.append('pageNo', pageNo);
    params = params.append('pageSize', pageSize);
    params = params.append('searchText', searchText);
    params = params.append('jobIds', JobsIds);
    params = params.append('createDate', createDate);
    return this.http.get(API_URL, { params: params });
  }

  getWorkOrderDetailsByTaskOrderId(workOrderId) {
    const API_URL = `${environment.apiUrl}${ConstantsService.ResidentModule_APIURL.SearchWorkOrder}`;
    let params = new HttpParams();
    params = params.append('searchText', workOrderId);
    return this.http.get(API_URL, { params: params });
  }

  WorkOrderAssign(data) {
    const API_URL = `${environment.apiUrl}${ConstantsService.SupervisorModule_APIURL.PostAssignWorkOrder}`;
    return this.http.post(API_URL, data);
  }

  getWorkOrderListByStatus(
    statusKey,
    pageNo,
    pageSize,
    searchText,
    sortByWorkOrder,
    selectedJobs,
    sortedColumn,
    isSortedDesc,
    isFieldAssignedByMe,
    dateFrom,
    dateTo
  ): Observable<WorkOrderListByStatus> {
    const API_URL = `${environment.apiUrl}${ConstantsService.FieldAgent_APIURL.GetFieldAgentWorkOrderListByStatus}`;
    let params = new HttpParams();
    params = params.append('dateFrom', dateFrom);
    params = params.append('dateTo', dateTo);
    params = params.append('status', statusKey);
    this.currentUser = this.authService.currentLoginUserValue;
    params = params.append('fieldAgentId', this.currentUser.Id.toString());
    params = params.append('pageNo', pageNo);
    params = params.append('pageSize', pageSize);
    if (sortedColumn && isSortedDesc) {
      params = params.append('sortedColumn', sortedColumn);
    }
    if (isFieldAssignedByMe) {
      params = params.append('isAssignedByMe', 'true');
    } else {
      params = params.append('isAssignedByMe', 'false');
    }
    if (isSortedDesc === 'true') {
      params = params.append('isSortedDesc', 'desc');
    } else {
      params = params.append('isSortedDesc', 'asc');
    }

    params = params.append('searchText', searchText ? searchText : '');
    params = params.append(
      'sortByCreateDate',
      sortByWorkOrder ? sortByWorkOrder : true
    );
    params = params.append(
      'jobIds',
      selectedJobs
        ? selectedJobs
        : null
    );

    return this.http.get(API_URL, { params: params })
      .pipe(map((data: any) => {
        if (data && data.Data && data.Success) {
          let workOrderListByStatus: WorkOrderListByStatus;
          workOrderListByStatus = data.Data;
          return workOrderListByStatus;
        }
      }));
  }
}
