import {
  Component,
  Output,
  EventEmitter,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { ConstantsService } from '../../services/constants.service';
import { environment } from 'src/environments/environment';
import * as EXIF from 'exif-js';
import { LoaderService } from 'src/app/core/helpers/services/loader.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadResponse } from '../../models/work-order';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['./multi-file-upload.component.css'],
})
export class MultiFileUploadComponent {
  uploadResponse = { status: '', message: '', filePath: '' };
  inputFiles: File[] = [];
  thumbnailImage: string;
  latitude: string;
  longitude: string;
  error: HttpErrorResponse;
  fileName = '';
  @Input()
  multiple = true;

  @Input()
  accept = ConstantsService.imageFormat +
    ',' +
    ConstantsService.videoFormat +
    ',' +
    ConstantsService.fileFormat;

  @Input()
  maxFileSize = 10485760;
  modalRef: BsModalRef;
  message: string;
  @ViewChild('template') template: TemplateRef<any>;

  @Input()
  UploadBtnText = 'Upload Picture';

  @Output() OnFileUploadResponse: EventEmitter<FileUploadResponse>;
  @Output() OnFileUploadResponseBase64: EventEmitter<{FileName: string, Content:  string, file: any }> = new EventEmitter<{FileName: string, Content:  string, file: any }>();
  @Input() isBase64needed = false;

  constructor(
    private uploadService: UploadService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
  ) {
    this.OnFileUploadResponse = new EventEmitter<FileUploadResponse>();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      if(this.isBase64needed) {
        this.onFileSelected(event);
        return;
      }
      for (const file of event.target.files) {
        if (this.isFileTypeAllowed(file)) {
          const indexOfExistingFile: number = this.inputFiles.findIndex(
            (item) => item.name === file.name
          );
          if (-1 !== indexOfExistingFile) {
            this.inputFiles.splice(indexOfExistingFile, 1);
          }
          EXIF.getData(file, function() {
            this.latitude = EXIF.getTag(this, 'GPSLatitude');
            this.longitude = EXIF.getTag(this, 'GPSLongitude');
          });
          this.inputFiles.push(file);
        }
      }
      if (this.inputFiles && this.inputFiles.length > 0) {
        this.loaderService.isLoading.next(true);
        this.onSubmit();
      }
    }
  }
  // to place in service
  isFileTypeAllowed(file): boolean {
    let fileExtension = file.name.split('.');
    fileExtension = fileExtension[fileExtension.length - 1];
    return (file.type &&
        (-1 !== this.accept.indexOf(file.type.split('/')[0]) ||
          -1 !== this.accept.indexOf(file.type.split('/')[1]))) ||
      -1 !== this.accept.indexOf(fileExtension);
  }

  onClearAll() {
    this.inputFiles.splice(0, this.inputFiles.length);
  }

  onSubmit() {
    if (this.inputFiles.length === 0) {
      return;
    }
    let formData = new FormData();
    this.inputFiles.forEach((element) => {
      formData.append('file', element, element.name);
    });
    this.uploadService.upload(formData)
      .pipe(finalize(() => this.loaderService.isLoading.next(false)))
      .subscribe(
      (res) => {
        this.uploadResponse = res;
        if (
          res.StatusCode === 200 && res.Success &&
          res.Message === 'Success' && res.Data &&
          res.Data.DocumentID
        ) {
          let mapProperty = {
            photoLatitude: this.latitude,
            photoLongitude: this.longitude,
          };
          Object.assign(res, mapProperty);
          this.OnFileUploadResponse.emit(res);
          this.thumbnailImage = environment.apiUrl + (res.Data.FilePath || '');
          this.onClearAll();
        }
      },
      (err: HttpErrorResponse) => {
        this.message = ConstantsService.ErrorMessage.FileUploadFailed;
        this.onClearAll();
        this.modalRef = this.modalService.show(this.template, {
          class: 'modal-md',
        });

        this.error = err;
      }
    );
  }

  confirm(): void {
    this.modalRef.hide();
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.convertToBase64(file);
    }
  }

  convertToBase64(file: File): void {
    const reader = new FileReader();

    reader.onload = async () => {
      const result = reader.result;

      if (typeof result === 'string') {
        this.OnFileUploadResponseBase64.emit({FileName: file.name, Content:  result.split(',')[1], file: file })
      } else {
        const base64String = this.blobToBase64(result as unknown as Blob);
        this.OnFileUploadResponseBase64.emit({FileName: file.name, Content: await base64String, file: file })
      }
    };

    reader.readAsDataURL(file);
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
