import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PopoverRef} from '../../overlay/overlay.ref';
import {Subject} from 'rxjs';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {AsyncRender} from '../../../../../core/helpers/services/async.render';

@Component({
  selector: 'app-dropdown-control-options-list',
  templateUrl: './dropdown-control-options-list.component.html',
  styleUrls: ['./dropdown-control-options-list.component.scss'],
})
export class DropdownControlOptionsListComponent implements OnInit, OnDestroy, AfterViewInit {

  public data: { name: string; value: number | string }[] = [];

  public activeItemValue: number | string | null = null;

  public width: string | number = 0;

  public readonly ITEM_HEIGHT: number = 32;

  public numberOfItemsToDisplay: number = 5;

  private onDestroy: Subject<void> = new Subject<void>();

  @ViewChild(CdkVirtualScrollViewport)
  public viewPort: CdkVirtualScrollViewport;

  constructor(private popoverRef: PopoverRef) {
    this.popoverRef.overlay.outsidePointerEvents().subscribe(() => {
      this.popoverRef.close('backdropClick', {});
    });
    this.prepareData();
  }

  public setValue(item: { name: string; value: number | string }): void {
    this.popoverRef.close('close', item);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    this.scrollToActive();
  }

  private prepareData(): void {
    if (this.popoverRef.data) {
      this.data = this.popoverRef.data.data;
      this.activeItemValue = this.popoverRef?.data?.activeItemValue;
      this.width = this.popoverRef.overlay.getConfig().width;
      this.numberOfItemsToDisplay = this.popoverRef.data.numberOfItemsToDisplay;
      this.numberOfItemsToDisplay =
        this.data.length > this.numberOfItemsToDisplay
          ? this.numberOfItemsToDisplay
          : this.data.length;
    }
  }

  @AsyncRender
  private scrollToActive(): void {
    if (this?.activeItemValue) {
      const selectedIndex = this.data.findIndex((elem) => this.activeItemValue === elem.value);
      if (selectedIndex > -1) {
        this.viewPort.scrollToIndex(selectedIndex, 'auto');
      }
    }
  }
}
