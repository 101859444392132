import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input
} from '@angular/core';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar-event',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.css'],
})
export class CalendarEventComponent {
  toggleButton = true;
  buttonName = this.translate.instant('CommonLabel.SwitchToTable');

  constructor(
    private commonService: CommonService,
    private authService: AuthenticationService,
    private translate: TranslateService
  ) {  }

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  @Input() events = [];
  @Input() searchText: string;
  @Input() jobIds: string;
  @Input() fieldAgentId: string;
  @Input() dateFrom: string;
  @Input() dateTo: string;
  @Input() isCompleteByMyself: string;

  activeDayIsOpen: boolean = false;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0);
      this.viewDate = date;
    }
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd,
                    }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent(event);
  }

  handleEvent(event: any): void {
    let modulesName = this.authService.currentLoginUserValue.Role.toLowerCase();
    if (event.workOrderType === 'Manual' ||
      event.workOrderType === null ||
      event.workOrderType === 'PDF') {
      this.commonService.navigateByURL(
        `/${modulesName}/home/details/${event.workOrderId}/${event.workOrderType}`
      );
    } else if (event.workOrderType === 'Resident') {
      this.commonService.navigateByURL(
        `/${modulesName}/home/details/${event.workOrderId}/Resident`
      );
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  toggleCalendar() {
    this.toggleButton = !this.toggleButton;
    this.buttonName = this.toggleButton
      ? this.translate.instant('CommonLabel.SwitchToTable')
      : this.translate.instant('CommonLabel.SwitchToCalendar');
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
