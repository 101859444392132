import { Component, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { LoaderService } from 'src/app/core/helpers/services/loader.service';
import { APIResponse } from '../../models/api-response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-work-order',
  templateUrl: './download-work-order.component.html',
  styleUrls: ['./download-work-order.component.css'],
})
export class DownloadWorkOrderComponent {
  constructor(
    private commonService: CommonService,
    private loaderService: LoaderService
  ) {}

  @Input() WorkIdVal: any;
  @Input() workOrderType: string;
  @Input() DownloadType: any;

  downloadWorkOrder(downloadType: string) {
    if (downloadType === 'pdf' && this.WorkIdVal) {
      this.commonService.navigateByURL(
        `supervisor/home/download-work-order/${this.WorkIdVal}/${this.workOrderType}`
      );
    } else {
      if (this.WorkIdVal) {
        this.loaderService.isLoading.next(true);
        let model = {
          WorkOrderId: this.WorkIdVal,
          WorkOrderType: this.workOrderType.toLowerCase(),
          DownloadType: 'csv',
        };
        this.commonService
          .downloadWorkOrder(model)
          .subscribe((res: APIResponse) => {
            this.loaderService.isLoading.next(false);
            const a = document.createElement('a');
            let url = environment.apiUrl + res.Data;
            a.href = url;
            a.download = url;
            a.click();
            window.URL.revokeObjectURL(url);
          });
      }
    }
  }
}
