<div class="con-info-group first-form mar-top-70">
  <h5>{{ 'BtnText.ContactInfo' | translate }}</h5>
  <button
    class="btn btn-oclm d-xs-flex"
    data-toggle="modal"
    data-target="#contact-info"
  >
    {{ 'BtnText.ViewDetails' | translate }}
  </button>
</div>
<div class="wrp-map-stpr">
  <div id="stepper1" class="bs-stepper">
    <div class="bs-stepper-header">
      <div class="step activeted" data-target="#test-l-1">
        <button class="btn step-trigger">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">
            {{ 'BtnText.WorkOrderDetails' | translate }}
          </span>
        </button>
      </div>
      <div class="line" [ngClass]="{ 'red-line': showLine }"></div>
      <div
        class="step activeted"
        [ngClass]="{ 'activeted ': showLine }"
        data-target="#test-l-2"
      >
        <button class="btn step-trigger" [disabled]="!workOrderForm.valid">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">
            {{ 'BtnText.CloseOutInfo' | translate }}
          </span>
        </button>
      </div>
      <div class="line" [ngClass]="{ 'red-line': secondLine }"></div>
      <div
        class="step"
        [ngClass]="{ active: secondLine }"
        data-target="#test-l-3"
      >
        <button class="btn step-trigger" [disabled]="!workOrderForm.valid">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">{{
            'BtnText.PreviewCreate' | translate
          }}</span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content">
      <form [formGroup]="workOrderForm">
        <div id="test-l-1" class="content">
          <div class="card card-body">
            <div class="first-form">
              <div class="row">
                <div
                  class="col-md-12 col-sm-12 col-xs-12"
                  *ngIf="currentUserRole !== 'Admin'"
                >
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.JobName' | translate }} /
                      {{ 'CommonLabel.CustomerName' | translate }}
                    </label>
                    <div class="wrp">
                      <select
                        class="form-control"
                        id="fieldAgent"
                        formControlName="CustomerId"
                        (change)="getSelectedJobs($event.target.value)"
                        [ngClass]="{
                          'is-invalid': submitted && f.CustomerId.errors
                        }"
                      >
                        <option [value]="null">
                          -{{ 'CommonLabel.Select' | translate }}-
                        </option>
                        <option
                          *ngFor="let value of jobListDetails"
                          [value]="value.Id"
                        >
                          {{ value.Name }}
                        </option>
                      </select>
                      <img
                        src="../../../../assets/image/work-request-icon.png"
                        class='user-icon'
                      />
                      <div
                        *ngIf="submitted && f.CustomerId.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.CustomerId.errors.required">
                          {{ 'Message.JobCustomerNameRequired' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--START Added for admin role -->
                <div
                  class="col-md-6 col-sm-6 col-xs-12"
                  *ngIf="currentUserRole === 'Admin'"
                >
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.JobName' | translate }} /
                      {{ 'CommonLabel.CustomerName' | translate }}
                    </label>
                    <div class="wrp">
                      <!-- job name search -->
                      <input
                        formControlName="CustomerName"
                        [typeahead]="jobListDetails"
                        typeaheadOptionField="Name"
                        (typeaheadOnSelect)="onSelect($event)"
                        placeholder="Search job name"
                        [isAnimated]="true"
                        [ngClass]="{
                          'is-invalid': submitted && f.CustomerId.errors
                        }"
                        class="form-control"
                      />
                      <div
                        *ngIf="submitted && f.CustomerId.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.CustomerId.errors.required">
                          {{ 'Message.JobCustomerNameRequired' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-sm-6 col-xs-12"
                  *ngIf="currentUserRole === 'Admin'"
                >
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.SelectSupervisor' | translate }}
                    </label>
                    <div class="wrp">
                      <!-- supervisor search -->
                      <select
                        class="form-control"
                        id="fieldAgent"
                        (change)="onSelectSupervisor($event.target.value)"
                        formControlName="SuperVisorId"
                        [ngClass]="{
                          'is-invalid': submitted && f.SuperVisorId.errors
                        }"
                      >
                        <option [value]="null">
                          -{{ 'CommonLabel.Select' | translate }}-
                        </option>
                        <option
                          *ngFor="let value of supervisorList"
                          [value]="value.ID"
                        >
                          {{ value.Text }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted && f.SuperVisorId.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.SuperVisorId.errors.required">
                          {{ 'Message.Supervisor' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.Location' | translate }}/{{
                        'CommonLabel.Address' | translate
                      }}
                    </label>
                    <div class="wrp">
                      <input
                        type="text"
                        placeholder="{{ 'CommonLabel.Address' | translate }}"
                        formControlName="Address"
                        (keydown.enter)="setLocationName($event)"
                        (focusout)="setLocationName($event)"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        #search
                        [ngClass]="{
                          'is-invalid': submitted && f.Address.errors
                        }"
                        class="form-control"
                      />
                      <img
                        src="../../../../assets/image/region-icon.png"
                        class='user-icon'
                      />
                      <a
                        style="text-decoration: underline; cursor: pointer"
                        (click)="scrollToElement()"
                        class="btn d-sm-none btn-link"
                        >Show in map</a
                      >

                      <div
                        *ngIf="submitted && f.Address.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.Address.errors.required">
                          {{ 'Message.LocationRequired' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--END Added for admin role -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label>{{ 'Table.WorkCategory' | translate }}</label>
                    <div class="wrp">
                      <select
                        class="form-control"
                        id="workcatergory"
                        formControlName="WorkCategory"
                        (change)="onChangeOfWorkCategory($event.target.value)"
                        [ngClass]="{
                          'is-invalid': submitted && f.WorkCategory.errors
                        }"
                      >
                        <option [ngValue]="null">
                          -{{ 'CommonLabel.Select' | translate }}-
                        </option>
                        <option
                          *ngFor="let WorkCategory of workCategoryList"
                          [ngValue]="WorkCategory.Text"
                        >
                          {{ WorkCategory.Text }}
                        </option>
                      </select>
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                      <div
                        *ngIf="submitted && f.WorkCategory.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.WorkCategory.errors.required">
                          {{ 'Message.WorkCategoryRequired' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.DescriptionOfWork' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      (change)="onDescription($event)"
                      formControlName="WorkRequestID"
                      [ngClass]="{
                        'is-invalid': submitted && f.WorkRequestID.errors
                      }"
                      placeholder="{{
                        'CommonLabel.DescriptionOfWork' | translate
                      }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/comment-icon.png"
                      class='user-icon'
                    />
                    <div
                      *ngIf="submitted && f.WorkRequestID.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.WorkRequestID.errors.required">
                        {{ 'Message.DescriptionWorkRequired' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12"
                *ngIf="
                  !showScheduleDate &&
                  workOrderForm['controls'].CompletedOn.value == null
                "
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.CompleteBy' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      [minDate]="minDate"
                      formControlName="CompletedBy"
                      class="form-control"
                    />
                    <img
                      src="../../../../assets/image/date-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-xs-12"
                *ngIf="
                  !showScheduleDate &&
                  workOrderForm['controls'].CompletedOn.value != null
                "
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.CompleteOn' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      [minDate]="minDate"
                      formControlName="CompletedOn"
                      class="form-control"
                    />
                    <img
                      src="../../../../assets/image/date-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <!-- conditional StartedDate -->
              <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.ScheduleBy' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      formControlName="StartDate"
                      placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      [minDate]="minDate"
                      class="form-control"
                    />
                    <img
                      src="../../../../assets/image/date-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="!existingRecord">
                <div class="form-group">
                  <label>{{ 'CommonLabel.Notes' | translate }}</label>
                  <div class="wrp">
                    <textarea
                      placeholder="{{ 'CommonLabel.Notes' | translate }}"
                      rows="1"
                      formControlName="Notes"
                      class="form-control"
                    ></textarea>
                    <img
                      src="../../../../../assets/image/comment-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>

              <ng-container *ngIf="showScheduleDate">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.CompleteBy' | translate }}
                    </label>
                    <div class="wrp">
                      <input
                        type="text"
                        placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                        bsDatepicker
                        [bsConfig]="bsConfig"
                        [minDate]="minDate"
                        formControlName="CompletedBy"
                        class="form-control"
                      />
                      <img
                        src="../../../../assets/image/date-icon.svg"
                        class='user-icon'
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-8 d-none d-sm-block d-sm-none d-md-block"
                ></div>
              </ng-container>

              <div
                class="col-md-8 col-sm-8 col-xs-8"
                *ngIf="WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0"
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.ViewNotes' | translate }}
                  </label>
                  <div class="view-note-box">
                    <!-- 1st box -->
                    <ng-container *ngFor="let item of WorkOrderNotesDetail">
                      <div
                        class="view-note"
                        *ngIf="
                          item && item.NotesText && item.NotesText.length > 0
                        "
                      >
                        <div class="notify-msg">
                          {{ item.CreatedBy | firstWord }}
                        </div>
                        <div class="notify-text">
                          <span class="notify-date">
                            {{ item.CreatedBy }},
                            {{ item.CreatedDate | date: 'd MMMM, y, h:mm a' }}
                          </span>
                          <span>{{ item.NotesText }}</span>
                        </div>
                      </div>
                      <!-- 2st box -->
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="existingRecord">
                <div class="form-group">
                  <label>{{ 'CommonLabel.Notes' | translate }}</label>
                  <div class="wrp">
                    <textarea
                      placeholder="{{ 'CommonLabel.Notes' | translate }}"
                      rows="1"
                      formControlName="Notes"
                      class="form-control"
                    ></textarea>
                    <img
                      src="../../../../../assets/image/comment-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>

              <div
                class="col-md-4 col-sm-4 col-xs-12"
                *ngIf="existingRecord"
              ></div>

              <!-- END -  This dropdown will be displayed only status change -->
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                *ngIf="currentUserRole === 'FieldAgent'"
              >
                <div class="form-group">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      formControlName="notifyCheck"
                      class="custom-control-input"
                    />
                    <span class="custom-control-label">{{
                      'BtnText.NotifyUser' | translate
                    }}</span>
                  </label>
                </div>
              </div>

              <ng-container
                *ngFor="let item of DynamicFieldsList; let i = index"
              >
                <div class="col-md-8 col-sm-8 col-xs-4">
                  <div class="form-group">
                    <label>{{ item.FieldName }}</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <img src="../../../../assets/image/job-icon.svg" />
                        </span>
                      </div>
                      <input
                        class="form-control"
                        style="
                          border-left: 0px;
                          border-right: 0px;
                          margin-left: -11px;
                        "
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="item.Values"
                        placeholder="Values"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <img
                            (click)="onDelete(item.Values)"
                            src="../../../../assets/image/delete.svg"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-8 col-sm-8 col-xs-12 add-field">
                <span>
                  <strong>{{ 'BtnText.AddMoreField' | translate }}</strong>
                </span>
                <span>
                  <app-add-more-fields
                    (onAddMoreFields)="onAddMoreFieldsResponse($event)"
                  ></app-add-more-fields>
                </span>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12">
                <p class="thumb-text">
                  {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
                </p>
                <app-preview-file
                  [uploadFile]="uploadBeforePhotoFilePath"
                  [beforeComments]="uploadBeforeCommentData"
                  [WorkIdVal]="WorkIdVal"
                  [workOrderType]="workOrderType"
                  [BeforeCreationWorkOrder]="'BeforeCreationWorkOrder'"
                ></app-preview-file>

                <app-multi-file-upload
                  (OnFileUploadResponse)="fileUploadResponseBefore($event)"
                  [UploadBtnText]="'Upload Picture'"
                >
                </app-multi-file-upload>
              </div>
            </div>
            <div
              class="col-md-8 col-sm-8 col-xs-12 mt-3"
              *ngIf="currentUserRole !== 'CommunityManager'"
            >
              <ng-content></ng-content>
            </div>
            <div class="mt-5 group-btn-last">
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-oclm-null"
                  (click)="backToPrevious()"
                >
                  {{ 'BtnText.Cancel' | translate }}
                </button>
              </div>
              <div class="form-group">
                <span *ngIf="showScheduleDate; else elseSpan">
                  <button
                    type="submit"
                    class="btn btn-oclm-null"
                    data-toggle="modal"
                    data-target="#CustomizedEvent"
                  >
                    {{ 'BtnText.Save' | translate }}
                  </button>
                </span>
                <ng-template #elseSpan>
                  <button
                    type="submit"
                    class="btn btn-oclm-null"
                    data-toggle="modal"
                    (click)="createWorkOrder('Save')"
                  >
                    {{ 'BtnText.Save' | translate }}
                  </button>
                </ng-template>
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-oclm-null"
                  data-toggle="modal"
                  data-target="#CustomizedEvent"
                >
                  Schedule
                </button>
              </div>
              <div class="download-cls grid-span" *ngIf="existingRecord">
                <app-download-work-order
                  [WorkIdVal]="WorkIdVal"
                  [workOrderType]="workOrderType"
                ></app-download-work-order>
              </div>
              <div class="form-group text-right grid-span" *ngIf="showNextBtn">
                <button
                  type="submit"
                  class="btn btn-oclm w-50"
                  (click)="next('first')"
                >
                  {{ 'BtnText.Next' | translate }}
                  <img
                    src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="test-l-2" class="content">
          <div class="card card-body">
            <div class="first-form">
              <div class="row">
                <div class="col-md-10 col-xs-10">
                  <div class="form-group">
                    <label>
                      {{ 'CommonLabel.CannedResponse' | translate }}
                    </label>
                    <div class="wrp">
                      <select
                        class="form-control"
                        id="cannedCategory"
                        formControlName="CannedResponseId"
                        (change)="getCannedResponseText($event.target.value)"
                        [ngClass]="{
                          'is-invalid': submitted && f.CannedResponseId.errors
                        }"
                      >
                        <option [value]="null">
                          -{{ 'CommonLabel.Select' | translate }}-
                        </option>
                        <option
                          *ngFor="let value of cannedResponseList"
                          [ngValue]="value.ID"
                        >
                          {{ value.Text }}
                        </option>
                      </select>
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                      <div
                        *ngIf="submitted && f.CannedResponseId.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.CannedResponseId.errors.required">
                          Canned Response is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-10 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <div class="wrp">
                      <input
                        type="text"
                        placeholder="More on canned response"
                        formControlName="CannedResponseText"
                        class="form-control"
                      />
                      <img
                        src="../../../../assets/image/job-icon.svg"
                        class='user-icon'
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4"></div>
                <div class="col-md-12 c0l-xs-12">
                  <p class="thumb-text">
                    {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
                  </p>
                  {{uploadAfterPhotoFilePath}}
                  <app-preview-file
                    [uploadFile]="uploadAfterPhotoFilePath"
                    [afterComments]="uploadAfterCommentData"
                    [WorkIdVal]="WorkIdVal"
                    [AfterCreationWorkOrder]="'AfterCreationWorkOrder'"
                    [workOrderType]="workOrderType"
                  ></app-preview-file>
                  <app-multi-file-upload
                    (OnFileUploadResponse)="fileUploadResponseAfter($event)"
                    [UploadBtnText]="'Upload Picture'"
                  >
                  </app-multi-file-upload>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6 col-sm-6 col-5 form-group">
                <button
                  type="submit"
                  class="btn btn-oclm-null"
                  (click)="previous()"
                >
                  {{ 'BtnText.Back' | translate }}
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-7 form-group text-right">
                <button
                  type="submit"
                  class="btn btn-oclm w-50"
                  (click)="next('second')"
                >
                  {{ 'BtnText.Next' | translate }}
                  <img
                    src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div id="test-l-3" class="content mt-4">
        <div class="card card-body">
          <div class="first-form">
            <div class="row">
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                *ngIf="currentUserRole !== 'Admin'"
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.JobName' | translate }} /
                    {{ 'CommonLabel.CustomerName' | translate }}
                  </label>
                  <div class="wrp">
                    <select
                      disabled
                      class="form-control"
                      id="fieldAgent"
                      [(ngModel)]="workOrder.CustomerId"
                    >
                      <option [value]="null">
                        -{{ 'CommonLabel.Select' | translate }}-
                      </option>
                      <option
                        *ngFor="let value of jobListDetails"
                        [value]="value.Id"
                      >
                        {{ value.Name }}
                      </option>
                    </select>
                    <img src="../../../../assets/image/work-request-icon.png" class='user-icon' />
                  </div>
                </div>
              </div>
              <!--START Added for admin role -->
              <div
                class="col-md-6 col-sm-6 col-xs-12"
                *ngIf="currentUserRole === 'Admin'"
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.JobName' | translate }} /
                    {{ 'CommonLabel.CustomerName' | translate }}
                  </label>
                  <div class="wrp">
                    <!-- job name search -->
                    <input
                      disabled
                      [(ngModel)]="workOrder.CustomerName"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-md-6 col-sm-6 col-xs-12"
                *ngIf="currentUserRole === 'Admin'"
              >
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.SelectSupervisor' | translate }}
                  </label>
                  <div class="wrp">
                    <!-- supervisor search -->
                    <input
                      disabled
                      [(ngModel)]="workOrder.SuperVisorName"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <!--END Added for admin role -->
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.DescriptionOfWork' | translate }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      disabled
                      [(ngModel)]="workOrder.WorkRequestID"
                      placeholder="{{
                        'CommonLabel.DescriptionOfWork' | translate
                      }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/comment-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label>
                    {{ 'CommonLabel.Location' | translate }}/{{
                      'CommonLabel.Address' | translate
                    }}
                  </label>
                  <div class="wrp">
                    <input
                      type="text"
                      disabled
                      placeholder="{{ 'CommonLabel.Address' | translate }}"
                      [(ngModel)]="workOrder.Address"
                      class="form-control"
                    />
                    <img
                      src="../../../../assets/image/region-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 col-sm-8 col-xs-12">
              <div class="form-group">
                <label>{{ 'Table.WorkCategory' | translate }}</label>
                <div class="wrp">
                  <select
                    disabled
                    class="form-control"
                    id="workcatergory"
                    [(ngModel)]="workOrder.WorkCategory"
                  >
                    <option [value]="null">
                      -{{ 'CommonLabel.Select' | translate }}-
                    </option>
                    <option
                      *ngFor="let value of workCategoryList"
                      [value]="value.Text"
                    >
                      {{ value.Text }}
                    </option>
                  </select>
                  <img
                    src="../../../../assets/image/job-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-4 col-xs-12"
              *ngIf="
                !showScheduleDate &&
                workOrderForm['controls'].CompletedOn.value == null
              "
            >
              <div class="form-group">
                <label>
                  {{ 'CommonLabel.CompleteBy' | translate }}
                </label>
                <div class="wrp">
                  <input
                    disabled
                    type="text"
                    [(ngModel)]="workOrder.CompletedBy"
                    placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    class="form-control"
                  />
                  <img
                    src="../../../../assets/image/date-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>

            <div
              class="col-md-4 col-sm-4 col-xs-12"
              *ngIf="
                !showScheduleDate &&
                workOrderForm['controls'].CompletedOn.value != null
              "
            >
              <div class="form-group">
                <label>
                  {{ 'CommonLabel.CompleteOn' | translate }}
                </label>
                <div class="wrp">
                  <input
                    type="text"
                    placeholder="{{ 'CommonLabel.CompleteOn' | translate }}"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    formControlName="CompletedOn"
                    class="form-control"
                    disabled
                  />
                  <img
                    src="../../../../assets/image/date-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
              <div class="form-group">
                <label> {{ 'CommonLabel.ScheduleBy' | translate }} Test </label>
                <div class="wrp">
                  <input
                    disabled
                    type="text"
                    [(ngModel)]="workOrder.StartDate"
                    placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    class="form-control"
                  />
                  <img
                    src="../../../../assets/image/date-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="!existingRecord">
              <div class="form-group">
                <label>{{ 'CommonLabel.Notes' | translate }}</label>
                <div class="wrp">
                  <textarea
                    disabled
                    rows="1"
                    [(ngModel)]="workOrder.Notes"
                    class="form-control"
                  ></textarea>
                  <img
                    src="../../../../../assets/image/comment-icon.png"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="existingRecord">
              <div class="form-group">
                <label>
                  {{ 'CommonLabel.CannedResponse' | translate }}
                </label>
                <div class="wrp">
                  <select
                    disabled
                    class="form-control"
                    id="cannedCategory"
                    [(ngModel)]="workOrder.CannedResponseId"
                  >
                    <option [value]="null">-select-</option>
                    <option
                      *ngFor="let value of cannedResponseList"
                      [value]="value.ID"
                    >
                      {{ value.Text }}
                    </option>
                  </select>
                  <img
                    src="../../../../assets/image/job-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-4 col-xs-12"
              *ngIf="showScheduleDate && !workOrder.CompletedOn"
            >
              <div class="form-group">
                <label>
                  {{ 'CommonLabel.CompleteBy' | translate }}
                </label>
                <div class="wrp">
                  <input
                    disabled
                    type="text"
                    [(ngModel)]="workOrder.CompletedBy"
                    placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    class="form-control"
                  />
                  <img
                    src="../../../../assets/image/date-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-4 col-xs-12"
              *ngIf="showScheduleDate && workOrder.CompletedOn"
            >
              <div class="form-group">
                <label>
                  {{ 'CommonLabel.CompleteOn' | translate }}
                </label>
                <div class="wrp">
                  <input
                    disabled
                    type="text"
                    [(ngModel)]="workOrder.CompletedOn"
                    placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    class="form-control"
                  />
                  <img
                    src="../../../../assets/image/date-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="existingRecord">
              <div class="form-group">
                <div class="wrp">
                  <input
                    readonly
                    type="text"
                    placeholder=""
                    [(ngModel)]="workOrder.CannedResponseText"
                    class="form-control"
                  />
                  <img
                    src="../../../../assets/image/job-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-4"></div>
            <div
              class="col-md-8 col-sm-8 col-xs-8"
              *ngIf="WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0"
            >
              <div class="form-group">
                <label>
                  {{ 'CommonLabel.ViewNotes' | translate }}
                </label>
                <div class="view-note-box">
                  <!-- 1st box -->
                  <ng-container *ngFor="let item of WorkOrderNotesDetail">
                    <div
                      class="view-note"
                      *ngIf="
                        item && item.NotesText && item.NotesText.length > 0
                      "
                    >
                      <div class="notify-msg">
                        {{ item.CreatedBy | firstWord }}
                      </div>
                      <div class="notify-text">
                        <span class="notify-date">
                          {{ item.CreatedBy }},
                          {{ item.CreatedDate | date: 'd MMMM, y,h:mm a' }}
                        </span>
                        <span>{{ item.NotesText }}</span>
                      </div>
                    </div>
                    <!-- 2st box -->
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="existingRecord">
              <div class="form-group">
                <label>{{ 'CommonLabel.Notes' | translate }}</label>
                <div class="wrp">
                  <textarea
                    disabled
                    rows="1"
                    [(ngModel)]="workOrder.Notes"
                    class="form-control"
                  ></textarea>
                  <img
                    src="../../../../../assets/image/comment-icon.png"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-4 col-xs-12"
              *ngIf="existingRecord"
            ></div>

            <ng-container *ngFor="let item of DynamicFieldsList; let i = index">
              <div class="col-md-8 col-sm-8 col-xs-4" *ngIf="i % 2 == 0">
                <div class="form-group">
                  <label>{{ item.FieldName }}</label>
                  <div class="wrp">
                    <input
                      readonly
                      class="form-control mb-3"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="item.Values"
                      placeholder="Values"
                    />
                    <img
                      src="../../../../assets/image/job-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-4" *ngIf="i % 2 != 0">
                <div class="form-group">
                  <label>{{ item.FieldName }}</label>
                  <div class="wrp">
                    <input
                      readonly
                      class="form-control mb-3"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="item.Values"
                      placeholder="Values"
                    />
                    <img
                      src="../../../../assets/image/job-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="card card-body">
          <div class="first-form">
            <div class="row">
              <div class="col-md-6 c0l-xs-12 mb-4">
                <p class="thumb-text">
                  {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
                </p>
                <app-preview-file
                  [uploadFile]="uploadBeforePhotoFilePath"
                  [beforeComments]="uploadBeforeCommentData"
                  [WorkIdVal]="WorkIdVal"
                  [workOrderType]="workOrderType"
                  [BeforeCreationWorkOrder]="'BeforeCreationWorkOrder'"
                ></app-preview-file>
              </div>
              <div class="col-md-6 c0l-xs-12 mb-4">
                <p class="thumb-text">
                  {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
                </p>
                <app-preview-file
                  [uploadFile]="uploadAfterPhotoFilePath"
                  [afterComments]="uploadAfterCommentData"
                  [WorkIdVal]="WorkIdVal"
                  [AfterCreationWorkOrder]="'AfterCreationWorkOrder'"
                  [workOrderType]="workOrderType"
                ></app-preview-file>
              </div>
            </div>
          </div>
        </div>
        <!-- html end -->
        <div class="row mt-3">
          <div class="col-md-12 col-sm-6 col-xs-6">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-5 form-group">
                <button
                  type="submit"
                  class="btn btn-oclm-null"
                  (click)="previous()"
                >
                  {{ 'BtnText.Back' | translate }}
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-7 form-group text-right">
                <button
                  type="submit"
                  class="btn btn-oclm w-50"
                  (click)="createWorkOrder('finalSave')"
                >
                  {{ btnText }}
                  <img
                    src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="login-left-Mapbox" #target style="opacity: 0.8">
    <app-google-map
      [extLat]="latitude"
      [extLong]="longitude"
      [zipCode]="zipCode"
      [markerDraggable]="true"
      (gmapResponse)="gmapResponseUpdate($event)"
      (gmapForCityName)="gmapResponseForCityName($event)"
    >
    </app-google-map>
  </div>
</div>

<ng-container *ngFor="let item of uploadAfterPhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngFor="let item of uploadBeforePhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model0{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
  id="contact-info"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">
          {{ 'BtnText.ContactInfo' | translate }}
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="ContactForm">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.FirstName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                formControlName="ContactFirstName"
                placeholder="{{ 'CommonLabel.FirstName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.LastName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                formControlName="ContactLastName"
                placeholder="{{ 'CommonLabel.LastName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.Email' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                formControlName="ContactEmail"
                placeholder="{{ 'CommonLabel.Email' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/email-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>
              {{ 'CommonLabel.PhoneNumber' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                formControlName="ContactPhoneNumber"
                appPhoneNumber
                placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
                class="form-control"
                minlength='10'
                maxlength="10"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                required
              />
              <img
                src="../../../../assets/image/phone-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-12 form-group">
            <label>
              {{ 'CommonLabel.Location' | translate }}/{{
                'CommonLabel.Address' | translate
              }}
            </label>
            <div class="wrp">
              <input
                type="text"
                maxlength="200"
                formControlName="ContactAddress"
                placeholder="{{ 'CommonLabel.Location' | translate }}"
                class="form-control"
                (keydown.enter)="setCustomerLocationName($event)"
                (focusout)="setCustomerLocationName($event)"
                #searchContactAddress
              />
              <img
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
      </div>
      <div class="foot-btn text-center mt-1 mb-4">
        <button type="button" class="btn btn-oclm w-50" data-dismiss="modal">
          {{ 'BtnText.Save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Event Customized form -->

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
  id="CustomizedEvent"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">
          {{ 'BtnText.CustomizedEventInfo' | translate }}
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="customizedWorkOrderForm">
          <div class="row">
            <div class="col-12 form-group">
              <label> {{ 'CommonLabel.Title' | translate }}</label>
              <div class="wrp">
                <input
                  type="text"
                  formControlName="CustomerName"
                  placeholder="{{ 'CommonLabel.Title' | translate }}"
                  class="form-control"
                  maxlength="100"
                />
                <img
                  src="../../../../assets/image/user-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
            <div class="col-12 form-group">
              <label>{{ 'CommonLabel.Description' | translate }}</label>
              <div class="wrp">
                <input
                  type="text"
                  formControlName="WorkRequestID"
                  placeholder="{{ 'CommonLabel.Description' | translate }}"
                  class="form-control"
                />
                <img
                  src="../../../../assets/image/user-icon.png"
                  class='user-icon'
                />
              </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="form-group">
                <label> {{ 'CommonLabel.Location' | translate }} </label>
                <div class="wrp">
                  <input
                    type="text"
                    placeholder="{{ 'CommonLabel.Location' | translate }}"
                    formControlName="Address"
                    class="form-control"
                  />
                  <img
                    src="../../../../assets/image/region-icon.png"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="foot-btn text-center mt-1 mb-4">
            <button
              type="submit"
              class="btn btn-oclm w-50"
              (click)="createCustomizedWorkOrderScheduled()"
              data-dismiss="modal"
            >
              {{ 'BtnText.Save' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>{{ message }}</p>
    <div class="modal-footer">
      <button type="button" class="btn btn-oclm" (click)="confirm()">Ok</button>
    </div>
  </div>
</ng-template>

<ng-template #commentBeforeTemplate>
  <div class="modal-body text-center">
    <div class="form-group">
      <label for="comment">Comment</label>
      <input
        type="text"
        [(ngModel)]="uploadBeforeComment"
        class="form-control"
        id="comment"
        placeholder="Enter comment"
      />
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-oclm" (click)="saveBeforeComment()">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #commentAfterTemplate>
  <div class="modal-body text-center">
    <div class="form-group">
      <label for="comment">After Picture</label>
      <input
        type="text"
        [(ngModel)]="uploadAfterComment"
        class="form-control"
        id="comment"
        placeholder="Enter comment"
      />
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-oclm" (click)="saveAfterComment()">
        Save
      </button>
    </div>
  </div>
</ng-template>

<app-model #modalInfo (response)="modalResponse($event)"></app-model>
