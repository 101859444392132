import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneNumber]',
})
export class PhoneNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedData = event.clipboardData?.getData('text/plain');
    const cleanedData = pastedData.replace(/[^\d]/g, ''); // Remove non-digit characters
    const formattedData = this.formatPhoneNumber(cleanedData);

    document.execCommand('insertHTML', false, formattedData);
  }

  private formatPhoneNumber(phoneNumber: string): string {
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return as-is if not matching the expected pattern
  }
}
