<div
  bsModal
  #lgModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  class="modal fade print"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-full-screen">
    <div class="modal-content">
      <div class="modal-header">
        <div class="login-logo">
          <img
            src="../../../../assets/small_logo.jpg"
            class="img-responsive"
            alt="logo"
          />
        </div>
        <button
          type="button"
          class="close pull-right d-print-none"
          aria-label="Close"
          (click)="lgModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Do you want to upload the Completion of work order documents? -->
        <ng-content></ng-content>
        <h4 class="d-flex justify-content-center">Work Order Details</h4>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Task Name</th>
                <th>Job Name</th>
                <th>Address</th>
                <th>Work Category</th>
                <th>Work Status</th>
                <th>Assigned To</th>
                <th>Due Date</th>
              </tr>
            </thead>

            <tbody>
              <ng-container
                *ngIf="
                  modelDetails && modelDetails.length > 0;
                  else elseTemplate
                "
              >
                <tr *ngFor="let item of modelDetails">
                  <td>{{ item.TaskName }}</td>
                  <td>{{ item.JobName }}</td>
                  <td>{{ item.Address }}</td>
                  <td>{{ item.WorkCategory }}</td>
                  <td>{{ item.WorkStatusText }}</td>
                  <td>{{ item.AssignedTo }}</td>
                  <td>{{ item.DueDate | date: 'MMM d, y' }}</td>
                </tr>
              </ng-container>
              <ng-template #elseTemplate>
                <tr>
                  <td colspan="7">No Record Found!</td>
                </tr>
              </ng-template>

              <!-- *ngFor="let event of events" -->
            </tbody>
          </table>
        </div>
        <div class="foot-btn">
          <button type="button" class="btn btn-oclm" (click)="decline()">
            <!-- No -->
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
