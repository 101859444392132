import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { User, UserModel } from 'src/app/shared/models/user';
import { APIResponse } from 'src/app/shared/models/api-response';
import { ConstantsService } from 'src/app/shared/services/constants.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSessionSubject: BehaviorSubject<User | null> = new BehaviorSubject(
    null
  );
  public currentUser: Observable<User>;
  public user: User;

  constructor(private http: HttpClient) {
    if (localStorage.getItem(environment.CUID)) {

      this.currentUserSessionSubject = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem(environment.CUID))
      );
    }
    this.currentUser = this.currentUserSessionSubject.asObservable();
    this.user = JSON.parse(localStorage.getItem(environment.CUID));
    this.currentUserSessionSubject.asObservable();
  }

  public get currentLoginUserValue(): User {
    return this.currentUserSessionSubject.value;
  }

  public setCurrentLoginUser() {
    this.currentUserSessionSubject.next(null);
  }

  login(data: UserModel) {
    let API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.profileDetails.UserLogin}`;
    let user;

    let model = {
      userName: data.username,
      passWord: data.password,
      roleId: data.roleId,
    };
    return this.http.post(API_URL, model).pipe(
      map((res: APIResponse) => {
        // login successful if there's a jwt token in the response
        if (res && res.Data && res.Success) {
          user = res.Data;
          localStorage.setItem(environment.CUID, JSON.stringify(user));
          this.currentUserSessionSubject.next(user);
        } else {
          localStorage.setItem(environment.CUID, JSON.stringify(null));
          this.currentUserSessionSubject.next(null);
        }
        return user;
      })
    );
  }

  logout() {
    localStorage.removeItem(environment.OLCMToken);
    localStorage.removeItem(environment.CUID);
    localStorage.removeItem(environment.headerTitle);
    this.currentUserSessionSubject.next(null);
    this.currentUserSessionSubject.next(undefined);
  }

  saveAgreement(UserId: number, Agreement: boolean): Observable<any> {
    let API_URL = `${environment.apiUrl}${ConstantsService.Common_APIURL.profileDetails.saveAgreement}`;
    return this.http.post(API_URL, { UserId, Agreement });
  }

}
