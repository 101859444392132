import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Stepper from 'bs-stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { JobList } from '../../models/job-list';
import { CommonService } from '../../services/common.service';
import { APIResponse } from '../../models/api-response';
import { ConstantsService } from '../../services/constants.service';
import { DynamicFieldsModel, DynamicModel } from '../../models/dynamic-model';
import { Location } from '@angular/common';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CannedResponse, FileUploadResponse, Role, uploadPhotoFilePath, WorkOrderForm } from '../../models/work-order';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { MethodsForAllRoles } from '../../services/methods-for-all-roles.service';
import { WorkOderModel } from '../../models/workOderModel';

@Component({
  selector: 'app-work-order-manual-pdf',
  templateUrl: './work-order-manual-pdf.component.html',
  styleUrls: ['./work-order-manual-pdf.component.css'],
})
export class WorkOrderManualPdfComponent implements OnInit {
  private stepper: Stepper;
  workOrderForm: FormGroup;
  ContactForm: FormGroup;
  DynamicFieldsList: DynamicFieldsModel[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  @Input() existingRecord;
  @Input() existingWorkOrderDetails: WorkOrderForm;
  @Output() onWorkOrderUpdateResponse: EventEmitter<any>;
  jobListDetails: JobList[] = [];
  workCategoryList: DynamicModel[] = [];
  submitted: boolean = false;
  showLine: boolean = false;
  secondLine: boolean = false;
  selectedJobName: string = '';
  uploadBeforePhotoFilePath: uploadPhotoFilePath[] = [];
  uploadAfterPhotoFilePath: uploadPhotoFilePath[] = [];
  workOrder: WorkOderModel = new WorkOderModel();
  extLat: string | number;
  extLong: string | number;
  minDate: Date;
  maxDate: Date;
  completeByInit: Date;
  cannedResponseList: CannedResponse[] = [];
  UploadedPhotoAfterIdsTemp: string[] = [];
  UploadedPhotoBeforeIdsTemp: string[] = [];
  btnText: string = 'Create Work Order';
  currentUserRole;
  showScheduleDate = false;
  jobId;
  supervisorList: Role[];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private commonService: CommonService,
    private location: Location,
    private methodsForAllRoles: MethodsForAllRoles,

  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: 'theme-red', adaptivePosition: true }
    );
    this.onWorkOrderUpdateResponse = new EventEmitter<any>();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.completeByInit = new Date();
    this.completeByInit.setDate(this.maxDate.getDate() + 30);
  }

  next(stepperValue) {
    this.commonService.setToDisplayBackBtn(true);
    if (stepperValue == 'first') {
      this.submitted = true;
      if (this.workOrderForm.invalid) {
        return;
      }
      // }
      this.showLine = true;
    } else if (stepperValue == 'second') {
      this.secondLine = true;
      this.bindValueInField();
    }

    this.stepper.next();
  }

  previous() {
    this.showLine = this.stepper['_currentIndex'] === 2;
    this.secondLine = this.stepper['_currentIndex'] === 2;
    this.stepper.previous();
  }

  getWorkOrderStatus() {
    this.commonService.workOrderStatusObs.subscribe((res) => {
      this.showScheduleDate = res;
    });
  }

  onSubmit() {
    return false;
  }
  // alive;
  ngOnInit() {
    this.getWorkOrderStatus();
    this.currentUserRole = this.authService.currentLoginUserValue.Role;
    this.btnText = 'Create Work Order';
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });
    this.createForm();
    this.creatContactForm();
    this.getWorkCategoryList();
    if (this.existingRecord) {
      this.btnText = 'Save';
    }
    if (
      this.currentUserRole &&
      this.currentUserRole === ConstantsService.User_Role.Admin
    ) {
      this.getAllJobListDetails();
    } else {
      this.getJobListBySupervisorId();
    }
  }

  getAllJobListDetails() {
    let searchText = '';
    this.commonService
      .getAllJobList(searchText)
      .subscribe((res: APIResponse) => {
        this.jobListDetails = res.Data;
        if (
          this.existingWorkOrderDetails &&
          this.existingWorkOrderDetails.CustomerId
        ) {
          this.getSelectedJobs(this.existingWorkOrderDetails.CustomerId);
        }
      });
  }

  getSupervisorListByJobId() {
    if (this.jobId) {
      this.commonService
        .getSupervisorAndFAListByJobId(this.jobId)
        .subscribe((res: APIResponse) => {
          if (res && res.Data && res.Success) {
            this.supervisorList = res.Data;
          }
        });
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.jobId = event.item.Id;
    this.workOrderForm.patchValue({
      CustomerName: event.item.Name,
      CustomerId: this.jobId,
    });
    this.getSupervisorListByJobId();
  }

  ngOnDestroy() {}

  ngOnChanges() {
    if (this.existingRecord && this.existingWorkOrderDetails) {
      this.btnText = 'Create Work Order';
      this.createForm();
      console.log(
        'ng on changes..child component exting details',
        this.existingRecord
      );
      this.workOrderForm.patchValue({
        Notes:
          this.existingWorkOrderDetails && this.existingWorkOrderDetails.Notes
            ? this.existingWorkOrderDetails.Notes
            : '',
        WorkRequestID:
          this.existingWorkOrderDetails &&
          this.existingWorkOrderDetails.WorkRequestID
            ? this.existingWorkOrderDetails.WorkRequestID
            : '',
        Address:
          this.existingWorkOrderDetails && this.existingWorkOrderDetails.Address
            ? this.existingWorkOrderDetails.Address
            : '',
      });
      this.ContactForm.patchValue({
        ContactFirstName: this.existingWorkOrderDetails.ContactFirstName
          ? this.existingWorkOrderDetails.ContactFirstName
          : '',
        ContactLastName: this.existingWorkOrderDetails.ContactLastName
          ? this.existingWorkOrderDetails.ContactLastName
          : '',
        ContactPhoneNumber: this.existingWorkOrderDetails.ContactPhoneNumber
          ? this.existingWorkOrderDetails.ContactPhoneNumber
          : '',
        ContactEmail: this.existingWorkOrderDetails.ContactEmail
          ? this.existingWorkOrderDetails.ContactEmail
          : '',
        ContactAddress: this.existingWorkOrderDetails.ContactAddress
          ? this.existingWorkOrderDetails.ContactAddress
          : '',
      });
    }
  }

  get f() {
    return this.workOrderForm.controls;
  }

  creatContactForm() {
    this.ContactForm = this.formBuilder.group({
      ContactFirstName: [''],
      ContactLastName: [''],
      ContactPhoneNumber: [''],
      ContactEmail: [''],
      ContactAddress: [''],
    });
  }

  createForm() {
    this.workOrderForm = this.formBuilder.group({
      WorkRequestID: [null, Validators.required],
      CreatedDate: [new Date()],
      CustomerId: [null, Validators.required],
      WorkCategory: [null, Validators.required],
      CompletedBy: [null, Validators.required],
      Address: ['', Validators.required],
      DynamicFields: [''],
      Notes: [''],
      Latitude: [''],
      Longitude: [''],
      CreatedBy: [this.authService.currentLoginUserValue.Id],
      UploadedPhotoBeforeIds: [''],
      UploadedPhotoAfterIds: [''],
      CannedResponseId: [null],
      CompletedAddress: [''],
      notifyCheck: [false],
      StartDate: [new Date()],
      EndDate: [new Date()],
      SuperVisorId: [null],
      CustomerName: [''],
      SaveBtnText: [''],
    });
    this.workOrderForm.controls['CompletedBy'].setValue(this.completeByInit);
  }

  bindValueInField() {
    this.workOrder = this.workOrderForm.value;
  }

  //#region - file upload
  getBeforeFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadBeforePhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
  }

  onChangeOfWorkCategory(selectedWorkCategory) {
    if (!this.existingRecord) {
      this.getCannedResponseList(selectedWorkCategory);
    }
  }

  getWorkCategoryList() {
    this.commonService.getWorkCategoryList().subscribe((res: APIResponse) => {
      this.workCategoryList = res.Data || [];
    });
  }

  // Get canned response from DB
  getCannedResponseList(workCategory) {
    this.commonService
      .getCannedResponseList(workCategory)
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data || [];
      });
  }

  getJobListBySupervisorId() {
    this.commonService
      .getJobListBySupervisor()
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.jobListDetails = res.Data;
        }
      });
  }

  getSelectedJobs(Id) {
    if (Id) {
      let selectedJobs = this.jobListDetails.find((x) => x.Id == Id);
      this.selectedJobName = selectedJobs ? selectedJobs.Name : '';
      if (
        this.authService.currentLoginUserValue.Role ===
        ConstantsService.User_Role.Admin
      ) {
        this.jobId = Id;
        this.workOrderForm.patchValue({
          CustomerName: this.selectedJobName,
        });
        this.getSupervisorListByJobId();
      }
    }
  }

  onAddMoreFieldsResponse(response) {
    this.DynamicFieldsList.push(response);
    this.workOrderForm.patchValue({
      DynamicFields: this.DynamicFieldsList,
    });
  }

  createWorkOrder(btnText?: string) {
    if (this.workOrderForm.invalid) {
      return;
    }
    if (this.existingRecord) {
      this.bindValueInField();
    }
    if (this.workOrderForm.value.UploadedPhotoBeforeIds.length == 0) {
      this.workOrderForm.patchValue({
        UploadedPhotoBeforeIds: '',
        SaveBtnText: btnText ? btnText : '',
      });
    }
    if (this.workOrderForm.value.UploadedPhotoAfterIds.length == 0) {
      this.workOrderForm.patchValue({
        UploadedPhotoAfterIds: '',
      });
    }
    Object.assign(this.workOrderForm.value, this.ContactForm.value);
    this.onWorkOrderUpdateResponse.emit(this.workOrderForm.value);
  }

  gmapResponseUpdate(eventElement) {
    this.workOrderForm.patchValue({
      Latitude: eventElement.lat,
      Longitude: eventElement.long,
    });
  }

  fileUploadResponseBefore(response: FileUploadResponse): void {
    this.UploadedPhotoBeforeIdsTemp.push(response.Data.DocumentID);
    this.workOrderForm.controls['UploadedPhotoBeforeIds'].setValue(
      this.UploadedPhotoBeforeIdsTemp.toString()
    );
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    let tempFilesArr: uploadPhotoFilePath[];
    tempFilesArr = fileUploadResponse ? this.uploadBeforePhotoFilePath.concat(fileUploadResponse) : [];
    this.uploadBeforePhotoFilePath = tempFilesArr;

    if (response && response.photoLatitude) {
      this.extLat = response.photoLatitude;
    }
    if (response && response.photoLongitude) {
      this.extLong = response.photoLongitude;
    }
  }

  fileUploadResponseAfter(response: FileUploadResponse): void {
    this.UploadedPhotoAfterIdsTemp.push(response.Data.DocumentID);
    this.workOrderForm.controls['UploadedPhotoAfterIds'].setValue(
      this.UploadedPhotoAfterIdsTemp.toString()
    );
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    let tempFilesArr: uploadPhotoFilePath[];
    tempFilesArr = fileUploadResponse ? this.uploadAfterPhotoFilePath.concat(fileUploadResponse) : [];
    this.uploadAfterPhotoFilePath = tempFilesArr;

    if (response && response.photoLatitude) {
      this.extLat = response.photoLatitude;
    }
    if (response && response.photoLongitude) {
      this.extLong = response.photoLongitude;
    }
  }

  backToPrevious() {
    this.location.back();
  }
}
