import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css'],
})
export class AutoCompleteComponent implements OnChanges {

  @Input() address: string;
  @Input() set saveInfo(value: any) {
    if(value) {
      this.addValidatorToControl();
    }
  }
  addressForm: FormGroup;
  @ViewChild('places') places: GooglePlaceDirective;
  @Output() onAddressChange = new EventEmitter<any>();
  options = {
    types: [],
    componentRestrictions: { country: 'UA' },
  };
  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  createForm(): void {
    this.addressForm = this.formBuilder.group({
      address: [''],
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes !== undefined) {
      this.addressForm.patchValue({
        address: changes?.address?.currentValue
      })
    }
  }
  get f() {
    return this.addressForm.controls
  }

  public onChange(address: Address) {
    // if (address.photos && address.photos.length > 0) {
    //   console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
    // }
    this.onAddressChange.emit(address);
  }

  onMouseLeave(e) {
    this.emitAddress(e);
  }

  onMouseEnter(e) {
    this.emitAddress(e);
  }

  private emitAddress(e) {
    if (e.target.value) {
      this.onAddressChange.emit(e.target.value);
    }
  }

  addValidatorToControl(): void {
    if (this.addressForm.value.address === '') {
      this.addressForm
        .get('address')
        .setValidators([Validators.required]);
      this.addressForm.get('address').updateValueAndValidity();
    } else {
      this.addressForm.get('address').clearValidators();
    }
  }
}
