import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, } from '@angular/core';
import Stepper from 'bs-stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { JobList } from '../../models/job-list';
import { CommonService } from '../../services/common.service';
import { APIResponse } from '../../models/api-response';
import { DynamicFieldsModel, DynamicModel } from '../../models/dynamic-model';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { ModelComponent } from '../model/model.component';
import { MapsAPILoader } from '@agm/core';
import { environment } from 'src/environments/environment';
import { CannedResponse, FileUploadResponse, uploadPhotoFilePath, WorkOrderDetail, WorkOrderForm } from '../../models/work-order';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { MethodsForAllRoles } from '../../services/methods-for-all-roles.service';
import { WorkOderModel } from '../../models/workOderModel';

@Component({
  selector: 'app-existing-work-order',
  templateUrl: './existing-work-order.component.html',
  styleUrls: ['./existing-work-order.component.css'],
})
export class ExistingWorkOrderComponent implements OnInit {
  apiUrl = environment.apiUrl;
  private stepper: Stepper;
  workOrderForm: FormGroup;
  ContactForm: FormGroup;
  DynamicFieldsList: DynamicFieldsModel[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  @Input() existingRecord;
  @Input() existingWorkOrderDetails: WorkOrderForm;
  @Output() onWorkOrderUpdateResponse: EventEmitter<any>;
  jobListDetails: JobList[] = [];
  workCategoryList: DynamicModel[] = [];
  submitted: boolean = false;
  showLine: boolean = false;
  secondLine: boolean = false;
  selectedJobName: string = '';
  uploadBeforePhotoFilePath: uploadPhotoFilePath[] = [];
  uploadAfterPhotoFilePath: uploadPhotoFilePath[] = [];
  workOrder: WorkOderModel = new WorkOderModel();
  extLat: string | number;
  extLong: string | number;
  minDate: Date;
  maxDate: Date;
  completeByInit: Date;
  cannedResponseList: CannedResponse[] = [];
  UploadedPhotoAfterIdsTemp: string[] = [];
  UploadedPhotoBeforeIdsTemp: string[] = [];
  btnText: string = 'Create Work Order';
  WorkOrderNotesDetail: WorkOrderDetail[] = [];
  showScheduleDate: boolean = false;
  @Input() WorkIdVal;
  @ViewChild(ModelComponent) modalInfo: ModelComponent;

  /**
   * Agm map
   */
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private location: Location,
    private methodsForAllRoles: MethodsForAllRoles,
  ) {
    this.bsConfig = Object.assign(
      {},
      { containerClass: 'theme-red', adaptivePosition: true }
    );
    this.onWorkOrderUpdateResponse = new EventEmitter<any>();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.completeByInit = new Date();
    this.completeByInit.setDate(this.maxDate.getDate() + 30);
  }

  modalResponse(event) {
    if (!event) {
      this.stepper.next();
      this.secondLine = true;
      this.bindValueInField();
    }
  }

  next(stepperValue) {
    this.commonService.setToDisplayBackBtn(true);
    if (stepperValue == 'first') {
      this.submitted = true;
      if (this.workOrderForm.invalid) {
        return;
      }
      this.createWorkOrder();
      this.showLine = true;
      this.stepper.next();
    } else if (stepperValue == 'second') {
      if (this.workOrderForm.value.UploadedPhotoAfterIds.length == 0) {
        let model = {
          header: 'File Upload Confirmation',
          description:
            'Do you want to upload the Completion of work order documents?',
          btnText1: 'Yes',
          btnText2: 'No',
        };
        this.modalInfo.showInfoModal(model);
        return;
      }
      this.secondLine = true;
      this.bindValueInField();
      console.log('second stepper');
      this.stepper.next();
    }
  }

  previous() {
    this.showLine = this.stepper['_currentIndex'] === 2;
    this.secondLine = this.stepper['_currentIndex'] === 2;
    this.stepper.previous();
  }

  onSubmit() {
    return false;
  }
  // alive;
  ngOnInit() {
    this.getWorkOrderStatus();
    this.btnText = 'Create Work Order';
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });
    // Create form
    this.createForm();
    this.creatContactForm();
    this.getWorkCategoryList();
    this.getJobListBySupervisorId();
    if (this.existingRecord) {
      this.getExistingWorkOrder();
      this.btnText = 'Save';
    }
    this.getLocationLatitudeLong();
  }

  private getLocationLatitudeLong() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  setLocationName(event) {
    event.preventDefault();
    if (event.target.value) {
      this.workOrderForm.patchValue({
        Address: event.target.value,
      });
    }
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
            // this.workOrderForm.patchValue({
            //   Address: this.address,
            // });
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  getWorkOrderStatus() {
    this.commonService.workOrderStatusObs.subscribe((res) => {
      this.showScheduleDate = res;
    });
  }

  ngOnChanges() {
    if (this.existingRecord) {
      this.btnText = 'Save';
      this.createForm();
      console.log(
        'ng on changes..child component exting details',
        this.existingRecord
      );
      this.getExistingWorkOrder();
      this.getLocationLatitudeLong();
    }
  }

  get f() {
    return this.workOrderForm.controls;
  }

  creatContactForm() {
    this.ContactForm = this.formBuilder.group({
      ContactFirstName: [''],
      ContactLastName: [''],
      ContactPhoneNumber: [''],
      ContactEmail: [''],
      ContactAddress: [''],
    });
  }

  // Create form
  createForm() {
    this.workOrderForm = this.formBuilder.group({
      WorkRequestID: [null, Validators.required],
      CreatedDate: [new Date()],
      CustomerId: [null, Validators.required],
      WorkCategory: [null, Validators.required],
      CompletedBy: [null, Validators.required],
      CompletedOn: [null],
      Address: ['', Validators.required],
      DynamicFields: [''],
      Notes: [''],
      Latitude: [''],
      Longitude: [''],
      CreatedBy: [this.authService.currentLoginUserValue.Id],
      UploadedPhotoBeforeIds: [''],
      UploadedPhotoAfterIds: [''],
      CannedResponseId: [null],
      CannedResponseText: [null],
      CompletedAddress: [''],
      StartDate: [new Date()],
      EndDate: [new Date()],
      SaveBtnText: [''],
    });
    this.workOrderForm.controls['CompletedBy'].setValue(this.completeByInit);
  }
  // For preview page
  bindValueInField() {
    this.workOrder = this.workOrderForm.value;
  }

  // For existing records
  getExistingWorkOrder() {
    if (this.existingWorkOrderDetails) {
      this.workOrderForm.patchValue({
        CompletedBy: moment(this.existingWorkOrderDetails.CompletedBy).format(
          'MM/DD/YYYY'
        ),
        CompletedOn:
          this.existingWorkOrderDetails.CompletedOn != null
            ? moment(this.existingWorkOrderDetails.CompletedOn).format(
                'MM/DD/YYYY'
              )
            : null,
        WorkRequestID: this.existingWorkOrderDetails.WorkRequestID,
        CreatedDate: this.existingWorkOrderDetails.CreatedDate,
        CustomerId: this.existingWorkOrderDetails.CustomerId,
        WorkCategory: this.existingWorkOrderDetails.WorkCategory,
        Address: this.existingWorkOrderDetails.Address,
        Notes: this.existingWorkOrderDetails.Notes,
        CreatedBy: this.authService.currentLoginUserValue.Id,
        UploadedPhotoAfterIds: this.existingWorkOrderDetails
          .UploadedPhotoAfterIds
          ? this.existingWorkOrderDetails.UploadedPhotoAfterIds
          : [],
        UploadedPhotoBeforeIds: this.existingWorkOrderDetails
          .UploadedPhotoBeforeIds
          ? this.existingWorkOrderDetails.UploadedPhotoBeforeIds
          : [],
        DynamicFields: this.existingWorkOrderDetails.DynamicFields,
        Latitude: this.existingWorkOrderDetails.Latitude,
        Longitude: this.existingWorkOrderDetails.Longitude,
        CannedResponseId: this.existingWorkOrderDetails.CannedResponseId
          ? this.existingWorkOrderDetails.CannedResponseId
          : null,
        CompletedAddress: this.existingWorkOrderDetails.CompletedAddress
          ? this.existingWorkOrderDetails.CompletedAddress
          : '',
        CannedResponseText: this.existingWorkOrderDetails.CannedResponseText
          ? this.existingWorkOrderDetails.CannedResponseText
          : '',
      });
      this.ContactForm.patchValue({
        ContactFirstName: this.existingWorkOrderDetails.ContactFirstName
          ? this.existingWorkOrderDetails.ContactFirstName
          : '',
        ContactLastName: this.existingWorkOrderDetails.ContactLastName
          ? this.existingWorkOrderDetails.ContactLastName
          : '',
        ContactPhoneNumber: this.existingWorkOrderDetails.ContactPhoneNumber
          ? this.existingWorkOrderDetails.ContactPhoneNumber
          : '',
        ContactEmail: this.existingWorkOrderDetails.ContactEmail
          ? this.existingWorkOrderDetails.ContactEmail
          : '',
        ContactAddress: this.existingWorkOrderDetails.ContactAddress
          ? this.existingWorkOrderDetails.ContactAddress
          : '',
      });
      this.WorkOrderNotesDetail =
        this.existingWorkOrderDetails.WorkOrderNotesDetail || [];
      this.extLat = this.existingWorkOrderDetails.Latitude;
      this.extLong = this.existingWorkOrderDetails.Longitude;
      this.DynamicFieldsList = this.existingWorkOrderDetails.DynamicFields;
      this.getBeforeFile(this.existingWorkOrderDetails.UploadedDocPathsBefore);
      this.getAfterFile(this.existingWorkOrderDetails.UploadedDocPathsAfter);
      // if(this.existingWorkOrderDetails.UploadedPhotoAfterIds) {
      //   this.UploadedPhotoAfterIdsTemp.push(this.existingWorkOrderDetails.UploadedPhotoAfterIds);
      // }
      this.existingWorkOrderDetails.UploadedPhotoBeforeIds
        ? this.UploadedPhotoBeforeIdsTemp.push(
            this.existingWorkOrderDetails.UploadedPhotoBeforeIds
          )
        : (this.UploadedPhotoBeforeIdsTemp = []);
      this.existingWorkOrderDetails.UploadedPhotoAfterIds
        ? this.UploadedPhotoAfterIdsTemp.push(
            this.existingWorkOrderDetails.UploadedPhotoAfterIds
          )
        : (this.UploadedPhotoAfterIdsTemp = []);
      this.getCannedResponseList(this.existingWorkOrderDetails.WorkCategory);
    }
  }
  getBeforeFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadBeforePhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
  }
  getAfterFile(fileArray) {
    fileArray.forEach((element) => {
      let fileExtension = element.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadAfterPhotoFilePath.push({
        filePath: element,
        extension: fileExtension,
      });
    });
  }
  //#region - On Change of work category
  onChangeOfWorkCategory(selectedWorkCategory) {
    if (this.existingRecord) {
      this.getCannedResponseList(selectedWorkCategory);
    }
  }
  //#endregion

  //#region - Master details
  getWorkCategoryList() {
    this.commonService.getWorkCategoryList().subscribe((res: APIResponse) => {
      this.workCategoryList = res.Data || [];
    });
  }

  // Get canned response from DB
  getCannedResponseList(workCategory) {
    this.commonService
      .getCannedResponseList(workCategory)
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data || [];
      });
  }

  getJobListBySupervisorId() {
    this.commonService
      .getJobListBySupervisor()
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.jobListDetails = res.Data;
        }
      });
  }
  getSelectedJobs(Id) {
    if (Id) {
      let selectedJobs = this.jobListDetails.find((x) => x.Id == Id);
      this.selectedJobName = selectedJobs ? selectedJobs.Name : '';
    }
  }
  //#endregion

  //#region - Add more fields
  onAddMoreFieldsResponse(response) {
    this.DynamicFieldsList.push(response);
    this.workOrderForm.patchValue({
      DynamicFields: this.DynamicFieldsList,
    });
  }
  //#endregion

  //#region - work order form
  createWorkOrder(btnText?: string) {
    this.submitted = true;
    if (this.workOrderForm.invalid) {
      return;
    }

    if (this.existingRecord) {
      this.bindValueInField();
    }

    this.workOrderForm.patchValue({
      EndDate: this.workOrderForm.value.CompletedBy,
      SaveBtnText: btnText ? btnText : '',
    });
    if (this.workOrderForm.value.UploadedPhotoBeforeIds.length == 0) {
      this.workOrderForm.patchValue({
        UploadedPhotoBeforeIds: '',
      });
    }
    if (this.workOrderForm.value.UploadedPhotoAfterIds.length == 0) {
      this.workOrderForm.patchValue({
        UploadedPhotoAfterIds: '',
      });
    }
    Object.assign(this.workOrderForm.value, this.ContactForm.value);
    this.onWorkOrderUpdateResponse.emit(this.workOrderForm.value);
  }
  //#endregion

  //#region - On Gmap select /drag/marked
  gmapResponseUpdate(eventElement) {
    this.workOrderForm.patchValue({
      Latitude: eventElement.lat,
      Longitude: eventElement.long,
    });
  }

  //#region - File Upload response
  fileUploadResponseBefore(response: FileUploadResponse): void {
    this.UploadedPhotoBeforeIdsTemp.push(response.Data.DocumentID);
    this.workOrderForm.controls['UploadedPhotoBeforeIds'].setValue(
      this.UploadedPhotoBeforeIdsTemp.toString()
    );
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    fileUploadResponse ? this.uploadBeforePhotoFilePath = [...fileUploadResponse] : '';

    if (response && response.photoLatitude) {
      this.extLat = response.photoLatitude;
    }
    if (response && response.photoLongitude) {
      this.extLong = response.photoLongitude;
    }

  }
  fileUploadResponseAfter(response: FileUploadResponse): void {
    this.UploadedPhotoAfterIdsTemp.push(response.Data.DocumentID);
    this.workOrderForm.controls['UploadedPhotoAfterIds'].setValue(
      this.UploadedPhotoAfterIdsTemp.toString()
    );
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    fileUploadResponse ? this.uploadAfterPhotoFilePath = [...fileUploadResponse] : '';

    if (response && response.photoLatitude) {
      this.extLat = response.photoLatitude;
    }
    if (response && response.photoLongitude) {
      this.extLong = response.photoLongitude;
    }
  }
  //#endregion

  backToPrevious() {
    this.location.back();
  }

  CannedResponseName;

  getCannedResponseText(cannedId) {
    let cannedResponse = this.cannedResponseList.find(
      (item) => item.ID == cannedId.split(':')[0]
    );
    this.CannedResponseName = cannedResponse.Text;
    this.commonService.setStatusTextSubjectMethod(true);
  }
}
