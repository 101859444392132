import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AuthenticationService } from './core/helpers/services/authentication.service';
import { User } from './shared/models/user';
import { ConstantsService } from './shared/services/constants.service';
import { CommonService } from './shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';
import { CookiesService } from './shared/services/cookies.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  currentUser: User;
  hideActiveClass: boolean;
  notAccepted = false;
  modalRef: BsModalRef;
  @ViewChild('agreement') agreement: TemplateRef<any>;

  constructor(
    private cookiesService: CookiesService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    this.authenticationService.currentUser.subscribe((value: User) => {
      if (value !== null) {
        this.notAccepted = !this.isAcceptedAgreement(value);
        this.currentUser = value;
        if (this.currentUser && this.currentUser) {
          let lang = this.currentUser.LanguageName === 'English' ? 'en' : 'sp';
          translate.setDefaultLang(lang);
        }
      }
    });
    this.commonService.toggleSidebarValue.subscribe((value) => {
      this.hideActiveClass = value;
    });
  }

  get isUserActive() {
    return (
      this.currentUser &&
      this.currentUser.Role &&
      (this.currentUser.Role === ConstantsService.User_Role.SuperVisor ||
        this.currentUser.Role === ConstantsService.User_Role.FieldAgent ||
        this.currentUser.Role === ConstantsService.User_Role.Admin ||
        this.currentUser.Role === ConstantsService.User_Role.CommunityManager)
    );
  }

  get isCommunityManager() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.CommunityManager
    );
  }

  acceptAgreement() {
    this.authenticationService.saveAgreement(this.currentUser.Id, true)
      .subscribe(() => {
        this.notAccepted = !this.notAccepted;
        this.currentUser.Agreement = true;
        localStorage.setItem(environment.CUID, JSON.stringify(this.currentUser));
      });
  }

  isAcceptedAgreement(value: User): boolean {
    if (value !== null && value !== undefined) {
      return value.Agreement;
    }
    return true;
  }
}
