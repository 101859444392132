import { MethodsForAllRoles } from '../../../shared/services/methods-for-all-roles.service';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { LoaderService } from '../../helpers/services/loader.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIResponse } from 'src/app/shared/models/api-response';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';
import { Job, User } from 'src/app/shared/models/user';
import { takeUntil } from 'rxjs/operators';
import { role } from 'src/utils';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Role } from '../../../shared/models/role';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit, OnDestroy {
  photoURL: string;
  profileForm: FormGroup;
  submitted = false;
  show: boolean;
  accept: string;
  modalRef: BsModalRef;
  message: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('template') template: TemplateRef<any>;
  multipleImage = false;
  currentUser: User;
  role: Role;
  jobList: Job[] = [];

  constructor(
    private fbBuilder: FormBuilder,
    private commonService: CommonService,
    private uploadService: UploadService,
    private loaderService: LoaderService,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService,
    private title: Title,
    private methodsForAllRoles: MethodsForAllRoles
  ) {
    this.show = false;
    this.accept = '.png,.jpg,.jpeg';
    this.authenticationService.currentUser.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.currentUser = value;
      this.title.setTitle(`OCLM - ${role(this.currentUser?.Role)} - Profile`);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.profileForm.controls;
  }

  ngOnInit(): void {
    this.methodsForAllRoles.setHeaderTitleInSession('Profile', 'Perfil');
    this.getProfile();
    this.createForm();
    this.checkValidity();
  }

  createForm() {
    this.profileForm = this.fbBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Password: [''],
      PhoneNumber: [''],
      Address: [''],
      ProfileId: [''],
      DocId: [''],
      DocPath: ['']
    });
  }

  getProfile() {
    this.loaderService.isLoading.next(true);
    this.commonService.getProfileByUserId().pipe(takeUntil(this.destroy$)).subscribe(
      (res: APIResponse) => {
        if (res && res.Data) {
          this.jobList = res.Data.Jobs;
          this.profileForm.patchValue(res.Data);
          this.photoURL = res.Data.DocPath;
          this.loaderService.isLoading.next(false);
        }
      },
      (err) => this.loaderService.isLoading.next(false)
    );
  }

  checkValidity(): void {
    this.submitted = true;
    this.profileForm
      .get('PhoneNumber')
      .setValidators([Validators.required,
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/)
      ]);
    this.profileForm.get('PhoneNumber').updateValueAndValidity();
  }

  onSave() {
    this.checkValidity();
    if (this.profileForm.invalid) {
      return;
    }
    this.loaderService.isLoading.next(true);
    this.commonService.updateProfileDetails(this.profileForm.value).pipe(takeUntil(this.destroy$)).subscribe(
      (res: APIResponse) => {
        if (res && res.Success) {
          const user = res.Data;
          localStorage.setItem(environment.CUID, JSON.stringify(user));

          this.message = ConstantsService.SuccessMessage.ProfileUpdateSuccess;
          this.modalRef = this.modalService.show(this.template, {
            class: 'modal-md'
          });
          this.commonService.changeStausProfile(true);
          this.getProfile();
        } else {
          this.loaderService.isLoading.next(false);
          this.message = ConstantsService.ErrorMessage.SomeThingWrongMessage;
          this.modalRef = this.modalService.show(this.template, {
            class: 'modal-md'
          });
        }
      },
      (err) => this.loaderService.isLoading.next(false)
    );
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.loaderService.isLoading.next(true);
      let file = event.target.files[0];

      if (this.isFileTypeAllowed(file)) {
        let formData = new FormData();
        formData.append(
          'file',
          event.target.files[0],
          event.target.files[0].name
        );
        this.uploadService.upload(formData).subscribe(
          (res) => {
            if (
              res.StatusCode === 200 &&
              res.Success &&
              res.Message === 'Success' &&
              res.Data
            ) {
              this.loaderService.isLoading.next(false);
              this.profileForm.patchValue({
                DocId: res.Data.DocumentID
              });
              this.message = ConstantsService.SuccessMessage.FileUploadSuccess;
              this.modalRef = this.modalService.show(this.template, {
                class: 'modal-md'
              });
            }
          },
          (err) => {
            this.loaderService.isLoading.next(false);
            this.message = ConstantsService.ErrorMessage.FileUploadFailed;
            this.modalRef = this.modalService.show(this.template, {
              class: 'modal-md'
            });
          }
        );
      }
    }
  }

  /**
   * Function to check if file type is allowed or not
   * @param file: Input file object
   * @returns: true (fle type is allowed)/false (file type is not allowed)
   */
  isFileTypeAllowed(file): boolean {
    let fileExtension = file.name.split('.');
    fileExtension = fileExtension[fileExtension.length - 1];
    if (
      (file.type &&
        (-1 !== this.accept.indexOf(file.type.split('/')[0]) ||
          -1 !== this.accept.indexOf(file.type.split('/')[1]))) ||
      -1 !== this.accept.indexOf(fileExtension)
    ) {
      return true;
    } else {
      this.message = ConstantsService.ErrorMessage.FileUploadExtension;
      this.modalRef = this.modalService.show(this.template, {
        class: 'modal-md'
      });

      return false;
    }
  }

  confirm(): void {
    this.modalRef.hide();
  }

  password() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
