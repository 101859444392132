import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DatepickerCustomHeaderComponent} from './datepicker-custom-header/datepicker-custom-header.component';
import {DatepickerDateAdapter, MY_DATE_FORMATS} from './datepicker-date-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('false', style({transform: 'rotate(0)'})),
      state('true', style({transform: 'rotate(-180deg)'})),
      transition('true => false', animate('200ms ease-out')),
      transition('false => true', animate('200ms ease-in'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: DateAdapter, useClass: DatepickerDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ]
})
export class DatepickerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  public dateToSet: Date = new Date();

  @Output()
  public emitDateSelected: EventEmitter<Date> = new EventEmitter<Date>();

  @ViewChild('picker', {read: undefined, static: false})
  public picker: MatDatepicker<Date>;

  public customHeader = DatepickerCustomHeaderComponent;

  public date = new FormControl(this.dateToSet);

  constructor() {
  }

  ngOnInit(): void {
    this.date.valueChanges.subscribe(value => {
      this.emitDateSelected.emit(value);
    });
  }

  ngOnChanges(): void {
    this.setDefaultDate();
  }

  ngAfterViewInit(): void {
    this.setDefaultDate();
  }

  private setDefaultDate(): void {
    if (this.dateToSet && this.picker) {
      this.picker.startAt = this.dateToSet;
      this.date.setValue(this.dateToSet);
    }
  }

  public openPicker(): void {
    this.picker.open();
  }
}
