import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {MatDateRangePicker} from '@angular/material/datepicker';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DatepickerCustomHeaderComponent} from '../datepicker/datepicker-custom-header/datepicker-custom-header.component';
import {DatepickerDateAdapter, MY_DATE_FORMATS} from '../datepicker/datepicker-date-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {FormControl} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {DatePipe} from '@angular/common';

type DropDownSource = 'start' | 'end';

type DateEmit = { dateFrom: Date; dateTo: Date };

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('false', style({transform: 'rotate(0)'})),
      state('true', style({transform: 'rotate(-180deg)'})),
      transition('true => false', animate('200ms ease-out')),
      transition('false => true', animate('200ms ease-in'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: DateAdapter, useClass: DatepickerDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ]
})
export class DatepickerRangeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  public datesToSet: DateEmit | null = null;

  @Output()
  public emitDateSelected: EventEmitter<DateEmit> = new EventEmitter<DateEmit>();

  @ViewChild('picker', {read: undefined, static: false})
  public picker: MatDateRangePicker<Date>;

  @ViewChild('rangeInput') public rangeInput: ElementRef;

  @ViewChild('dateStartContainer') public dateStartContainer: ElementRef;

  @ViewChild('dateEndContainer') public dateEndContainer: ElementRef;

  public customHeader = DatepickerCustomHeaderComponent;

  public matDateStart: FormControl = new FormControl({value: new Date(), disabled: true});

  public matDateEnd: FormControl = new FormControl({value: new Date(), disabled: true});

  public dateStart: FormControl = new FormControl({value: '', disabled: true});

  public dateEnd: FormControl = new FormControl({value: '', disabled: true});

  public popupsOpened: Map<DropDownSource, boolean> = new Map<DropDownSource, boolean>();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    const matDateStartSubs$ = this.matDateStart.valueChanges;
    const matDateEndSubs$ = this.matDateEnd.valueChanges;

    const format = 'EEEE d MMMM y';

    combineLatest([matDateStartSubs$, matDateEndSubs$]).subscribe(result => {
      const dateStartFormatted = result[0] ? new DatePipe(this.locale).transform(result[0], format).toLocaleLowerCase() : null;
      const dateEndFormatted = result[1] ? new DatePipe(this.locale).transform(result[1], format).toLocaleLowerCase() : null;
      this.dateStart.setValue(dateStartFormatted);
      this.dateEnd.setValue(dateEndFormatted);
      this.emitDateSelected.emit({dateFrom: result[0], dateTo: result[1]});
    });
  }

  ngAfterViewInit(): void {
    this.picker.closedStream.subscribe(result => {
      this.popupsOpened.set('start', false);
      this.popupsOpened.set('end', false);
    });
  }

  ngOnChanges(): void {
    if (this.datesToSet) {
      this.matDateStart.setValue(this.datesToSet?.dateFrom);
      this.matDateEnd.setValue(this.datesToSet?.dateTo);
    }
  }

  public openPicker(source: DropDownSource): void {
    const endDateLeftOffset = this.dateEndContainer.nativeElement.offsetLeft;
    const startDateLeftOffset = this.dateStartContainer.nativeElement.offsetLeft;
    let offset;
    if (source === 'start') {
      offset = startDateLeftOffset;
    } else if (source === 'end') {
      offset = endDateLeftOffset;
    } else {
      offset = 0;
    }
    this.renderer.setStyle(this.rangeInput.nativeElement, 'left', offset + 'px');
    this.popupsOpened.set(source, true);
    this.picker.open();
  }
}
