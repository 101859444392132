<div class="container" matRipple matRippleColor="#1d58a8">
  <input
    type="text"
    class="container__input"
    matDatepicker="picker"
    formControl="date"
  />
  <div class="container__close-icon" (click)="openPicker()">
    <svg-icon
      [@rotatedState]='this.picker.opened'
      class="icon"
      src="../../../../../assets/icons/arrow-down.svg"
    ></svg-icon>
  </div>
  <mat-datepicker
    #picker
    panelClass="'picker-container'"
    [calendarHeaderComponent]="customHeader"
    disabled="false"
  ></mat-datepicker>
</div>

