<div
  class="container"
  [class.disabled]="disabled"
  [class.outline]="outline"
  #dropdownControl
  (click)="openDropdown()"
  matRipple
  matRippleColor="#1d58a8"
>
  <div class="container__value">
    <span [class.placeholder]="!value" class="notranslate">{{ value || placeholder }}</span>
  </div>
  <div class="container__arrow-icon">
    <svg-icon [@rotatedState]='isDropdownOpened' class="icon" src="assets/icons/shared-controls/arrow-down.svg"></svg-icon>
  </div>
</div>
