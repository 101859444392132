import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MainUsersComponent } from './main-users.component';
import { EditUserComponent } from './shared/components/edit-user/edit-user.component';
import { CreateUserComponent } from './shared/components/create-user/create-user.component';

export const routes: Routes = [
  { path: '', component: MainUsersComponent },
  { path: 'create', component: CreateUserComponent},
  { path: ':id', component: EditUserComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
})
export class UsersRoutingModule {}
