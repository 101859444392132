import { Component } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  currentUser: User;
  sidebarActive: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router
  ) {
    this.authenticationService.currentUser.subscribe(
      (value) => (this.currentUser = value)
    );
    this.commonService.toggleSidebarValue.subscribe(
      (value) => (this.sidebarActive = value)
    );
  }

  get isSupervisor() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.SuperVisor
    );
  }

  get isFieldAgent() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.FieldAgent
    );
  }

  get isCommunityManager() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.CommunityManager
    );
  }

  get isAdmin() {
    return (
      this.currentUser &&
      this.currentUser.Role === ConstantsService.User_Role.Admin
    );
  }

  headerTileChange(Title: string) {
    this.commonService.setToDisplayBackBtn(false);
    this.commonService.setToggleSidebar(false);
    this.commonService.setCurrentPaginationValue({
      pageNumber: 1
    });
    localStorage.setItem(environment.headerTitle, Title);
    this.commonService.headerTitle$.next(Title === 'Profile');

  }

  logout() {
    this.commonService.setToDisplayBackBtn(false);
    this.authenticationService.logout();
    this.authenticationService.setCurrentLoginUser();
    this.commonService.navigateByURL('');
  }

  onHomePage() {
    if (this.currentUser.Role) {
      this.router.navigateByUrl(
        `${this.currentUser.Role.toLocaleLowerCase()}/home/details`
      );
    }
  }
}
