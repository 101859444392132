import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadUserComponent } from './head-user/head-user.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PaginationModule,PaginationConfig} from 'ngx-bootstrap/pagination';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UsersListComponent } from './users-list/users-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditUserComponent } from './edit-user/edit-user.component';
import {RouterModule} from '@angular/router';
import { CreateUserComponent } from './create-user/create-user.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SortTableDirective } from '../directives/sort-table.directive';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from '../../../../../shared/shared.module';


@NgModule({
  declarations: [
    HeadUserComponent,
    UsersListComponent,
    EditUserComponent,
    CreateUserComponent,
    SortTableDirective

  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    PaginationModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  providers:[PaginationConfig],
  exports: [HeadUserComponent, UsersListComponent,SortTableDirective ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
