import { Component, ElementRef, forwardRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { OverlayService } from '../overlay/overlay.service';
import { DropdownControlOptionsListComponent } from './dropdown-control-options-list/dropdown-control-options-list.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface DropdownValue { name: string; value: number | string; }

@Component({
  selector: 'app-dropdown-control',
  templateUrl: './dropdown-control.component.html',
  styleUrls: ['./dropdown-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownControlComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('rotatedState', [
      state('false', style({ transform: 'rotate(0)' })),
      state('true', style({ transform: 'rotate(-180deg)' })),
      transition('true => false', animate('200ms ease-out')),
      transition('false => true', animate('200ms ease-in'))
    ])
  ]
})
export class DropdownControlComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input()
  public placeholder: string = '';

  @Input()
  public outline: boolean = false;
  @Input()
  multiple: boolean;

  @Input()
  public options: { name: string; value: number | string; }[] = [];

  @Input()
  public disabled: boolean = false;

  @ViewChild('dropdownControl', { read: ElementRef })
  public dropdownControl: ElementRef;

  @Input()
  public numberOfItemsToDisplay: number = 7;

  public isDropdownOpened: boolean = false;
  public backdropClick: boolean = false;

  private popoverRef: any;

  private onDestroy: Subject<void> = new Subject<void>();

  private inputValue: DropdownValue;

  private onChange: (_: number | string) => void = () => null;

  private onTouched: () => void = () => null;

  get value(): string {
    return this.inputValue?.name;
  }

  constructor(
    private popoverOverlayService: OverlayService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    const value = this.options && this.options[0] ? this.options[0].value : 0;
    this.writeValue(value);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public writeValue(value: number | string): void {
    const filter = this.options.find(item => item.value === value);
    this.onTouched();
    const valueChanged = filter !== this.inputValue;
    if (valueChanged) {
      this.inputValue = filter;
      this.onChange(filter?.value);
    }
    if (this.isDropdownOpened) this.isDropdownOpened = false;
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public openDropdown(): void {
    if (this.disabled || this.backdropClick) {
      return;
    }

    if (this.isDropdownOpened) {
      this.isDropdownOpened = false;
      return;
    }

    this.popoverRef = this.popoverOverlayService.open({
      origin: this.dropdownControl,
      content: DropdownControlOptionsListComponent,
      data: {
        data: this.options,
        numberOfItemsToDisplay: this.numberOfItemsToDisplay,
        activeItemValue: this.value,
      },
      width: this.dropdownControl.nativeElement.clientWidth,
      positions: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ],
    });
    this.isDropdownOpened = true;

    this.popoverRef.afterClosed$.subscribe((res) => {
      if (res && res.data && res.type === 'close') {
        this.writeValue(res.data.value);
      }
      if (res.type === 'backdropClick') {
        if (this.isDropdownOpened) {
          this.isDropdownOpened = false;
          this.backdropClick = true;
          setTimeout(() => this.backdropClick = false);
        }
      }
    });
  }
}
