import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-agreement-popup',
  templateUrl: './agreement-popup.component.html',
  styleUrls: ['./agreement-popup.component.scss']
})
export class AgreementPopupComponent implements OnInit {

  constructor() {
  }
  @Input('showAgreeButton') showAgreeButton = true;
  @Input('showCloseButton') showCloseButton = false;
  @Output('acceptAgreement') acceptAgreement = new EventEmitter<boolean>();
  @Output('closeAgreement') closeAgreement = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  accept() {
    this.acceptAgreement.emit(true);
  }

  close() {
    this.closeAgreement.emit(true);
  }
}
