import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {PopoverRef} from '../overlay/overlay.ref';

@Component({
  selector: 'app-table-columns-selection-dialog',
  templateUrl: './table-columns-selection-dialog.component.html',
  styleUrls: ['./table-columns-selection-dialog.component.scss']
})
export class TableColumnsSelectionDialogComponent implements OnInit {

  public data: { column: string; header: string; hidden: boolean }[] = [];

  public form = new FormGroup({});

  constructor(
    private popoverRef: PopoverRef,
  ) {
    this.popoverRef.overlay.outsidePointerEvents().subscribe(() => {
      this.popoverRef.close('backdropClick', {});
    });
    this.data = this.popoverRef.data.filter(item => item.column !== 'select' && item.column !== 'action');
    this.data.forEach(item => {
      this.form.addControl(item.column, new FormControl(!item.hidden));
    });
  }

  ngOnInit(): void {
  }

  public save(): void {
    this.data = this.data.map(item => {
      return {
        column: item.column,
        hidden: item.hidden = !this.form.get(item.column).value,
        header: item.header,
      };
    });
    this.popoverRef.close('close', this.data);
  }

  public cancel(): void {
    this.popoverRef.close('close', {});
  }
}
