import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { forkJoin, Subject } from 'rxjs';
import { Role } from 'src/app/shared/models/role';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { ValidationForms } from '../../../../../helpers/services/validation-forms';
import { forms_errors } from '../../../../../../shared/constants/message-validators';
import { Job } from '../../../../../../shared/models/user';
import { MatOptionSelectionChange } from '@angular/material/core';
import { customEmailValidator } from '../../../../../../shared/utils/custom-form-validators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy {
  private selectedJobs = [];

  public userform: FormGroup;
  public roleList: Role[] = [];
  public workList: Job[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('templateDelete') templateDelete: TemplateRef<any>;
  @ViewChild('templateChange') templateChange: TemplateRef<any>;
  public selecterUserName: string;
  public modalRefDelete: BsModalRef;
  public modalRefClose: BsModalRef;
  public forms_errors = forms_errors;
  private userId: number;

  constructor(
    private location: Location,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private userService: UserService,
    private modalService: BsModalService,
    private router: Router,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.getParamsRoute();
    this.initForm();
    this.getAllDataForForm();
  }

  getParamsRoute() {
    this.route.paramMap
      .pipe(
        switchMap((params) => this.userService.getUserById(+params.get('id')))
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data['StatusCode'] === 200) {
          if ( data['Data']['Jobs'] !== null) {
            this.selectedJobs = data['Data']['Jobs'];
            this.initForm(this.selectedJobs)
          }
          this.userform.patchValue({
            firstname: data['Data']['FirstName'],
            lastname: data['Data']['LastName'],
            role: data['Data']['Role'],
            phone: data['Data']['Phone'],
            email: data['Data']['Email']
            // jobs: data['Data']['Jobs']
          });
        }
        this.subscribeToFormValueChanges();
      });
  }

  blurForms() {
    return ValidationForms(this.userform);
  }

  getAllDataForForm() {
    let roledata$ = this.commonService.getUserTypeList();
    let workcategory$ = this.commonService.getAllJobList('');
    forkJoin(roledata$, workcategory$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([role, work]: any) => {
        this.roleList = role;
        this.workList = work['Data'];
      });
  }

  backToTheList() {
    this.location.back();
  }

  // resetPassword() {
  //   this.router.navigate(['/forgot-password']);
  // }

  deleteUser(template: TemplateRef<any>) {
    this.modalRefDelete = this.modalService.show(template);
  }

  clickOk() {
    this.userService
      .blockUser(this.userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.modalRefDelete.hide();
        if (res['StatusCode'] === 200) {
          this.modalRefClose = this.modalService.show(this.templateDelete);
        }
      });
  }

  onSubmit(form: FormGroup) {
    if (this.selectedJobs.length) {
      this.userform.patchValue({
        jobs: this.selectedJobs
      });
    }
    this.userService
      .updateUser(this.userId, form)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data['StatusCode'] === 200) {
          this.modalRefClose = this.modalService.show(this.templateChange);
        }
      });
  }


  closeModalAndRedirect() {
    this.modalRefClose.hide();
    this.router.navigateByUrl('/users');
  }

  jobListChange(job: Job, e: MatOptionSelectionChange) {
    if (e.isUserInput) {
      e.source.selected && this.selectedJobs.push(job);
      if(!e.source.selected) {
        this.selectedJobs = this.selectedJobs.filter((item) => {
          return item.Id !== job.Id
        });
      }

    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initForm(selectedItems: Job[] = []) {
    const selectedIds: number[] = selectedItems.map((item: Job) => item.Id);
    this.userform = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      role: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, customEmailValidator()]],
      jobs: [selectedIds, Validators.required]
      // vendor: new FormControl('', Validators.required),
      // punch: new FormControl('', Validators.required),
    });
  }

  private subscribeToFormValueChanges(): void {
    this.userId = +this.route.snapshot.paramMap.get('id');
    this.userform.valueChanges.subscribe(() => {
      ValidationForms(this.userform);
    });
  }
}
