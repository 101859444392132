import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
@Injectable()
export class MessagingService {
  currentMessageSubject = new BehaviorSubject(null);
  currentMessage = this.currentMessageSubject.asObservable();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private commonService: CommonService
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (browserToken) => {
        // Once user will token- Save as it for logged In Device ID
        localStorage.setItem(
          environment.CurrentBrowserDeviceId,
          browserToken
        );
        // Mapping the current browser Id to logged in user
        this.commonService
          .updateLoggedInUserbyBrowserId(browserToken)
          .subscribe((res) => {
          });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessageSubject.next(payload);
    });
  }
}
