<div>
  <div (mouseleave)="showDropDown = false">
    <button class="drop-toggle btn flat" (click)="showDropDown = !showDropDown">
      <span style="font-weight: 600" *ngIf="selectName.length <= 0">{{
        labelText
      }}</span>
      <span *ngIf="selectName.length > 0"
        >{{ selectName[0].substring(0, 9).concat('...') }}
      </span>
      <span *ngIf="lengthSelectedName > 1">+ {{ lengthSelectedName }}</span>
      <i class="fa fa-angle-down"></i>
    </button>
    <div class="drop-show" *ngIf="showDropDown">
      <label
        *ngFor="let a of multiSelectData | sortBy"
        class="custom-control custom-checkbox">
        <input
          type="checkbox"
          [(ngModel)]="a.checked"
          (ngModelChange)="getSelectedValue(a.checked, a.Id, a.Name)"
          class="custom-control-input"/>
        <span class="custom-control-label">{{ a.Name }}</span>
      </label>
    </div>
  </div>
</div>
