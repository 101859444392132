<div class="wrp-map-stpr">
  <div id="stepper1" class="bs-stepper">
    <div class="bs-stepper-header">
      <div class="step activeted" data-target="#test-l-1">
        <button class="btn step-trigger">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">{{
            'BtnText.ContactInfo' | translate
          }}</span>
        </button>
      </div>
      <div class="line" [ngClass]="{ 'red-line': firstLine }"></div>
      <div
        class="step"
        [ngClass]="{ 'activeted ': firstLine }"
        data-target="#test-l-2"
      >
        <button class="btn step-trigger">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">{{
            'BtnText.WorkOrderDetails' | translate
          }}</span>
        </button>
      </div>
      <div class="line" [ngClass]="{ 'red-line': secondLine }"></div>
      <div
        class="step"
        [ngClass]="{ active: secondLine }"
        data-target="#test-l-3"
      >
        <button class="btn step-trigger">
          <span class="bs-stepper-circle"></span>
          <span class="bs-stepper-label">{{
            'BtnText.UploadPictures' | translate
          }}</span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content">
      <div id="test-l-1" class="content">
        <div class="card card-body">
          <div class="first-form">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>{{ 'CommonLabel.FirstName' | translate }}</label>
                  <div class="wrp">
                    <input
                      readonly
                      [(ngModel)]="taskOrderList.FirstName"
                      type="text"
                      placeholder="{{ 'CommonLabel.FirstName' | translate }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/user-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>{{ 'CommonLabel.LastName' | translate }}</label>
                  <div class="wrp">
                    <input
                      readonly
                      [(ngModel)]="taskOrderList.LastName"
                      type="text"
                      placeholder="{{ 'CommonLabel.LastName' | translate }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/user-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>{{ 'CommonLabel.PhoneNumber' | translate }}</label>
                  <div class="wrp">
                    <input
                      readonly
                      [(ngModel)]="taskOrderList.PhoneNumber"
                      type="text"
                      placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
                      class="form-control"
                    />
                    <img alt="icon"
                         src="../../../../assets/image/phone-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>{{ 'CommonLabel.Email' | translate }}</label>
                  <div class="wrp">
                    <input
                      readonly
                      [(ngModel)]="taskOrderList.ContactEmail"
                      type="email"
                      placeholder="{{ 'CommonLabel.Email' | translate }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/inbox-icon-2.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label>{{ 'CommonLabel.Address' | translate }}</label>
                  <div class="wrp">
                    <textarea
                      readonly
                      [(ngModel)]="taskOrderList.Address"
                      rows="1"
                      required
                      class="form-control"
                    ></textarea>
                    <img
                      src="../../../../../assets/image/region-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="group-btn-last mt-5">
            <div class="form-group">
              <button
                type="submit"
                class="btn btn-oclm-null"
                (click)="backToLastPage()"
              >
                {{ 'BtnText.Cancel' | translate }}
              </button>
            </div>
            <div class="download-cls">
              <app-download-work-order
                [WorkIdVal]="WorkIdVal"
                [workOrderType]="'resident'"
              ></app-download-work-order>
            </div>
            <div class="form-group text-right">
              <button
                type="submit"
                class="btn btn-oclm w-50"
                (click)="next('first')"
              >
                {{ 'BtnText.Next' | translate }}
                <img alt="icon"
                     src="../../../../assets/image/065-right-arrow-1.svg"
                  class="arrow-right"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="test-l-2" class="content">
        <div class="card card-body">
          <div class="first-form">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label
                    >{{ 'CommonLabel.JobName' | translate }} /
                    {{ 'CommonLabel.CustomerName' | translate }}</label
                  >
                  <div class="wrp">
                    <input
                      readonly
                      [(ngModel)]="taskOrderList.JobName"
                      type="text"
                      placeholder="{{ 'CommonLabel.JobName' | translate }}/{{
                        'CommonLabel.CustomerName' | translate
                      }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/work-request-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label>{{
                    'CommonLabel.DescriptionOfWork' | translate
                  }}</label>
                  <div class="wrp">
                    <input
                      readonly
                      [(ngModel)]="taskOrderList.WorkRequestID"
                      type="text"
                      placeholder="{{
                        'CommonLabel.DescriptionOfWork' | translate
                      }}"
                      class="form-control"
                    />
                    <img
                      src="../../../../../assets/image/comment-icon.png"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label>{{ 'Table.CreatedBy' | translate }}</label>
                  <div class="wrp">
                    <textarea
                      readonly
                      [(ngModel)]="taskOrderList.CreatedDate"
                      rows="1"
                      required
                      class="form-control"
                    ></textarea>
                    <img alt="icon"
                         src="../../../../assets/image/date-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 col-sm-8 col-xs-12">
              <div class="form-group">
                <label
                  >{{ 'CommonLabel.Location' | translate }}/{{
                    'CommonLabel.Address' | translate
                  }}</label
                >
                <div class="wrp">
                  <input
                    readonly
                    [(ngModel)]="taskOrderList.Address"
                    type="text"
                    placeholder="{{ 'CommonLabel.Location' | translate }}/{{
                      'CommonLabel.Address' | translate
                    }}"
                    class="form-control"
                  />
                  <img alt="icon"
                       src="../../../../assets/image/region-icon.png"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="form-group">
                <label>{{ 'CommonLabel.CompleteBy' | translate }}</label>
                <div class="wrp">
                  <input
                    [bsConfig]="bsConfig"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [(ngModel)]="taskOrderList.CompletedBy"
                    type="text"
                    placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                    bsDatepicker
                    class="form-control"
                  />
                  <img alt="icon"
                       src="../../../../assets/image/date-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>

            <div class="col-md-8 col-sm-8 col-xs-12">
              <div class="form-group">
                <label>{{ 'Table.WorkCategory' | translate }}</label>
                <div class="wrp">
                  <select
                    class="form-control"
                    id="workcatergory"
                    [(ngModel)]="taskOrderList.WorkCategory"
                    (change)="onChangeOfWorkCategory($event.target.value)"
                  >
                    <!-- [ngClass]="{ 'is-invalid': submitted && f.WorkCategory.errors }" -->
                    <option [value]="null">
                      -{{ 'CommonLabel.Select' | translate }}-
                    </option>
                    <option
                      *ngFor="let value of workCategoryList"
                      [value]="value.Text"
                    >
                      {{ value.Text }}
                    </option>
                  </select>
                  <img alt="icon"
                       src="../../../../assets/image/job-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
              <div class="form-group">
                <label>
                  {{ 'CommonLabel.ScheduleBy' | translate }}
                </label>
                <div class="wrp">
                  <input
                    type="text"
                    [(ngModel)]="taskOrderList.StartDate"
                    placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    class="form-control"
                  />
                  <img alt="icon"
                       src="../../../../assets/image/date-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12">
              <div class="form-group">
                <label>{{ 'CommonLabel.Notes' | translate }}</label>
                <div class="wrp">
                  <textarea
                    [(ngModel)]="taskOrderList.Notes"
                    rows="1"
                    required
                    class="form-control"
                  ></textarea>
                  <img
                    src="../../../../../assets/image/comment-icon.png"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="form-group">
                <label>{{ 'CommonLabel.CannedResponse' | translate }}</label>
                <div class="wrp">
                  <select
                    class="form-control"
                    id="workcatergory"
                    [(ngModel)]="taskOrderList.CannedResponseId"
                    (change)="getCannedResponseText($event.target.value)"
                  >
                    <!-- [ngClass]="{ 'is-invalid': submitted && f.WorkCategory.errors }" -->
                    <option [value]="null">
                      -{{ 'CommonLabel.Select' | translate }}-
                    </option>
                    <option
                      *ngFor="let value of cannedResponseList"
                      [value]="value.ID"
                    >
                      {{ value.Text }}
                    </option>
                  </select>
                  <img alt="icon"
                       src="../../../../assets/image/job-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>
            <div
              class="col-md-8 col-sm-8 col-xs-12"
              *ngIf="
                CannedResponseName == 'Other' ||
                taskOrderList.CannedResponseText
              "
            >
              <div class="form-group">
                <!-- <label>
                                  {{'CommonLabel.CannedResponse' | translate}}
                              </label> -->
                <div class="wrp">
                  <input
                    type="text"
                    placeholder="Other canned response"
                    [(ngModel)]="taskOrderList.CannedResponseText"
                    class="form-control"
                  />
                  <img alt="icon"
                       src="../../../../assets/image/job-icon.svg"
                    class='user-icon'
                  />
                </div>
              </div>
            </div>

            <!-- View Notes start here -->
            <div
              class="col-md-8 col-sm-8 col-xs-12"
              *ngIf="
                WorkOrderNotesDetail &&
                WorkOrderNotesDetail.length > 0 &&
                WorkOrderNotesDetail[0].NotesText.length > 0
              "
            >
              <div class="form-group">
                <label>{{ 'CommonLabel.ViewNotes' | translate }}</label>
                <div class="view-note-box">
                  <!-- 1st box -->
                  <ng-container *ngFor="let item of WorkOrderNotesDetail">
                    <div
                      class="view-note"
                      *ngIf="
                        item && item.NotesText && item.NotesText.length > 0
                      "
                    >
                      <div class="notify-msg">
                        {{ item.CreatedBy | firstWord }}
                      </div>
                      <div class="notify-text">
                        <span class="notify-date">
                          {{ item.CreatedBy }},
                          {{ item.CreatedDate | date: 'd MMMM, y,h:mm a' }}
                        </span>
                        <span>{{ item.NotesText }}</span>
                      </div>
                    </div>
                    <!-- 2st box -->
                  </ng-container>
                </div>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-4 col-xs-4"
              *ngIf="
                WorkOrderNotesDetail &&
                WorkOrderNotesDetail.length > 0 &&
                WorkOrderNotesDetail[0].NotesText.length > 0
              "
            ></div>
            <ng-container *ngFor="let item of DynamicFieldsList; let i = index">
              <div class="col-md-8 col-sm-8 col-xs-4" *ngIf="i % 2 == 0">
                <div class="form-group">
                  <label>{{ item.FieldName }}</label>
                  <div class="wrp">
                    <input
                      class="form-control mb-3"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="item.Values"
                      placeholder="Values"
                    />
                    <img alt="icon"
                         src="../../../../assets/image/job-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-4" *ngIf="i % 2 != 0">
                <div class="form-group">
                  <label>{{ item.FieldName }}</label>
                  <div class="wrp">
                    <input
                      class="form-control mb-3"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="item.Values"
                      placeholder="Values"
                    />
                    <img alt="icon"
                         src="../../../../assets/image/job-icon.svg"
                      class='user-icon'
                    />
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-8 col-sm-8 col-xs-12 add-field">
              <span
                ><strong>{{ 'BtnText.AddMoreField' | translate }}</strong></span
              >
              <span>
                <app-add-more-fields
                  (onAddMoreFields)="onAddMoreFieldsResponse($event)"
                >
                </app-add-more-fields>
              </span>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-6 col-sm-6 col-5 form-group">
              <button
                type="submit"
                class="btn btn-oclm-null"
                (click)="previous()"
              >
                {{ 'BtnText.Back' | translate }}
              </button>
            </div>
            <div class="col-md-6 col-sm-6 col-7 form-group">
              <button
                type="submit"
                class="btn btn-oclm"
                (click)="next('second')"
              >
                {{ 'BtnText.Next' | translate }}
                <img alt="icon"
                     src="../../../../assets/image/065-right-arrow-1.svg"
                  class="arrow-right"
                />
              </button>

              <button class="btn btn-success ml-2" (click)="onApproveHandle()">
                {{ 'BtnText.Approve' | translate }}
              </button>

              <button class="btn btn-danger ml-2" (click)="onRejectHandle()">
                {{ 'BtnText.Reject' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <form> -->
      <div id="test-l-3" class="content mt-4">
        <div class="first-form">
          <div class="row">
            <div class="col-md-6 c0l-xs-12 mb-4">
              <p class="thumb-text">
                {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
              </p>
              <app-preview-file
                [uploadFile]="uploadBeforePhotoFilePath"
              ></app-preview-file>
              <!-- <button type="submit" class="btn btn-secondary z-index"> Upload More </button> -->
              <app-multi-file-upload
                (OnFileUploadResponse)="fileUploadResponseBefore($event)"
                [UploadBtnText]="'Upload Picture'"
              >
              </app-multi-file-upload>
            </div>

            <div class="col-md-6 c0l-xs-12 mb-4">
              <p class="thumb-text">
                {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
              </p>
              <app-preview-file
                [uploadFile]="uploadAfterPhotoFilePath"
              ></app-preview-file>
              <!-- <button type="submit" class="btn btn-secondary z-index"> Upload Picture </button> -->
              <app-multi-file-upload
                (OnFileUploadResponse)="fileUploadResponseAfter($event)"
                [UploadBtnText]="'Upload Picture'"
              >
              </app-multi-file-upload>
            </div>
          </div>
        </div>
        <!-- html end -->
        <div class="row mt-3">
          <div class="col-md-12 col-sm-6 col-xs-6">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-5 form-group">
                <button
                  type="submit"
                  class="btn btn-oclm-null"
                  (click)="previous()"
                >
                  {{ 'BtnText.Back' | translate }}
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-7 form-group text-right">
                <button
                  type="submit"
                  class="btn btn-oclm w-50"
                  (click)="createWorkOrder()"
                >
                  {{ 'BtnText.Save' | translate }}
                  <img alt="icon"
                       src="../../../../assets/image/065-right-arrow-1.svg"
                    class="arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>

  <div class="login-left-Mapbox" style="opacity: 0.8">
    <div>
      <input
        type="text"
        class="form-control"
        placeholder="Enter a location or drag a marker"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        #search
      />
    </div>
    <agm-map
      [styles]="styles"
      [disableDefaultUI]="false"
      [latitude]="latitude"
      [longitude]="longitude"
      [zoom]="zoom"
    >
      <agm-marker
        [latitude]="latitude"
        [longitude]="longitude"
        [iconUrl]="iconURI"
      ></agm-marker>
    </agm-map>
    <div class="row">
      <!-- <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label>Location/Address</label>
                    <div class="wrp">
                        <input readonly [(ngModel)]="taskOrderList.ContactEmail" type="email" placeholder="Email ID"
                            class="form-control">
                        <img src="../../../../assets/image/region-icon.png" class="user-iocn">
                    </div>
                </div>
            </div> -->
      <!-- <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label>Created By</label>
                    <div class="wrp">
                        <input readonly [(ngModel)]="taskOrderList.PhoneNumber" type="text" placeholder="Phone number"
                            class="form-control">
                        <img src="../../../../assets/image/phone-icon.png" class="user-iocn">
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</div>

<ng-container *ngFor="let item of uploadAfterPhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngFor="let item of uploadBeforePhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model0{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
