import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.css'],
})
export class ModelComponent implements OnInit {
  btnClick;
  header;
  description;
  btnText1;
  btnText2;
  modelDetails;

  @ViewChild(ModalDirective) public lgModal: ModalDirective;
  @Output() response: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.modelDetails = {
      header: 'Work Order Confirmation',
      description: 'Would you like to create this for yourself or for others?',
      btnText1: 'Myself',
      btnText2: 'Others',
    };
  }

  confirm(): void {
    this.btnClick = true;
    this.lgModal.hide();
    this.response.emit(true);
  }

  decline(): void {
    this.btnClick = false;
    this.lgModal.hide();
    this.response.emit(false);
  }

  showInfoModal(model): void {
    this.modelDetails = model;
    this.lgModal.show();
  }
}
