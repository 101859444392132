<p class="title-main">All Media Library</p>
<div class="img-box-pre mt-4">
  <div class="bdr-box">
    <div>
      <p class="title">
        Before Information
      </p>
    </div>
    <div class="image-box">

      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect"
            style="background-image: url(https://oconnell.mobileprogramming.net/WEBAPI//WorkOrderIMages/7a9a4e86-159d-4ab1-b166-eb50c87ed0c6.jpg);">
          </div>
          <p class="caption">Welcome here</p>
        </div>

      </div>
      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect"
            style="background-image: url(https://oconnell.mobileprogramming.net/WEBAPI//WorkOrderIMages/ff796af3-e980-4d73-8838-4fbd8989700b.jpg);">
          </div>
        </div>
      </div>
      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect" style="background-image: url(../../../../assets/image/login-logo.png);"></div>
        </div>
      </div>
      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect" style="background-image: url(../../../../assets/image/login-logo.png);"></div>
        </div>
      </div>

    </div>
  </div>
  <div class="bdr-box">
    <div>
      <p class="title">
        After Information
      </p>
    </div>
    <div class="image-box">

      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect"
            style="background-image: url(https://oconnell.mobileprogramming.net/WEBAPI//WorkOrderIMages/7a9a4e86-159d-4ab1-b166-eb50c87ed0c6.jpg);">
          </div>
        </div>
      </div>

      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect" style="background-image: url(../../../../assets/image/1.jpg);"></div>
        </div>
      </div>
      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect" style="background-image: url(../../../../assets/image/1.jpg);"></div>
        </div>
      </div>
      <div class="aspect-ratio-box">
        <div class="aspect-ratio-box-inside">
          <div class="img-aspect" style="background-image: url(../../../../assets/image/1.jpg);"></div>
        </div>
      </div>


    </div>
  </div>
</div>

