export const saveWithSchedule = 'saveWithSchedule';
export const Schedule = 'Schedule';
export const fileUpload = 'fileUpload';
export const Manual = 'Manual';
export const Completed = 'Completed';
export const Resident = 'Resident';
export const Pending = 'Pending';
export const WorkInProgress = 'WorkInProgress';
export const Notes = 'notes';
export const Reopen = 'reopen';
export const Save = 'Save';
export const Success = 'Success';
export const CommunityManager = 'CommunityManager';
export const CloseWorkOrder = 'Close Work Order';
export const LosAngelesLongitude: number = 34.0273324350005;
export const LosAngelesLatitude: number = -118.21635826781015;

export const role = (roleName: string) => {
  switch (roleName) {
    case 'CommunityManager':
      return 'Client';
    case 'SuperVisor':
      return 'Supervisor';
    case 'FieldAgent':
      return 'Field Agent';

    default:
      return 'Admin';
  }
};
