import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainUsersComponent } from './main-users.component';
import { UsersRoutingModule } from './users-routing.module';
import { ComponentsModule } from './shared/components/components.module';
import { UserService } from './shared/services/user.service';
import { BeforePrintStylesDirective } from './shared/directives/before-print-styles.directive';



@NgModule({
  declarations: [MainUsersComponent, BeforePrintStylesDirective],
  imports: [CommonModule, UsersRoutingModule, ComponentsModule],
  providers: [UserService],

})
export class UsersModule {}
