<div class="range-input" #rangeInput>
  <mat-date-range-input [rangePicker]="picker">
    <input type="text" matStartDate hidden [formControl]="matDateStart"/>
    <input type="text" matEndDate hidden [formControl]="matDateEnd"/>
  </mat-date-range-input>
</div>

<div class="container-wrapper">
  <div class="container" (click)="openPicker('start')" #dateStartContainer matRipple matRippleColor="#1d58a8">
    <input type="text" class="container__input" [formControl]="dateStart"/>
    <div class="container__close-icon">
      <svg-icon
        [@rotatedState]="popupsOpened.get('start')"
        class="icon"
        src="assets/icons/arrow-down.svg"
      ></svg-icon>
    </div>
  </div>
  <span>
    <ng-content></ng-content>
  </span>
  <div class="container" (click)="openPicker('end')" #dateEndContainer matRipple matRippleColor="#1d58a8">
    <input type="text" class="container__input" [formControl]="dateEnd"/>
    <div class="container__close-icon">
      <svg-icon
        [@rotatedState]="popupsOpened.get('end')"
        class="icon"
        src="assets/icons/arrow-down.svg"
      ></svg-icon>
    </div>
  </div>
</div>

<mat-date-range-picker
  #picker
  [panelClass]="'picker-container'"
  [calendarHeaderComponent]="customHeader"
  disabled="false"
></mat-date-range-picker>

