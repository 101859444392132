<!--<div class="agreement-wrapper">-->
<!--  <pdf-viewer-->
<!--    [src]="'https://wp.oclm.com/wp-content/uploads/2023/01/CCPA.pdf'"-->
<!--    [render-text]="true"-->
<!--    [original-size]="false"-->
<!--    style="height: 75vh; width: 100%;"-->
<!--  ></pdf-viewer>-->
<!--  <div *ngIf="showAgreeButton" class="btn-container">-->
<!--    <button class="agree" (click)="accept()">Agree</button>-->
<!--  </div>-->
<!--  <div *ngIf="showCloseButton" class="btn-container">-->
<!--    <button class="agree" (click)="close()">Close</button>-->
<!--  </div>-->
<!--</div>-->


<div class="popup-outer">
  <div class="popup">
    <div class="policy">
      <div class="pop-image">
        <img src="../assets/image/logo-pop.jpg" width="200px" alt="logo" />
      </div>
      <h3 style="text-align: center;">ONLINE PRIVACY POLICY</h3>
      <p>O’Connell Landscape Maintenance Inc. (the “<strong>Company</strong>” or “<strong>we</strong>”) has developed this privacy policy out of respect for the privacy of our customers, visitors to our website, job applicants, and independent contractors. This policy describes the personal information we collect, use, and disclose about individual consumers, applicants, and contractors who visit or interact with this website, visit any of our offices, stores, facilities or locations, purchase or inquire about any of our products or services, contract with us to provide services, apply for a position of employment, or otherwise interact or do business with us.</p>
      <p>Whenever you visit our website, we will collect some information from you automatically simply by you visiting and navigating through this site, and some voluntarily when you submit information using a form on the website, utilize the Live Chat feature on our website, enroll in or subscribe to our newsletter or marketing communications, request information, or use any of the other interactive portions of our website including our maintenance hub / portal (such as when submitting, reviewing and managing the quote process and other landscape related matters). For clarity, when referencing our “website” in this privacy policy “website” shall include our maintenance hub / portal which can currently be accessed at https://portal.oclm.com/#/login. Through this website, we will collect information that can identify you and/or your activity.</p>
      <p>Additionally, whenever you communicate, interact or do business with us, whether online or at any of our physical locations or facilities, or whether you are contracted to perform services for us or apply for a position of employment, we will be collecting personal information from you or about you in the course of our interaction or dealings with you.</p>
      <p>This policy does <strong>not</strong> apply to our current and former employees and their family members, dependents, and beneficiaries; if you are a California resident who is a current or former employee of the Company or a family member, dependent, or beneficiary of any of our current or former employees, you may request access to our Employee Privacy Policy by sending an email to hr@oclm.com.</p>
      <h2>Consent to Share Personal Information When Using Live Chat Function</h2>
      <p>By using the Live Chat feature, you consent to our collection and analysis of all personal information provided. The Live Chat feature does not use any chatbot or artificial intelligence technology. Rather, each chat takes place with a live representative of the Company. We utilize a vendor called Drift and/or Hotjar (“Chat Vendor”) to process, analyze, and store the contents of the chat on our behalf. The Chat Vendor will not sell this data or share it with anyone besides the Company or another vendor engaged to assist in the services provided to the Company. The Chat Vendor will not use or disclose this data for any purpose other than providing services to the Company. By using these forms and features, you direct the Company to disclose to and share with the Chat Vendor any personal information you provide.</p>
      <h2>Collection of Personal Information and Sensitive Personal Information</h2>
      <p>In the last 12 months, we have collected the following categories of personal information about you based on your specific transactions and interactions with us or our website. For each category of information, the categories of third parties with whom we have disclosed the information in the last 12 months are referenced by a letter that coincides with the letter in the list of categories of service providers and third parties that follows soon after this table. We have not sold or shared personal information about you (including in the following categories of information below) within the last 12 months.</p>
      <table style="border-collapse: collapse;">
        <thead>
        <tr>
          <th style="border: 1px solid black;">Category</th>
          <th style="border: 1px solid black;">Examples</th>
          <th style="border: 1px solid black;">Disclosed in Last 12 Months To</th>
          <th style="border: 1px solid black;">Retention Period</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td style="border: 1px solid black;">Personal Identifiers</td>
          <td style="border: 1px solid black;">Name, alias, social security number, date of birth, driver’s license or state identification card number, passport number.</td>
          <td style="border: 1px solid black;">A, B, D, E, F, G, H. I, J, K, L, M, N, O</td>
          <td style="border: 1px solid black;">Duration of our relationship with you plus 4 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Contact Information</td>
          <td style="border: 1px solid black;">Home, postal or mailing address, email address, home phone number, cell phone number, related management company (if applicable).</td>
          <td style="border: 1px solid black;">A, B, D, E, F, G, H. I, J, K, L, M, N, O</td>
          <td style="border: 1px solid black;">Duration of our relationship with you plus 4 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Account Information</td>
          <td style="border: 1px solid black;">Username and password for Company accounts and systems, and any required security or access code, password, security questions, or credentials allowing access to your Company accounts.</td>
          <td style="border: 1px solid black;">Not Disclosed</td>
          <td style="border: 1px solid black"><span style="text-decoration: underline">Username</span>: permanent; <span style="text-decoration: underline">Password or security code</span>: while in use + 1 year</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Protected Classifications</td>
          <td style="border: 1px solid black;">Race, ethnicity, national origin, sex, gender, sexual orientation, gender identity, religious or philosophical beliefs, age, disability, medical or mental condition, military status, familial status, union membership.</td>
          <td style="border: 1px solid black;">A, D, K</td>
          <td style="border: 1px solid black;">Duration of our relationship with you plus 4 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Commercial Transactional Data</td>
          <td style="border: 1px solid black;">Information regarding products or services provided, purchasing history.</td>
          <td style="border: 1px solid black;">A, B, C, E, F, G, H, N, M, O</td>
          <td style="border: 1px solid black;">4 years after transaction, unless necessary to maintain for a longer period for product warranty, or OSHA / Cal/OSHA or other regulatory compliance</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Internet Network and Computer Activity</td>
          <td style="border: 1px solid black;">Date and time of your visit to this website; webpages visited; links clicked on the website; browser ID; browser type; device ID; operating system; form information downloaded; domain name from which our site was accessed; search history; and cookies; internet or other electronic network activity information related to usage of Company networks, servers, intranet, or shared drives, including system and file access logs, security clearance level, browsing history, search history, and usage history.</td>
          <td style="border: 1px solid black;">F, H, L</td>
          <td style="border: 1px solid black;">2 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Geolocation Data</td>
          <td style="border: 1px solid black;">IP address and/or GPS location, latitude & longitude.</td>
          <td style="border: 1px solid black;">L</td>
          <td style="border: 1px solid black;">2 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Mobile Device Data</td>
          <td style="border: 1px solid black;">Information collected when you navigate, access or use any of our websites via mobile device, including device type, software type; data identifying your device if you access our business networks and systems, including cell phone make, model, and serial number, cell phone number, and cell phone provider.</td>
          <td style="border: 1px solid black;">B, E, F, H</td>
          <td style="border: 1px solid black;">2 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Financial / Employment Data</td>
          <td style="border: 1px solid black;">Information collected through credit or financing applications, including employment history, company name, role, salary, dates of employment, bank accounts, income sources.</td>
          <td style="border: 1px solid black;">A, D, I, J, K</td>
          <td style="border: 1px solid black;">4 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Inferences</td>
          <td style="border: 1px solid black;">Based on analysis of your activity on the website, we may develop inferences regarding preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
          <td style="border: 1px solid black;">B, E, F, H</td>
          <td style="border: 1px solid black;">Duration of our relationship with you plus 4 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Online Portal and Mobile App Access and Usage Information</td>
          <td style="border: 1px solid black;">Username and password, account history, usage history, file access logs, and security clearance level.</td>
          <td style="border: 1px solid black;">L</td>
          <td style="border: 1px solid black;">2 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Visual, Audio or Video Recordings</td>
          <td style="border: 1px solid black;">Your image when recorded or captured in surveillance camera footage or pictures of you taken on our premises or at our events or that you share with us.</td>
          <td style="border: 1px solid black;">L</td>
          <td style="border: 1px solid black;">Surveillance video – 90 days; duration of our relationship with you plus 4 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Pre-Hire Information</td>
          <td style="border: 1px solid black;">Information gathered as part of background screening and reference checks, pre-hire drug test results, information recorded in job interview notes by persons conducting job interviews for the Company, information contained in candidate evaluation records and assessments, information in work product samples you provided, and voluntary disclosures by you.</td>
          <td style="border: 1px solid black;">I, J, K</td>
          <td style="border: 1px solid black;">If hired, this data will be retained for duration of employment plus 5 years. If not hired, it will be retained for 4 years from when position is filled or the date we receive your information, whichever is longer.</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Employment and Education History</td>
          <td style="border: 1px solid black;">Information contained in your resume regarding educational history, information in transcripts or records of degrees, vocational certifications obtained, and information regarding prior job experience, positions held, and when permitted by applicable law your salary history or expectations.</td>
          <td style="border: 1px solid black;">I, J, K, O</td>
          <td style="border: 1px solid black;">If hired, this data will be retained for duration of employment plus 5 years. If not hired, it will be retained for 4 years from when position is filled or the date we receive your information, whichever is longer.</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Professional Related Information</td>
          <td style="border: 1px solid black;">Information contained in tax forms/1099 forms, safety records, licensing and certification records, and performance records, and information related to services provided by independent contractors, including in statements of work.</td>
          <td style="border: 1px solid black;">A, I, J, K, O</td>
          <td style="border: 1px solid black;">Duration of our relationship with you plus 4 years</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;">Facility & Systems Access Information</td>
          <td style="border: 1px solid black;">Information identifying you, if you accessed our secure company facilities, systems, networks, computers, and equipment, and at what times, using keys, badges, fobs, login credentials, or other security access method.</td>
          <td style="border: 1px solid black;">L</td>
          <td style="border: 1px solid black;">2 years</td>
        </tr>
        </tbody>
      </table>
      <p>Of the above categories of Personal Information, the following are categories of Sensitive Personal Information the Company may collect from or about consumers, independent contractors, or applicants:</p>
      <ol type="1">
        <li>	Personal Identifiers (social security number, driver’s license or state identification card number, passport number)</li>
        <li> Account Information (your Company account log-in, in combination with any required security or access code, password, or credentials allowing access to the account)</li>
        <li> Protected Classifications (racial or ethnic origin, religious or philosophical beliefs, union membership, or sexual orientation)</li>
        <li> Biometric Information (used for the purpose of uniquely identifying you)</li>
        <li> Medical and Health Information</li>
        <li> Geolocation Data (IP address and/or GPS location, latitude & longitude)</li>
      </ol>
      <p>Personal information <i style="font-weight: bold">does not</i>  include:</p>
      <ul>
        <li>Publicly available information from government records.</li>
        <li>Information that a business has a reasonable basis to believe is lawfully made available to the general
          public by the consumer, independent contractor, or applicant, or from widely distributed media.</li>
        <li>Information made available by a person to whom the consumer, independent contractor, or applicant
          has disclosed the information if the consumer, independent contractor, or applicant has not restricted
          the information to a specific audience.</li>
        <li>Deidentified or aggregated information.</li>
      </ul>
      <h2>We may collect your personal information from the following sources:</h2>
      <ul>
        <li>	You the consumer, independent contractor, or job applicant, when you visit the website and voluntarily submit information through forms on the website or social media, when you visit any of our stores or physical locations, when you purchase or inquire about any of our products or services, when you utilize the Live Chat feature on the website, when you enter into a contract to perform services for us, or when you apply for a position of employment</li>
        <li>	Our employees and contractors, when you interact with them</li>
        <li>	Other customers and visitors, when you interact with them or when they observe you</li>
        <li>	We utilize cookies to automatically collect information about our website visitors</li>
        <li>	Surveillance cameras at our physical locations</li>
        <li>	Lead generators and referral sources</li>
        <li>	Credit and consumer reporting agencies</li>
        <li>	Recruiters</li>
        <li>	Social media platforms</li>
        <li>	Company-issued computers, electronic devices, and vehicles</li>
        <li>	Company systems, networks, software applications, and databases you log into or use</li>
        <li>	You the consumer when you submit a quote (through the website or otherwise), landscape request, or information about your community, home owners association, community members or other persons</li>
      </ul>
      <h2>We may disclose your personal information to/with the following categories of service providers, contractors, or third parties:</h2>
      <ol type="A">
        <li>	Financial institutions</li>
        <li> <em>[Intentionally Omitted]</em></li>
        <li>	Product manufacturers/administrators</li>
        <li>	Government agencies</li>
        <li>	Promotional or other fulfilment vendors</li>
        <li>	Support vendors for marketing, managing or hosting the website, and the Live Chat function on the website</li>
        <li>	Transaction support vendors (e.g., check guaranty, payment processors)</li>
        <li>	Data analytics vendors</li>
        <li>	Professional employer organizations</li>
        <li>	Consumer reporting agencies or credit reporting agencies</li>
        <li>	Employee tracking and talent management systems</li>
        <li>	Security and risk management vendors</li>
        <li>	Corporate customers and potential corporate customers, including their affiliates/agents</li>
        <li>	Original equipment manufacturers (OEM) and supplies (including the suppliers and makers of the products we sell to our customers)</li>
        <li>	Insurance providers, administrators, adjusters and brokers</li>
      </ol>
      <h2>We may collect your personal information for the following business purposes:</h2>
      <ol type="1">
        <li> To fulfill or meet the purpose for which you provided the information</li>
        <li> To process and submit financing applications, including to apply for credit, or credit pre-qualification.</li>
        <li> To process, complete, and maintain records on transactions and landscape services.</li>
        <li> To provide warranty coverage on products and services.</li>
        <li> To retain your selection for Text opt in/opt out to ensure customers who opted out are not sent any text messages</li>
        <li> To provide and communicate recall notifications to</li>
        <li> To schedule, manage and keep track of customer appointments.</li>
        <li> To complete</li>
        <li> To maintain records of when customers decline a service or sale</li>
        <li> To respond to consumer inquiries, including requests for information, customer support online, quotes and other landscape requests,
          Live Chat on the website, phone calls, and in-store inquiries.</li>
        <li> To provide interest-based and targeted advertising.</li>
        <li> To improve user experience on our website.</li>
        <li> To understand the demographics of our website visitors.</li>
        <li> To detect security incidents.</li>
        <li> To debug, identify, and repair errors that impair existing intended functionality of our website.</li>
        <li> To protect against malicious or illegal activity and prosecute those responsible.</li>
        <li> To verify and respond to consumer requests, including quotes and other landscape requests.</li>
        <li> To prevent identity theft</li>
        <li><h3>JOB APPLICANT PURPOSES:</h3></li>
        <ol type="a">
          <li> To fulfill or meet the purpose for which you provided the
            information. For example, if you share your name and contact information to apply for a job with the Company,
            we will use that Personal Information in connection with your candidacy for employment.</li>
          <li> To comply with local, state, and federal law and regulations requiring employers to maintain certain records, as well as local, state, and federal law, regulations, ordinances, guidelines, and orders relating to COVID-19.</li>
          <li> To evaluate your job application and candidacy for employment.</li>
          <li> To obtain and verify background check and references.</li>
          <li> To communicate with you regarding your candidacy for employment.</li>
          <li> To reduce the risk of spreading infectious diseases in or through the workplace.</li>
        </ol>
        <li><h3>INDPENDENT CONTRACTOR PURPOSES:</h3></li>
        <ol type="a">
          <li> To fulfill or meet the purpose for which you provided the information</li>
          <li> To comply with state and federal law and regulations requiring businesses to maintain certain records (accident or safety records, and tax records/1099 forms).</li>
          <li> To engage the services of independent contractors and compensate them for services.</li>
          <li> To evaluate, make, and communicate decisions regarding an independent contractor, including decisions to hire and/or terminate.</li>
          <li> To grant independent contractors access to secure Company facilities, systems, networks, computers, and equipment, and maintain information on who accessed such facilities, systems, networks, computers, and equipment, and what they did therein or thereon.</li>
          <li> To implement, monitor, and manage electronic security measures on independent contractor devices that are used to access Company networks and systems.</li>
          <li> To evaluate, assess, and manage the Company’s business relationship with vendors, service providers, and contractors that provide services to the Company</li>
          <li> To improve user experience on Company computers, networks, devices, software applications or systems, and to debug, identify, and repair errors that impair existing intended functionality of our systems</li>
          <li> To reduce the risk of spreading infectious diseases in or through the workplace.</li>
        </ol>
      </ol>
      <p><strong>We may disclose your personal information for the following business purposes as numbered above: </strong>1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19(a), 19(b), 19(d), 20(a), 20(e), 20(f), 20(g), 20(h), and 20(i).</p>
      <p><strong>We do <u style="text-decoration: underline">NOT</u> and will not sell your personal information in exchange for monetary or other valuable consideration</strong>. <strong>We do not share your personal information for cross-context behavioral advertising.</strong></p>
      <p><strong>We do not and will not use or disclose your sensitive personal information for purposes other than the following:</strong></p>

      <ol type="1">
        <li> To perform the services reasonably expected by an average employee who requests those services.</li>
        <li> To detect security incidents that compromise the availability, authenticity, integrity, and confidentiality of stored or transmitted personal information.</li>
        <li> To resist malicious, deceptive, fraudulent, or illegal actions directed at the business and to prosecute those responsible for those actions.</li>
        <li> To ensure the physical safety of natural persons.</li>
        <li> For short-term, transient use.</li>
        <li> To perform services on behalf of the Company.</li>
        <li> To verify or maintain the quality or safety of a product, service or device that is owned, manufactured, manufactured for, or controlled by the Company, and to improve, upgrade, or enhance the service or device that is owned, manufactured by, manufactured for, or controlled by the Company.</li>
        <li> For purposes that do not involve inferring characteristics about the consumers, contractors, and applicants.</li>
      </ol>
      <h2>Retention of Personal Information</h2>
      <p>We will retain each category of personal information in accordance with our established data retention schedule as indicated above. In deciding how long to retain each category of personal information that we collect, we consider many criteria, including, but not limited to: the business purposes for which the Personal Information was collected; relevant federal, state and local recordkeeping laws; applicable statutes of limitations for claims to which the information may be relevant; and legal preservation of evidence obligations.</p>
      <p>We apply our data retention procedures on an annual basis to determine if the business purposes for collecting the personal information, and legal reasons for retaining the personal information, have both expired. If so, we will purge the information in a secure manner.</p>
      <h2>Third Party Vendors</h2>
      <p>We may use other companies and individuals to perform certain functions on our behalf. Examples include administering e-mail services and running special promotions. Such parties only have access to the personal information needed to perform these functions and may not use or store the information for any other purpose. Subscribers or site visitors will never receive unsolicited e-mail messages from vendors working on our behalf.</p>
      <h2>Business Transfers</h2>
      <p>In the event we sell or transfer a particular portion of its business assets, information of consumers, contractors and applicants may be one of the business assets transferred as part of the transaction. If substantially all of our assets are acquired, information of consumers, contractors and applicants may be transferred as part of the acquisition.</p>
      <h2>Compliance with Law and Safety</h2>
      <p>We may disclose specific personal and/or sensitive personal information based on a good faith belief that such disclosure is necessary to comply with or conform to the law or that such disclosure is necessary to protect our employees or the public.</p>
      <h2>Use of Cookies and Other Tracking Technologies</h2>
      <p>Cookies are small files that a website may transfer to a user’s computer that reside there for either the duration of the browsing session (session cookies) or on a permanent, until deleted, basis (persistent cookies) that may be used to identify a user, a user’s machine, or a user’s behavior. We make use of cookies under the following circumstances and for the following reasons:</p>
      <ul>
        <li> Provide you with services available through the website and to enable you to use some of its features</li>
        <li> Authenticate users and prevent fraudulent use of user accounts</li>
        <li> Identify if users have accepted the use of cookies on the website</li>
        <li> Compile data about website traffic and how users use the website to offer a better website experience</li>
        <li> Understand and save visitor preferences for future visits, such as remembering your login details or language preference, to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the website</li>
        <li> Track your browsing habits to enable us to show advertising which is more likely to be of interest to you, including advertising by third parties on our website</li>
      </ul>
      <p>You may delete cookies from your web browser at any time or block cookies on your equipment, but this may affect the functioning of or even block the website. You can prevent saving of cookies (disable and delete them) by changing your browser settings accordingly at any time. It is possible that some functions will not be available on our website when use of cookies is deactivated. Check the settings of your browser. Below you can find some guidance:</p>

      <ul>
        <li> <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Safari</a></li>
        <li> <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a></li>
        <li> <a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">Internet Explorer</a></li>
        <li> <a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
        <li> <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer#w_cookie-settings">Mozilla</a></li>
      </ul>
      <h2>External Links</h2>
      <p>Our website contain links to other sites. We are not responsible for the privacy practices or the content of such websites. To help ensure the protection of your privacy, we recommend that you review the Privacy Policy of any site you visit via a link from our website.</p>

      <h2>Passwords</h2>
      <p>The personal data record created through your registration with our website can only be accessed with the unique password associated with that record. To protect the integrity of the information contained in this record, you should not disclose or otherwise reveal your password to third parties.</p>

      <h2>Children Under the Age of 16</h2>
      <p>We do not knowingly sell or share the personal information of consumers under 16 years of age.</p>

      <h2 style="text-align: left;">How We Protect the Information that We Collect</h2>
      <p>The protection of the information that we collect about visitors to this website is of the utmost importance to us and we take every reasonable measure to ensure that protection, including:</p>

      <ul>
        <li> We use commercially reasonable tools and techniques to protect against unauthorized access to our systems.</li>
        <li> We restrict access to private information to those who need such access in the course of their duties for us.</li>
      </ul>
      <h2><strong>International Visitors</strong></h2>
      <p>We do not target, market to, or offer our products or services to consumers outside of the United States. You agree not to submit your personally identifiable information through the website if you reside outside the United States. If we become aware that a person residing inside the European Economic Area, European Union, Great Britain, or Switzerland has submitted their personal information to us, we will delete it.</p>

      <h2>Rights Under the CCPA and CPRA</h2>
      <p>This section of the Privacy Policy applies only to California residents who are natural persons; it does not apply to any entities (whether business, non-profit or governmental). If you are a California resident, you have the following rights:</p>

      <ol type="1">
        <li> <u style="text-decoration: underline">Right to Know.</u> The right to request, up to 2 times in a 12-month period, that we identify to you (1) the categories of personal information we have collected about you going back to January 1, 2022, unless doing so would be impossible or involve disproportionate effort, or unless you request a specific time period, (2) the categories of sources from which the personal information was collected, (3) the business or commercial purpose for collecting, selling, or sharing this information, (4) the categories of third parties with whom we share or have shared your personal information, and (5) the categories of personal information that we have disclosed about you for a business purpose and the categories of persons to whom it was disclosed for a business purpose;</li>
        <li> <u style="text-decoration: underline">Right to Access.</u> The right to request, up to 2 times in a 12-month period, that we disclose to you, free of charge, the specific pieces of personal information we have collected about you going back to January 1, 2022, unless doing so would be impossible or involve disproportionate effort, or unless you request a specific time period;</li>
        <li> <u style="text-decoration: underline">Right to Delete.</u> The right to request, up to 2 times in a 12-month period, that we delete personal information that we collected from you, subject to certain exceptions;</li>
        <li> <u style="text-decoration: underline">Right to Correct.</u> The right to request that we correct inaccurate personal information (to the extent such an inaccuracy exists) that we maintain about you;</li>
        <li><u style="text-decoration: underline"> Right to Limit.</u> The right to limit the use or disclosure of your sensitive personal information;</li>
        <li> The right to designate an authorized agent to submit one of the above requests on your behalf. See below for how you can designate an authorized agent; and</li>
        <li> The right to not be discriminated or retaliated against for exercising any of the above rights, including an applicant’s and independent contractor’s right not to be retaliated against for exercising the above rights.</li>
      </ol>
      <p><strong>You can submit any of the above types of consumer requests through any of the 3 options below:</strong></p>
      <ol type="1">
        <li> Submit an e-mail request by emailing <a href="mailto:hr@oclm.com">hr@oclm.com.</a></li>
        <li> Call our privacy toll-free line at 800-339-1106.</li>
        <li> Complete a paper form, which can be requested at the Company’s corporate office at 23091 Arroyo Vista, Rancho Santa Margarita, CA 92688.</li>
      </ol>
      <h2>How We Will Verify That it is Really You Submitting the Request</h2>
      <p>If you are a California resident, when you submit a Right to Know, Right to Access, Right to Delete, or Right to Correct request through one of the methods provided above, we will ask you to provide some information in order to verify your identity and respond to your request. Specifically, we will ask you to verify information that can be used to link your identity to particular records in our possession, which depends on the nature of your relationship and interaction with us. For example, we may need you to provide your name, email, phone number, IP address, browser ID, amount of your last purchase with the business, and/or date of your last transaction with the business.</p>

      <h2>Responding to Your Right to Know, Right to Access, Right to Delete, and Right to Correct Requests</h2>
      <p>Upon receiving a verifiable request from a California resident, we will confirm receipt of the request no later than 10 business days after receiving it. We endeavor to respond to a verifiable request within forty-five (45) calendar days of its receipt. If we require more time (up to an additional 45 calendar days, or 90 calendar days total from the date we receive your request), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically, at your option. The response we provide will also explain the reasons we cannot comply with a request, if applicable.</p>
      <p>We do not charge a fee to process or respond to your verifiable request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
      <p>For a request to correct inaccurate personal information, we will accept, review, and consider any documentation that you provide, and we may require that you provide documentation to rebut our own documentation that the personal information is accurate. You should make a good-faith effort to provide us with all necessarily information at the time that you make the request to correct. We may deny a request to correct if we have a good-faith, reasonable, and documented belief that a request to correct is fraudulent or abusive. If we deny your request to correct, we shall inform you of our decision not to comply and provide an explanation as to why we believe the request is fraudulent.</p>

      <h2>Responding to Your Request to Limit the Use of Sensitive Personal Information</h2>
      <p>We will act upon a verifiable request to limit the use of sensitive personal information within fifteen (15) business days of its receipt. We will notify all third parties that use or disclose sensitive personal information of your request to limit and instruct them to comply with the request within the same time frame. We will notify you when this has been completed by mail or electronically, at your option.</p>
      <p>A request to limit need not be a verifiable request. However, we may deny a request to limit if we have a good faith, reasonable, and documented belief that a request to limit is fraudulent. If we deny your request to limit, we shall inform you of our decision not to comply and provide an explanation as to why we believe the request is fraudulent.</p>

      <h2>If You Have an Authorized Agent:</h2>
      <p>If you are a California resident, you can authorize someone else as an authorized agent who can submit a request on your behalf. To do so, you must either (a) execute a valid, verifiable, and notarized power of attorney or (b) provide other written, signed authorization that we can then verify. When we receive a request submitted on your behalf by an authorized agent who does not have a power of attorney, that person will be asked to provide written proof that they have your permission to act on your behalf, and we will also contact you and ask you for information to verify your own identity directly with us and not through your authorized agent. We may deny a request from an authorized agent if the agent does not provide your signed permission demonstrating that they have been authorized by you to act on your behalf.</p>

      <h2>Other California Privacy Rights</h2>
      <p>The California Civil Code permits California Residents with whom we have an established business relationship to request that we provide you with a list of certain categories of personal information that we have disclosed to third parties for their direct marketing purposes during the preceding calendar year. To make such a request, please send an email <a href="mailto:hr@oclm.com">hr@oclm.com,</a> or write to us at the address listed below. Please mention that you are making a “California Shine the Light” inquiry.</p>

      <h2><strong>Consent to Terms and Conditions</strong></h2>
      <p>By using this website, you consent to all terms and conditions expressed in this Privacy Policy.</p>

      <h2>Changes to Our Privacy Policy</h2>
      <p>As our services evolve and we perceive the need or desirability of using information collected in other ways, we may from time to time amend this Privacy Policy. We encourage you to check our website frequently to see the current Privacy Policy in effect and any changes that may have been made to them. If we make material changes to this Privacy Policy, we will post the revised Privacy Policy and the revised effective date on this website. Please check back here periodically or contact us at the address listed at the end of this Privacy Policy.</p>

      <h2>Consumers With Disabilities</h2>
      <p>This policy is in a form that is accessible to consumers with disabilities.</p>

      <h2>Questions About the Policy</h2>
      <p>This website is owned and operated by O’Connell Landscape Maintenance Inc. If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hr@oclm.com">hr@oclm.com</a> or call 800-339-1106.</p>
      <p><strong><em>**This policy was last updated January 1, 2023.</em></strong></p>
    </div>
  </div>
  <div class="btn-container">
    <div class="close-btn" *ngIf="showAgreeButton" (click)="accept()">Agree</div>
    <div class="close-btn" *ngIf="showCloseButton" (click)="close()">Close</div>
  </div>
</div>

