<div class="table-maine">
  <div class="tab-content" *ngIf="activeTab != 'Schedule'">
    <div id="{{ activeTab }}" class="tab-pane active">
      <div class="table-responsive">
        <div class="table-responsive">
          <table class="table table-style-admin">
            <thead>
              <th sorttable="UserId" (sort)="onSort($event)">
                {{ 'TableUser.Id' | translate }}
              </th>
              <th sorttable="FirstName" (sort)="onSort($event)">
                {{ 'TableUser.FirstName' | translate }}
              </th>
              <th sorttable="FirstName" (sort)="onSort($event)">
                {{ 'TableUser.LastName' | translate }}
              </th>
              <th sorttable="Phone" (sort)="onSort($event)">
                {{ 'TableUser.Phone' | translate }}
              </th>
              <th sorttable="Email" (sort)="onSort($event)">
                {{ 'TableUser.Email' | translate }}
              </th>
              <th sorttable="Role" (sort)="onSort($event)">
                {{ 'TableUser.Role' | translate }}
              </th>
              <th sorttable="Jobs" (sort)="onSort($event)">
                {{ 'TableUser.Jobs' | translate }}
              </th>
              <th></th>
            </thead>
            <tbody>
              <ng-container *ngIf="UserList && UserList.length > 0">
                <tr *ngFor="let item of UserList; let i = index"
                    [routerLink]="['/users',item.UserId]"
                    [ngClass]="{'work-order-active': sessionWorkId === item.UserId}"
                    [class.pending]="item.Status === 'Pending'"
                >
                  <td>
                    <strong>{{ item.UserId }}</strong>
                  </td>
                  <td>
                    <p>{{ item.FirstName }}</p>
                  </td>
                  <td>
                    <p>{{ item.LastName }}</p>
                  </td>
                  <td>
                    <p>
                      {{ item.Phone }}
                    </p>
                  </td>
                  <td>
                    {{ item.Email }}
                  </td>
                  <td>
                    <p>{{ item.Role }}</p>
                  </td>
                  <td>
                    <p *ngFor="let i of item.Jobs; let t = index"> {{ i?.Name }}</p>
                  </td>
                  <td><a [routerLink]="['/users',item.UserId]"><img
                    src="../../../../../../../assets/image/edit_black_24dp.svg" class="img-fluid" /></a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img (click)="
                        blockUser(template,item.UserId,item.FirstName)
                      " src="../../../../../assets/image/delete.svg" class="img-fluid" />

                  </td>
                </tr>
              </ng-container>
            </tbody>
            <ng-container *ngIf="userListPgination == 0">
              <div class="inbox-body">
                {{ 'Message.NoRecordFound' | translate }}
              </div>
            </ng-container>
          </table>
        </div>
        <div class="page-select">
          <div class="coustom-width ma-l">
            <select class="form-control" id="itemperpage" #itemperpage [(ngModel)]="pageSize"
              (change)="selectItemPerPage(itemperpage.value)">
              <option [value]="10">
                {{ 'CommonLabel.ItemPerPage' | translate }}
              </option>
              <option *ngFor="let value of itemPerPage" [value]="value.pageSize">
                <input type="checkbox" />
                {{ value.pageSize }}
              </option>
            </select>
          </div>
          <ng-container *ngIf="userListPgination != 0">
            <pagination [totalItems]="userListPgination" (pageChanged)="pageChanged($event)" [itemsPerPage]="pageSize"
              [(ngModel)]="pageNo" [maxSize]="maxSize" [boundaryLinks]="true" previousText="&lsaquo;"
              nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefDelete.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h6>Are you sure to delete user?</h6>
  </div>
  <div class="group">
    <button type="button" class="btn btn-danger" (click)="clickOk()">OK</button>
    <button type="button" class="btn btn-outline-danger" (click)="modalRefDelete.hide()">Cancel</button>
  </div>`
</ng-template>

<ng-template #templateDelete>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefDelete.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h6>User {{selecterUserName}} has been deleted</h6>
  </div>
  <div class="group">
    <button type="button" class="btn btn-danger" (click)="modalRefClose.hide()">OK</button>
  </div>`
</ng-template>
