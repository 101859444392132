import {ElementRef, Injectable, Injector} from '@angular/core';
import {ConnectedPosition, Overlay, OverlayConfig, PositionStrategy,} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {PopoverContent, PopoverRef} from './overlay.ref';
import {OverlayComponent} from './overlay.component';

export type PopoverParams<T> = {
    width?: string | number;
    height?: string | number;
    positions: ConnectedPosition[];
    bottomOnly?: boolean;
    origin: ElementRef;
    content: PopoverContent;
    data?: T;
};

@Injectable({
    providedIn: 'root',
})
export class OverlayService {
    constructor(private overlay: Overlay, private injector: Injector) {}

    public open<T>({
        origin,
        content,
        data,
        width,
        height,
        positions,
    }: PopoverParams<T>): PopoverRef<T> {
        const overlayRef = this.overlay.create(
            this.getOverlayConfig({ origin, width, height, positions })
        );
        const popoverRef = new PopoverRef<T>(overlayRef, content, data);

        const injector = Injector.create({
            providers: [{ provide: PopoverRef, useValue: popoverRef }],
            parent: this.injector,
            name: '',
        });
        overlayRef.attach(new ComponentPortal(OverlayComponent, null, injector));

        return popoverRef;
    }

    private getOverlayConfig({
        origin,
        width,
        height,
        positions,
    }: {
        origin: ElementRef;
        width?: string | number;
        height?: string | number;
        positions: ConnectedPosition[];
    }): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop: false,
            width,
            height,
            backdropClass: 'popover-backdrop',
            positionStrategy: this.getOverlayPosition(origin, positions),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
    }

    private getOverlayPosition(
        origin: ElementRef,
        positions: ConnectedPosition[]
    ): PositionStrategy {
        return this.overlay
            .position()
            .flexibleConnectedTo(origin)
            .withPositions(positions)
            .withFlexibleDimensions(false)
            .withPush(false);
    }
}
