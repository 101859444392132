<div class="main-padding-box">
  <div class="row">
    <div class="col-md-12" *ngIf="workOrderId">
      Work Order Id: {{ workOrderId }}
    </div>
    <div class="show-div">
      <div
        class="col-md-4 offset-md-8"
        *ngIf="currentUserRole == 'Admin' || currentUserRole == 'FieldAgent'"
      >
        <div class="form-group">
          <label class="oclm-color">{{
            'CommonLabel.WorkOrderStatus' | translate
          }}</label>
          <div class="wrp">
            <img
              src="../../../../../../assets/image/reports-icon-t.svg"
              class='user-icon'
              style="top: 9px"
            />
            <select
              class="form-control"
              [(ngModel)]="workOrderDetails.WorkStatusText"
              (change)="workOrderStatusChange($event)"
              disabled
              id="exampleFormControlSelect1"
            >
              <option [value]="null">
                -{{ 'BtnText.SelectStatus' | translate }}-
              </option>
              <option *ngFor="let value of statusList" [value]="value.Text">
                {{ value.DisplayText }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrapper-login">
  <div class="login-right-box">
    <div class="con-info-group first-form mar-top-70">
      <h5 class="d-none d-sm-block">
        {{ 'BtnText.ContactInfo' | translate }}
      </h5>
      <button
        class="btn btn-oclm"
        data-toggle="modal"
        data-target="#contact-info"
      >
        {{ 'BtnText.ViewDetails' | translate }}
      </button>
      <button
        *ngIf="hideEditButton()"
        class="btn btn-oclm"
        (click)="navigateURL(workOrderDetails.WorkOrderType)"
      >
        {{ 'CommonLabel.EditOrder' | translate }}
      </button>
      <button
        *ngIf="
          currentUserRole === 'SuperVisor' &&
          workOrderDetails.WorkStatusText === 'UnderReview' &&
          (workOrderDetails.CannedResponseId ||
            workOrderDetails.CannedResponseText)
        "
        class="btn btn-oclm"
        (click)="completeOrder()"
      >
        {{ 'BtnText.CompleteOrder' | translate }}
      </button>
    </div>
    <div class="wrp-map-stpr">
      <div class="first-form">
        <div class="row">
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            *ngIf="currentUserRole !== 'Admin'"
          >
            <div class="form-group">
              <label>
                {{ 'CommonLabel.JobName' | translate }} /
                {{ 'CommonLabel.CustomerName' | translate }}
              </label>
              <div class="wrp">
                <select
                  class="form-control"
                  id="fieldAgent"
                  [(ngModel)]="workOrderDetails.CustomerId"
                  disabled
                >
                  <option [value]="null">
                    -{{ 'CommonLabel.Select' | translate }}-
                  </option>
                  <option
                    *ngFor="let value of jobListDetails"
                    [value]="value.Id"
                  >
                    {{ value.Name }}
                  </option>
                </select>
                <img
                  src="../../../../assets/image/work-request-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <!--START Added for admin role -->
          <div
            class="col-md-6 col-sm-6 col-xs-12"
            *ngIf="currentUserRole === 'Admin'"
          >
            <div class="form-group">
              <label>
                {{ 'CommonLabel.JobName' | translate }} /
                {{ 'CommonLabel.CustomerName' | translate }}
              </label>
              <div class="wrp">
                <!-- job name search -->
                <input
                  [(ngModel)]="workOrderDetails.CustomerName"
                  [typeahead]="jobListDetails"
                  typeaheadOptionField="Name"
                  placeholder="Search job name"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-sm-6 col-xs-12"
            *ngIf="currentUserRole === 'Admin'"
          >
            <div class="form-group">
              <label>
                {{ 'CommonLabel.SelectSupervisor' | translate }}
              </label>
              <div class="wrp">
                <!-- supervisor search -->
                <input
                  type="text"
                  disabled
                  placeholder="{{ 'CommonLabel.Address' | translate }}"
                  [(ngModel)]="workOrderDetails.SupervisorName"
                  class="form-control"
                />

                <select
                  class="form-control sr-only"
                  id="fieldAgent"
                  disabled
                  [(ngModel)]="workOrderDetails.SuperVisorId"
                >
                  <option [value]="null">
                    -{{ 'CommonLabel.Select' | translate }}-
                  </option>
                  <option
                    *ngFor="let value of supervisorList"
                    [value]="value.ID"
                  >
                    {{ value.Text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--END Added for admin role -->
          <!-- location -->
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>
                {{ 'CommonLabel.Location' | translate }}/{{
                  'CommonLabel.Address' | translate
                }}
              </label>
              <div class="wrp">
                <input
                  type="text"
                  disabled
                  placeholder="{{ 'CommonLabel.Address' | translate }}"
                  [(ngModel)]="workOrderDetails.Address"
                  class="form-control"
                />
              </div>
              <img alt="icon"
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
                style="margin-top: 30px; margin-left: 15px"
              />
            </div>
            <a
              style="text-decoration: underline; cursor: pointer"
              href="https://www.google.com/maps/place/{{
                workOrderDetails.Address
              }}"
              class="btn d-sm-none btn-link"
              >View direction</a
            >
          </div>

          <!-- description -->

          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>{{ 'Table.WorkCategory' | translate }}</label>
              <div class="wrp">
                <select
                  class="form-control"
                  id="workcatergory"
                  [(ngModel)]="workOrderDetails.WorkCategory"
                  disabled
                >
                  <option [value]="null">
                    -{{ 'CommonLabel.Select' | translate }}-
                  </option>
                  <option
                    *ngFor="let value of workCategoryList"
                    [value]="value.Text"
                  >
                    {{ value.Text }}
                  </option>
                </select>
                <img alt="icon"
                  src="../../../../assets/image/job-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.DescriptionOfWork' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.WorkRequestID"
                placeholder="{{ 'CommonLabel.DescriptionOfWork' | translate }}"
                class="form-control"
              />
              <img alt="icon"
                src="../../../../../assets/image/comment-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>

        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label>{{ 'CommonLabel.Notes' | translate }}</label>
            <div class="wrp">
              <textarea
                placeholder="{{ 'CommonLabel.Notes' | translate }}"
                disabled
                rows="1"
                [(ngModel)]="workOrderDetails.Notes"
                class="form-control"
              ></textarea>
              <img alt="icon"
                src="../../../../../assets/image/comment-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-sm-4 col-xs-12 here"
          *ngIf="!showScheduleDate && !workOrderDetails.CompletedOn"
        >
          <div class="form-group">
            <label>
              {{ 'CommonLabel.CompleteBy' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                bsDatepicker
                disabled
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [(ngModel)]="workOrderDetails.CompletedBy"
                class="form-control"
              />
              <img alt="icon"
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-sm-4 col-xs-12 here"
          *ngIf="!showScheduleDate && workOrderDetails.CompletedOn"
        >
          <div class="form-group">
            <label>
              {{ 'CommonLabel.CompleteOn' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                bsDatepicker
                disabled
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [(ngModel)]="workOrderDetails.CompletedOn"
                class="form-control"
              />
              <img alt="icon"
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <!-- conditional StartedDate -->
        <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.ScheduleBy' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.StartDate"
                placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                bsDatepicker
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [bsConfig]="bsConfig"
                class="form-control"
              />
              <img alt="icon"
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="!existingRecord">
          <div class="form-group">
            <label>{{ 'CommonLabel.Notes' | translate }}</label>
            <div class="wrp">
              <textarea
                placeholder="{{ 'CommonLabel.Notes' | translate }}"
                rows="1"
                disabled
                [(ngModel)]="workOrderDetails.Notes"
                class="form-control"
              ></textarea>
              <img alt="icon"
                src="../../../../../assets/image/comment-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <ng-container *ngFor="let item of DynamicFieldsList; let i = index">
          <div class="col-md-8 col-sm-8 col-xs-4">
            <div class="form-group">
              <label>{{ item.FieldName }}</label>
              <div class="wrp">
                <input
                  readonly
                  class="form-control mb-3"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="item.Values"
                  placeholder="Values"
                />
                <img alt="icon"
                  src="../../../../assets/image/job-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
        </ng-container>
        <div
          class="col-md-8 col-sm-8 col-xs-12"
          *ngIf="currentUserRole == 'CommunityManager'"
        >
          <span>
            <label><strong>Add Notes</strong></label>
            <input
              [(ngModel)]="workOrderDetails.NotesVal"
              type="text"
              placeholder="Add Notes"
              class="form-control"
              maxlength="100"
            />
            <button
              type="button"
              class="btn btn-oclm mt-4 mb-4"
              (click)="addMoreFields()"
            >
              {{ 'BtnText.Add' | translate }}
            </button>
          </span>
        </div>
        <div
          class="col-md-8 col-sm-8 col-xs-12"
          *ngIf="existingRecord && !cmWorkOrderEnable"
        >
          <div class="form-group">
            <label>
              {{ 'CommonLabel.CannedResponse' | translate }}
            </label>
            <div class="wrp">
              <select
                class="form-control"
                id="cannedCategory"
                [(ngModel)]="workOrderDetails.CannedResponseId"
                disabled
              >
                <option [value]="null">
                  -{{ 'CommonLabel.Select' | translate }}-
                </option>
                <option
                  *ngFor="let value of cannedResponseList"
                  [value]="value.ID"
                >
                  {{ value.Text }}
                </option>
              </select>
              <img alt="icon"
                src="../../../../assets/image/job-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.CompleteBy' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                bsDatepicker
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [(ngModel)]="workOrderDetails.CompletedBy"
                disabled
                class="form-control"
              />
              <img alt="icon"
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-8 col-sm-8 col-xs-12"
          *ngIf="existingRecord && workOrderDetails.CannedResponseText"
        >
          <div class="form-group">
            <div class="wrp">
              <input
                type="text"
                disabled
                placeholder="Other canned response"
                [(ngModel)]="workOrderDetails.CannedResponseText"
                class="form-control"
              />
              <img alt="icon"
                src="../../../../assets/image/job-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-4"></div>
        <div
          class="col-md-8 col-sm-8 col-xs-8"
          *ngIf="WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0"
        >
          <div class="form-group">
            <label>
              {{ 'CommonLabel.ViewNotes' | translate }}
            </label>
            <div class="view-note-box">
              <!-- 1st box -->
              <ng-container *ngFor="let item of WorkOrderNotesDetail">
                <div
                  class="view-note"
                  *ngIf="item && item.NotesText && item.NotesText.length > 0"
                >
                  <div class="notify-msg">{{ item.CreatedBy | firstWord }}</div>
                  <div class="notify-text">
                    <span class="notify-date">
                      {{ item.CreatedBy }},
                      {{ item.CreatedDate | date: 'd MMMM, y, h:mm a' }}
                    </span>
                    <span>{{ item.NotesText }}</span>
                  </div>
                </div>
                <!-- 2st box -->
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-xs-12"></div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <p class="thumb-text">
            {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
          </p>
          <app-preview-file
            [uploadFile]="uploadBeforePhotoFilePath"
            [beforeComments]="uploadBeforeCommentData"
            [WorkIdVal]="workOrderId"
            [workOrderType]="workOrderType"
            [BeforeCreationWorkOrder]="'BeforeCreationWorkOrder'"
          ></app-preview-file>
        </div>
        <div class="col-md-4">
          <p class="thumb-text">
            {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
          </p>
          <app-preview-file
            [uploadFile]="uploadAfterPhotoFilePath"
            [afterComments]="uploadAfterCommentData"
            [WorkIdVal]="workOrderId"
            [AfterCreationWorkOrder]="'AfterCreationWorkOrder'"
            [workOrderType]="workOrderType"
          ></app-preview-file>
        </div>
      </div>

      <div class="login-left-Mapbox" style="opacity: 0.8">
        <app-google-map
          [extLat]="workOrderDetails.Latitude"
          [extLong]="workOrderDetails.Longitude"
          [markerDraggable]="false"
        >
        </app-google-map>
      </div>
    </div>
  </div>
</div>
<div
  style="margin-bottom: 30px"
  class="download-cls d-flex justify-content-center"
  *ngIf="existingRecord"
>
  <app-download-work-order
    [WorkIdVal]="workOrderId"
    [workOrderType]="workOrderType"
  ></app-download-work-order>
</div>

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
  id="contact-info"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">
          {{ 'BtnText.ContactInfo' | translate }}
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.FirstName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.ContactFirstName"
                placeholder="{{ 'CommonLabel.FirstName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img alt="icon"
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.LastName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.ContactLastName"
                placeholder="{{ 'CommonLabel.LastName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img alt="icon"
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.Email' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.ContactEmail"
                placeholder="{{ 'CommonLabel.Email' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img alt="icon"
                src="../../../../assets/image/email-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>
              {{ 'CommonLabel.PhoneNumber' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.ContactPhoneNumber"
                placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
                class="form-control"
                minlength='10'
                maxlength="10"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                required
              />
              <img alt="icon"
                src="../../../../assets/image/phone-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-12 form-group">
            <label>
              {{ 'CommonLabel.Location' | translate }}/{{
                'CommonLabel.Address' | translate
              }}
            </label>
            <div class="wrp">
              <input
                type="text"
                disabled
                maxlength="200"
                [(ngModel)]="workOrderDetails.ContactAddress"
                placeholder="{{ 'CommonLabel.Location' | translate }}"
                class="form-control"
              />
              <img alt="icon"
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
      </div>
      <div class="foot-btn text-center mt-1 mb-4">
        <button
          type="button"
          disabled
          class="btn btn-oclm w-50"
          data-dismiss="modal"
        >
          {{ 'BtnText.Save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>{{ message }}</p>
    <div class="modal-footer">
      <button type="button" class="btn btn-oclm" (click)="confirm()">Ok</button>
    </div>
  </div>
</ng-template>
