export const validation_messages = {
  firstname: { required: '*Firstname is required' },
  lastname: { required: '*Lastname is required' },
  email: {
    required: '*Email is required',
    email: '*Wrong email',
    invalidEmail: "*Please enter a valid email address (e.g., name@example.com)."
  },
  phone: { required: '*Phone is required' },
  role: { required: '*Role is required' },
  jobs: { required: '*Jobs is required' },
  ZipCode: { required: '*ZipCode is required' },
  CustomerName: { required: '*CustomerName is required' },
  City: { required: '*City is required' },
  CustomerClass: { required: '*CustomerClass is required' },
  BranchName: { required: '*BranchName is required' },
  CustomerId: { required: '*CustomerId is required' },
};
export const forms_errors = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  role: '',
  jobs: '',
  CustomerName: '',
  City: '',
  ZipCode: '',
  CustomerClass: '',
  BranchName: '',
  CustomerId: '',
};
