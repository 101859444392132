<div class="container" [class.disabled]="disabled" [class.outline]="outline">
  <input
    type="text"
    class="container__input"
    [placeholder]="placeholder"
    [type]="type"
    [formControl]="control"
    [matAutocomplete]="autocomplete"
    [attr.maxlength]="maxlength"
    [attr.disabled]="disabled || null"
    
  />
  <mat-autocomplete #autocomplete="matAutocomplete" class="input-control-autocomplete-panel">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name" (click)="autocompleteOptionClick()">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
  <div class="container__close-icon" (click)="resetInput($event)" *ngIf="!disabled">
    <svg-icon [@rotatedState]='!!value' class="icon" src="assets/icons/shared-controls/close.svg"></svg-icon>
  </div>
</div>
