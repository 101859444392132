// Comment one for using second

// This one for deployment //

export const environment = {
  production: false,
  headerTitle: 'HeaderTitle',
  CUID: 'currentUser',
  OLCMToken: 'OLCMID',
  ResidentUID: 'residentUser',
  applicationUrl: 'https://devworkorders.oclm.com/#',
  apiUrl: 'https://devworkorders.oclm.com/WEBAPI/',
  imagePath: 'https://oconnell.mobileprogramming.net/WEBAPI',
  googleApis: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
  googleAPIKey: 'AIzaSyB3IotPWwSy3YapBZt8iwfZ3f6CKsDzLg0',
  apiKey: 'AIzaSyAjgsY815j2HQYUYv5VgSUGU7p4gBQf5W0',
  clientId:
    '816152032639-pur2rns8con0j0099srat3fbfl15l1l0.apps.googleusercontent.com',
  firebase: {
    apiKey: 'AIzaSyAjgsY815j2HQYUYv5VgSUGU7p4gBQf5W0',
    authDomain: 'mobileprogramming-286716.firebaseapp.com',
    databaseURL: 'https://mobileprogramming-286716.firebaseio.com',
    projectId: 'mobileprogramming-286716',
    storageBucket: 'mobileprogramming-286716.appspot.com',
    messagingSenderId: '816152032639',
    appId: '1:816152032639:web:37d3fee06214510de1564a',
  },
  fireBaseLegacyServerKey:
    'AAAASlHsHmo:APA91bFvjRsSYm-kDlfLOO07Wz1HmKslaLh7vvsjhW8a63pompgCsA-rFXfIDmqUfFK1ZIwlsQ__KnnZ0qxSLavAMRgy65fOsb1PiQshY71a1yrTjzI5jnVqm85EpzFqC9dJWY0uqE-e',
  CurrentBrowserDeviceId: 'CurrentBrowserDeviceId',
};

// This one for development //

// export const environment = {
//   production: true,
//   headerTitle: 'HeaderTitle',
//   CUID: 'currentUser',
//   OLCMToken: 'OLCMID',
//   ResidentUID: 'residentUser',
//   applicationUrl: 'https://devworkorders.oclm.com/#/',
//   apiUrl: 'https://devworkorders.oclm.com/WEBAPI/',
//   imagePath: 'https://devworkorders.oclm.com/WEBAPI/',
//   googleApis: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
//   googleAPIKey: 'AIzaSyB3IotPWwSy3YapBZt8iwfZ3f6CKsDzLg0',
//   apiKey: 'AIzaSyAjgsY815j2HQYUYv5VgSUGU7p4gBQf5W0',
//   clientId:
//     '816152032639-pur2rns8con0j0099srat3fbfl15l1l0.apps.googleusercontent.com',
//   firebase: {
//     apiKey: 'AIzaSyAjgsY815j2HQYUYv5VgSUGU7p4gBQf5W0',
//     authDomain: 'mobileprogramming-286716.firebaseapp.com',
//     databaseURL: 'https://mobileprogramming-286716.firebaseio.com',
//     projectId: 'mobileprogramming-286716',
//     storageBucket: 'mobileprogramming-286716.appspot.com',
//     messagingSenderId: '816152032639',
//     appId: '1:816152032639:web:37d3fee06214510de1564a',
//   },
//   fireBaseLegacyServerKey:
//     'AAAASlHsHmo:APA91bFvjRsSYm-kDlfLOO07Wz1HmKslaLh7vvsjhW8a63pompgCsA-rFXfIDmqUfFK1ZIwlsQ__KnnZ0qxSLavAMRgy65fOsb1PiQshY71a1yrTjzI5jnVqm85EpzFqC9dJWY0uqE-e',
//   CurrentBrowserDeviceId: 'CurrentBrowserDeviceId',
// };
