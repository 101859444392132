import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  NgZone,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MapsAPILoader, AgmMap } from '@agm/core';

import { MapStyles } from 'src/app/shared/models/map-style';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css'],
})
export class GoogleMapComponent implements OnInit, OnChanges {
  address: string;
  locationChosen = false;
  latitude: number;
  longitude: number;
  zoom: number=16;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild(AgmMap, { static: true }) public agmMap: AgmMap;
  currentLocation: string
  @Input() extLong: number;
  @Input() agmHeight = '275px';
  @Input() extLat: number;
  @Input() markerDraggable = false;
  @Input() zipCode: string | any[];
  @Output() gmapResponse = new EventEmitter<any>();
  @Output() gmapForCityName = new EventEmitter<any>();

  styles = MapStyles;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnChanges(simpleChange: SimpleChanges) {
    if (this.zipCode) {
      this.getCoordinates(this.zipCode);
    } else if (
      simpleChange.extLat?.currentValue &&
      simpleChange.extLong?.currentValue
    ) {
      this.latitude = +simpleChange.extLat?.currentValue;
      this.longitude = +simpleChange.extLong.currentValue;
    }
  }

  ngOnInit(): void {
    this.GoogleMapLoad();
  }

  getCoordinates(address: string | any[]) {
    fetch(environment.googleApis + address + '&key=' + environment.googleAPIKey)
      .then((response) => response.json())
      .then((data) => {
        if (data.results) {
          this.latitude = data.results[0]?.geometry?.location?.lat;
          this.longitude = data.results[0]?.geometry?.location?.lng;

          this.gmapForCityName.emit({
            latitude: data.results[0]?.geometry?.location?.lat,
            longitude: data.results[0]?.geometry?.location?.lng,
            address: data.results[0]?.formatted_address,
          });
        }
      });
  }

  private GoogleMapLoad() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    });

  }

  setLocationName($event) {
    $event.preventDefault();
    if ($event.target.value) {
      this.latitude = $event.coords.lat;
      this.longitude = $event.coords.lat;
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = this.extLat ? this.extLat : position.coords.latitude;
        this.longitude = this.extLong
          ? this.extLong
          : position.coords.longitude;
        this.getAddress(this.latitude, this.longitude);

      });
    }
  }

  mapClicked($event) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.gmapResponse.emit({
      latitude: this.latitude,
      longitude: this.longitude,
    });
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder();
      let latlng = {
        lat: this.latitude,
        lng: this.longitude,
      };
      geocoder.geocode(
        {
          location: latlng,
        },
        function (results) {
          if (results[0]) {
            this.currentLocation = results[0].formatted_address;
          } else {
            console.log('Not found');
          }
        }
      );
    });
    this.locationChosen = true;
  }

  markerDragEnd($event) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.gmapResponse.emit({
      latitude: this.latitude,
      longitude: this.longitude,
    });
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: { formatted_address: string }[], status: string) => {
        if (status === 'OK') {
          if (results[0]) {

            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
}
