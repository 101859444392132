<nav
  id='sidebar'
  *ngIf='isSupervisor'
  [ngClass]='{ active: sidebarActive }'
  class='d-print-none'>
  <div class='sidebar-header' (click)='onHomePage()'>
    <img alt='icon'
         src='../../../../assets/image/small_logo.jpg'
         class='img-fluid web-log img-responsive' />
    <img alt='icon'
         src='../../../../assets/image/o-logo-1.png'
         class='mob-log img-fluid'/>
  </div>
  <div style='position: absolute;
              font-size: 14px;
              color: #a5a0a0;
              bottom: 10px;
              left: 75px;'>Version 2.4</div>
  <ul class='list-unstyled components'>
    <li [routerLinkActive]="['active']">
      <a
        class='home web'
        [routerLink]="['supervisor/home']"
        (click)="headerTileChange('Home')">
        {{ 'SideBar.Home' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['supervisor/home']"
        (click)="headerTileChange('Home')">
        <img alt='icon' src='../../../../assets/image/home-icon-2.svg' />
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        class='inbox web'
        [routerLink]="['supervisor/inbox']"
        (click)="headerTileChange('Inbox')">
        {{ 'SideBar.Inbox' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['supervisor/inbox']"
        (click)="headerTileChange('Inbox')"
      ><img alt='icon' src='../../../../assets/image/inbox-icon-2.svg'
      /></a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        class='field web'
        [routerLink]="['supervisor/field-agent']"
        (click)="headerTileChange('Field Agent')"
      >
        {{ 'SideBar.FieldAgent' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['supervisor/field-agent']"
        (click)="headerTileChange('Field Agent')"
      ><img alt='icon' src='../../../../assets/image/field-agent-icon-21.svg'
      /></a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        class='reports web'
        [routerLink]="['/reports']"
        (click)="headerTileChange('Reports')"
      >
        {{ 'SideBar.Reports' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/reports']"
        (click)="headerTileChange('Reports')"
      ><img alt='icon' src='../../../../assets/image/reports-icon-t.svg'
      /></a>
    </li>

    <li [routerLinkActive]="['active']">
      <a
        class='profile web'
        [routerLink]="['/profile']"
        (click)="headerTileChange('Profile')"
      >
        {{ 'SideBar.Profile' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/profile']"
        (click)="headerTileChange('Profile')"
      ><img alt='icon' src='../../../../assets/image/field-agent-icon-2.svg'
      /></a>
    </li>
    <!-- <li [routerLinkActive]="['active']">
      <a
        class="users web"
        [routerLink]="['/users']"
        (click)="headerTileChange('Users')"
      >
        {{ 'SideBar.Users' | translate }}
      </a>
      <a
        class="mob"
        [routerLink]="['/users']"
        (click)="headerTileChange('Users')"
        ><img src="../../../../assets/image/users.svg"
      /></a>
    </li> -->
    <li [routerLinkActive]="['active']">
      <a
        class='setting web'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      >
        {{ 'SideBar.Settings' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      ><img alt='icon' src='../../../../assets/image/settings-icon-2.svg'
      /></a>
    </li>

    <li>
      <a class='log-out web' data-toggle='modal' data-target='#logout'>
        {{ 'SideBar.Logout' | translate }}
      </a>

      <a data-toggle='modal' data-target='#logout' class='mob'>
        <img alt='icon' src='../../../../assets/image/logout-icon-2.svg' />
      </a>
    </li>

    <li class='help-block help-feedback-links'>
      <a target='_blank'
         href='https://docs.google.com/forms/d/e/1FAIpQLSfOBCyhMf2BAOtT4yN0kSlLRPxNn18F2jntoCndOuZ5rRyVdA/viewform'
         class='feedback web flex-align-center' style='display: flex'>
        Feedback
      </a>
      <!--      <a>-->
      <!--        <img alt='icon' src='../../../../assets/image/feedback-icon.svg'-->
      <!--             class='height-width-24px mob' />-->
      <!--      </a>-->
    </li>

    <!--    <li>-->
    <!--      <a target="_blank" href='' class='help-mr-80' >-->
    <!--        <img alt='icon' src='../../../../assets/image/help-icon12.png' class='height-width-24px' />-->
    <!--        <span>Help</span>-->
    <!--      </a>-->
    <!--    </li>-->
  </ul>
</nav>

<!-- Field agent side bar -->
<nav id='sidebar' *ngIf='isFieldAgent' [ngClass]='{ active: sidebarActive }'>
  <div class='sidebar-header' (click)='onHomePage()'>
    <img alt='icon'
         src='../../../../assets/image/small_logo.jpg'
         class='img-fluid web-log'
    />
    <img alt='icon'
         src='../../../../assets/image/o-logo-1.png'
         class='mob-log img-fluid'
    />
  </div>
  <div style='position: absolute;
              font-size: 14px;
              color: #a5a0a0;
              bottom: 10px;
              left: 75px;'>Version 2.4</div>
  <ul class='list-unstyled components'>
    <li [routerLinkActive]="['active']">
      <a
        class='home web'
        [routerLink]="['fieldagent/home']"
        (click)="headerTileChange('Home')"
      >
        {{ 'SideBar.Home' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['fieldagent/home']"
        (click)="headerTileChange('Home')"
      >
        <img alt='icon' src='../../../../assets/image/home-icon-2.svg' />
      </a>
    </li>

    <li [routerLinkActive]="['active']">
      <a
        class='profile web'
        [routerLink]="['/profile']"
        (click)="headerTileChange('Profile')"
      >
        {{ 'SideBar.Profile' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/profile']"
        (click)="headerTileChange('Profile')"
      ><img src='../../../../assets/image/field-agent-icon-2.svg'
      /></a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        class='setting web'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      >
        {{ 'SideBar.Settings' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      ><img src='../../../../assets/image/settings-icon-2.svg'
      /></a>
    </li>

    <li>
      <a class='log-out web' data-toggle='modal' data-target='#logout'>
        {{ 'SideBar.Logout' | translate }}
      </a>
      <a class='mob'
      ><img
        src='../../../../assets/image/logout-icon-2.svg'
        data-toggle='modal'
        data-target='#logout'
      /></a>
    </li>
    <li class='help-block help-feedback-links'>
      <a target='_blank'
         href='https://docs.google.com/forms/d/e/1FAIpQLSfOBCyhMf2BAOtT4yN0kSlLRPxNn18F2jntoCndOuZ5rRyVdA/viewform'
         class='feedback web flex-align-center' style='display: flex'>
        Feedback
      </a>
      <!--      <a>-->
      <!--        <img alt='icon' src='../../../../assets/image/feedback-icon.svg'-->
      <!--             class='height-width-24px mob' />-->
      <!--      </a>-->
  </ul>
</nav>
<!-- community manager side bar -->
<nav
  id='sidebar'
  *ngIf='isCommunityManager'
  [ngClass]='{ active: sidebarActive }'>
  <div class='sidebar-header' (click)='onHomePage()'>
    <img
      src='../../../../assets/image/small_logo.jpg'
      class='img-fluid web-log' />
    <img
      src='../../../../assets/image/o-logo-1.png'
      class='mob-log img-fluid' />
  </div>
  <div style='position: absolute;
              font-size: 14px;
              color: #a5a0a0;
              bottom: 10px;
              left: 75px;'>Version 2.4</div>
  <ul class='list-unstyled components'>
    <li [routerLinkActive]="['active']">
      <a
        class='home web'
        [routerLink]="['communitymanager/home']"
        (click)="headerTileChange('Home')"
      >{{ 'SideBar.Home' | translate }}</a
      >
      <a
        class='mob'
        [routerLink]="['communitymanager/home']"
        (click)="headerTileChange('Home')"
      ><img src='../../../../assets/image/home-icon-2.svg'
      /></a>
    </li>

    <li [routerLinkActive]="['active']">
      <a
        class='reports web'
        [routerLink]="['/reports']"
        (click)="headerTileChange('Reports')">
        {{ 'SideBar.Reports' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/reports']"
        (click)="headerTileChange('Reports')"
      ><img src='../../../../assets/image/reports-icon-t.svg'
      /></a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        class='profile web'
        [routerLink]="['/profile']"
        (click)="headerTileChange('Profile')"
      >
        {{ 'SideBar.Profile' | translate }}
      </a>
      <a class='mob' [routerLink]="['/profile']"
      ><img src='../../../../assets/image/field-agent-icon-2.svg'
      /></a>
    </li>
    <li [routerLinkActive]="['active']">
      <a
        class='setting web'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      >
        {{ 'SideBar.Settings' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      ><img src='../../../../assets/image/settings-icon-2.svg'
      /></a>
    </li>
    <li>
      <a class='log-out web' data-toggle='modal' data-target='#logout'>
        {{ 'SideBar.Logout' | translate }}
      </a>
      <a class='mob'><img
        src='../../../../assets/image/logout-icon-2.svg'
        data-toggle='modal'
        data-target='#logout'
      /></a>
    </li>
    <li class='help-block help-feedback-links'>
      <a target='_blank'
         href='https://docs.google.com/forms/d/e/1FAIpQLSfOBCyhMf2BAOtT4yN0kSlLRPxNn18F2jntoCndOuZ5rRyVdA/viewform'
         class='feedback web flex-align-center' style='display: flex'>
        Feedback
      </a>
    </li>


    <!--      <a>-->
    <!--        <img alt='icon' src='../../../../assets/image/feedback-icon.svg'-->
    <!--             class='height-width-24px mob' />-->
    <!--      </a>-->
  </ul>

</nav>
<!-- community manager side bar -->

<!-- Admin side bar -->
<nav id='sidebar' *ngIf='isAdmin' [ngClass]='{ active: sidebarActive }'>
  <div class='sidebar-header' (click)='onHomePage()'>
    <img
      src='../../../../assets/image/small_logo.jpg'
      class='img-fluid web-log'
    />
    <img
      src='../../../../assets/image/o-logo-1.png'
      class='mob-log img-fluid'
    />
  </div>
  <div style='position: absolute;
              font-size: 14px;
              color: #a5a0a0;
              bottom: 10px;
              left: 75px;'>Version 2.4</div>
  <ul class='list-unstyled components'>

    <li [routerLinkActive]="['active']">
      <a
        class='home web'
        [routerLink]="['admin/home']"
        (click)="headerTileChange('Home')"
      >
        {{ 'SideBar.Home' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['admin/home']"
        (click)="headerTileChange('Home')"
      >
        <img src='../../../../assets/image/home-icon-2.svg' />
      </a>
    </li>

    <li [routerLinkActive]="['active']">
      <a
        class='inbox web'
        [routerLink]="['admin/inbox/detail']"
        (click)="headerTileChange('Inbox')"
      >
        {{ 'SideBar.Inbox' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['admin/inbox/detail']"
        (click)="headerTileChange('Inbox')"
      ><img src='../../../../assets/image/inbox-icon-2.svg'
      /></a>
    </li>

    <li [routerLinkActive]="['active']">
      <a
        class='reports web'
        [routerLink]="['/reports']"
        (click)="headerTileChange('Reports')"
      >
        {{ 'SideBar.Reports' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/reports']"
        (click)="headerTileChange('Reports')"
      ><img src='../../../../assets/image/report-icon-t.svg'
      /></a>
    </li>

    <li [routerLinkActive]="['active']">
      <a
        class='profile web'
        [routerLink]="['/profile']"
        (click)="headerTileChange('Profile')">
        {{ 'SideBar.Profile' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/profile']"
        (click)="headerTileChange('Profile')"
      ><img src='../../../../assets/image/field-agent-icon-2.svg'
      /></a>
    </li>

    <!-- Users -->

     <li [routerLinkActive]="['active']">
      <a
        class="users web"
        [routerLink]="['/users']"
        (click)="headerTileChange('Users')">
        {{ 'SideBar.Users' | translate }}
      </a>
      <a
        class="mob"
        [routerLink]="['/users']"
        (click)="headerTileChange('Users')"
        ><img src="../../../../assets/image/users.svg"
      /></a>
    </li>

    <!-- Jobs -->

     <li [routerLinkActive]="['active']">
      <a
        class="jobs web"
        [routerLink]="['/jobs']"
        (click)="headerTileChange('Jobs')">
        {{ 'SideBar.Jobs' | translate }}
      </a>
      <a
        class="mob"
        [routerLink]="['/jobs']"
        (click)="headerTileChange('Jobs')"
        ><img src="../../../../assets/image/job-icon.svg"
      /></a>
    </li>

    <li [routerLinkActive]="['active']">
      <a
        class='setting web'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      >
        {{ 'SideBar.Settings' | translate }}
      </a>
      <a
        class='mob'
        [routerLink]="['/settings']"
        (click)="headerTileChange('Settings')"
      ><img src='../../../../assets/image/settings-icon-2.svg'
      /></a>
    </li>

    <li>
      <a class='log-out web' data-toggle='modal' data-target='#logout'>
        {{ 'SideBar.Logout' | translate }}
      </a>
      <a data-toggle='modal' data-target='#logout' class='mob'
      ><img src='../../../../assets/image/logout-icon-2.svg'
      /></a>
    </li>

    <li class='help-block help-feedback-links'>
      <a target='_blank'
         href='https://docs.google.com/forms/d/e/1FAIpQLSfOBCyhMf2BAOtT4yN0kSlLRPxNn18F2jntoCndOuZ5rRyVdA/viewform'
         class='feedback web flex-align-center' style='display: flex'>
        Feedback
      </a>
      <!--      <a>-->
      <!--        <img alt='icon' src='../../../../assets/image/feedback-icon.svg'-->
      <!--             class='height-width-24px mob' />-->
      <!--      </a>-->
  </ul>
</nav>

<!-- Admin side bar -->

<!-- Modal -->
<div
  class='modal fade'
  id='logout'
  tabindex='-1'
  role='dialog'
  aria-labelledby='exampleModalLabel'
  aria-hidden='true'
>
  <div class='modal-dialog modal-dialog-centered' role='document'>
    <div class='modal-content'>
      <div class='modal-header'>
        <h5 class='modal-title text-center'>
          {{ 'CommonLabel.LogOut' | translate }}
        </h5>
        <button
          type='button'
          class='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div class='modal-body'>
        <p class='delete-msg'>
          {{ 'Message.LogoutConfirm' | translate }}
          <br />
          {{ 'Message.LogoutWebsite' | translate }}
        </p>
        <div class='foot-btn'>
          <button
            type='button'
            class='btn btn-oclm-null'
            (click)='logout()'
            data-dismiss='modal'
          >
            {{ 'BtnText.LogOut' | translate }}
          </button>
          <button type='submit' class='btn btn-oclm' data-dismiss='modal'>
            {{ 'BtnText.NotNow' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
