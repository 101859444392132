import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './core/components/profile/profile.component';
import { AuthGuard } from './core/helpers/auth/auth.guard';
import { SettingsComponent } from './core/components/settings/settings.component';
import { ReportsComponent } from './core/components/reports/reports.component';
import { BlankPageComponent } from './core/components/blank-page/blank-page.component';
import { ReportDetailsComponent } from './core/components/report-details/report-details.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module')
      .then((am) => am.AuthModule)
  },
  {
    path: 'work_order_submit',
    loadChildren: () => import('./modules/unauthorized-user/unauthorized-user.module')
      .then((m) => m.UnauthorizedUserModule)
  },
  {
    path: 'resident',
    loadChildren: () =>
      import('./modules/resident/resident.module').then(
        (rm) => rm.ResidentModule
      ),
  },
  {
    path: 'supervisor',
    loadChildren: () =>
      import('./modules/oclm-supervisor/oclm-supervisor.module').then(
        (sup) => sup.OclmSupervisorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'fieldagent',
    loadChildren: () =>
      import('./modules/oclm-field-agent/oclm-field-agent.module').then(
        (fa) => fa.OclmFieldAgentModule
      ),
  },
  {
    path: 'communitymanager',
    loadChildren: () =>
      import(
        './modules/oclm-community-manager/oclm-community-manager.module'
        ).then((cm) => cm.OclmCommunityManagerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/oclm-admin/oclm-admin.module').then(
        (admin) => admin.OclmAdminModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('../app/core/Users/main-users/users.module').then(
        (users) => users.UsersModule
      ),
  },
  {
    path: 'jobs',
    loadChildren: () => import('./modules/jobs/jobs.module').then((jobs) => jobs.JobsModule),
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
  },
  {
    path: 'reports/:chartType',
    component: ReportDetailsComponent,
  },
  {
    path: 'blank',
    component: BlankPageComponent,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
