import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  currentUser: User;

  constructor(
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentLoginUserValue;
  }

  getWorkOrderListByStatus(
    statusKey,
    pageNo,
    pageSize,
    searchText,
    sortByWorkOrder,
    sortedColumn,
    isSortedDesc,
    dateFrom,
    dateTo,
    selectedJobs = ''
  ) {
    const API_URL = `${environment.apiUrl}${ConstantsService.Admin_APIURL.GetAdminListWorkOrderByStatus}`;
    let params = new HttpParams();
    let isCompleted: string | boolean = false;
    params = params.append('dateFrom', dateFrom);
    params = params.append('dateTo', dateTo);
    params = params.append('status', statusKey);
    params = params.append('pageNo', pageNo);
    params = params.append('pageSize', pageSize);
    params = params.append('isCompleteByMyself', isCompleted);

    if (sortedColumn && isSortedDesc) {
      params = params.append('sortedColumn', sortedColumn);
    }
    if (isSortedDesc === 'true') {
      params = params.append('isSortedDesc', 'desc');
    } else {
      params = params.append('isSortedDesc', 'asc');
    }

    params = params.append('searchText', searchText ? searchText : '');
    params = params.append(
      'sortByCreateDate',
      sortByWorkOrder ? sortByWorkOrder : true
    );
    params = params.append(
      'jobIds',
      selectedJobs
        ? selectedJobs
        : ''
    );
    return this.httpClient.get(API_URL, { params: params });
  }

}
