<div class="task-list">
  <div class="task-left-box">
    <div class="wrp pad-both">
      <input type="text" placeholder="{{ 'DropDownLabel.Search' | translate }}" class="form-control" #searchInputText />
      <img alt="search" src="../../../../assets/image/search-icon.svg" class='user-icon' />
      <a (click)="resetInputSearch()" *ngIf="enableCrossIcon">
        <img alt="close" src="../../../../assets/image/close.png" class="cross" />
      </a>
    </div>
  </div>
  <div class="task-right">
    <div class="dropdown">
      <button type="button" [routerLink]="['./create']" aria-haspopup="true" class="btn btn-danger btn-lg">
        {{ 'BtnText.CreateUser' | translate }}
      </button>
    </div>
  </div>
</div>
