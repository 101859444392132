import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/shared/services/common.service';
import { forkJoin, Subject } from 'rxjs';
import { Role } from 'src/app/shared/models/role';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ValidationForms } from '../../../../../helpers/services/validation-forms';
import { APIResponse } from 'src/app/shared/models/api-response';
import { forms_errors } from '../../../../../../shared/constants/message-validators';
import { LoaderService } from '../../../../../helpers/services/loader.service';
import { customEmailValidator } from '../../../../../../shared/utils/custom-form-validators';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
})
export class CreateUserComponent implements OnInit, OnDestroy {
  public userform: FormGroup;
  private userId: number = 0
  public roleList: Role[] = [];
  public workList = [];
  public modalRefClose: BsModalRef;
  dropdownSettings: IDropdownSettings = {};
  @ViewChild('templateChange') templateChange: TemplateRef<any>;
  @ViewChild('templateError') templateError: TemplateRef<any>;
  @ViewChild('templateSentAgain') templateSentAgain: TemplateRef<any>;
  @ViewChild('templateInvitationLink') templateInvitationLink: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public forms_errors = forms_errors;
  stepIndex = 1;
  userData: any;

  constructor(
    private location: Location,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private userService: UserService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getAllDataForForm();
    this.userform = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      email: new FormControl('', [customEmailValidator(), Validators.required]),
      phone: new FormControl('', Validators.compose([Validators.required])),
      jobs: new FormControl('', Validators.required),
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.phoneNumberValueChanges();
    this.userform.valueChanges.subscribe(() => console.log(this.userform))
  }

  phoneNumberValueChanges(): void {
    this.userform.get('phone').valueChanges.subscribe((value: string) => {
      // Apply the transformation function
      const formattedValue = this.formatPhoneNumber(value);

      // Update the form control value with the formatted value
      this.userform.get('phone').setValue(formattedValue, { emitEvent: false });
    });
  }

  public static initialForm(): FormGroup {
    return new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone: new FormControl('', Validators.compose([Validators.required])),
      jobs: new FormControl('', Validators.required),
      // vendor: new FormControl('', Validators.required),
      // punch: new FormControl('', Validators.required),
    });
  }

  blurForms() {
    return ValidationForms(this.userform);
  }

  getAllDataForForm() {
    let roledata$ = this.commonService.getUserTypeList();
    let workcategory$ = this.commonService.getAllJobList('');
    forkJoin(roledata$, workcategory$).subscribe(([role, work]: any) => {

      this.roleList = role;
      this.workList = work['Data'].map((item)=>{return {id:item.Id,name:item.Name}});
    });
  }

  backToTheList() {
    this.location.back();
  }

  formatPhoneNumber(value: string): string {
    if (value) {
      const numericValue = value.replace(/\D/g, '');
      return numericValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }


  onSubmit() {
    if (this.userform.invalid) return
    this.loaderService.isLoading.next(true);
    ValidationForms(this.userform);
    this.userService
      .createUser(this.userform.value)
      .pipe(
        switchMap((data) => {
          this.userData = data['Data'];
         return  this.userService.sendInvitationLink(data['Data'])
        })
      )
      .pipe(
        finalize(() => {
          this.loaderService.isLoading.next(false);
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: APIResponse) => {
          if (data?.StatusCode === 200 && data?.Success === true) {
            this.modalRefClose = this.modalService.show(this.templateChange);
            this.stepIndex = 2;
          }
        },
        (err) => {
          if (err.error.StatusCode === 201) {
            this.modalRefClose = this.modalService.show(this.templateError);
          } else if(err.error.StatusCode === 202) {
            this.userId = err.error.Data;
            this.modalRefClose = this.modalService.show(this.templateSentAgain);
          }
        }
      );
  }

  resendInviteLink(): void {
    this.userService.sendInvitationLink(this.userData)
      .subscribe(() => {
        this.modalRefClose = this.modalService.show(this.templateChange);
        this.stepIndex = 2;
      })
  }

  sentInviteAgain() {
    this.loaderService.isLoading.next(true);
    this.userService.sendInvitationLink(this.userId)
      .pipe(finalize(() => this.loaderService.isLoading.next(false)))
      .subscribe(() =>  this.modalRefClose.hide())
  }

  // getInvitationLink() {
  //   this.modalRefClose = this.modalService.show(this.templateInvitationLink);
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  resetForm() {
    this.userform.reset();
    this.getAllDataForForm();
    this.stepIndex = 1;
  }
}
