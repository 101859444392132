import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

interface DateDisplay {
  year: string;
  month: string;
  day: string;
}

@Injectable()
export class DatepickerDateAdapter extends NativeDateAdapter {

  parse(value: string): Date {

    if (value.match(/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/)) {
      //Checks is format dd/mm/yyyy

      let parts = value.split("/");
      return new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10));

    } else if (value.match(/^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/)) {
      //Checks is format dd.mm.yyyy
      let parts = value.split(".");
      return new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10));
    }
    return;
  }

  // getDayOfWeekNames(style: 'long' | 'short'): readonly string[] {
  //   return  getLocaleDayNames(this.locale, 1, 3);
  // }

  getFirstDayOfWeek(): number {
    return 1;
  }

  format(date: Date, displayFormat: string | DateDisplay): string {
    return new DatePipe(this.locale).transform(date, 'dd/MM/yyyy');
  }
}
