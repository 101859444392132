<div class="header">
  <button mat-icon-button (click)="previous()">
    <svg-icon src="../../../../../../assets/icons/arrow-left.svg"></svg-icon>
  </button>
  <span
    class="header__label"
    (click)="yearSelection()"
    matRipple
    matRippleColor="#1D58A8"
  >{{ month }}</span>
  <button mat-icon-button (click)="next()">
    <svg-icon src="../../../../../../assets/icons/arrow-right.svg"></svg-icon>
  </button>
</div>
