<div class="wrp-map-stpr">
  <div class="content">
    <div class="card card-body">
      <div class="first-form">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 con-info-group">
            <h5>{{ 'BtnText.ContactInfo' | translate }}</h5>
            <button
              class="btn btn-oclm"
              data-toggle="modal"
              data-target="#contact-info"
            >
              {{ 'BtnText.ViewDetails' | translate }}
            </button>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label
                >{{ 'CommonLabel.JobName' | translate }} /
                {{ 'CommonLabel.CustomerName' | translate }}</label
              >
              <div class="wrp">
                <input
                  type="text"
                  readonly
                  [(ngModel)]="taskOrderList.JobName"
                  placeholder="{{ 'CommonLabel.JobName' | translate }} / {{
                    'CommonLabel.CustomerName' | translate
                  }}"
                  class="form-control"
                />
                <img
                  src="../../../../assets/image/work-request-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>{{ 'CommonLabel.DescriptionOfWork' | translate }}</label>
              <div class="wrp">
                <input
                  type="text"
                  readonly
                  [(ngModel)]="taskOrderList.WorkRequestID"
                  placeholder="{{
                    'CommonLabel.DescriptionOfWork' | translate
                  }}"
                  class="form-control"
                />
                <img
                  src="../../../../assets/image/comment-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>{{ 'CommonLabel.CreatedBy' | translate }}</label>
              <div class="wrp">
                <input
                  type="text"
                  readonly
                  [(ngModel)]="taskOrderList.LastName"
                  placeholder="{{ 'CommonLabel.CreatedBy' | translate }}"
                  class="form-control"
                />
                <img
                  src="../../../../../assets/image/user-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label
              >{{ 'CommonLabel.Location' | translate }}/{{
                'CommonLabel.Addresss' | translate
              }}</label
            >
            <div class="wrp">
              <input
                type="text"
                readonly
                [(ngModel)]="taskOrderList.Address"
                placeholder="Address"
                class="form-control"
              />
              <img
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12">
          <div class="form-group">
            <label>{{ 'CommonLabel.CompleteBy' | translate }}</label>
            <div class="wrp">
              <input
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [(ngModel)]="taskOrderList.CompletedBy"
                type="text"
                placeholder="Complete by"
                bsDatepicker
                class="form-control"
              />
              <img
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label>{{ 'Table.WorkCategory' | translate }}</label>
            <div class="wrp">
              <select
                class="form-control"
                id="workcatergory"
                [(ngModel)]="taskOrderList.WorkCategory"
                (change)="onChangeOfWorkCategory($event.target.value)"
              >
                <!-- [ngClass]="{ 'is-invalid': submitted && f.WorkCategory.errors }" -->
                <option [value]="null">-select-</option>
                <option
                  *ngFor="let value of workCategoryList"
                  [value]="value.Text"
                >
                  {{ value.Text }}
                </option>
              </select>
              <img
                src="../../../../assets/image/job-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12">
          <div class="form-group">
            <label>{{ 'CommonLabel.CannedResponse' | translate }}</label>
            <div class="wrp">
              <select
                class="form-control"
                id="workcatergory"
                [(ngModel)]="taskOrderList.CannedResponseId"
              >
                <!-- [ngClass]="{ 'is-invalid': submitted && f.WorkCategory.errors }" -->
                <option [value]="null">
                  -{{ 'CommonLabel.Select' | translate }}-
                </option>
                <option
                  *ngFor="let value of cannedResponseList"
                  [value]="value.ID"
                >
                  {{ value.Text }}
                </option>
              </select>
              <img
                src="../../../../assets/image/job-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label>{{ 'CommonLabel.Notes' | translate }}</label>
            <div class="wrp">
              <textarea
                rows="1"
                [(ngModel)]="taskOrderList.Notes"
                class="form-control"
              ></textarea>
              <img
                src="../../../../../assets/image/comment-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-4"></div>
        <div
          class="col-md-8 col-sm-8 col-xs-12"
          *ngIf="WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0"
        >
          <div class="form-group">
            <label>{{ 'CommonLabel.ViewNotes' | translate }}</label>
            <div class="view-note-box">
              <!-- 1st box -->
              <ng-container *ngFor="let item of WorkOrderNotesDetail">
                <div
                  class="view-note"
                  *ngIf="item && item.NotesText && item.NotesText.length > 0"
                >
                  <div class="notify-msg">{{ item.CreatedBy | firstWord }}</div>
                  <div class="notify-text">
                    <span class="notify-date">
                      {{ item.CreatedBy }},
                      {{ item.CreatedDate | date: 'd MMMM, y,h:mm a' }}
                    </span>
                    <span>{{ item.NotesText }}</span>
                  </div>
                </div>
                <!-- 2st box -->
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-sm-4 col-xs-4"
          *ngIf="WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0"
        ></div>
        <ng-container *ngFor="let item of DynamicFieldsList; let i = index">
          <div class="col-md-8 col-sm-8 col-xs-4" *ngIf="i % 2 == 0">
            <div class="form-group">
              <label>{{ item?.Text }}</label>
              <div class="wrp">
                <input
                  class="form-control mb-3"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="item.Value"
                  placeholder="Values"
                />
                <img
                  src="../../../../assets/image/job-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4" *ngIf="i % 2 != 0">
            <div class="form-group">
              <label>{{ item.Text }}</label>
              <div class="wrp">
                <input
                  class="form-control mb-3"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="item.Value"
                  placeholder="Values"
                />
                <img
                  src="../../../../assets/image/job-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
        </ng-container>
        <div class="col-md-8 col-sm-8 col-xs-12 add-field">
          <span
            ><strong>{{ 'BtnText.AddMoreField' | translate }}</strong></span
          >
          <span>
            <app-add-more-fields
              (onAddMoreFields)="onAddMoreFieldsResponse($event)"
            >
            </app-add-more-fields>
          </span>
        </div>

        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label>{{
              'CommonLabel.LocationCompletionWorkOrder' | translate
            }}</label>
            <div class="wrp">
              <input
                type="text"
                [(ngModel)]="taskOrderList.CompletedAddress"
                placeholder="{{
                  'CommonLabel.LocationCompletionWorkOrder' | translate
                }}"
                class="form-control"
              />
              <img
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
      </div>

      <div class="first-form">
        <div class="row">
          <div class="col-md-6 c0l-xs-12 mb-4">
            <p class="thumb-text">
              {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
            </p>
            <app-preview-file
              [uploadFile]="uploadBeforePhotoFilePath"
            ></app-preview-file>
            <!-- <button type="submit" class="btn btn-secondary z-index"> Upload More </button> -->
            <app-multi-file-upload
              (OnFileUploadResponse)="fileUploadResponseBefore($event)"
              [UploadBtnText]="'Upload Picture'"
            >
            </app-multi-file-upload>
          </div>

          <div class="col-md-6 c0l-xs-12 mb-4">
            <p class="thumb-text">
              {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
            </p>
            <app-preview-file
              [uploadFile]="uploadAfterPhotoFilePath"
            ></app-preview-file>
            <!-- <button type="submit" class="btn btn-secondary z-index"> Upload Picture </button> -->
            <app-multi-file-upload
              (OnFileUploadResponse)="fileUploadResponseAfter($event)"
              [UploadBtnText]="'Upload Picture'"
            >
            </app-multi-file-upload>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-1 group-btn-last">
    <button
      type="submit"
      tabindex="0"
      class="btn btn-oclm-null router-link-active"
      (click)="backToLast()"
    >
      {{ 'BtnText.Cancel' | translate }}
    </button>
    <span class="download-cls">
      <app-download-work-order
        [WorkIdVal]="WorkIdVal"
        [workOrderType]="'resident'"
      ></app-download-work-order>
    </span>
    <div class="text-right">
      <button class="btn btn-oclm w-50" (click)="createWorkOrder()">
        {{ 'BtnText.Save' | translate }}
        <img
          src="../../../../assets/image/065-right-arrow-1.svg"
          class="arrow-right pull-right"
        />
      </button>
    </div>
  </div>

  <div class="login-left-Mapbox" style="opacity: 0.8">
    <div>
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'CommonLabel.SearchNearestLocation' | translate }}"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        #search
      />
    </div>
    <agm-map
      [styles]="styles"
      [disableDefaultUI]="false"
      [latitude]="latitude"
      [longitude]="longitude"
      [zoom]="zoom"
    >
      <agm-marker
        [latitude]="latitude"
        [longitude]="longitude"
        [iconUrl]="iconURI"
      ></agm-marker>
    </agm-map>
  </div>
</div>

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
  id="contact-info"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">
          {{ 'BtnText.ContactInfo' | translate }}
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.FirstName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                readonly
                [(ngModel)]="taskOrderList.FirstName"
                placeholder="{{ 'CommonLabel.FirstName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.LastName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                readonly
                [(ngModel)]="taskOrderList.LastName"
                placeholder="{{ 'CommonLabel.LastName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.Email' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                readonly
                [(ngModel)]="taskOrderList.ContactEmail"
                placeholder="{{ 'CommonLabel.Email' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/email-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.PhoneNumber' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                readonly
                [(ngModel)]="taskOrderList.PhoneNumber"
                placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
                appPhoneNumber
                class="form-control"
                minlength='10'
                maxlength="10"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                required
              />
              <img
                src="../../../../assets/image/phone-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-12 form-group">
            <label
              >{{ 'CommonLabel.Location' | translate }}/{{
                'CommonLabel.Address' | translate
              }}</label
            >
            <div class="wrp">
              <input
                type="text"
                readonly
                [(ngModel)]="taskOrderList.Address"
                placeholder="{{ 'CommonLabel.Address' | translate }}"
                class="form-control"
                maxlength="200"
              />
              <img
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
      </div>
      <div class="foot-btn text-center mt-1 mb-4">
        <button type="button" class="btn btn-oclm w-50" data-dismiss="modal">
          {{ 'BtnText.Ok' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngFor="let item of uploadAfterPhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngFor="let item of uploadBeforePhotoFilePath; let i = index">
  <!-- The Modal -->
  <div class="modal fade" id="model0{{ i }}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <!-- Modal body -->
        <div class="modal-body">
          <img [src]="apiUrl + item" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
