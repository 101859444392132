import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal/public_api';

@Component({
  selector: 'app-add-more-fields',
  templateUrl: './add-more-fields.component.html',
  styleUrls: ['./add-more-fields.component.css'],
})
export class AddMoreFieldsComponent implements OnInit {
  isModalShown = false;
  FieldName: string;
  Values: string;
  @ViewChild('autoShownModal', { static: false })
  autoShownModal: ModalDirective;

  @Output()
  onAddMoreFields: EventEmitter<any>;
  constructor() {
    this.onAddMoreFields = new EventEmitter<any>();
  }

  ngOnInit(): void {}

  //#region - for Model after successfully creation and add more items
  showModal(): void {
    this.isModalShown = true;
  }
  hideModal(): void {
    this.autoShownModal.hide();
  }
  onHidden(): void {
    this.isModalShown = false;
  }

  addItem(): void {
    this.isModalShown = true;
    this.FieldName = '';
    this.Values = '';
  }

  addMoreFields(): void {
    let object = {
      FieldName: this.FieldName,
      Values: this.Values,
    };
    this.onAddMoreFields.emit(object);
    this.isModalShown = false;
  }
  //#endregion
}
