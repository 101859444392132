import { SupervisorService } from 'src/app/modules/oclm-supervisor/services/supervisor.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { comment, commentDoc, UploadDocument } from '../../models/work-order';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { APIResponse } from '../../models/api-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.css']
})
export class PreviewFileComponent implements OnChanges, OnDestroy {
  apiUrl = environment.apiUrl;
  modalRef: BsModalRef;
  imageExt = ['png', 'jpg', 'jpeg', 'gif', 'tiff'];

  @Input() uploadFile: any[];
  @Input() base64 = false;
  @Output() onImageDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onImageCommentUpdated: EventEmitter<any>
    = new EventEmitter<{ comment: string, docId: string }>();
  @Output() updateBeforeCommentWhileCreatingOrder: EventEmitter<comment>
    = new EventEmitter<comment>();
  @Output() updateAfterCommentWhileCreatingOrder: EventEmitter<comment>
    = new EventEmitter<comment>();
  @Input() WorkIdVal: any;
  @Input() AfterCreationWorkOrder: any;
  @Input() BeforeCreationWorkOrder: string;
  @Input() workOrderType: string;
  @Input() beforeComments: { uploadComment: string, uploadId: string }[] = [];
  @Input() afterComments = [];
  @Input() isDelete = false;
  @Input() isEdit = true;
  @Input() view = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  updateCommentBefore: UploadDocument;
  updateCommentAfter: UploadDocument;
  comment: string;
  commentId: string;
  carouselItems: any[];
  activeSlideIndex = 0;
  imagePreview: any;
  sanitizedFileData: SafeResourceUrl;

  constructor(
    private modalService: BsModalService,
    private supervisorService: SupervisorService,
    private sanitizer: DomSanitizer,
  ) {
    this.onImageDelete = new EventEmitter<any>();
    this.onImageCommentUpdated = new EventEmitter<any>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.uploadFile?.currentValue[changes?.uploadFile?.currentValue.length - 1 ]?.uploadComment === undefined){
      this.comment = '';
    }
    if (changes.BeforeCreationWorkOrder) {
      if (this.BeforeCreationWorkOrder === 'BeforeCreationWorkOrder') {
        this.carouselItems = Object.assign([], this.uploadFile);
      } else {
        this.carouselItems = Object.assign([], this.uploadFile);
      }
    }
  }

  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  onImageClick(template: TemplateRef<any>, item: any) {
    if (item.extension !== 'pdf') {
      this.carouselItems = Object.assign([], this.uploadFile).sort((a, b) =>
        a.extension > b.extension ? 1 : -1);
      const selectedIndex = this.carouselItems.findIndex(
        (f) => f.filePath === item.filePath);
      this.array_move(this.carouselItems, selectedIndex, 0);
      this.imagePreview = item;
      this.modalRef = this.modalService.show(template);
    }
  }

  onDeleteImage(item: any,index: number) {
    const result = confirm('Are you sure you want to delete?');

    if (result) {
      let docId: string;
      if (item.filePath.includes('https://storage.googleapis.com')) {
        docId = item.filePath.split('/')[5].split('.')[0];
      } else {
        docId = item.filePath.split('/')[2].split('.')[0];
      }
      if (index >= 0 && index < this.uploadFile.length) {
        this.uploadFile.splice(index, 1);
        this.onImageDelete.next(docId)
      }
    }

  }

  onImageCommentUpdate(filePathUrl: string, EditCommentTemplate: any) {
    if (this.WorkIdVal) {
      this.supervisorService
        .getWorkOrderByWorkId(this.WorkIdVal, this.workOrderType ? this.workOrderType : 'manual')
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: APIResponse) => {
          if (this.BeforeCreationWorkOrder === 'BeforeCreationWorkOrder') {
            this.updateCommentBefore = result.Data.BeforeWorkOrderDocumentDetail
              .find((x: { uploadDocument: string }) => x.uploadDocument === filePathUrl);
            if (this.updateCommentBefore === undefined) {
              this.commentId = filePathUrl.split('/')[5].split('.')[0];
              this.comment = '';
              return;
            }

            this.comment = this.updateCommentBefore?.uploadComment;
            this.commentId = this.updateCommentBefore?.uploadDocument.split('/')[5].split('.')[0];

          } else if (this.AfterCreationWorkOrder === 'AfterCreationWorkOrder') {
            this.updateCommentAfter = result.Data.AfterWorkOrderDocumentDetail
              .find((x: { uploadDocument: string }) => x.uploadDocument === filePathUrl);
            if (this.updateCommentAfter === undefined) {
              this.commentId = filePathUrl.split('/')[5].split('.')[0];
              this.comment = '';
              return;
            }

            this.comment = this.updateCommentAfter?.uploadComment;
            this.commentId = this.updateCommentAfter?.uploadDocument.split('/')[5].split('.')[0];
          }
        });
    } else {
      this.updateCommentWhileCreatingOrder(filePathUrl);
    }
    this.modalRef = this.modalService.show(EditCommentTemplate);
  }

  updateCommentWhileCreatingOrder(filePathUrl: string): void {
    const docId = filePathUrl.split('/')[5].split('.')[0];
    const beforeComment = this.beforeComments.find((commentObj) => {
      return commentObj.uploadId === docId;
    });
    const afterComment = this.afterComments.find((commentObj: commentDoc) => commentObj.uploadId === docId);

    let comment: commentDoc;
    comment = beforeComment ? beforeComment : afterComment;
    this.comment = comment?.uploadComment;
    this.commentId = comment?.uploadId;
  }

  updateImageComment(comment: string, docId: string) {
    if (this.WorkIdVal && (this.updateCommentAfter || this.updateCommentBefore)) {
      this.onImageCommentUpdated.emit({ docId, comment });
      this.modalRef.hide();
      return;
    }
    if (this.BeforeCreationWorkOrder) {
      this.updateBeforeCommentWhileCreatingOrder.emit({ comment, uploadId: docId });
    } else {
      this.updateAfterCommentWhileCreatingOrder.emit({ comment, uploadId: docId });
    }

    this.modalRef.hide();
  }

  getUrl(filePath: string) {
    if (filePath.includes('https://storage.googleapis.com')) {
      return filePath;
    } else {
      return environment.apiUrl + filePath;
    }
  }

  get64Url(fileContent: any, type = ''): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:${type};base64,${fileContent}`);
  }

  openDocumentInNewTab(data, type) {
    const sanitizedUrl = this.get64Url(data, type);
    const blob = this.dataURItoBlob(sanitizedUrl['changingThisBreaksApplicationSecurity'].toString());

    // Create a blob URL
    const blobUrl = URL.createObjectURL(blob);

    // Open the blob URL in a new tab
    const newTab = window.open(blobUrl, '_blank');

    // Revoke the blob URL after opening the new tab (optional)
    URL.revokeObjectURL(blobUrl);

    // Check if the new tab was successfully opened
    if (newTab) {
      newTab.focus();
    } else {
      console.error('Failed to open the document in a new tab.');
    }
  }

  // Convert data URI to Blob
  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  findExt(uploadDocument: string) {
    const ext = uploadDocument
      .substring(uploadDocument.lastIndexOf('.') + 1)
      .toLowerCase();
    return this.imageExt.includes(ext);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
