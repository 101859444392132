<button class="btn btn-sm btn-secondary" (click)="toggleCalendar()">
  {{ buttonName }}
</button>
<ng-container *ngIf="toggleButton; else tabularView">
  <div class="calendar-box">
    <div>
      <div class="btn-group">
        <div
          class="btn btn-calendar"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-left"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg>
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate">
          Today
        </div>
        <div
          class="btn btn-calendar"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-chevron-right"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="text-center text-middle">
      <h4>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</h4>
    </div>
    <div class="text-md-right text-sm-left">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          Month
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Week
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day">
          Day
        </div>
      </div>
    </div>
  </div>

  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </div>
</ng-container>
<ng-template #tabularView>
  <app-calendar-table
    [searchText]="searchText"
    [jobIds]="jobIds"
    [fieldAgentId]="fieldAgentId"
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    [iscompleteByMyself]="isCompleteByMyself"
  ></app-calendar-table>
</ng-template>
