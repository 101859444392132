import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { FileUploadAPIResponse } from '../../models/api-response';
import { CommonService } from '../../services/common.service';
import { ConstantsService } from '../../services/constants.service';
import { LoaderService } from 'src/app/core/helpers/services/loader.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  uploadResponse = { status: '', message: '', filePath: '' };
  inputFiles: File[] = [];
  modalRef: BsModalRef;
  message: string;
  @ViewChild('template') template: TemplateRef<any>;
  error: HttpErrorResponse;

  @Input()
  multiple;

  @Input()
  accept = 'pdf, xlxs, pptx, m4a';

  @Input()
  maxFileSize = 10485760;

  @Input()
  UploadBtnText = 'Upload Files';

  @Output()
  OnFileUploadResponse: EventEmitter<FileUploadAPIResponse>;

  constructor(
    private uploadService: UploadService,
    private modalService: BsModalService,
    private commonService: CommonService,
    private loaderService: LoaderService
  ) {
    this.OnFileUploadResponse = new EventEmitter<FileUploadAPIResponse>();
  }

  ngOnInit() {}

  onFileChange(event) {
    if (event.target.files.length > 0) {
      for (const file of event.target.files) {
        if (this.isFileTypeAllowed(file)) {
          const indexOfExistingFile: number = this.inputFiles.findIndex(
            (item) => item.name === file.name
          );
          if (-1 !== indexOfExistingFile) {
            this.inputFiles.splice(indexOfExistingFile, 1);
          }
          // If no duplicate file the push in model
          this.inputFiles.push(file);
        }
      }
    }
  }

  /**
   * Function to check if file type is allowed or not
   * @param file: Input file object
   * @returns: true (fle type is allowed)/false (file type is not allowed)
   */
  isFileTypeAllowed(file): boolean {
    let fileExtension = file.name.split('.');
    fileExtension = fileExtension[fileExtension.length - 1];
    return (file.type &&
        (-1 !== this.accept.indexOf(file.type.split('/')[0]) ||
          -1 !== this.accept.indexOf(file.type.split('/')[1]))) ||
      -1 !== this.accept.indexOf(fileExtension);
  }

  /**
   * Function to handle clear all file event
   */
  onClearAll() {
    this.inputFiles.splice(0, this.inputFiles.length);
  }

  onSubmit() {
    if (this.inputFiles.length === 0) {
      // logic to display the message
      return;
    }
    this.loaderService.isLoading.next(true);
    // Added in the form data
    let formData = new FormData();
    this.inputFiles.forEach((element) => {
      formData.append('file', element, element.name);
    });
    // Service to upload the file in the server
    this.uploadService.upload(formData).subscribe(
      (res) => {
        this.uploadResponse = res;
        if (
          res.StatusCode === 200 &&
          res.Success &&
          res.Message === 'Success' &&
          res.Data &&
          res.Data.DocumentID
        ) {
          this.loaderService.isLoading.next(false);
          //emit the event to get the document order id
          this.OnFileUploadResponse.emit(res);
          // this.thumbnailImage = environment.apiUrl + (res.Data.FilePath[0] || '');
          this.onClearAll();
        }
      },
      (err: HttpErrorResponse) => {
        this.loaderService.isLoading.next(false);
        this.message = ConstantsService.ErrorMessage.FileUploadFailed;
        this.modalRef = this.modalService.show(this.template, {
          class: 'modal-md',
        });

        this.error = err;
      }
    );
  }
  confirm(): void {
    this.modalRef.hide();
  }
}
