<div class="container mt-3 mb-3 ml-3" *ngIf="this.currentUser.Role === 'Admin'">
  <input
    [typeahead]="userListData"
    typeaheadOptionField="Name"
    (typeaheadOnSelect)="onSelect($event)"
    [isAnimated]="true"
    placeholder="Search Users"
    class="form-control w-25"
  />
</div>

<form
  name="form"
  (ngSubmit)="f.form.valid && onSave()"
  #f="ngForm"
  [mustMatch]="['password', 'RePassword']"
  novalidate>
  <div class="main-padding">
    <h5 class="mb-4">{{ 'CommonLabel.ChangePassword' | translate }}</h5>
    <div class="row">
      <div class="col-md-6 col-sx-12">
        <div class="form-group">
          <label>{{ 'CommonLabel.NewPassword' | translate }}</label>
          <div class="wrp">
            <input
              [type]="show ? 'text' : 'password'"
              placeholder="{{ 'CommonLabel.NewPassword' | translate }}"
              [(ngModel)]="newPassword"
              name="password"
              maxlength="20"
              minlength="6"
              #Password="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && Password.invalid }"
              class="form-control"
            />
            <img
              src="../../../../assets/image/password-icon.svg"
              class='user-icon'
            />
            <img
              src="../../../../assets/image/eye-icon-1.svg"
              class="eye-iocn"
              (click)="password('newPass')"/>
            <div
              *ngIf="f.submitted && Password.invalid"
              class="invalid-feedback">
              <div *ngIf="Password.errors.required">Please enter password</div>
              <div *ngIf="Password.errors.minlength">
                Password must be at least 6 characters
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sx-12">
        <div class="form-group">
          <label>{{ 'CommonLabel.ConfirmNewPassword' | translate }}</label>
          <div class="wrp">
            <input
              [type]="showConf ? 'text' : 'password'"
              placeholder="{{ 'CommonLabel.ConfirmNewPassword' | translate }}"
              [(ngModel)]="confPassword"
              name="RePassword"
              maxlength="20"
              #RePassword="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && RePassword.invalid }"
              class="form-control pr-4"
            />
            <img
              src="../../../../assets/image/password-icon.svg"
              class='user-icon'
            />
            <img
              src="../../../../assets/image/eye-icon-1.svg"
              class="eye-iocn"
              (click)="password('confPass')"
            />
            <div
              *ngIf="f.submitted && RePassword.invalid"
              class="invalid-feedback"
            >
              <!-- <div *ngIf="RePassword.errors.required">Please enter confirm password</div> -->
              <div *ngIf="RePassword.errors.mustMatch">
                Please enter the same password as password Field
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h5 class="mt-3 mb-4">{{ 'CommonLabel.UpdateLanguage' | translate }}</h5>
    <div class="row">
      <div class="col-md-6 col-sx-12">
        <div class="form-group">
          <label>{{ 'CommonLabel.DefaultLanguage' | translate }}</label>

          <select
            class="form-control"
            (change)="switchLang($event.target.value)"
            [(ngModel)]="settingDetails.LanguageName"
            [ngModelOptions]="{ standalone: true }">
            >
            <option
              *ngFor="let language of languageList"
              [value]="language.value">
              {{ language.key }}
            </option>
          </select>
          <!-- <div #elementRef id="google_translate_element"></div> -->
        </div>
      </div>
    </div>
    <h5 class="mt-3 mb-4">
      {{ 'CommonLabel.NotificationSettings' | translate }}
    </h5>

    <div class="row" *ngFor="let setting of emailSettings">
      <div class='settings-row'>
        <div class='notification-settings-label' >{{ setting.Text }}</div>
        <div class="radio-wrp">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              class="custom-control-input"
              [id]="'yes'+setting.Id"
              [name]="'created'+setting.Id"
              [(ngModel)]="setting.Enabled"
              value="true"/>
            <label class="custom-control-label" [for]="'yes'+setting.Id">
              {{ 'BtnText.Yes' | translate }}
            </label>
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              class="custom-control-input"
              [id]="'no'+setting.Id"
              [(ngModel)]="setting.Enabled"
              [name]="'created'+setting.Id"
              value="false"/>
            <label class="custom-control-label" [for]="'no'+setting.Id">
              {{ 'BtnText.No' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
<!--      <div class="col-md-4">-->
<!--        <label for="isVacationOn">{{-->
<!--          'CommonLabel.Vacation' | translate-->
<!--        }}</label>-->
<!--        <div class="radio-wrp">-->
<!--          <div class="custom-control custom-radio">-->
<!--            <input-->
<!--              type="radio"-->
<!--              class="custom-control-input"-->
<!--              id="isVacationOn"-->
<!--              name="isVacation"-->
<!--              [(ngModel)]="settingDetails.IsVacation"-->
<!--              value="true"-->
<!--            />-->
<!--            <label class="custom-control-label" for="isVacationOn">-->
<!--              {{ 'BtnText.Yes' | translate }}-->
<!--            </label>-->
<!--          </div>-->
<!--          <div class="custom-control custom-radio">-->
<!--            <input-->
<!--              type="radio"-->
<!--              class="custom-control-input"-->
<!--              id="isVacationOff"-->
<!--              [(ngModel)]="settingDetails.IsVacation"-->
<!--              name="isVacation"-->
<!--              value="false"-->
<!--            />-->
<!--            <label class="custom-control-label" for="isVacationOff">-->
<!--              {{ 'BtnText.No' | translate }}-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="button-wrp mt-5">
      <button class="btn btn-oclm-null" (click)="backToPrevious('true')">
        {{ 'BtnText.Cancel' | translate }}
      </button>
      <div class="col-md-4">
        <button class="btn btn-oclm w-100">
          {{ 'BtnText.SaveSetting' | translate }}
          <img
            src="../../../../assets/image/065-right-arrow-1.svg"
            class="arrow-right pull-right"
          />
        </button>
      </div>
    </div>
  </div>
</form>

<ng-template #template>
  <div class="modal-body text-center">
    <p>{{ message }}</p>
    <button type="button" class="btn btn-oclm" (click)="confirm()">Ok</button>
  </div>
</ng-template>
