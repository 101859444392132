import { Component } from '@angular/core';

@Component({
  selector: 'carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.css'],
})
export class CarouselSlideComponent {

}
