<div class="container" [style.width.px]="width">
  <div [style.height.px]="(numberOfItemsToDisplay * ITEM_HEIGHT)">
    <ul>
      <cdk-virtual-scroll-viewport [itemSize]="ITEM_HEIGHT" class="scroll-height">
        <ng-container *cdkVirtualFor="let item of data">
          <li (click)="setValue(item)" [style.height.px]="ITEM_HEIGHT">{{ item.name }}</li>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </ul>
  </div>
</div>
