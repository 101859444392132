import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  PipeTransform,
  Pipe,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(value: any[]): any[] {
    value.sort(function (a, b) {
      let alc = a.checked;
      let blc = b.checked;
      return alc < blc ? 1 : alc > blc ? 0 : 1;
    });
    return value;
  }
}

@Component({
  selector: 'app-multi-select-jobs-dropdown',
  templateUrl: './multi-select-dropdown-jobs.component.html',
  styleUrls: ['./multi-select-dropdown-jobs.component.css'],
})
export class MultiSelectDropdownJobsComponent implements OnChanges, OnInit {
  @Input() list: any[];
  @Input() deSelectCheckBox;
  @Input() statusIds;

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  @Input() labelText?: string = this.translate.instant('CommonLabel.SelectJob');

  checkedList = [];
  currentSelected: {};
  showDropDown;
  selectName = [];
  multiSelectData = [];
  lengthSelectedName: number;
  constructor(private translate: TranslateService) {
    this.checkedList = [];
    this.selectName = [];
  }
  ngOnInit(): void {
    if (this.statusIds) {
      const ids = this.statusIds.split(',');
      let filteredData = [];
      if (ids.length) {
        ids.forEach((id: number) => {
          const result = this.list.find((x) => x.Id === id);
          filteredData.push(result);
        });
        this.multiSelectData = filteredData;
      }
    }
  }
  compare(a: { checked: boolean }, b: { checked: boolean }) {
    const _a = a.checked;
    const _b = b.checked;

    let comparison = 0;
    if (_a > _b) {
      comparison = -1;
    } else if (_a < _b) {
      comparison = 1;
    }
    return comparison;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!this.deSelectCheckBox) {
      this.checkedList = [];
      this.selectName = [];
      this.currentSelected = {};
    }
    if (this.list && !this.statusIds) {
      this.multiSelectData = this.list.sort(this.compare);
    }
  }

  getSelectedValue(status: Boolean, value: String, Name: String) {
    if (status) {
      this.lengthSelectedName = this.checkedList.length + 1;
      this.checkedList.push(value);
      this.selectName.push(Name);
      this.deSelectCheckBox = true;
    } else {
      var index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
      this.selectName.splice(index, 1);
      this.lengthSelectedName = this.checkedList.length + 1 - 1;
    }

    this.currentSelected = { checked: status, Id: value };
    this.shareCheckedlist();
    this.shareIndividualStatus();
  }
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.deSelectCheckBox);
  }
}
