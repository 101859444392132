<div class="container">
  <div class="row">
    <div class="col-12 header">
      <div class="header-backlist">
        <img alt="icon"  src="../../../../assets/image/arrow_back_black_24dp.svg" class="img-fluid" />
        <span class="header-backlist_back" (click)="backToTheList()">Back to the list</span>
      </div>
      <div class="header-create">
        <div>
          <h3 class="information">Create a user</h3>
        </div>
<!--        <div class="header-button">-->
<!--          <button type="submit" (click)="onSubmit()" [disabled]="userform.invalid"-->
<!--            class="btn btn-outline-danger btn-lg">-->
<!--            Add another-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <div>
        <form [formGroup]="userform">
          <div class="row">
            <div class="row-one form-group">
              <div class="mb-3">
                <div>
                  <label class="form-label">First Name</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">First Name</mat-label>
                  <input matInput formControlName="firstname" type="text" [ngClass]="{'error': forms_errors.firstname}"
                    (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.firstname">
                    {{forms_errors.firstname}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">
                <div>
                  <label class="form-label">Last Name</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">Last Name</mat-label>
                  <input matInput formControlName="lastname" type="text" [ngClass]="{'error': forms_errors.lastname}"
                    (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.lastname">
                    {{forms_errors.lastname}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">
                <div>
                  <label class="form-label">Email</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">Email</mat-label>
                  <input matInput type="email" formControlName="email"
                         [ngClass]="{'error': userform.get('email').hasError('invalidEmail') && userform.get('email').touched}"
                    (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.email">
                    {{forms_errors.email}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <mat-divider class="divider" [vertical]="true"></mat-divider>
            <div class="row-two">
              <div class="mb-3">
                <div>
                  <label class="form-label">Phone</label>
                </div>
                <mat-form-field class="example-full-width" floatLabel="never">
                  <mat-label class="label">Phone</mat-label>
                  <input matInput
                         appPhoneNumber
                         formControlName="phone"
                         type="text"
                         mask="000-000-0000"
                         minlength='10'
                         maxlength='12'
                    [ngClass]="{'error':forms_errors.phone }" (blur)="blurForms()">
                  <mat-error *ngIf="forms_errors.phone">
                    {{forms_errors.phone}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">

                <div>
                  <label for="jobs" class="form-label">Role</label>
                </div>
                <mat-form-field floatLabel="never">
                  <mat-label class="label">Role</mat-label>
                  <mat-select formControlName="role" [ngClass]="{'error':forms_errors.role }" (blur)="blurForms()"
                    id="role">
                    <mat-option *ngFor="let item of roleList" [value]="item.DisplayText">{{item.DisplayText}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="forms_errors.role">
                    {{forms_errors.role}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="mb-3">
                <div>
                  <label for="jobs" class="form-label">Jobs Assigned</label>
                </div>
                <mat-form-field floatLabel="never">
                  <mat-label class="label">Jobs Assigned</mat-label>
                  <mat-select formControlName="jobs" multiple [ngClass]="{'error':forms_errors.jobs }"
                    (blur)="blurForms()" id="jobs">
                    <mat-option *ngFor="let item of workList" [value]="item">
                      {{item.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="forms_errors.jobs">
                    {{forms_errors.jobs}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="header-link">
<!--            <div class="invite_link">-->
<!--              <a>-->
<!--                <h6 class="header-create_link" (click)="getInvvitationLink()">Get invite link to share</h6>-->
<!--              </a>-->
<!--            </div>-->
            <div class="header-button_send">
              <button *ngIf='stepIndex === 1' type="submit" class="btn btn-danger btn-lg" (click)="onSubmit()"
                [disabled]="userform.invalid">Send Invite</button>
              <button *ngIf='stepIndex === 2' type="submit" class="btn btn-danger btn-lg" (click)="resendInviteLink()"
                      [disabled]="userform.invalid">Resend Invite</button>
              <button *ngIf='stepIndex === 2' (click)='resetForm()'
                      class="btn btn-outline-danger btn-lg">
                Delete
              </button>
            </div>

          </div>
        </form>
      </div>
    </div>

  </div>
</div>
<ng-template #templateChange>
  <div class="modal-header_link">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefClose.hide()">
      <span class="close-button" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>The invitation link has been sent</h5>
  </div>
  <div class="group">
    <button type="button" class="btn btn-danger btn-lg" (click)="modalRefClose.hide()">OK</button>
  </div>`
</ng-template>

<ng-template #templateError>
  <div class="modal-header_link">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefClose.hide()">
      <span class="close-button" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>A user with such an Email already exists!</h5>
  </div>
  <div class="group">
    <button type="button" class="btn btn-danger btn-lg" (click)="modalRefClose.hide()">OK</button>
  </div>`
</ng-template>
<ng-template #templateSentAgain>
  <div class="modal-header_link">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefClose.hide()">
      <span class="close-button" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>User haven’t created password yet!</h5>
  </div>
  <div class="group">
    <button type="button" class="btn btn-success btn-lg" (click)="sentInviteAgain()">Send Invite again</button>
    <button type="button" class="btn btn-danger btn-lg" (click)="modalRefClose.hide()">Close</button>
  </div>`
</ng-template>

<ng-template #templateInvitationLink>
  <div class="modal-header_link">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefClose.hide()">
      <span class="close-button" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>Get an invite link to share</h5>
  </div>
  <div class="group">
    <input type="url" class="form-control url">
    <button type="button" class="btn btn-danger btn-lg linkbutton" (click)="modalRefClose.hide()">Copy link</button>
  </div>`
</ng-template>
