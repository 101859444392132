import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-head-user',
  templateUrl: './head-user.component.html',
  styleUrls: ['./head-user.component.css'],
})
export class HeadUserComponent implements OnInit,OnDestroy {
  @ViewChild('searchInputText', { static: true }) searchInputText: ElementRef;
  @Output() onChange = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();
  public searchText = '';
  public enableCrossIcon = false;
  public activeTab = 'WorkInProgress';
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.filterListOfUser();
  }

  // Reset search input text
  resetInputSearch() {
    this.searchInputText.nativeElement.value = '';
    this.searchText = '';
    this.enableCrossIcon = false;
    this.commonService.passSearchText('');
  }
  filterListOfUser() {
    fromEvent(this.searchInputText.nativeElement, 'keyup')
      .pipe(
        map((event: KeyboardEvent) => {
          return (event.target as HTMLInputElement).value;
        }),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((text: string) => {
        this.searchText = text;
        this.searchText.length > 0
          ? (this.enableCrossIcon = true)
          : (this.enableCrossIcon = false);
        this.outputValueFilter(text);
      });
  }
  outputValueFilter(text: string) {
    this.onChange.emit(text);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
