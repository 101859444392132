import {  Component, OnInit,  ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-main-users',
  templateUrl: './main-users.component.html',
  styleUrls: ['./main-users.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MainUsersComponent implements OnInit {
  public searchText: string;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}
  handleChange(searchText: string) {
    this.commonService.changeText(searchText)
  }
}
