import { Component, Input, QueryList, ViewChildren, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { SortableTableDirective, SortEvent, compare, } from '../../../shared/components/sortable-table/sortable-table.directive';
import { Role } from '../../../shared/models/role';
import { CommonService } from 'src/app/shared/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-report-details-table',
  templateUrl: './report-details-table.component.html',
  styleUrls: ['./report-details-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDetailsTableComponent implements OnInit, OnDestroy {
  @Input() modelDetails = [];
  @Input() version: string = 'standard';

  destroy$: Subject<boolean> = new Subject<boolean>();
  public modelFilter = [];
  currentUserRole: Role;

  constructor(
    private authService: AuthenticationService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    (this.modelFilter = this.modelDetails)
      this.commonService.passFilterTextChartObservable
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          (this.modelFilter = this.modelDetails?.filter(
            (item) => item.WorkCategory === data
          ))
            this.cdr.detectChanges();
        });

    this.commonService.passFilterTextChartByStatusObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        (this.modelFilter = this.modelDetails?.filter(
          (item) => item.WorkStatusText === data
        ))
          this.cdr.detectChanges();
      });
    this.currentUserRole = this.authService.currentLoginUserValue.Role;
  }

  @ViewChildren(SortableTableDirective)
  headers: QueryList<SortableTableDirective>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction !== '' || column !== '') {
      this.modelDetails = this.modelDetails.sort((a, b) => {

        let res: number | string;
        if (Number.isInteger(a[column])) {
          res = compare(a[column], b[column]);
        } else {
          res = compare(a[column]?.toUpperCase() ?? new Date().toISOString(), b[column]?.toUpperCase() ?? new Date().toISOString());
        }
        return direction === 'asc' ? res : -res;
      });
    }
  }

  detailPage(item: { WorkID: number; WorkOrderType: string }) {
    window.open(`/#/${this.currentUserRole.toLowerCase()}/home/details/${item.WorkID}/${item.WorkOrderType}`);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
