export interface IReports {
  jobIds: string;
  WorkCategory: string;
  Branch: string;
  DateFrom: string;
  DateTo: string;
  Days: string;
  fieldAgentIds: string;
  statusIds: string;
  searchText: string;
  label?: string;
  downLoad?: string;
  cannedResponse?: string;
}

export interface IReportDetail {
  jobIds: string;
  WorkCategory: string;
  Branch: string;
  DateFrom: string;
  DateTo: string;
  Days: string;
  fieldAgentIds: string;
  statusIds: string;
  searchText: string;
  userId?: number;
  reportView: string;
  isAdmin?: boolean;
}

export class ReportType {
  WorkOrderOwners: any[];
  workOrderCategories: any[];
  BackLogs: any[];
  WorkOrderJob: any[];
  CategoryDrillDown: any[];
  WorkOrderCustomerLabels: any[];
}

export interface ChartData {
  name: string;
  count: number;
  y: number;
  color: string;
}
