<div class='report-head main-padding mt-4'>
  <div class='wrp report-search'>
    <input
      type='text'
      placeholder="{{ 'DropDownLabel.Search' | translate }}"
      class='form-control'
      #searchInputText
    />
    <img src='../../../../assets/image/search-icon.svg' class='user-icon' />
    <a (click)='resetInputSearch()' *ngIf='enableCrossIcon'>
      <img alt='icon' src='../../../../assets/image/close.png' class='cross' />
    </a>
  </div>
  <ng-container *ngIf='isSetCustomDate; else empty'>
    <div class='report-select set-font'>
      <label>Between</label>
      <span class='wrp'>
        <input
          type='text'
          placeholder='Datepicker'
          bsDatepicker
          [bsConfig]='bsConfig'
          [(ngModel)]='DateFrom'
          class='form-control'
        />
      </span>
    </div>
    <div class='report-select set-font'>
      <label>And</label>
      <span class='wrp'>
        <input
          type='text'
          placeholder='Datepicker'
          bsDatepicker
          [bsConfig]='bsConfig'
          [(ngModel)]='DateTo'
          class='form-control'
        />
      </span>
    </div>
  </ng-container>
  <ng-template #empty>
    <div></div>
    <div></div>
  </ng-template>

  <div class='report-select'>
    <label style='width: 292px'>Filter by Date Range</label>
    <select class='form-control' [(ngModel)]='Days' (change)='onChange($event)'>
      <option [value]='null'>Select Days</option>
      <option>30 Days</option>
      <option>60 Days</option>
      <option>90 Days</option>
      <option>Set custom dates</option>
    </select>
  </div>
</div>

<div class='report-head report-head2 main-padding mt-5'>
  <div class='report-selection'>
    <app-multi-select-dropdown
      [list]='jobListDetails'
      (shareCheckedList)='shareCheckedJobList($event)'
      (shareIndividualCheckedList)='shareIndividualCheckedList($event)'
      [deSelectCheckBox]='deSelectCheckBox'
      [labelText]="'Select Jobs'">
    </app-multi-select-dropdown>
  </div>
  <div>
    <div class='report-selection'>
      <app-multi-select-dropdown
        [list]='workCategoryList'
        (shareCheckedList)='shareCheckedListCat($event)'
        (shareIndividualCheckedList)='shareIndividualCheckedCat($event)'
        [deSelectCheckBox]='deSelectCheckBox'
        [labelText]="'Select Category'">
      </app-multi-select-dropdown>
    </div>
  </div>

  <div class='report-selection'>
    <ng-container>
      <app-multi-select-dropdown
        [list]='statusList'
        (shareCheckedList)='shareCheckedListStatus($event)'
        (shareIndividualCheckedList)='shareStatusCheckedCat($event)'
        [deSelectCheckBox]='deSelectCheckBox'
        [labelText]="'Select Status'">
      </app-multi-select-dropdown>
    </ng-container>
  </div>

  <div>
    <div class='report-selection' *ngIf='!isCommunityManager'>
      <app-multi-select-dropdown
        [list]='branchList'
        (shareCheckedList)='shareCheckedListBranch($event)'
        (shareIndividualCheckedList)='shareIndividualCheckedBranch($event)'
        [deSelectCheckBox]='deSelectCheckBox'
        [labelText]="'Select Branch'"
      >
      </app-multi-select-dropdown>
    </div>
  </div>
  <div class='report-selection' *ngIf='!isCommunityManager'>
    <ng-container>
      <app-multi-select-dropdown
        [list]='fielgAgentList'
        (shareCheckedList)='shareCheckedListAgent($event)'
        (shareIndividualCheckedList)='shareIndividualCheckedListAgent($event)'
        [deSelectCheckBox]='deSelectCheckBox'
        [labelText]="'Select Agent'">
      </app-multi-select-dropdown>
    </ng-container>
  </div>

  <div class='report-select'>
    <button class='btn btn-oclm w-100 pad-only' (click)='applyFilters()'>
      Apply Filters
    </button>
  </div>
</div>

<div class='main-padding mt-4'>
  <div class='dropdown report-select'>
    <button
      type='button'
      data-toggle='dropdown'
      aria-haspopup='true'
      class='btn btn-oclm dropdown-toggle'
    >
      Export Details
    </button>
    <div
      aria-labelledby='dropdownMenuButton'
      class='dropdown-menu dropdown-menu-right'
    >
      <a class='dropdown-item' (click)="exportWorkOrderReportByKey('detail')"
      >Details</a
      >
      <a class='dropdown-item' (click)="exportWorkOrderReportByKey('csv')"
      >CSV</a
      >
    </div>
  </div>
</div>

<!-- Charts start  -->
<div class='main-padding mt-5'>
  <div class='row'>
    <ng-container *ngIf='!isCommunityManager'>
      <div class='col-md-5'>
        <div class='d-flex justify-content-between'>
          <p class='bold'>Work Order Distribution</p>

          <button
            class='btn btn-link'
            style='margin-top: -14px'
            (click)='onBacklogShowDetail()'
          >
            Show Detail
          </button>
        </div>
        <ng-container
          *ngIf='
            reportValues &&
              reportValues.BackLogs &&
              reportValues.BackLogs.length > 0;
            else elseTemplate1
          '
        >
          <highcharts-chart
            id='diagram'
            name='backLogOption'
            [Highcharts]='Highcharts'
            [options]='backLogOption'
            style='width: 100%; height: 300px; display: block'></highcharts-chart>
        </ng-container>

        <ng-template #elseTemplate1>
          <span class='box'>No Record Found</span>
        </ng-template>
      </div>
      <div class='col-md-7'>
        <div class='d-flex justify-content-between'>
          <p class='bold'>Work Orders by Owner</p>
          <button
            class='btn btn-link'
            style='margin-top: -14px'
            (click)='onOwnerShowDetail()'
          >
            Show Detail
          </button>
        </div>

        <ng-container
          *ngIf='reportValues &&
              reportValues.WorkOrderOwners &&
              reportValues.WorkOrderOwners.length > 0;
              else elseTemplate2'>
          <highcharts-chart
            [Highcharts]='Highcharts'
            [options]='OwnerOption'
            style='width: 100%; height: 300px; display: block'>
          </highcharts-chart>
        </ng-container>
        <ng-template #elseTemplate2>
          <span class='box'>No Record Found</span>
        </ng-template>
      </div>
    </ng-container>

    <div class='col-md-5 mt-4 mb-4'>
      <div class='d-flex justify-content-between'>
        <p class='bold'>Work Orders by Category</p>
        <button
          class='btn btn-link'
          style='margin-top: -14px'
          (click)='onCategoryShowDetail()'
        >
          Show Detail
        </button>
      </div>

      <ng-container
        *ngIf='
          reportValues &&
            reportValues.workOrderCategories &&
            reportValues.workOrderCategories.length > 0;
          else elseTemplate3
        '
      >
        <highcharts-chart
          [Highcharts]='Highcharts'
          [options]='categoryOption'
          style='width: 100%; height: 300px; display: block'
        ></highcharts-chart>
      </ng-container>
      <ng-template #elseTemplate3>
        <span class='box'>No Record Found</span>
      </ng-template>
    </div>
    <div class='col-md-7 mt-4 mb-4'>
      <div class='d-flex justify-content-between'>
        <p class='bold'>Work Orders by Job</p>
        <button
          class='btn btn-link'
          style='margin-top: -14px'
          (click)='onJobShowDetail()'
        >
          Show Detail
        </button>
      </div>
      <ng-container
        *ngIf='
          reportValues &&
            reportValues.WorkOrderJob &&
            reportValues.WorkOrderJob.length > 0;
          else elseTemplate4
        '
      >
        <highcharts-chart
          [Highcharts]='Highcharts'
          [options]='completedOption'
          style='width: 100%; height: 300px; display: block'
        ></highcharts-chart>
      </ng-container>
      <ng-template #elseTemplate4>
        <span class='box'>No Record Found</span>
      </ng-template>
    </div>
    <div class='col-md-12 mt-4 mb-4 report-map'>
      <p class='bold'>Work Orders Map</p>
      <ng-container
        *ngIf='workOrderDetails && workOrderDetails.length > 0'>
        <app-google-pin
          [workOrderDetails]='workOrderDetails'
          [role]='userRole'>
        </app-google-pin>
      </ng-container>
      <ng-template #elseTemplate5>
        <span class='box'>Map is under construction</span>
      </ng-template>
    </div>
  </div>
</div>
