import { commentDoc, WorkOrderDetail } from './work-order';
import { DynamicFieldsModel } from './dynamic-model';

export class WorkOrderModel {
  CompletedBy: string;
  WorkRequestID: string;
  CustomerId: string;
  WorkCategory: string;
  Address: string;
  CreatedBy: string;
  DynamicFields: DynamicFieldsModel[];
  Latitude: number;
  Longitude: number;
  CannedResponseId: number;
  CompletedAddress: string;
  StartDate: string | Date;
  EndDate: Date = new Date();
  CannedResponseText: string;
  SuperVisorId: string;
  CustomerName: string;
  Notes: string;
  WorkStatusText: string;
  FieldAgentId: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhoneNumber: string;
  ContactEmail: string;
  ContactAddress: string;
  WorkId: number;
  UploadedDocPathsAfter: string;
  UploadedDocPathsBefore: string;
  CompletedOn: string;
  WorkOrderNotesDetail: WorkOrderDetail[] = [];
  NotesText?: string;
  NotesVal: string;
  WorkOrderType: string;
  uploadBeforeDocument?: commentDoc[];
  uploadAfterDocument?: commentDoc[];
  SupervisorName?: string;
  FieldAgentName?: string;
}
