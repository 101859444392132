import { Role } from './role';

export class UserModel {
  username: string;
  password: string;
  roleId: string;
}

export class User {
  Id: number;
  Email: string;
  Role: Role;
  token: string;
  Name: string;
  DocPath: string;
  SuperVisorId: number;
  JobIds: string;
  LanguageName: string;
  IsNotification: boolean;
  IsVacation?: boolean;
  IsEnableEmailNotification?: boolean;
  AccessToken: string;
  FirstName: string;
  LastName: string;
  Address: string;
  PhoneNumber: string;
  Agreement?: boolean;
}

export class ClientUser extends User {
  UserId: string;
  Phone?: string;
  Jobs: Job[];
}

export type Job = {
  Id: number,
  Name: string,
  City?: string,
  ZipCode?: string,
  CustomerId?: string,
  BranchName?: string,
  CustomerClass?: string,
  UserIds?: number,
  Labels?: Label[],
}

export type Label = {
  LabelId: number,
  Name: string,
  Values: LabelValue[],
}

export type LabelValue = {
  ValueId: number,
  Value: string,
  Selected: boolean,
}

export class SettingModel {
  ProfileId: Number;
  LanguageName: string;
  IsNotification: string;
  IsVacation: string;
  IsEnableEmailNotification: string;
  WorkOrderIsCreated: string;
  AnotherUserAddsInternalNoteToaWorkOrder: string;
  WorkOrderIsClosed: string;
  AnotherUserAssignsWorkOrderToMe: string;
  FieldAgentSubmitsClosedWorkOrderForReview: string;
}

export interface LabelForWO
{ WorkOrderId: number, Labels: { LabelId: number, ValueId: number }[] }


