import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InputControlComponent } from './material-components/input-control/input-control.component';
import { DropdownControlComponent } from './material-components/dropdown-control/dropdown-control.component';
import { DropdownControlOptionsListComponent } from './material-components/dropdown-control/dropdown-control-options-list/dropdown-control-options-list.component';
import { OverlayComponent } from './material-components/overlay/overlay.component';
import { DatepickerComponent } from './material-components/datepicker/datepicker.component';
import { DatepickerRangeComponent } from './material-components/datepicker-range/datepicker-range.component';
import { DatepickerCustomHeaderComponent } from './material-components/datepicker/datepicker-custom-header/datepicker-custom-header.component';
import { ButtonComponent } from './material-components/button/button.component';
import { FileUploadZoneComponent } from './material-components/file-upload-zone/file-upload-zone.component';
import { TableColumnsSelectionDialogComponent } from './material-components/table-columns-selection-dialog/table-columns-selection-dialog.component';
import localeCan from '@angular/common/locales/ca-FR';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


registerLocaleData(localeCan);

@NgModule({
  declarations: [
    InputControlComponent,
    DropdownControlComponent,
    DropdownControlOptionsListComponent,
    OverlayComponent,
    DatepickerComponent,
    DatepickerRangeComponent,
    DatepickerCustomHeaderComponent,
    ButtonComponent,
    FileUploadZoneComponent,
    TableColumnsSelectionDialogComponent
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatRippleModule,
    FormsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule
  ],
  exports: [
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatDividerModule,
    InputControlComponent,
    DropdownControlComponent,
    DropdownControlOptionsListComponent,
    DatepickerComponent,
    DatepickerRangeComponent,
    DatepickerCustomHeaderComponent,
    ButtonComponent,
    FileUploadZoneComponent,
    TableColumnsSelectionDialogComponent,
    MatProgressBarModule
  ],
  bootstrap: [DatepickerComponent],
  entryComponents: [DatepickerComponent, DatepickerCustomHeaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MaterialModule {}
