<div class="work-order-head">
  <div *ngIf="currentUserRole == 'Admin'">
    <label>
      {{ 'CommonLabel.AssignFieldAgent' | translate }}
    </label>
    <div class="radio-wrp">
      <div class="custom-control custom-radio">
        <input
          type="radio"
          class="custom-control-input"
          [(ngModel)]="assignFieldAgent"
          disabled
          value="myself"
          id="customRadio"
          name="example1"
        />
        <label class="custom-control-label" for="customRadio">{{
          'BtnText.Myself' | translate
          }}</label>
      </div>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          class="custom-control-input"
          [(ngModel)]="assignFieldAgent"
          value="other"
          disabled
          id="customRadio1"
          name="example1"
        />
        <label class="custom-control-label" for="customRadio1">{{
          'BtnText.OtherFieldAgent' | translate
          }}</label>
      </div>
    </div>
  </div>
  <div *ngIf="currentUserRole == 'Admin'">
    <!-- style="visibility: hidden; blur-box" -->
    <div
      class="form-group"
      [ngClass]="{ 'blur-box': assignFieldAgent === 'myself' }"
    >
      <label>{{ 'BtnText.AssignAgent' | translate }}</label>
      <div class="wrp">
        <img
          src="../../../../../assets/image/field-agent-icon-21.svg"
          class='user-icon'
        />
        <select
          class="form-control"
          id="fieldAgent1"
          disabled
          [(ngModel)]="workOrderDetails.FieldAgentId"
        >
          <option [value]="null">
            -{{ 'BtnText.AssignAgent' | translate }}-
          </option>
          <option *ngFor="let fa of fieldAgentListDetails" [value]="fa.Id">
            {{ fa.Name }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div
    class="align-box"
    *ngIf="currentUserRole == 'Admin' || currentUserRole == 'FieldAgent'"
  >
    <div class="form-group">
      <label class="oclm-color">{{
        'CommonLabel.WorkOrderStatus' | translate
        }}</label>
      <div class="wrp">
        <img
          src="../../../../../../assets/image/reports-icon-t.svg"
          class='user-icon'
          style="top: 9px"
        />
        <select
          class="form-control"
          [(ngModel)]="workOrderDetails.WorkStatusText"
          (change)="workOrderStatusChange($event)"
          disabled
          id="exampleFormControlSelect1"
        >
          <option [value]="null">
            -{{ 'BtnText.SelectStatus' | translate }}-
          </option>
          <option *ngFor="let value of statusList" [value]="value.Text">
            {{ value.DisplayText }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="wrapper-login">
  <div class="login-right-box">
    <div class="con-info-group first-form">
      <h5>{{ 'BtnText.ContactInfo' | translate }}</h5>
      <button
        class="btn btn-oclm"
        data-toggle="modal"
        data-target="#contact-info"
      >
        {{ 'BtnText.ViewDetails' | translate }}
      </button>
    </div>
    <div class="wrp-map-stpr">
      <div class="first-form">
        <div class="row">
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            *ngIf="currentUserRole !== 'Admin'"
          >
            <div class="form-group">
              <label>
                {{ 'CommonLabel.JobName' | translate }} /
                {{ 'CommonLabel.CustomerName' | translate }}
              </label>
              <div class="wrp">
                <input
                  [(ngModel)]="workOrderDetails.JobName"
                  class="form-control"
                  disabled
                />
                <img
                  src="../../../../assets/image/work-request-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <!--START Added for admin role -->
          <div
            class="col-md-6 col-sm-6 col-xs-12"
            *ngIf="currentUserRole === 'Admin'"
          >
            <div class="form-group">
              <label>
                {{ 'CommonLabel.JobName' | translate }} /
                {{ 'CommonLabel.CustomerName' | translate }}
              </label>
              <div class="wrp">
                <!-- job name search -->
                <input
                  [(ngModel)]="workOrderDetails.CustomerName"
                  [typeahead]="jobListDetails"
                  typeaheadOptionField="Name"
                  placeholder="Search job name"
                  class="form-control"
                  disabled
                />
                <img
                  src="../../../../assets/image/user-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-sm-6 col-xs-12"
            *ngIf="currentUserRole === 'Admin'"
          >
            <div class="form-group">
              <label>
                {{ 'CommonLabel.SelectSupervisor' | translate }}
              </label>
              <div class="wrp">
                <!-- supervisor search -->
                <select
                  class="form-control"
                  id="fieldAgent"
                  disabled
                  [(ngModel)]="workOrderDetails.SuperVisorId"
                >
                  <option [value]="null">
                    -{{ 'CommonLabel.Select' | translate }}-
                  </option>
                  <option
                    *ngFor="let value of supervisorList"
                    [value]="value.ID"
                  >
                    {{ value.Text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--END Added for admin role -->
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>
                {{ 'CommonLabel.DescriptionOfWork' | translate }}
              </label>
              <div class="wrp">
                <input
                  type="text"
                  disabled
                  [(ngModel)]="workOrderDetails.WorkRequestID"
                  placeholder="{{
                    'CommonLabel.DescriptionOfWork' | translate
                  }}"
                  class="form-control"
                />
                <img
                  src="../../../../../assets/image/comment-icon.png"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label>
                {{ 'CommonLabel.Location' | translate }}/{{
                'CommonLabel.Address' | translate
                }}
              </label>
              <div class="wrp">
                <input
                  type="text"
                  disabled
                  placeholder="{{ 'CommonLabel.Address' | translate }}"
                  [(ngModel)]="workOrderDetails.Address"
                  class="form-control"
                />
                <img src="../../../../assets/image/region-icon.png"
                     class='user-icon'/>

                <a style="text-decoration: underline; cursor: pointer"
                  class="btn d-sm-none btn-link"
                  href="https://www.google.com/maps/place/{{ workOrderDetails.Address}}">View direction</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label>{{ 'Table.WorkCategory' | translate }}</label>
            <div class="wrp">
              <select
                class="form-control"
                id="workcatergory"
                [(ngModel)]="workOrderDetails.WorkCategory"
                disabled
              >
                <option [value]="null">
                  -{{ 'CommonLabel.Select' | translate }}-
                </option>
                <option
                  *ngFor="let value of workCategoryList"
                  [value]="value.Text"
                >
                  {{ value.Text }}
                </option>
              </select>
              <img
                src="../../../../assets/image/job-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-12">
          <div class="form-group">
            <label>{{ 'CommonLabel.details' | translate }}</label>
            <div class="wrp">
              <textarea
                placeholder="{{ 'CommonLabel.details' | translate }}"
                disabled
                rows="3"
                [(ngModel)]="workOrderDetails.AdditionalComment"
                class="form-control"
              ></textarea>
              <img
                src="../../../../../assets/image/comment-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 here"
             *ngIf="!showScheduleDate && !workOrderDetails.CompletedOn">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.CompleteBy' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                bsDatepicker
                disabled
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [(ngModel)]="workOrderDetails.CompletedBy"
                class="form-control"
              />
              <img
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 here"
             *ngIf="!showScheduleDate && workOrderDetails.CompletedOn">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.CompleteOn' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                bsDatepicker
                disabled
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [(ngModel)]="workOrderDetails.CompletedOn"
                class="form-control"/>
              <img src="../../../../assets/image/date-icon.svg"
                   class='user-icon'/>
            </div>
          </div>
        </div>
        <!-- conditional StartedDate -->
        <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.ScheduleBy' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.StartDate"
                placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                bsDatepicker
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [bsConfig]="bsConfig"
                class="form-control"
              />
              <img
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-12" *ngIf="!existingRecord">
          <div class="form-group">
            <label>{{ 'CommonLabel.Comments' | translate }}</label>
            <div class="wrp">
              <textarea
                placeholder="{{ 'CommonLabel.Comments' | translate }}"
                rows="1"
                disabled
                [(ngModel)]="workOrderDetails.Notes"
                class="form-control"
              ></textarea>
              <img
                src="../../../../../assets/image/comment-icon.png"
                class='user-icon'/>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let item of DynamicFieldsList; let i = index">
          <div class="col-md-8 col-sm-8 col-xs-4" *ngIf="i % 2 == 0">
            <div class="form-group">
              <label>{{ item.FieldName }}</label>
              <div class="wrp">
                <input
                  readonly
                  class="form-control mb-3"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="item.Values"
                  placeholder="Values"/>
                <img
                  src="../../../../assets/image/job-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4" *ngIf="i % 2 != 0">
            <div class="form-group">
              <label>{{ item.FieldName }}</label>
              <div class="wrp">
                <input
                  readonly
                  class="form-control mb-3"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="item.Values"
                  placeholder="Values"
                />
                <img
                  src="../../../../assets/image/job-icon.svg"
                  class='user-icon'
                />
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-md-8 col-sm-8 col-xs-12"
             *ngIf="currentUserRole == 'CommunityManager' && activeTab === 'Pending'">
          <span>
            <div
              class="flex-row justify-content-center justify-content-around my-2">
            </div>
          </span>
        </div>

        <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="showScheduleDate">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.CompleteBy' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                bsDatepicker
                [bsConfig]="bsConfig"
                [minDate]="minDate"
                [(ngModel)]="workOrderDetails.CompletedBy"
                disabled
                class="form-control"
              />
              <img
                src="../../../../assets/image/date-icon.svg"
                class='user-icon'
              />
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-xs-4"></div>
        <div
          class="col-md-12 col-sm-12 col-xs-12"
          *ngIf="WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0">
          <div class="form-group">
            <label>
              {{ 'CommonLabel.ViewNotes' | translate }}
            </label>
            <div class="view-note-box">
              <!-- 1st box -->
              <ng-container *ngFor="let item of WorkOrderNotesDetail">
                <div
                  class="view-note"
                  *ngIf="item && item.NotesText && item.NotesText.length > 0"
                >
                  <div class="notify-msg">{{ item.CreatedBy | firstWord }}</div>
                  <div class="notify-text">
                    <span class="notify-date">
                      {{ item.CreatedBy }},
                      {{ item.CreatedDate | date: 'd MMMM, y, h:mm a' }}
                    </span>
                    <span>{{ item.NotesText }}</span>
                  </div>
                </div>
                <!-- 2st box -->
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-xs-12"></div>
      </div>
      <div class="row">
        <div class="col-md-6 c0l-xs-12 mb-4">
          <p class="thumb-text">
            {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
          </p>
          <app-preview-file
            [uploadFile]="uploadBeforePhotoFilePath"
            (onImageDelete)="onImageDelete($event)"
            [isDelete]="true"
            [isEdit]="true"
            [WorkIdVal]="workOrderId"
            [workOrderType]="workOrderType"
            (onImageCommentUpdated)="onImageCommentUpdated($event)"
            [BeforeCreationWorkOrder]="'BeforeCreationWorkOrder'"
          ></app-preview-file>
        </div>
        <div class="col-md-6 c0l-xs-12 mb-4 text-center">
          <p class="thumb-text">
            {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
          </p>
          <app-preview-file
            [uploadFile]="uploadAfterPhotoFilePath"
            [isEdit]="false"
            (onImageDelete)="onImageDelete($event)"
            [isDelete]="true"
          ></app-preview-file>
        </div>
      </div>

      <div class="login-left-Mapbox" style="opacity: 0.8">
        <app-google-map
          [extLat]="extLat"
          [extLong]="extLong"
          [markerDraggable]="false"
        >
        </app-google-map>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
  id="contact-info"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">
          {{ 'BtnText.ContactInfo' | translate }}
        </h5>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.FirstName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.FirstName"
                placeholder="{{ 'CommonLabel.FirstName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.LastName' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.LastName"
                placeholder="{{ 'CommonLabel.LastName' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/user-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'CommonLabel.Email' | translate }}</label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.ContactEmail"
                placeholder="{{ 'CommonLabel.Email' | translate }}"
                class="form-control"
                maxlength="100"
              />
              <img
                src="../../../../assets/image/email-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label>
              {{ 'CommonLabel.PhoneNumber' | translate }}
            </label>
            <div class="wrp">
              <input
                type="text"
                disabled
                [(ngModel)]="workOrderDetails.PhoneNumber"
                placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
                class="form-control"
                minlength='10'
                maxlength="10"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                required
              />
              <img
                src="../../../../assets/image/phone-icon.png"
                class='user-icon'
              />
            </div>
          </div>
          <div class="col-md-12 form-group">
            <label>
              {{ 'CommonLabel.Location' | translate }}/{{
              'CommonLabel.Address' | translate
              }}
            </label>
            <div class="wrp">
              <input
                type="text"
                disabled
                maxlength="200"
                [(ngModel)]="workOrderDetails.Address"
                placeholder="{{ 'CommonLabel.Location' | translate }}"
                class="form-control"
              />
              <img
                src="../../../../assets/image/region-icon.png"
                class='user-icon'
              />
            </div>
          </div>
        </div>
      </div>
      <div class="foot-btn text-center mt-1 mb-4">
        <button
          type="button"
          disabled
          class="btn btn-oclm w-50"
          data-dismiss="modal"
        >
          {{ 'BtnText.Save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>{{ message }}</p>
    <div class="modal-footer">
      <button type="button" class="btn btn-oclm" (click)="confirm()">Ok</button>
    </div>
  </div>
</ng-template>
