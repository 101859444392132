<div class='print-wrapper'>
  <button class='btn btn-oclm print-btn d-print-none button-print' (click)='onPrint()'>
    Print
  </button>
  <button class='btn print-btn d-print-none btn-secondary' (click)='goBack()'>
    Back
  </button>
  <div class='report-print-logo mt-4 mb-5 d-none d-print-block'>
    <img src='../../../../assets/image/small_logo.jpg'
         alt='logo' class='img-responsive' />
  </div>

  <div class='row mb-4' *ngIf="chartName === 'Categories'">
    <div class='col-lg-8 col-6 col-sm-8'>
      <div class='row'  [ngStyle]="{'row-gap': '10px'}">
      <div class='col-lg-4 col-md-4 col-sm-4'>
        <div class='d-print-block d-none'>
          {{ categoriesNames && categoriesNames }}
        </div>
        <div class='d-print-none'>
          <app-multi-select-dropdown
            [list]='workCategoryList'
            (shareCheckedList)='shareCheckedListCat($event)'
            (shareIndividualCheckedList)='shareIndividualCheckedCat($event)'
            [deSelectCheckBox]='deSelectCheckBox'
            [labelText]="'Select Category'">
          </app-multi-select-dropdown>
        </div>
      </div>
      <div class='col-lg-4 col-md-4 col-sm-4'>
        <div class='d-print-none'>
          <app-multi-select-dropdown
            [list]='statusList'
            (shareCheckedList)='shareCheckedListStatusOwner($event)'
            (shareIndividualCheckedList)='shareIndividualCheckedCat($event)'
            [deSelectCheckBox]='deSelectCheckBox'
            [labelText]="'Select Status'">
          </app-multi-select-dropdown>
        </div>
      </div>
      <div class='col-lg-4 col-md-4 col-sm-4'>
        <div class='report-select'>
          <select class='form-control border-none select-border'
                  [(ngModel)]='Days'
                  (change)='shareCheckedListStatus($event, true)'>
            <option [value]='null'>Select Days</option>
            <option>30 Days</option>
            <option>60 Days</option>
            <option>90 Days</option>
            <option>Set custom dates</option>
          </select>
        </div>
      </div>
      <div class='col-lg-4 col-md-4 col-sm-4'>
        <div class='report-select'>

          <app-multi-select-dropdown
            [list]='cannedResponseList'
            (shareCheckedList)='shareCheckedListStatusCanned($event)'
            (shareIndividualCheckedList)='shareIndividualCheckedCat($event)'
            [deSelectCheckBox]='deSelectCheckBox'
            [labelText]="'Canned Response'">
          </app-multi-select-dropdown>
        </div>
      </div>
        <ng-container *ngFor='let customLabel of customerLabelFilterData'>
          <div class='custom-label-filter col-lg-4 col-md-4 col-sm-4'>
            <select class='form-control border-none select-border'
                    [ngModel]="selectedCustomLabels.get(customLabel.LabelId) || null"
                    (change)='shareCheckedListCustomerLabels(customLabel.LabelId,$event)'>
              <option [value]="null" disabled>{{customLabel.Name}}</option>
              <option [value]="''">Default</option>
              <option [value]='value.ValueId' *ngFor='let value of customLabel.Values'>{{value.Value}}</option>
            </select>
          </div>
        </ng-container>
      </div>
    </div>
    <div class='col-lg-4 col-6 col-sm-4'>
      <div class='row' [ngStyle]="{'row-gap': '10px'}">
        <div class='col-md-12 col-sm-12'>
          <button class='btn btn-oclm' (click)='getReportsDetails()'>Apply filters</button>
        </div>
        <div class='col-md-12 col-sm-12'>
          <button class='btn btn-oclm dropdown-toggle' (click)='downloadCSV()'>Download CSV</button>
        </div>
        <div class='col-md-12 col-sm-12'>
          <button class='btn btn-oclm dropdown-toggle' (click)='downloadPDF()'>Download PDF</button>
        </div>
      </div>
    </div>
  </div>

  <div class='row justify-content-center' *ngIf='isSetCustomDate'>
    <div class='report-select col-md-2 set-font'>
      <label>Between</label>
      <span class='wrp'>
        <input
          type='text'
          placeholder='Datepicker'
          bsDatepicker
          [bsConfig]='bsConfig'
          [(ngModel)]='DateFrom'
          class='form-control'
        />
      </span>
    </div>
    <div class='report-select col-md-2 set-font'>
      <label>And</label>
      <span class='wrp'>
        <input
          type='text'
          placeholder='Datepicker'
          bsDatepicker
          [bsConfig]='bsConfig'
          [(ngModel)]='DateTo'
          class='form-control'
        />
      </span>
    </div>
    <div class='report-select col-md-2'>
      <button class='btn btn-oclm w-100 pad-only' (click)='applyDates()'>
        Apply Dates
      </button>
    </div>
  </div>
  <app-report-details-table [modelDetails]='modelDetails' version='standard'>
    <ng-container [ngSwitch]='chartName'>
      <ng-container *ngSwitchCase="'Backlog'">
        <div *ngIf='reportValues && reportValues.BackLogs' class='printChartPreview'>
          <p class='bold'>Work Order Distribution</p>
          <div class='row justify-content-md-center'>
            <div class='col-lg-12 print'>
              <highcharts-chart id='diagram'
                                name='backLogOption'
                                (click)='onPointSelect($event)'
                                [Highcharts]='Highcharts'
                                [options]='backLogOption'>
              </highcharts-chart>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'Owner'">
        <div *ngIf='reportValues && reportValues.WorkOrderOwners' class='printChartPreview'>
          <p class='bold'>Work Orders by Owner</p>
          <div class='w-50 d-print-none col-md-6'>
            <app-multi-select-jobs-dropdown [list]='statusList' (shareCheckedList)='shareCheckedListStatusOwner($event)'
                                            (shareIndividualCheckedList)='shareStatusCheckedCat($event)'
                                            [deSelectCheckBox]='deSelectCheckBox'
                                            [labelText]="'Select Status'" [statusIds]='statusIds'>
            </app-multi-select-jobs-dropdown>
          </div>
          <div class='printChartPreview'>
            <highcharts-chart [Highcharts]='Highcharts' (click)='onPointSelect($event)'
                              [options]='ownerOption'></highcharts-chart>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'Categories'">
        <!--str 66 change && on || 03/06-->
        <ng-container *ngIf='
            isCategory === true &&
              reportValues ||
              reportValues.workOrderCategories;
            else statusTmpl
          '>
          <div class='d-flex flex-wrap justify-content-between'>
            <div class='printChartPreview d-flex justify-content-center col-md-6 p-2'>
              <!-- Category Chart -->
              <highcharts-chart [Highcharts]='Highcharts'
                                [options]='categoryOption'></highcharts-chart>
            </div>

            <ng-container *ngFor='let option of customerLabelsOptions'>
              <div class='printChartPreview d-flex justify-content-center col-md-6 p-2'>
                <!-- Customer Label Chart -->
                <highcharts-chart [Highcharts]='Highcharts' (click)='onPointSelect($event)'
                                  [options]='option'></highcharts-chart>
              </div>
            </ng-container>
          </div>

        </ng-container>
        <ng-template #statusTmpl>
          <div *ngIf='statusData' class='printChartPreview d-flex justify-content-center'>
            <highcharts-chart (click)='onPointSelect($event)' [Highcharts]='Highcharts'
                              [options]='statusByCategoryOption'></highcharts-chart>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'Job'">
        <div *ngIf='reportValues && reportValues.WorkOrderJob' class='printChartPreview'>
          <div class='row'>
            <p class='bold mt-3 col-md-6'>Work Orders by Job</p>
            <div class='w-50 d-print-none col-md-6'>
              <app-multi-select-jobs-dropdown [list]='statusList'
                                              (shareCheckedList)='shareCheckedListStatus($event)'
                                              (shareIndividualCheckedList)='shareStatusCheckedCat($event)'
                                              [deSelectCheckBox]='deSelectCheckBox'
                                              [labelText]="'Select Status'"
                                              [statusIds]='statusIds'>
              </app-multi-select-jobs-dropdown>
            </div>
          </div>
          <highcharts-chart [Highcharts]='Highcharts' (click)='onPointSelect($event)'
                            [options]='completedOption'></highcharts-chart>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'detail'">
        <div class='printChartPreview'>
          <div *ngIf='reportValues && reportValues.BackLogs && !isCommunityManager' class='mb-4' style='height: 700px'>
            <p class='bold'>Work Order Distribution</p>
            <highcharts-chart id='diagram' name='backLogOption' [Highcharts]='Highcharts'
                              (click)='onPointSelect($event)' [options]='backLogOption'>
            </highcharts-chart>
          </div>
          <div *ngIf='reportValues && reportValues.WorkOrderOwners && !isCommunityManager' style='height: 700px'
               class='mb-4'>
            <p class='bold'>Work Orders by Owner</p>
            <highcharts-chart id='OwnerOption' [Highcharts]='Highcharts' (click)='onPointSelect($event)'
                              [options]='ownerOption'></highcharts-chart>
          </div>
          <div *ngIf='reportValues && reportValues.workOrderCategories' class='mb-4' style='height: 700px'>
            <p class='bold'>Work Orders by Categories</p>

            <highcharts-chart [Highcharts]='Highcharts' (click)='onPointSelect($event)' id='categoryOption'
                              [options]='categoryOption'>
            </highcharts-chart>
          </div>
          <div *ngIf='reportValues && reportValues.WorkOrderJob' style='height: 700px' class='mb-3'>
            <p class='bold'>Work Orders by Job</p>

            <highcharts-chart id='completedOption' (click)='onPointSelect($event)' [Highcharts]='Highcharts'
                              [options]='completedOption'>
            </highcharts-chart>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'CustomerLabels'">
        <div *ngIf='reportValues && reportValues.WorkOrderCustomerLabels' class='printChartPreview'>
          <p class='bold'>Work Orders by Customer Labels</p>
          <div class='w-50 d-print-none col-md-6'>
            <app-multi-select-dropdown [list]='statusList'
                                       (shareCheckedList)='shareCheckedListStatusOwner($event)'
                                       (shareIndividualCheckedList)='shareStatusCheckedCat($event)'
                                       [deSelectCheckBox]='deSelectCheckBox'
                                       [labelText]="'Select Status'">
            </app-multi-select-dropdown>
          </div>
          <div class='printChartPreview' *ngFor='let options of customerLabelsOptions'>
            <highcharts-chart [Highcharts]='Highcharts'
                              (click)='onPointSelect($event)'
                              [options]='options'>
            </highcharts-chart>
          </div>
        </div>
      </ng-container>

      <ng-template *ngSwitchDefault></ng-template>
    </ng-container>
  </app-report-details-table>
</div>


<!-- Print part -->

<div class='print-wrapper' [hidden]='true'>
  <button class='btn btn-oclm print-btn d-print-none button-print' (click)='onPrint()'>
    Print
  </button>
  <button class='btn print-btn d-print-none btn-secondary' (click)='goBack()'>
    Back
  </button>
  <div class='report-print-logo mt-4 mb-5 d-none d-print-block'>
    <img src='../../../../assets/image/small_logo.jpg'
         alt='logo' class='img-responsive' />
  </div>
  <div #printContent>
    <div class='table-responsive'>
      <table class='print-report-summary'>
        <tr>
          <td style='width: 20%' class='col-lg-2'>Report:</td>
          <td style='width: 80%' class='col-lg-10'>Work Orders by Category</td>
        </tr>
        <tr>
          <td style='width: 20%' class='col-lg-2'>Printed On:</td>
          <td style='width: 80%' class='col-lg-10'>{{ printedDate | date: 'MMM d, y, h:mm a' }}</td>
        </tr>
      </table>
    </div>
    <h6 class='bold' style='margin:20px 0;'>Applied Filters:</h6>
    <div class='table-responsive'>
      <table class='print-report-summary'>
        <tr>
          <td style='width: 20%'>Date Range</td>
          <td style='width: 80%'>{{ DateFrom ? DateFrom + '-' + DateTo : 'All time' }}</td>
        </tr>
        <tr>
          <td style='width: 20%'>Categories:</td>
          <td style='width: 80%'>
            <ng-container *ngIf='WorkCategory; else list'>
            <span *ngFor='let name of workCategoryListForPrint; let last = last'>
              {{ name.Name }}
              <ng-container *ngIf='!last'>,</ng-container>&nbsp;
            </span>
            </ng-container>
            <ng-template #list>
            <span *ngFor='let name of workCategoryList; let last = last'>
              {{ name.Name }}
              <ng-container *ngIf='!last'>,</ng-container>&nbsp;
            </span>
            </ng-template>
          </td>
        </tr>
        <tr>
          <td style='width: 20%' class='col-lg-2'>Statuses:</td>
          <td style='width: 20%' class='col-lg-10'>
            <ng-container *ngIf='statusIds; else stList'>
           <span *ngFor='let status of statusIdsForPrint; let last = last'>
                {{ status['Name'] }}
             <ng-container *ngIf='!last'>,</ng-container>&nbsp;
           </span>
            </ng-container>
            <ng-template #stList>
            <span *ngFor='let status of statusList; let last = last'>
               {{ status['Name'] }}
              <ng-container *ngIf='!last'>,</ng-container>&nbsp;
            </span>
            </ng-template>
          </td>
        </tr>
        <tr>
          <td style='width: 20%' class='col-lg-2'>Canned Response</td>
          <td style='width: 80%' class='col-lg-10'>
            <ng-container *ngIf='cannedResponseIds; else cannedList'>
            <span *ngFor='let status of cannedResponseForPrint; let last = last'>
              {{ status.Name }}
              <ng-container *ngIf='!last'>,</ng-container>&nbsp;
            </span>
            </ng-container>
            <ng-template #cannedList>
            <span *ngFor='let status of cannedResponseList; let last = last'>
              {{ status.Name }}
              <ng-container *ngIf='!last'>,</ng-container>&nbsp;
            </span>
            </ng-template>
          </td>
        </tr>
      </table>
    </div>
    <app-report-details-table
      version='print'
      [modelDetails]='modelDetails'>
      <ng-container [ngSwitch]='chartName'>
        <ng-container *ngSwitchCase="'Categories'">
          <!--str 66 change && on || 03/06-->
          <ng-container *ngIf='
            isCategory === true &&
              reportValues ||
              reportValues.workOrderCategories; else statusTmpl'>
            <div class='chart-container'>
              <div class='printChartPreview' *ngFor='let options of customerLabelsOptions'>
                <highcharts-chart [Highcharts]='Highcharts'
                                  (click)='onPointSelect($event)'
                                  [options]='options'>
                </highcharts-chart>
              </div>
            </div>
          </ng-container>
          <ng-template #statusTmpl>
            <div *ngIf='statusData' class='printChartPreview'>
              <highcharts-chart (click)='onPointSelect($event)' [Highcharts]='Highcharts'
                                [options]='statusByCategoryOption'></highcharts-chart>
            </div>
          </ng-template>
        </ng-container>

        <div class='charts-container'>
          <div class='charts-item' *ngFor='let chart of categoriesCharData'>
            <highcharts-chart [Highcharts]='Highcharts'
                              [options]='chart'>
            </highcharts-chart>
          </div>
        </div>

      </ng-container>
    </app-report-details-table>
  </div>
</div>
