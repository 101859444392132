import { UploadDocument, WorkOrderDetail } from './work-order';
import { Moment } from 'moment';
import { DynamicFieldsModel } from './dynamic-model';

export class WorkOrderCommunityModel {
  CompletedBy: string;
  WorkRequestID: string;
  JobName: string;
  CustomerId: number;
  WorkCategory: string;
  Address: string;
  CreatedBy: string;
  DynamicFields: DynamicFieldsModel[];
  Latitude: number;
  Longitude: number;
  CannedResponseId: number;
  CompletedAddress: string;
  StartDate: Moment | string | Date;
  EndDate: Date = new Date();
  CannedResponseText: string;
  SuperVisorId: string;
  CustomerName: string;
  Notes: string;
  AdditionalComment: string;
  WorkStatusText: string;
  FieldAgentId: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  ContactEmail: string;
  WorkId: string;
  UploadedDocPathsAfter: string;
  UploadedDocPathsBefore: string;
  uploadAfterDocument: UploadDocument;
  uploadBeforeDocument: UploadDocument;
  CompletedOn: string;
  WorkOrderNotesDetail: WorkOrderDetail[];
  NotesVal: string;
}
