import {
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {
  SortableTableDirective,
  SortEvent,
} from 'src/app/shared/components/sortable-table/sortable-table.directive';
import { User } from 'src/app/shared/models/user';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { AuthenticationService } from '../../helpers/services/authentication.service';
import { LoaderService } from '../../helpers/services/loader.service';
import { APIResponse, WorkOrderListByStatus } from '../../../shared/models/api-response';
import { SupervisorService } from '../../../modules/oclm-supervisor/services/supervisor.service';
import { finalize } from 'rxjs/operators';
import { AdminService } from '../../../modules/oclm-admin/admin.service';
import { CmService } from '../../../modules/oclm-community-manager/services/cm.service';
import { FieldAgentService } from '../../../modules/oclm-field-agent/services/field-agent.service';
import { CalendarTable } from '../../../shared/models/calendarTableModel';
import { ItemPerPage } from '../../../shared/models/work-order';

@Component({
  selector: 'app-calendar-table',
  templateUrl: './calendar-table.component.html',
  styleUrls: ['./calendar-table.component.css'],
})
export class CalendarTableComponent implements OnInit, OnChanges {
  scheduleWorkOrderTableData: CalendarTable[];
  pageNo: number;
  pageSize;
  calendarListTableLength = 0;
  currentUser: User;
  @Input() searchText: string;
  @Input() jobIds: string;
  @Input() fieldAgentId: string;

  @Input() dateFrom: string;
  @Input() dateTo: string;
  @Input() iscompleteByMyself: string;

  @ViewChildren(SortableTableDirective)
  headers: QueryList<SortableTableDirective>;
  sortedColumn: string;
  isSortedDesc: string;

  scheduledTableSearch: string;
  scheduledTableFieldAgentId: string;
  scheduledTableJobIds: string;
  scheduledTableDateTo: string;
  scheduledTableDateFrom: string;
  scheduledTableIscompleteByMyself: string;
  itemPerPage: ItemPerPage[] = ConstantsService.ItemsPerPage;

  constructor(
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private adminService: AdminService,
    private faService: FieldAgentService,
    private cmService: CmService,
    private supervisorService: SupervisorService,
    private loaderService: LoaderService
  ) {
    this.pageNo = this.commonService.CurrPaginationInboxIndexValue;
    this.pageSize = this.commonService.CurrentInboxPageSize;
    this.authenticationService.currentUser.subscribe((value) => {
      this.currentUser = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.searchText ||
      this.jobIds !== undefined ||
      this.fieldAgentId !== undefined ||
      this.dateFrom ||
      this.dateTo ||
      this.iscompleteByMyself
    ) {
      this.scheduledTableSearch = this.searchText ? this.searchText : '';
      this.scheduledTableJobIds = this.jobIds ? this.jobIds : '';
      this.scheduledTableDateFrom = this.dateFrom ? this.dateFrom : '';
      this.scheduledTableDateTo = this.dateTo ? this.dateTo : '';
      this.scheduledTableIscompleteByMyself = this.iscompleteByMyself
        ? this.iscompleteByMyself
        : '';
      this.scheduledTableFieldAgentId = this.fieldAgentId
        ? this.fieldAgentId
        : '';
      this.getWorkOrderListByStatusKeyByRole();
    }
  }

  ngOnInit() {
    this.getWorkOrderListByStatusKeyByRole();
  }

  getWorkOrderListByStatusKeyByRole() {

    this.loaderService.isLoading.next(true);
    this.commonService.setCurrentStatus({ Status: 'Schedule' });

    if (this.currentUser.Role === ConstantsService.User_Role.SuperVisor) {
      this.getSupervisorWOByStatusKey();
    }
    if (this.currentUser.Role === ConstantsService.User_Role.FieldAgent) {
      this.getFieldAgentWOByStatusKey();
    }
    if (this.currentUser.Role === ConstantsService.User_Role.Admin) {
      this.getAdminWOByStatusKey();
    }
    if (this.currentUser.Role === ConstantsService.User_Role.CommunityManager) {
      this.getClientWOByStatusKey();
    }

  }

  pageChanged(event: PageChangedEvent): void {
    this.pageNo = event.page;
    this.pageSize = event.itemsPerPage;
    this.commonService.setCurrentInboxPageSize({ pageSize: this.pageSize });
    this.commonService.setCurrentPaginationInboxvalue({ pageNo: this.pageNo });
    this.getWorkOrderListByStatusKeyByRole();
  }

  selectItemPerPage(value) {
    this.pageSize = value;
    this.commonService.setCurrentInboxPageSize({ pageSize: value });
    this.getWorkOrderListByStatusKeyByRole();
  }

  setWorkIdInSession(workId, workOrderType) {
    this.commonService.setCurrentWorkId({ selectedWorkId: workId });
    if (workOrderType == 'Manual') {
      this.commonService.navigateByURL(
        `${
          this.currentUser && this.currentUser.Role.toLocaleLowerCase()
        }/home/details/${workId}/${workOrderType}`
      );
    } else {
      this.commonService.navigateByURL(
        `${
          this.currentUser && this.currentUser.Role.toLocaleLowerCase()
        }/home/details/${workId}/${workOrderType}`
      );
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.sortedColumn = column.toString();
      this.isSortedDesc = direction;
      this.getWorkOrderListByStatusKeyByRole();
    } else {
      this.sortedColumn = column.toString();
      this.isSortedDesc = direction;
      this.getWorkOrderListByStatusKeyByRole();
    }
  }

  getAdminWOByStatusKey(): void {
    this.adminService
      .getWorkOrderListByStatus(
        'Schedule',
        this.pageNo,
        this.pageSize,
        this.searchText,
        true,
        this.sortedColumn,
        this.isSortedDesc,
        this.dateFrom,
        this.dateTo
      )
      .pipe(
        finalize(() => this.loaderService.isLoading.next(false))
      )
      .subscribe(
        (res: APIResponse) => {
          this.loaderService.isLoading.next(false);
          if (res && res.Data && res.Success) {
            this.scheduleWorkOrderTableData = res.Data.result;
            this.calendarListTableLength = res.Data.TotalRecordCount.Schedule;
          }
        }
      );
  }

  getClientWOByStatusKey(): void {
    this.cmService
      .getWorkOrderListByStatus(
        {
          statusKey: 'Schedule',
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          searchText: this.searchText,
          sortByWorkOrder: true,
          jobIds: null,
          sortedColumn: this.sortedColumn,
          isSortedDesc: this.isSortedDesc
        }
      )
      .pipe(
        finalize(() => this.loaderService.isLoading.next(false))
      )
      .subscribe(
        (res: APIResponse) => {
          this.loaderService.isLoading.next(false);
          if (res && res.Data && res.Success) {
            this.scheduleWorkOrderTableData = res.Data.result;
            this.calendarListTableLength = res.Data.TotalRecordCount.Schedule;
          }
        }
      );
  }

  getSupervisorWOByStatusKey(): void {
    this.supervisorService
      .getWorkOrderListByStatus(
        'Schedule',
        this.pageNo,
        this.pageSize,
        this.searchText,
        true,
        null,
        this.sortedColumn,
        this.isSortedDesc,
        this.dateFrom,
        this.dateTo,
      )
      .pipe(
        finalize(() => this.loaderService.isLoading.next(false))
      )
      .subscribe(
        (res: APIResponse) => {
          this.loaderService.isLoading.next(false);
          if (res && res.Data && res.Success) {
            this.scheduleWorkOrderTableData = res.Data.result;
            this.calendarListTableLength = res.Data.TotalRecordCount.Schedule;
          }
        }
      );
  }

  getFieldAgentWOByStatusKey(): void {
    this.faService
      .getWorkOrderListByStatus(
        'Schedule',
        this.pageNo,
        this.pageSize,
        this.searchText,
        true,
        null,
        null,
        this.sortedColumn,
        this.isSortedDesc,
        this.dateFrom,
        this.dateTo
      )
      .pipe(
        finalize(() => this.loaderService.isLoading.next(false))
      )
      .subscribe(
        (res: WorkOrderListByStatus) => {
          this.loaderService.isLoading.next(false);
          this.scheduleWorkOrderTableData = res.result;
          this.calendarListTableLength = res.TotalRecordCount.Schedule;
        }
      );
  }

}
