import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { UserShort } from '../models/userShort';
import { User } from '../../../../../shared/models/user';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APIResponse, UserList } from 'src/app/shared/models/api-response';
import { ConstantsService } from 'src/app/shared/services/constants.service';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  getListOfUsers(
    pageNo,
    pageSize,
    searchText,
    isSortedDesc,
    role = ''
  ): Observable<UserList> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.ProfileList}`;
    let params = new HttpParams();
    if (role === 'Client') {
      params = params.append('role', role);
    } else if (searchText === '') {
      params = params.append('pageNo', pageNo);
      params = params.append('pageSize', pageSize);
      params = params.append('isSortedDesc', isSortedDesc);
      params = params.append('role', role);
    } else {
      params = params.append('searchText', encodeURIComponent(searchText));
      params = params.append('pageNo', pageNo);
      params = params.append('pageSize', pageSize);
      params = params.append('isSortedDesc', isSortedDesc);
      params = params.append('role', role);
    }
    return this.http.get<UserList>(API_URL, { params })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  blockUser(id?: number): Observable<UserShort> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.BlockUser}`;
    return this.http.post<UserShort>(`${API_URL}?userId=${id}`, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getUserById(id): Observable<UserShort> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.GetUserById}`;
    return this.http.get<UserShort>(`${API_URL}?userId=${id}`).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getUserLogin(id: number): Observable<User> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.GetUserLogin}`;
    return this.http.get<User>(`${API_URL}?id=${id}`).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  updateUser(UserId, formData): Observable<UserShort> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.EditProfile}`;
    return this.http
      .post<UserShort>(`${API_URL}`, { UserId, ...formData.value })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createUser(form): Observable<UserShort> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.CreateProfile}`;
    return this.http.post<UserShort>(API_URL, form).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  sendInvitationLink(userId): Observable<APIResponse> {
    const API_URL = `${environment.apiUrl}${ConstantsService.User_APIURL.SendInvite}`;
    return this.http.post<APIResponse>(`${API_URL}?userId=${userId}`, {});
  }
}
